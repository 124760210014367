import React, { useEffect, useState } from "react";
import AppButton from "../../../components/Button/AppButton";

import { RxCross2 } from "react-icons/rx";
import { BiEditAlt } from "react-icons/bi";
import { useActivityApis } from "./ActivityApis";
import PropTypes from "prop-types";
import { Popconfirm, notification } from "antd";

import { TimePicker } from "antd";
import moment from "moment/moment";
import axios from "axios";
import { Field, Form, Formik } from "formik";


const ListItemActivity = ({
  task_id,
  task_end_time,
  task_start_time,
  task_sub_category_name,
  task_status,
  setActivityState,
  task_sub_category_image,
  task_assigned_member_name,
  user,
  activityState,
  task
}) => {
  const [activityTask, setActivityTask] = useState({
    editing: false,
    editTaskId: "",
    startTime: null,
    endTime: null,
    editRecord: {}
  });
  const { doMarkAsComplete, removeTaskApis, onTimeUpdateStatusTask } =
    useActivityApis();

  const onMarkAsComplete = (statusNumber) => {
    let data = {
      user_id: user?.id,
      task_id: task_id,
      task_status: statusNumber,
      date: moment().format("YYYY-MM-DD"),
      max_streak_date: moment().utc().format("YYYY-MM-DD"),
      task_date_time: moment().format("YYYY-MM-DD hh:mm:ss")

    };
    doMarkAsComplete(data)
      .then((res) => {
        if (res.status === 200) {
          const lastStreakDate = localStorage.getItem('last_streak_date')
          let {

            milestone_achievements: { milestone_achievement_flag },

          } = res?.data;
          if (
            res.data?.milestone_achievements?.milestone_achievement_flag !==
            0 &&
            statusNumber !== 0
          ) {
            setActivityState((prev) => ({
              ...prev,
              render: true,
              IsGamificationOpen: true,

              earnedType: "milestone",
              congratesMessage: `🏁 Milestone Unlocked! 🌟 You've reached a significant milestone on your journey – 10 tasks completed! Enjoy your well-earned bonus points and keep reaching for the stars! 🚀🎉`,
            }));
          } else if (res.data?.max_streak_flag !== 0 && lastStreakDate != moment().format('YYYY-MM-DD') && statusNumber !== 0) {
            setActivityState((prev) => ({
              ...prev,
              render: true,
              IsGamificationOpen: true,

              earnedType: "maxStreak",
              congratesMessage: `🔥 Streak On! 🌟 Your dedication pays off with bonus points for keeping the streak alive. Keep up the winning streak! 💪🎉`,
            }));
            localStorage.setItem('last_streak_date', moment().format('YYYY-MM-DD'))
          } else if (
            res.data?.category_completion_flag !== 0 &&

            statusNumber !== 0
          ) {
            setActivityState((prev) => ({
              ...prev,
              render: true,
              IsGamificationOpen: true,
              earnedType: "Category",
              congratesMessage: `🏆 Category Conqueror! 🌟 You've aced all the tasks in the  ${res.data?.category_completion_name} category. Enjoy your well-deserved bonus points and keep dominating those categories! 🚀🎉`,
            }));
          } else if (

            statusNumber !== 0
          ) {
            setActivityState((prev) => ({
              ...prev,
              render: true,
              IsGamificationOpen: true,
              earnedType: "Task",
              congratesMessage: `🏆 Task Completed! 🌟 You've earned ${res.data?.todays_task_completion_point} points for conquering another task. Keep up the great work!`,
            }));
          } else {
            setActivityState((prev) => ({
              ...prev,
              render: true,
            }));
          }

          // {category_completion_flag
          //   :
          //   1,
          //   category_completion_name
          //   :
          //   "Business & Career",
          //   max_streak_flag
          //   :
          //   0,
          //   milestone_achievements
          //   :
          //   {milestone_achievement_flag: 0},
          //   todays_task_completion_flag
          //   :
          //   1,
          //   "todays_task_completion_point": "1"}

          // setActivityState((prev) => ({ ...prev, render: true }));
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };



  const onRemoveHandler = (Id) => {
    let data = { user_id: user?.id, task_id: Id };

    const eventData = activityState?.AllEvents?.find((item) => parseInt(item.description) === Id)

    if (Id) {

      removeTaskApis(data)
        .then((res) => {
          if (res.status === 200) {
            if (eventData) {

              const url = `https://www.googleapis.com/calendar/v3/calendars/${eventData?.organizer?.email}/events/${eventData?.id}`;

              try {
                const response = axios.delete(url, {

                  headers: {
                    'Authorization': `Bearer ${localStorage.getItem("access_token")}`,
                  },
                });

                if (response.ok) {
                  console.log(`Event with ID  deleted successfully.`);
                } else {
                  console.error(`Failed to delete event: ${response.statusText}`);
                }
              } catch (error) {
                console.error('Error deleting event:', error);
              }
            }
            setActivityState((prev) => ({ ...prev, render: true }));
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    }


  };

  const onChange = (time, timeString, type) => {
    setActivityTask((prevActivityTask) => ({
      ...prevActivityTask,
      [type]: moment(timeString, "h:mm A"),
    }));
  };

  useEffect(() => {
    if (task_end_time && task_start_time) {
      setActivityTask((prev) => ({
        ...prev,
        startTime: moment(task_start_time, "h:mm A"),
        endTime: moment(task_end_time, "h:mm A"),
      }));
    }
  }, [task_end_time, task_start_time]);

  let timeStatusNotification = null; // Initialize the notification reference

  const onTimeUpdateTask = (task_id) => {
    if (
      activityTask.endTime >= activityTask.startTime &&
      activityTask.startTime !== null &&
      activityTask.endTime !== null
    ) {
      let data = {
        task_id: task_id,
        user_id: user?.id,
        task_start_time: activityTask?.startTime?._i,
        task_end_time: activityTask?.endTime?._i,
      };

      // onTimeUpdateStatusTask(data)
      //   .then((res) => {
      //     if (res.status === 200) {
      //       setActivityTask((prev) => ({
      //         ...prev,
      //         editTaskId: "",
      //         editing: false,
      //       }));
      //       setActivityState((prev) => ({ ...prev, render: true }));
      //       notification["success"]({
      //         message: "Task time updated successfully",
      //         style: {
      //           fontFamily: 'Montserrat, sans-serif',
      //         },
      //       })
      //     }
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   });
    } else {
      if (timeStatusNotification) {
        // If the notification already exists, update it
        timeStatusNotification.update({
          message: "End time must be greater than start time",
          style: {
            fontSize: "8px",
          },
        });
      } else {
        // If the notification doesn't exist, create it
        timeStatusNotification = notification.warning({
          message: "End time must be greater than start time",
          style: {
            fontSize: "8px !important",
          },
        });
      }
    }
  };

  const getStartDate = () => {
    setTimeout(() => {
      return moment(activityTask.startTime, "h:mm A");
    }, 5);
  };

  useEffect(() => {
    // Log the updated startTime and endTime
    setActivityTask((prev) => ({
      ...prev,
    }));
  }, [activityTask.startTime, activityTask.endTime]);

  const initialValues = {
    startTime: activityTask.startTime,
    endTime: activityTask.endTime,
  };

  const timeSubmitTask = (values) => {
    if (
      activityTask.endTime >= activityTask.startTime &&
      activityTask.startTime !== null &&
      activityTask.endTime !== null
    ) {
      let data = {
        task_id: task_id,
        user_id: user?.id,
        task_start_time: activityTask?.startTime?._i,
        task_end_time: activityTask?.endTime?._i,
      };

      onTimeUpdateStatusTask(data)
        .then((res) => {
          if (res.status === 200) {
            setActivityTask((prev) => ({
              ...prev,
              editTaskId: "",
              editing: false,
            }));
            setActivityState((prev) => ({ ...prev, render: true }));
            notification["success"]({
              message: "Task time updated successfully",
              style: {
                fontFamily: 'Montserrat, sans-serif',
              },
            })
          }
        })
        .catch((error) => {
          console.log(error);
        });

    } else {
      if (timeStatusNotification) {
        // If the notification already exists, update it
        timeStatusNotification.update({
          message: "End time must be greater than start time",
          style: {
            fontSize: "8px",
          },
        });
      } else {
        // If the notification doesn't exist, create it
        timeStatusNotification = notification.warning({
          message: "End time must be greater than start time",
          style: {
            fontSize: "8px !important",
          },
        });
      }
    }
  };




  return (
    <li
      key={task_id}
      className="p-3 bg-background mb-2  flex justify-between rounded-[15px]"
    >
      <div className="flex  items-center gap-4">
        <img
          src={`${process.env.REACT_APP_FILE_URL_TASKS}/${task_sub_category_image}`}
          alt="sdf"
          className="activity_image w-[60px] h-[55px]"
        />
        <div className="">
          <h3 className="text-lg sm:font-medium md:font-semibold lg:font-semibold font-medium">
            {task_sub_category_name}
            <span className="text-sm text-primary-dark">
              {task_assigned_member_name
                ? ` - ${task_assigned_member_name}`
                : ""}
            </span>
          </h3>
          {activityTask?.editing && activityTask?.editTaskId === task_id ? (
            <div className="timeEdit_activity  font-medium text-lg">
              <Formik initialValues={initialValues} onSubmit={timeSubmitTask} enableReinitialize>
                <Form >
                  <div className="flex pb-2">
                    <div>
                      <Field name="startTime">
                        {({ field, form }) => (
                          <TimePicker
                            {...field}
                            use12Hours
                            format="h:mm A"
                            placeholder="Start Time"
                            value={field.value ? moment(field.value) : null} // Use defaultValue
                            onSelect={(time) => {
                              const timeString = time?.format("h:mm A");

                              onChange(time, timeString, "startTime");
                            }}
                          />
                        )}
                      </Field>
                    </div>
                    -
                    <div>

                      <Field name="endTime">
                        {({ field, form }) => (
                          <TimePicker
                            {...field}
                            use12Hours
                            format="h:mm A"
                            placeholder="Select End Time"
                            value={field.value ? moment(field.value) : null} // Use defaultValue
                            onSelect={(time) => {
                              const timeString = time?.format("h:mm A");
                              onChange(time, timeString, "endTime");
                            }}
                          />
                        )}
                      </Field>
                    </div>
                  </div>
                  <>
                    <AppButton
                      btnText="Save"
                      btnType={"Submit"}
                      className="bg-primary rounded border border-1 md:border-2 lg:border-2 border-primary text-white py-1 px-2 me-2"
                    // onClick={() => onTimeUpdateTask(task_id)}
                    />
                    <AppButton
                      btnText="Cancel"
                      btnType={"button"}
                      className="border-1 md:border-2 border lg:border-2  rounded border-primary text-primary py-1 px-2"
                      onClick={() => {
                        setActivityTask((prev) => ({
                          ...prev,
                          editing: false,
                        }));
                      }}
                    />
                  </>

                </Form>
              </Formik>

            </div>
          ) : (
            <>
              {task_start_time && task_end_time ? (
                <>
                  <div className="text-sm lg:font-medium md:font-medium sm:font-normal font-normal  flex text-paragraph gap-2">
                    {task_start_time} - {task_end_time}
                    {task_status !== "2" && task_status !== "1"
                      && activityState?.selectedDate >= moment().format("yyyy-MM-DD") && <span
                        className="h-[20px] w-[20px] bg-primary-dark rounded-full text-white flex items-center justify-center"
                        onClick={() => {
                          setActivityTask((prev) => ({
                            ...prev,
                            editing: !prev.editing,
                            editTaskId: task_id,
                            editRecord: task
                          }));
                        }}
                      >
                        <BiEditAlt />
                      </span>}

                  </div>
                </>
              ) : (
                "Select Time Range"
              )}
            </>
          )}

          <div className="gap-2 flex btn-activity pt-2">



            <>
              {task_status === "1" ? (
                <AppButton
                  btnText="completed!"
                  btnType={"button"}
                  onClick={() => onMarkAsComplete(0)}
                  className="bg-primary-lighter rounded border  border-primary-lighter text-primary-dark  py-1 px-2"
                />
              ) : task_status === "2" ? (
                <AppButton
                  btnText="Cancelled"
                  btnType={"button"}
                  // onClick={() => onMarkAsComplete(0)}
                  className=" rounded   text-red-500 px-1 "
                />
              ) : !activityTask?.editing && (task_id || activityTask?.editTaskId !== task_id) ? (
                <>
                  {activityState?.TaskActivitylist?.[0]?.task_date <=
                    moment().format("YYYY-MM-DD") && (
                      <AppButton
                        btnText="Mark as complete"
                        className="bg-primary rounded-md border-1 md:border-2 lg:border-2  border-primary text-white p-2 "
                        onClick={() => onMarkAsComplete(1)}
                      />
                    )}
                  {task_assigned_member_name?.length === 0 && activityState?.selectedDate >= moment().format("yyyy-MM-DD") && (
                    <AppButton
                      btnText="Assign"
                      className="rounded-md  border border-1 md:border-2 lg:border-2  border-primary text-primary py-1 px-2"
                      onClick={() => {
                        setActivityState((prev) => ({
                          ...prev,
                          isChooseMemberOpen: true,
                          assignTaskRecord: task_id,
                        }));
                      }}
                    />
                  )}
                </>
              ) : <></>}
            </>

          </div>
        </div>
      </div>

      <Popconfirm
        title="Are you sure want to delete this task?"
        onConfirm={() => onRemoveHandler(task_id)}
        okText={
          <span className="confirmpopUp-custom-button-style sure_btn font-montserrat">Yes</span>
        }
        cancelText={
          <span className="confirmpopUp-custom-button-style font-montserrat  ">No</span>
        }
        overlayClassName="custom-popconfirm"
      >
        <div className="bg-white shadow-customshadow cursor-pointer rounded-full h-[20px] w-[20px] flex items-center justify-center">
          <RxCross2 className="text-sm" />
        </div>
      </Popconfirm>

      {/* <div
        className="bg-white shadow-customshadow cursor-pointer rounded-full h-[20px] w-[20px] flex items-center justify-center"
        onClick={() => onRemoveHandler(task_id)}
      >
        <RxCross2 className="text-sm" />
      </div> */}
    </li>
  );
};

ListItemActivity.propTypes = {
  task_id: PropTypes.number.isRequired,
  task_end_time: PropTypes.string,
  task_start_tim: PropTypes.string,
  task_sub_category_name: PropTypes.string,
  task_status: PropTypes.string,
  setActivityState: PropTypes.func,
  user: PropTypes.object,
};

export default ListItemActivity;
