import instance from "../../AxiosFile/AxiosInstance";

export function useSocialApis() {
    const checkUserInfoThroughMedia = async (media_id, email) => {
        let res = await instance.get("/api/user_info_as_per_media_id",
            {
                media_id: media_id,
                email: email
            },

        );
        return res.data;
    };

    const socialMediaLogin = async (params) => {
        let res = await instance.post(`/api/social_media_login`, params, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
        return res.data;
    };

    return { checkUserInfoThroughMedia, socialMediaLogin }
}