import instance from "../../../AxiosFile/AxiosInstance";

export function useChatApis() {
  const addChatAttachment = async (body) => {
    let res = await instance.post("/api/chat_file_upload", body, {
      headers: {
        "Content-type": "multipart/form-data",
      },
    });
    return res.data;
  };

  return {
    addChatAttachment,
  };
}
