import React from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import AppButton from "../../../components/Button/AppButton";

import { Alert, Modal } from "antd";
import { validationSchema } from "../../../Helpers/constants";

import { forgotPassword, otpVarificationApi } from "../../../AxiosFile/ApisUrl";
import OTPInput from "react-otp-input";
import { useNavigate } from "react-router-dom";

const ModalForgot = ({ state, setState }) => {
  const [forgotForm, setForgotForm] = React.useState({
    email: "",
    error: "",
    success: "",
    loader: "",
    otpValidationScreen: false,
    otpValue: "",
    otpError: false
  });

  const navigate = useNavigate();
  const handleOk = () => {
    setState((prev) => ({ ...prev, open: false }));
    setForgotForm((prev) => ({ ...prev, otpValidationScreen: false }));
  };
  const handleCancel = () => {
    setState((prev) => ({ ...prev, open: false }));
    setForgotForm((prev) => ({ ...prev, otpValidationScreen: false }));
  };

  // handlesubmit email for
  const handleSubmitForm = (values, resetForm) => {
    setForgotForm((prev) => ({ ...prev, loader: true }));
    forgotPassword(values)
      .then((res) => {
        if (res.status === 200) {
          setForgotForm((prev) => ({
            ...prev,
            loader: false,
            otpValidationScreen: true,
            success: res.message,
          }));
          setTimeout(() => {
            setForgotForm((prev) => ({
              ...prev,
              success: "",
            }));
          }, 3000);
          resetForm();
        } else if (res.status === 404) {
          setForgotForm((prev) => ({
            ...prev,
            loader: false,
            error: res.message,
          }));
          setTimeout(() => {
            setForgotForm((prev) => ({
              ...prev,
              error: "",
            }));
          }, 3000);
        }
      })
      .catch((error) => {
        setTimeout(() => {
          setForgotForm((prev) => ({
            ...prev,
            error: "",
            loader: false,
          }));
        }, 3000);
      });
  };


  const handleChange = (otp) => {
    let value = otp
    setForgotForm((prev) => ({ ...prev, otpValue: value }))
  }

  const onVarify = () => {
    if (forgotForm?.otpValue?.length === 4) {
      let data = { email: forgotForm.email, otp: forgotForm.otpValue }
      otpVarificationApi(data).then((res) => {
        if (res?.data?.status === 200) {
          navigate("/reset", { state: { email: forgotForm.email } })
          setForgotForm((prev) => (
            { ...prev, email: "" }
          ))
        } else {
          setForgotForm((prev) => ({ ...prev, otpError: true }))
        }
      }).catch((error) => {
        console.log(error, "error")
      })
    }
  }



  return (
    <Modal
      open={state?.open}
      title=""
      onOk={handleOk}
      destroyOnClose
      width={580}
      height={500}
      style={{ display: "flex", width: "500px", alignItems: "center", justifyContent: "center" }}
      onCancel={handleCancel}
      closeIcon={false}
      footer={[
        <div className="w-[400px]">
          {!forgotForm?.otpValidationScreen ? <div className="w-full text-center">

            <Formik
              initialValues={{ email: "" }}
              validationSchema={validationSchema}
              onSubmit={(values, { resetForm }) => {
                handleSubmitForm(values, resetForm);
                // resetForm();
              }}
            >
              {({ isSubmitting, setFieldValue, values }) => (
                <Form>
                  <div className="pb-4">
                    <h1 className="text-lg font-semibold">Forgot Password?</h1>
                    <p className="text-paragraph text-sm pb-6 pt-2">
                      No worries, we’ll send you reset instructions.
                    </p>
                    {forgotForm?.success ? (
                      <div className="py-4 text-start">
                        <Alert
                          message={`${forgotForm?.success}`}
                          type="success"
                          showIcon
                        />
                      </div>
                    ) : forgotForm?.error ? (
                      <div className="py-4 text-start">
                        <Alert
                          message={`${forgotForm?.error}`}
                          type="error"
                          showIcon
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="mb-4 flex flex-col justify-start items-start">
                      <Field
                        type="email"
                        id="email"
                        value={values.email}
                        name="email"
                        placeholder="Email Address"
                        onChange={(e) => {
                          setFieldValue("email", e.target.value);
                          setForgotForm((prev) => ({
                            ...prev, email: e.target.value
                          }))
                        }}
                        className="w-full px-3 py-2 rounded-md"
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="text-red-500 text-sm pt-2 ps-2 formik-error"
                      />
                    </div>

                    <div className="w-full text-center">
                      <AppButton
                        btnText="Send OTP"
                        disabled={forgotForm?.loader ? true : false}
                        btnType="Submit"
                        className={`bg-ternary font-semibold w-full text-white py-[8px] rounded-2xl ${isSubmitting ? "opacity-50 cursor-not-allowed" : ""
                          }`}
                      />
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div> : <div className="w-full text-center">
            <h3 className="text-lg lg:text-xl md:text-xl  font-medium">Verify Password</h3>
            <p className="text-base text-paragraph">Enter the verification code we just sent you on your email address.</p>
            <div className="otp-inputs flex items-center justify-center w-100 py-4">

              <OTPInput
                value={forgotForm.otpValue}
                onChange={handleChange}
                numInputs={4}
                isInputNum={true}
                focusStyle={{
                  border: "1px solid red !important"
                }}
                renderSeparator={<span> </span>}
                renderInput={(props) => <input {...props} />}
              />
            </div>
            {forgotForm?.otpError && <p className="block text-rose-500 text-sm">Please enter valid  Otp</p>}

            <div className="text-base">Don’t receive a code?
              <span className="text-primary font-semibold cursor-pointer"> Resend</span></div>
            <AppButton btnText="Varify " btnType="submit" disabled={false} onClick={() => onVarify()} className="bg-ternary text-white w-[100%] py-2 rounded mt-4" />
          </div>}
        </div>

      ]}
    >
      {/* Content of the modal */}
    </Modal>
  );
};

export default ModalForgot;
