import { Modal, TimePicker, notification } from 'antd'
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import React, { useEffect, useState } from 'react'
import { useChallange } from './ChallangesApis';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

// isInfoOpen

const InfoModal = ({ state,
    setState,
    challengeID,
    user,
    stateNew,
    time
}) => {

    const navigate = useNavigate()

    const { unJoinHandle, joinedChallange,
        onResetHandler } = useChallange()

    const initialValues = {
        challenge_time: '', // Add any other form fields you might need
    };

    const [infoScreen, setInfoScreen] = useState({
        showResetScreen: false,
        isTimeUpdateShow: false

    })

    const handleOk = () => {
        setState((prev) => ({ ...prev, isInfoOpen: false }))
        setInfoScreen((prev) => ({ ...prev, isTimeUpdateShow: false, showResetScreen: false }))
    }
    const handleCancel = () => {
        setState((prev) => ({ ...prev, isInfoOpen: false }))
        setInfoScreen((prev) => ({ ...prev, isTimeUpdateShow: false, showResetScreen: false }))
    }





    const validationSchema = Yup.object({
        // challenge_time: Yup.string()
        //     .required('Please select a time'),
        // Add other validation rules for additional form fields if needed
    });

    const handleSubmit = (values, { resetForm }) => {
        if (infoScreen?.isTimeUpdateShow) {
            const params = {
                user_id: user?.id,
                challenge_id: challengeID,
                challenge_time: moment(values?.challenge_time?.$d).format("hh:mm A")
            }

            onResetHandler(params).then((res) => {
                if (res.status === 200) {
                    if (res.status === 200) {
                        notification["success"]({
                            description: res.message,
                            style: {
                                fontFamily: 'Montserrat, sans-serif',
                                fontSize: "14px"
                            },
                        });
                    } else if (res.status === 500) {
                        notification["warning"]({
                            description: res.message,
                            style: {
                                fontFamily: 'Montserrat, sans-serif',
                                fontSize: "14px"
                            },
                        });
                    }
                    setState((prev) => ({ ...prev, isInfoOpen: false }))
                    setInfoScreen((prev) => ({ ...prev, isTimeUpdateShow: false }))


                    resetForm();
                    // }
                }
            }).catch((error) => {
                console.log(error, "error");
            })
        } else {

            const params = {
                user_id: user?.id,
                challenge_id: challengeID,
                user_challenge_joinned_date: moment().format("YYYY-MM-DD hh:mm:ss"),
                challenge_time: moment(values?.challenge_time?.$d).format("hh:mm A")
            }

            joinedChallange(params).then((res) => {
                // if (res.status === 200) {
                if (res.status === 200) {
                    notification["success"]({
                        description: res.message,
                        style: {
                            fontFamily: 'Montserrat, sans-serif',
                            fontSize: "14px"
                        },
                    });
                } else if (res.status === 500) {
                    notification["warning"]({
                        description: res.message,
                        style: {
                            fontFamily: 'Montserrat, sans-serif',
                            fontSize: "14px"
                        },
                    });
                }
                setState((prev) => ({ ...prev, isInfoOpen: false }))
                setInfoScreen((prev) => ({ ...prev, showResetScreen: false }))


                resetForm();
                // }
            }).catch((error) => {
                console.log(error, "error");
            })
        }


    };

    const unJoinHandler = () => {
        const params = {
            user_id: user?.id,
            challenge_id: challengeID,
            user_challenge_joinned_date: moment().format('YYYY-MM-DD hh:mm:ss'),
            challenge_time: ''
        }
        unJoinHandle(params).then((res) => {
            if (res.status === 200) {
                navigate("/challenges")
                notification["success"]({
                    description: res.message,
                    style: {
                        fontFamily: 'Montserrat, sans-serif',
                        fontSize: "14px"
                    },
                });
            } else if (res.status === 500) {
                notification["warning"]({
                    description: res.message,
                    style: {
                        fontFamily: 'Montserrat, sans-serif',
                        fontSize: "14px"
                    },
                });

            }

            setState((prev) => ({ ...prev, isInfoOpen: false }))
        }).catch((error) => {
            console.log(error, "error");
        })
    }

    return (
        <Modal
            open={state.isInfoOpen}
            title=""
            onOk={handleOk}
            destroyOnClose
            width={350}
            closeIcon={false}
            onCancel={handleCancel}
            footer={false}
            style={{
                padding: "0px !important"
            }}
        >
            <div className="font-montserrat">

                {
                    infoScreen.showResetScreen ?
                        <div>
                            <h1 className="truncate" style={{ fontSize: '18px', fontWeight: '600' }}>{stateNew?.challenges_title || stateNew?.title}</h1>
                            <Formik
                                initialValues={initialValues}
                                onSubmit={handleSubmit}
                                validationSchema={validationSchema}

                            >
                                {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                                    <form onSubmit={handleSubmit}>
                                        <div>
                                            <label htmlFor="challenge_time" className="mb-1">
                                                Reminder:
                                            </label>
                                            <Field name="challenge_time">
                                                {({ field, form }) => (
                                                    <div className="mb-4 flex flex-col justify-start items-start">
                                                        <TimePicker
                                                            {...field}
                                                            value={values.challenge_time || null}
                                                            onSelect={(time) => form.setFieldValue('challenge_time', time)}
                                                            format="hh:mm A"
                                                            use12Hours
                                                            className="w-full placeholder-[#B0B0B0] rounded"
                                                            style={{ background: "#F5F5F5", border: "none" }}
                                                        />

                                                        {touched.challenge_time && errors.challenge_time && (
                                                            <div className="text-red-500 text-sm pt-2 ps-2 formik-error">
                                                                {errors.challenge_time}
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                            </Field>

                                        </div>

                                        {/* Add other form fields if needed */}

                                        <button type="submit" className='w-full border py-2 border-primary text-primary-dark rounded   font-semibold'>Join</button>
                                    </form>
                                )}
                            </Formik>
                        </div> : infoScreen.isTimeUpdateShow ?
                            <div>
                                {/* <h1 style={{ fontSize: '18px', fontWeight: '600' }}>{stateNew?.challenges_title || stateNew?.title}</h1> */}
                                <Formik
                                    initialValues={initialValues}
                                    onSubmit={handleSubmit}
                                    validationSchema={validationSchema}

                                >
                                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                                        <form onSubmit={handleSubmit}>
                                            <div>
                                                <label htmlFor="challenge_time" className="mb-1">
                                                    Reminder:
                                                </label>
                                                <Field name="challenge_time">
                                                    {({ field, form }) => (
                                                        <div className="mb-4 flex flex-col justify-start items-start">
                                                            <TimePicker
                                                                {...field}
                                                                value={values.challenge_time || null}
                                                                onSelect={(time) => form.setFieldValue('challenge_time', time)}
                                                                format="hh:mm A"
                                                                use12Hours
                                                                className="w-full placeholder-[#B0B0B0] rounded"
                                                                style={{ background: "#F5F5F5", border: "none" }}
                                                            />

                                                            {touched.challenge_time && errors.challenge_time && (
                                                                <div className="text-red-500 text-sm pt-2 ps-2 formik-error">
                                                                    {errors.challenge_time}
                                                                </div>
                                                            )}
                                                        </div>
                                                    )}
                                                </Field>

                                            </div>

                                            {/* Add other form fields if needed */}

                                            <button type="submit" className='w-full border py-2 border-primary text-primary-dark rounded   font-semibold'>Reset</button>
                                        </form>
                                    )}
                                </Formik>
                            </div> : time ? <div>
                                <div>
                                    <h1 className="truncate" style={{ fontSize: '18px', fontWeight: '600' }}>{stateNew?.challenges_title || stateNew?.title}</h1>
                                </div>
                                <div className="image-sections mb-2">
                                    {stateNew.challenges_image && <img
                                        src={
                                            stateNew?.challenges_image || stateNew?.image
                                                ? `${process.env.REACT_APP_FILE_URL_CHALLANGE}/${stateNew?.challenges_image || stateNew?.image}` : ""
                                        }
                                        alt="chllanege image"
                                        className="w-[100%] h-[200px] object-cover "
                                        style={{ background: "gray" }}
                                    />}
                                    <div className=' custom-scrollbar mt-1'
                                        style={{ minHeight: "80px", maxHeight: "140px", overflowY: "auto" }}>

                                        <p>{stateNew?.challenges_desc || stateNew?.description}</p>
                                    </div>

                                </div>


                                <div className='text-center'>

                                    <button
                                        onClick={() => setInfoScreen((prev) => ({ ...prev, showResetScreen: true }))}
                                        className='py-1 px-4 mb-2  w-full border font-semibold border-primary text-primary-dark rounded'
                                    >
                                        Join
                                    </button>

                                </div>
                            </div> :
                                <div>
                                    <div>
                                        <h1 className="truncate" style={{ fontSize: '18px', fontWeight: '600' }}>{stateNew?.challenges_title}</h1>
                                    </div>
                                    <div className="image-sections mb-2">
                                        {stateNew.challenges_image && <img
                                            src={
                                                stateNew.challenges_image
                                                    ? `${process.env.REACT_APP_FILE_URL_CHALLANGE}/${stateNew.challenges_image}` : ""
                                            }
                                            alt="chllanege image"
                                            className="w-[100%] h-[200px] object-cover "
                                            style={{ background: "gray" }}
                                        />}
                                        <div className=' custom-scrollbar mt-1'
                                            style={{ minHeight: "80px", maxHeight: "140px", overflowY: "auto" }}>
                                            <p>{stateNew?.challenges_desc}</p>
                                        </div>

                                    </div>


                                    <div className='text-center'>

                                        <button
                                            onClick={() => unJoinHandler()}
                                            className='py-1 px-4 mb-2 rounded w-full border font-semibold text-primary-dark border-primary-dark'
                                        >
                                            Leave
                                        </button>
                                        <button
                                            className='py-1 px-4 rounded w-full border font-semibold text-primary-dark border-primary-dark'
                                            onClick={() => setInfoScreen((prev) => ({
                                                ...prev, isTimeUpdateShow: true
                                            }))}
                                        >
                                            Reset Reminder
                                        </button>
                                    </div>
                                </div>
                }
            </div>
        </Modal>
    )
}

export default InfoModal
