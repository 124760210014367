import moment from "moment";

export const ScheduledNotification = (Activities) => {
  for (const item of Activities) {
    let dateStr = item.task_date + " " + item.task_start_time;
    let date = moment(dateStr, "YYYY-MM-DD hh:mm A").add(-10, "minutes");
    let timeDifference = date.diff(moment(), "milliseconds");

    if (date >= moment() && timeDifference > 0) {
      let timeOut = setTimeout(() => {}, timeDifference);
      // const scheduleNotificationCallback = (
      //   taskItem,
      //   taskTimeDifference,
      //   taskDate
      // ) => {
      //   return () => {
      //     console.log(taskTimeDifference, "time", taskDate, taskItem);
      //     // Add your notification logic here
      //   };
      // };
      // setTimeout(
      //   scheduleNotificationCallback(item, timeDifference, date),
      //   timeDifference
      // );
    }
  }
};
