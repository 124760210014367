import React from "react";
import ResetForm from "./ResetForm";
import Image from "../../assets/images/resetBg.svg";
import AuthPageImage from "../../components/AuthPagesImage/AuthPageImage";
import { HiArrowNarrowLeft } from "react-icons/hi"
import { useLocation, useNavigate } from "react-router-dom";

const Reset = () => {

  const { state } = useLocation();

  const navigate = useNavigate();
  const goBack = () => {
    navigate("/login")
  }
  return (
    <div className="w-full  ">
      <div className="w-[100%]    flex h-[100vh]">
        <div className="w-[50%]   lg:block md:hidden sm:hidden hidden  ">
          <AuthPageImage ImageUrl={Image} />
        </div>
        <div className="w-full   lg:w-[50%]  md:w-full  sm:w-full   px-4  sm:px-28 py-2 flex flex-col justify-center items-center relative">
          <div className=" flex flex-col max-w-[440px]">
            <span className="absolute top-6 cursor-pointer" onClick={() => goBack()}><HiArrowNarrowLeft /></span>
            <h2 className="py-1 font-semibold">Reset Password</h2>
            <p className="py-2 text-paragraph pb-3">
              Your new password must be different to previously used password.{" "}
            </p>
            <ResetForm state={state} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reset;
