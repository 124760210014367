import React from "react";
import goodEmoji from "../../assets/images/goodEmoji.svg"
import greatEmoji from "../../assets/images/greatEmoji.svg"
import okeyEmoji from "../../assets/images/okayEmoji.svg"
import badEmoji from "../../assets/images/BodEmoji.svg"
import teribleEmoji from "../../assets/images/terribleEmoji.svg"


const FeedbackEmojies = ({ wishingPopUpState, setWishingPopUpState }) => {
    const FeedbackReview = [
        { id: 1, img: greatEmoji, title: "Great" },
        { id: 2, img: goodEmoji, title: "Good" },
        { id: 3, img: okeyEmoji, title: "Okay" },
        { id: 4, img: badEmoji, title: "Bad" },
        { id: 5, img: teribleEmoji, title: "Terrible" },
    ];

    const addfeedbackApp = (feedbackItem) => {
        setTimeout(() => {
            setWishingPopUpState((prev) => ({ ...prev, selectedFeedBack: feedbackItem, currentPageNumber: wishingPopUpState?.currentPageNumber + 1 }))
        }, 1000);
    }
    return (
        <div className="flex flex-col items-center pt-4 pb-6 font-montserrat ">
            <h3 className="text-lg text-ternary font-montserrat text-center pb-6 font-semibold ">
                How do you feel about your progress today?
            </h3>
            <ul className="flex justify-between items-center w-full">
                {FeedbackReview?.map((emoji) => {
                    let { id, img, title } = emoji;
                    return <li key={id} onClick={() => addfeedbackApp(emoji)} className={`flex cursor-pointer items-center flex-col w-12 h-12 ${wishingPopUpState?.selectedFeedBack?.id === id && "text-primary font-semibold"} `}> <img src={img} alt="" className={wishingPopUpState?.selectedFeedBack?.id === id ? "border-2 border-primary rounded-full" : "hover:border-2 hover:border-primary-light rounded-full"} /> <span className="text-sm">{title}</span></li>
                })}
            </ul>
        </div>
    );
};

export default FeedbackEmojies;
