import instance from "../../../AxiosFile/AxiosInstance";

export function useNotification() {
  const getNotifications = async (id) => {
    let res = await instance.get(`/api/notification_list`, {
      params: {
        user_id: id,
      },
    });
    return res.data;
  };
  const storeNotification = async (body) => {
    let res = await instance.post(`/api/notification_store`, body);
    return res.data;
  };

  const acceptNotification = async (body) => {
    let res = await instance.post(`/api/assign_tasks_to_members_accept`, body);
    return res.data;
  };

  const rejectNotification = async (body) => {
    let res = await instance.post(`/api/assign_tasks_to_members_reject`, body);
    return res.data;
  };

  const acceptMemberNotification = async (body) => {
    let res = await instance.post(`/api/profile/members_accept`, body);
    return res.data;
  };

  const rejectMemberNotification = async (body) => {
    let res = await instance.post(`/api/profile/members_reject`, body);
    return res.data;
  };
  return {
    getNotifications,
    storeNotification,
    acceptNotification,
    rejectNotification,
    acceptMemberNotification,
    rejectMemberNotification,
  };
}
