import React from "react";
import logo from "../assets/images/logo.png";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBook,
  faChartLine,
  faCog,
  faCommentDots,
  faHome,
  faPlusSquare,
  faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { Drawer } from "antd";
import { iscloseSidebar } from "../Store/Slices/SidebarOpen";
import { useEffect } from "react";
import { authLogOut } from "../AxiosFile/ApisUrl";
const Sidebar = () => {
  const { isSidebarOpen } = useSelector((state) => state.isSidebarOpen);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { pathname } = useLocation();

  const onClose = () => {
    dispatch(iscloseSidebar());
  };
  const nav = [
    {
      name: "Home",
      path: "/home",
      icon: faHome,
    },
    {
      name: "Create Task",
      path: "/task",
      icon: faPlusSquare,
    },
    {
      name: "Activity",
      path: "/activity",
      icon: faChartLine,
    },
    {
      name: "Challenges",
      path: "/challenges",
      icon: true,
    },
    {
      name: "Journal",
      path: "/journal",
      icon: faBook,
    },
    {
      name: "Setting",
      path: "/setting",
      icon: faCog,
      suboption: [
        {
          name: "setting",
          path: "/sub",
        },
      ],
    },
    {
      name: "Chat",
      path: "/chat",
      icon: faCommentDots,
    },
  ];

  // let alreadyExistPath = nav.map((navItem) => navItem.path);
  // if (alreadyExistPath.includes(pathname)) {
  //   dispatch(iscloseSidebar());
  // }

  useEffect(() => {
    dispatch(iscloseSidebar());
  }, [pathname]);

  // const logoutHandler = () => {
  //   authLogOut()
  //     .then((res) => {
  //       if (res.status === 200) {
  //         localStorage.removeItem("user");
  //         localStorage.removeItem("user_token");
  //         localStorage.removeItem("previousRewardPoints");
  //         localStorage.removeItem("access_token");
  //         localStorage.removeItem("expires_in");
  //         localStorage.removeItem("expires_in");
  //         navigate("/login");
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error, "errrrror");
  //     });
  // };

  return (
    <>
      <div
        className={`hidden lg:block w-[280px] ${isSidebarOpen ? "block" : "hidden"
          }`}
      >
        <div className="fixed w-[280px]  top-0 bg-white bottom-0 h-full">
          <div className="px-4">
            <div className="logo mx-auto flex justify-center items-center w-full">
              <img src={logo} alt="App_logo" className="h-28 w-auto" />
            </div>
            <nav className=" sidebar-panel_list mt-8 flex flex-col justify-between">
              <ul className="relative">
                {nav?.map((ele, idx) => (
                  <li key={idx}>
                    <NavLink to={ele?.path}>
                      <div className=" p-3 rounded-full hover:bg-[#000]  text-[#8A8A8A] mb-2 hover:text-white cursor-pointer transition-all ease-in">
                        <div className="flex items-center">

                          {ele.icon === true ? <div style={{ transform: "rotate(79deg)" }}>
                            <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="20px" width="20px" xmlns="http://www.w3.org/2000/svg"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M12 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0"></path><path d="M12 7a5 5 0 1 0 5 5"></path><path d="M13 3.055a9 9 0 1 0 7.941 7.945"></path><path d="M15 6v3h3l3 -3h-3v-3z"></path><path d="M15 9l-3 3"></path></svg>
                          </div> : <FontAwesomeIcon icon={ele?.icon} />}

                          {/* <Icon icon={ele?.icon} color="red" classname="text-rose-300"/> */}
                          {/* {ele?.icon} */}
                          <span className="text-base font-medium  ml-4">
                            {ele?.name}
                          </span>
                        </div>
                      </div>
                    </NavLink>
                    {ele?.suboptions ? (
                      <ul className="pl-4">
                        {" "}
                        {/* Adjust the padding as needed */}
                        {ele.suboptions.map((subopt, subIdx) => (
                          <li key={subIdx}>
                            <NavLink to={subopt.path}>
                              <div className="p-2 rounded-full bg-gray-200  text-[#8A8A8A] hover:text-white cursor-pointer transition-all ease-in ml-2">
                                {subopt.name}
                              </div>
                            </NavLink>
                          </li>
                        ))}
                      </ul>
                    ) : null}
                  </li>
                ))}
              </ul>
              {/* <div className="absolute bottom-6   p-3 rounded-full text-[#8A8A8A] cursor-pointer mb-2">
                <div
                  className="flex items-center "
                  onClick={() => logoutHandler()}
                >
                  <FontAwesomeIcon
                    className="text-[#18605D]"
                    icon={faSignOutAlt}
                  />
                  <span className="text-base font-medium ml-4 text-[#18605D]">
                    Logout
                  </span>
                </div>
              </div> */}
            </nav>
          </div>
        </div>
      </div>

      <Drawer
        open={isSidebarOpen}
        placement={"left"}
        closable={false}
        width={280}
        onClose={onClose}
        key={"left"}
      >
        <div className="w-full ">
          <div className="px-4 ">
            <div className="logo mx-auto flex justify-center items-center w-full">
              <img src={logo} alt="App_logo" className="h-22 w-auto" style={{ width: "120px" }} />
            </div>
            <nav className=" sidebar-panel_list mt-4 flex flex-col justify-between">
              <ul className="relative">
                {nav?.map((ele, idx) => (
                  <li key={idx}>
                    <NavLink to={ele?.path}>
                      <div className=" p-3 rounded-full hover:bg-[#000]  text-[#8A8A8A] mb-2 hover:text-white cursor-pointer transition-all ease-in">
                        <div className="flex items-center">
                          {ele.icon === true ? <div style={{ transform: "rotate(79deg)" }}>
                            <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="20px" width="20px" xmlns="http://www.w3.org/2000/svg"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M12 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0"></path><path d="M12 7a5 5 0 1 0 5 5"></path><path d="M13 3.055a9 9 0 1 0 7.941 7.945"></path><path d="M15 6v3h3l3 -3h-3v-3z"></path><path d="M15 9l-3 3"></path></svg>
                          </div> : <FontAwesomeIcon icon={ele?.icon} />}

                          <span className="text-base font-medium  ml-4">
                            {ele?.name}
                          </span>
                        </div>
                      </div>
                    </NavLink>
                  </li>
                ))}
              </ul>
              {/* <div className="absolute bottom-6  p-3 rounded-full text-[#8A8A8A] cursor-pointer mb-2">
                <div
                  className="flex items-center"
                  onClick={() => logoutHandler()}
                >
                  <FontAwesomeIcon
                    className="text-[#18605D]"
                    icon={faSignOutAlt}
                  />
                  <span className="text-base font-medium ml-4 text-[#18605D]">
                    Logout
                  </span>
                </div>
              </div> */}
            </nav>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default Sidebar;
