import moment from "moment";
import instance from "../../../AxiosFile/AxiosInstance"

export function useDahsbord() {

    const getDashboardDetail = async (id) => {
        let res = await instance.get(`api/home_screen`, {
            params: {
                user_id: id,
                date: moment().utc().format("YYYY-MM-DD hh:mm:ss")
            }
        })
        return res.data
    }

    const getTaskActivites = async ({ id, date }) => {
        const response = await instance.get(`/api/task_activities`, {
            params: {
                user_id: id,
                date: date,
            },
        });
        if (response.data) {
            return response.data;
        }
    };

    const getInsidesWeekData = async (body) => {
        let res = await instance.get("/api/your_insights_for_week", {
            params: body
        })
        return res.data
    }


    const getInsidesMonthData = async (body) => {
        let res = await instance.get("/api/your_insights_for_month", {
            params: body
        })
        return res.data
    }


    const getInsidesQuarterData = async (body) => {
        let res = await instance.get("/api/your_insights_for_quarterly", {
            params: body
        })
        return res.data
    }

    const getInsidesYearData = async (body) => {

        let res = await instance.get("/api/your_insights_for_yearly", {
            params: body
        })
        return res.data
    }

    return {
        getDashboardDetail, getTaskActivites, getInsidesWeekData,
        getInsidesMonthData,
        getInsidesQuarterData,
        getInsidesYearData,
    }
}
