import React from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";

import { Alert, Modal, notification } from "antd";
import AppButton from "../../../../components/Button/AppButton";
import { useUserProfileModalState } from "../ProfileApi";
import { useState } from "react";
import moment from "moment";

const AddUser = ({ state, setState, user }) => {
  const [addUserState, setAddUserState] = useState({
    error: "",
    success: "",
  });
  const {
    addMemberHandler,
  } = useUserProfileModalState();


  const handleOk = () => {
    setState((prev) => ({ ...prev, addMemberIsOpen: false }));
  };
  const handleCancel = () => {
    setState((prev) => ({ ...prev, addMemberIsOpen: false }));
  };

  return (
    <Modal
      open={state.addMemberIsOpen}
      title=""
      onOk={handleOk}
      destroyOnClose
      width={480}
      onCancel={handleCancel}
      closeIcon={false}
      footer={[
        <div className="w-full text-center font-montserrat">
          <Formik
            initialValues={{
              member_name: "",
              member_email: "",
              member_mobile: "",
            }}
            validate={(values) => {
              const errors = {};

              if (!values.member_name) {
                errors.member_name = 'Member name is required';
              }

              if (!values.member_email) {
                errors.member_email = 'Member email is required';
              } else if (!/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.(com|in)$/.test(values.member_email)) {
                errors.member_email = 'Invalid email address';
              }



              if (!values.member_mobile) {
                errors.member_mobile = 'Member mobile is required';
              } else if (!/^\d{10}$/i.test(values.member_mobile)) {
                errors.member_mobile = 'Invalid mobile number (10 digits only)';
              }

              return errors;
            }}
            onSubmit={(values, { resetForm }) => {
              let data = { ...values, user_id: user?.id, date_time: moment().format('YYYY-MM-DD hh:mm:ss') };

              addMemberHandler(data)
                .then((res) => {
                  if (res.status === 200) {
                    notification["success"]({
                      message: res.message,
                      style: {
                        fontFamily: 'Montserrat, sans-serif',
                      },
                    });
                    setState((prev) => ({ ...prev, addMemberIsOpen: false }));
                  }
                })
                .catch((error) => {

                  setAddUserState((prev) => ({
                    ...prev,
                    error: error?.response?.data?.message,
                  }));
                  setTimeout(() => {
                    setAddUserState((prev) => ({
                      ...prev,
                      error: "",
                    }));
                  }, 4000);
                });
              resetForm();
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <div className="pb-4">
                  <h1 className="text-lg font-semibold mb-3 font-montserrat">Add Members</h1>

                  <div>
                    {addUserState.error && (
                      <Alert
                        message={`${addUserState.error}`}
                        showIcon
                        type="error"
                        className="mb-3 text-start"
                      />
                    )}
                  </div>
                  <div className="mb-4">

                    <div className=" flex  justify-start items-center">
                      <label htmlFor="member_name" className="font-poppins text-sm font-base text-black w-1/3 text-start">Name</label> &nbsp;
                      <Field
                        type="text"
                        id="member_name"
                        name="member_name"
                        placeholder="Enter Name"
                        className="w-full px-3 py-2 rounded-md text-xs h-10 font-poppins font-normal "
                      />
                    </div>
                    <ErrorMessage
                      name="member_name"
                      component="div"
                      className="text-red-500 text-sm pt-1  formik-error"
                    />
                  </div>


                  <div className="mb-4 w-full">

                    <div className=" flex  justify-start items-center">
                      <label htmlFor="name" className="font-poppins text-sm font-base text-black w-1/3 text-start ">Email Address </label> &nbsp;
                      <Field
                        type="member_email"
                        id="member_email"
                        name="member_email"
                        placeholder="Enter Email"
                        className="w-full px-3 py-2 rounded-md border-0 h-10  text-xs font-poppins font-normal"
                      />
                    </div>
                    <ErrorMessage
                      name="member_email"
                      component="div"
                      className="text-red-500 text-sm pt-1  formik-error"
                    />
                  </div>
                  <div className="mb-4 w-full flex flex-col ">
                    <div className=" flex   justify-start items-center">
                      <label htmlFor="mobile" className="font-poppins text-sm font-base text-black w-1/3 text-start">Mobile No.</label>
                      <Field
                        type="number"
                        id="member_mobile"
                        name="member_mobile"
                        placeholder="Enter No."
                        className="w-full px-3 py-2 h-10 rounded-md  text-xs font-poppins font-normal "
                      />
                    </div>
                    <ErrorMessage
                      name="member_mobile"
                      component="div"
                      className="text-red-500 text-sm pt-1 formik-error"
                    />
                  </div>

                  <div className="w-full text-center pt-4">
                    <AppButton
                      btnText="Add"
                      disabled={isSubmitting}
                      type="submit"
                      className={`bg-ternary font-semibold w-full text-white py-3 rounded-2xl ${isSubmitting ? "opacity-50 cursor-not-allowed" : ""
                        }`}
                    />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>,
      ]}
    >
      {/* Content of the modal */}
    </Modal>
  );
};

export default AddUser;
