import React from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";

import * as Yup from "yup";
import { Modal, TimePicker, notification } from "antd";
import AppButton from "../../../components/Button/AppButton";
import { useEffect } from "react";
import { useState } from "react";
import { useChallange } from "./ChallangesApis";
import moment from "moment";



const validationSchema = Yup.object().shape({
    challenges_title: Yup.string().required('Title is required'),
    challenges_desc: Yup.string().required('Description is required'),
    // challenges_time: Yup.string().required('Time is required'),
})

const ChallangesModal = ({ challengeParentState, setChallengeParentState, user }) => {

    const { createChallange } = useChallange()

    const handleOk = () => {
        setChallengeParentState((prev) => ({ ...prev, isCreateChallangeOpen: false }));
    };
    const handleCancel = () => {
        setChallengeParentState((prev) => ({ ...prev, isCreateChallangeOpen: false }));
    };
    useEffect(() => {

    }, []);

    const submitSubTask = (values, { resetForm }) => {

        // createChallange
        let data = {
            ...values,
            user_id: user?.id,
            challenge_date: moment().format("YYYY-MM-DD hh:mm:ss"),
            challenge_time: moment(values.challenge_time).format("hh:mm A")

        }
        createChallange(data).then((res) => {

            if (res.status === 200) {
                setChallengeParentState((prev) => ({ ...prev, isCreateChallangeOpen: false }));
                notification["success"]({
                    description: res.message,
                    style: {
                        fontFamily: 'Montserrat, sans-serif',
                        fontSize: "14px"
                    },
                });
            } else if (res.status === 500) {
                setChallengeParentState((prev) => ({ ...prev, isCreateChallangeOpen: false }));
                notification["warning"]({
                    description: res.message,
                    style: {
                        fontFamily: 'Montserrat, sans-serif',
                        fontSize: "14px"
                    },
                });
            }
        }).catch((err) => {
            console.log(err, "err");
        })




    };

    return (
        <Modal
            open={challengeParentState?.isCreateChallangeOpen}
            title=""
            onOk={handleOk}
            destroyOnClose
            width={480}
            onCancel={handleCancel}
            closeIcon={false}
            footer={[
                <div className="w-full text-center font-montserrat">
                    <Formik
                        initialValues={{
                            challenges_title: "",
                            challenges_desc: "",
                            challenges_image: null,
                            challenge_time: null
                        }}
                        validationSchema={validationSchema}
                        onSubmit={(values, { resetForm }) => {
                            submitSubTask(values, { resetForm });
                        }}
                        enableReinitialize
                    >
                        {({ isSubmitting, setFieldValue, values }) => (
                            <Form>
                                <div className="pb-4 relative w-full font-montserrat">
                                    <h1 className="text-lg font-semibold">Create Challange</h1>

                                    <div className="mb-4 flex flex-col justify-start items-start">
                                        <label htmlFor="taskName" > Title :</label>
                                        <Field
                                            type="text"
                                            id="challenges_title"
                                            name="challenges_title"
                                            placeholder="Enter Name"
                                            className="w-full px-3 py-2 rounded-md   placeholder-[#B0B0B0]"
                                            style={{ background: "white !important" }}
                                        />
                                        <ErrorMessage
                                            name="challenges_title"
                                            component="div"
                                            className="text-red-500 text-sm pt-2 ps-2 formik-error"
                                        />
                                    </div>
                                    <div className="mb-4 flex flex-col justify-start items-start">
                                        <label htmlFor="task_category_name" className="mb-1">
                                            Description :
                                        </label>
                                        {/* <Field
                                            as="select"
                                            id="task_category_name"
                                            name="task_category_name"
                                            className="w-full px-3 py-2 bg-[#f5f5f5]  rounded-md"
                                        >
                                            <option value="">Select</option>
                                            {customModalState?.CategoriesList?.map((category) => {
                                                let { id, task_category_name } = category;
                                                return (
                                                    <option key={id} value={id}>
                                                        {task_category_name}
                                                    </option>
                                                );
                                            })}
                                        </Field> */}
                                        <Field
                                            type="text"
                                            id="challenges_desc"
                                            name="challenges_desc"
                                            placeholder="Enter Name"
                                            className="w-full px-3 py-2 rounded-md   placeholder-[#B0B0B0]"
                                            style={{ background: "white !important" }}
                                        />
                                        <ErrorMessage
                                            name="challenges_desc"
                                            component="div"
                                            className="text-red-500 text-sm pt-2 ps-2 formik-error"
                                        />
                                    </div>

                                    <div>

                                        <Field name="challenge_time" className="time_picker_none">
                                            {({ field, form }) => (
                                                <div className="mb-4 flex flex-col justify-start items-start">
                                                    <label htmlFor="challenge_time" className="mb-1">
                                                        Reminder:
                                                    </label>
                                                    <TimePicker
                                                        {...field}
                                                        defaultValue={field.value ? field.value : null}
                                                        onSelect={(time) => form.setFieldValue('challenge_time', time)}
                                                        format="hh:mm A"
                                                        use12Hours
                                                        className="w-full placeholder-[#B0B0B0]  rounded-[12px]"
                                                        style={{ background: "#F5F5F5", border: "none" }}


                                                    />

                                                    {form.touched.challenges_time && form.errors.challenges_time && (
                                                        <div className="text-red-500 text-sm pt-2 ps-2 formik-error">
                                                            {form.errors.challenges_time}
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </Field>
                                        <ErrorMessage
                                            name="challenges_time"
                                            component="div"
                                            className="text-red-500 text-sm pt-2 ps-2 formik-error"
                                        />
                                    </div>


                                    <div className="mb-4 mt-2 flex flex-col justify-start items-start">
                                        <label className=" bg-[#f5f5f5]  rounded-lg h-28 w-full">
                                            <div className="relative px-3 py-2 h-full w-full  rounded-lg  items-center justify-center flex ">
                                                <div className="flex items-center justify-center h-full w-full">
                                                    <p className=" px-4 py-1 text-sm border border-dashed border-[#18605D] bg-white text-black font-medium rounded-md">
                                                        {values?.challenges_image
                                                            ? values?.challenges_image?.name
                                                            : "Attach Photo"}
                                                    </p>
                                                </div>
                                                <Field
                                                    type="file"
                                                    accept="image/*"
                                                    id="challenges_image"
                                                    value={null}
                                                    name="challenges_image"
                                                    className="border  px-3 py-2  rounded-lg items-center justify-center hidden bg-white text-none "
                                                    onChange={(e) => {

                                                        setFieldValue(
                                                            "challenges_image",
                                                            e.target?.files?.[0]
                                                        );
                                                    }}
                                                />
                                            </div>
                                        </label>
                                        <ErrorMessage
                                            name="challenges_image"
                                            component="div"
                                            className="text-red-500 text-sm pt-2 ps-2 formik-error"
                                        />
                                        <div className=" flex items-center w-full mt-3 text-center relative">
                                            <AppButton
                                                btnText="Create"
                                                // disabled={isSubmitting}
                                                btnType="Submit"
                                                className={`bg-ternary font-semibold text-xl w-full text-white px-5 py-2 mx-8 rounded-xl ${isSubmitting ? "opacity-50 cursor-not-allowed" : ""
                                                    }`}
                                            />
                                        </div>
                                    </div>

                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>,
            ]}
        >
            {/* Content of the modal */}
        </Modal>
    );
};

export default ChallangesModal;
