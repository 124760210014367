import axios from "axios";

function useGoogleCalender() {
  const getDyscoresCalender = async () => {
    try {
      const response = await fetch(
        "https://www.googleapis.com/calendar/v3/users/me/calendarList",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );

      const calendarList = await response.json();
      // setCalendars(calendarList.items);

      // Find and store the calendar with the summary "Dyscores-1"
      const dyscoresCalendar =
        calendarList?.items &&
        calendarList?.items?.find((calendar) => calendar.summary === "Lakshya");

      if (dyscoresCalendar) {
        return dyscoresCalendar;
      } else {
        setTimeout(() => {
          createDyscoresCalender();
        }, 2000);
      }
    } catch (error) {
      console.error("Error fetching calendar list:", error);
    }
  };

  const createDyscoresCalender = async () => {
    try {
      const response = await axios.post(
        "https://www.googleapis.com/calendar/v3/calendars",
        {
          kind: "calender/calender",
          summary: "Lakshya", // Set the summary for your new calendar
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        getDyscoresCalender();
        return response;
      } else {
        throw new Error("Failed to create calendar");
      }
    } catch (error) {
      console.error("Error creating calendar:", error);
    }
  };

  const getAlldyscoreEvents = async (Dyscorse) => {
    const existingEventsResponse = await axios.get(
      `https://www.googleapis.com/calendar/v3/calendars/${Dyscorse?.id}/events`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }
    );

    const existingEvents = await existingEventsResponse.data;

    // console.log(existingEvents, "SDFs");

    // Check if the event already exists

    const existingEvent =
      existingEvents && existingEvents?.items?.find((item) => item.id);

    return existingEvents;
  };

  const editGoogleCaldenderEvent = async (
    item,
    DyscoreCalender,
    matchingEvent,
    setActivityState
  ) => {
    try {
      const updateResponse = await fetch(
        `https://www.googleapis.com/calendar/v3/calendars/${DyscoreCalender?.id}/events/${matchingEvent.id}`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(item),
        }
      );

      if (updateResponse.ok) {
        setActivityState((prev) => ({
          ...prev,
          render: true,
          loader: false,
        }));
      } else {
        console.error(
          `Failed to update event '${matchingEvent.summary}': ${updateResponse.statusText}`
        );
      }
    } catch (error) {
      console.error("Error updating events:", error);
    }
  };

  const createEventInCalendar = async (
    data,
    DyscoreCalender,
    setActivityState
  ) => {
    // data => this is data coming from the function invokes

    try {
      const response = await fetch(
        `https://www.googleapis.com/calendar/v3/calendars/${DyscoreCalender?.id}/events`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      if (response.status === 200) {
        setActivityState((prev) => ({
          ...prev,
          render: true,
          loader: false,
        }));
      } else {
        throw new Error("Failed to create event");
      }
    } catch (error) {
      console.error("Error creating event:", error);
    }
  };

  const deleteEventFromCalender = async ({ selectedCalendar, eventId }) => {
    try {
      const response = await fetch(
        `https://www.googleapis.com/calendar/v3/calendars/${selectedCalendar}/events/${eventId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );

      if (response.status === 200) {
        return response;
        // Refresh the calendar events after deleting an event
      } else {
        throw new Error("Failed to delete event");
      }
    } catch (error) {
      console.error("Error deleting event:", error);
    }
  };

  return {
    getDyscoresCalender,
    createDyscoresCalender,
    getAlldyscoreEvents,
    createEventInCalendar,
    deleteEventFromCalender,
    editGoogleCaldenderEvent,
  };
}

export default useGoogleCalender;
