import React, { useEffect, useRef, useState } from "react";
import { GrAttachment } from "react-icons/gr";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { LuSend } from "react-icons/lu";
import { MdOutlineKeyboardVoice } from "react-icons/md";
import ImageViewer from "react-images-viewer"
import {
  getMessages,
  getToUserProfileName,
  getToUserProfilePicture,
  sendMessage,
} from "../../../firebase/chat";
import "react-chat-elements/dist/main.css";
import MessageList from "../../../components/chat/MessageList/MessageList";
import { useAudioRecorder } from "../../../components/Audio/AudioRecorder";
import { BsPause } from "react-icons/bs";
import { useChatApis } from "./ChatModuleApis";
import { FaFileAudio } from "react-icons/fa";
import placeholderImgae from "../../../assets/images/placeholderImage.jpg";
import { Dropdown, Popconfirm, notification } from "antd";
import emptyChatUserImage from "../../../assets/images/emptychatuser.svg";
import { collection, onSnapshot } from "firebase/firestore";
import { db } from "../../../firebase/firebase";
import { useSettings } from "../Setting/settingApis";
import ReactImageViewer from "../../../components/ReactImagesViewer";
import InfoModal from "../Challenges/InfoModal";

const ChatUserView = ({
  user,
  chatParentState,
  setChatParentState,
  backbutton,
}) => {
  const [imageVierwData, setImageViewr] = useState({
    ImgSrc: [],
    isOpenViewres: false,

  })
  const [infoModalData, setInfoModalData] = useState({
    isInfoOpen: false,
    isDataNew: {}
  })
  const messageListReferance = useRef();
  const { addChatAttachment } = useChatApis();
  const messageSectionRef = useRef(null);
  const {
    stopRecording,
    startRecording,
    setAudioState,
    audioState,
    formatTime,
    startRecording2,
    stopRecording2,
    downloadRecording,
  } = useAudioRecorder();

  const { onBlockHandler } = useSettings();

  useEffect(() => {
    if (audioState?.audioChunks.length > 0) {
      const blob = new Blob(audioState?.audioChunks, { type: "audio/wav" });
      setAudioState((prev) => ({
        ...prev,
        audioURL: URL.createObjectURL(blob),
      }));
    }
  }, [audioState?.audioChunks]);

  const [messagesState, setMesagesState] = useState({
    messageList: [],
  });

  const onMessageHandler = (value) => {
    setChatParentState((prev) => ({ ...prev, messgae: value }));
  };




  //get all messages of active user
  const getAllMessages = () => {
    getMessages(chatParentState?.activeUser)
      .then((res) => {
        if (res) {
          setChatParentState((prev) => ({
            ...prev,
            userMessages: res.data,
            dataChange: false,
          }));

          setMesagesState((prev) => ({ ...prev, messageList: res.data }));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getAllMessages();
  }, [
    chatParentState?.chatUser,
    chatParentState?.activeUser,
    chatParentState?.chatList,
    chatParentState?.dataChange,
  ]);

  const sendMessageToUser = async (
    userID,
    details,
    messageText,
    messageType
  ) => {
    if (messageType === "image") {
      let data = {
        file_attachment: messageText,
      };
      addChatAttachment(data).then((res) => {
        if (res.status === 200) {
          sendMessage(userID, details, res?.data?.file_url, messageType);
          getAllMessages();
        }
      });
      // await new Promise((resolve) => setTimeout(resolve, 2000));
    } else if (messageType === "text") {
      await sendMessage(userID, details, messageText, messageType);
    }
    getAllMessages();
    setTimeout(() => { }, 3000);
    setChatParentState((prev) => ({ ...prev, messgae: "" }));
  };

  const scrollToBottom = () => {
    if (messageSectionRef.current) {
      messageSectionRef.current.scrollTop =
        messageSectionRef.current.scrollHeight;
    }
  };

  //block a user
  const blockUser = () => {
    const data = {
      user_id: chatParentState?.chatUser?.from_user?.id,
      friend_user_id: chatParentState?.chatUser?.to_user?.id,
    };
    onBlockHandler(data)
      .then((res) => {
        if (res.status === 200) {
          notification["success"]({
            message: "User Blocked Successfully",
            style: {
              fontFamily: 'Montserrat, sans-serif',
            },
          });
          setChatParentState((prev) => ({
            ...prev,
            userMessages: [],
            activeUser: null,
            chatUser: null,
          }));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const items = [
    {
      key: "1",
      label: <Popconfirm
        title="Are you sure you want to block this user?"
        onConfirm={blockUser}
        okText={
          <span className="confirmpopUp-custom-button-style sure_btn font-montserrat">Yes</span>
        }
        cancelText={
          <span className="confirmpopUp-custom-button-style font-montserrat  ">No</span>
        }
        overlayClassName="custom-popconfirm"
      >

        <button className="font-montserrat" >Block</button>
      </Popconfirm>,
    },
  ];
  useEffect(() => {
    // Call scrollToBottom when the component initially renders and when new messages are added
    scrollToBottom();
  }, [messagesState.messageList, chatParentState?.userMessages]);

  useEffect(() => { }, [chatParentState?.chatUser]);
  const downloadRecordingaudio = async (
    userID,
    details,
    audioChunks,
    messageType
  ) => {
    const url = await downloadRecording();
    sendMessage(userID, details, url?.file_url, messageType);
    getAllMessages();
    setTimeout(() => { }, 3000);
    setAudioState((prev) => ({
      ...prev,
      audioChunks: [],
    }));
  };

  const onImageViewr = (data) => {

    if (data?.type === "image") {
      setImageViewr((prev) => ({
        ...prev, ImgSrc: [...imageVierwData?.ImgSrc, { src: data.text }], isOpenViewres: true
      }))
    }
  }

  const handleImageClose = () => {
    setImageViewr((prev) => ({
      ...prev, ImgSrc: [], isOpenViewres: false
    }))
  }



  return (
    <>
      <>
        {chatParentState?.chatUser ? (
          <>
            <div className="user_header relative flex justify-between items-center px-3 py-3  border-b">
              <div className="flex items-center ">
                <img
                  src={
                    chatParentState?.chatUser?.to_user?.profile_image
                      ? ` ${process.env.REACT_APP_FILE_URL_PROFILE}/${chatParentState?.chatUser?.to_user?.profile_image}`
                      : placeholderImgae
                  }
                  alt=""
                  className=" w-[40px] h-[40px] rounded-full me-2"
                />
                <div className="overflow-hidden truncate max-full">
                  <h4 className="w-full flex justify-between">
                    {getToUserProfileName(
                      user?.id ?? "",
                      chatParentState?.chatUser
                    )}
                  </h4>
                </div>
              </div>
              <div>
                <Dropdown
                  menu={{
                    items,
                  }}
                  placement="bottomLeft"
                  arrow
                >
                  <HiOutlineDotsVertical />
                </Dropdown>
              </div>
            </div>
            <div
              className="message-section min-h-[60vh] h-96 overflow-y-scroll px-3 py-3"
              ref={messageSectionRef}
            >
              <ul className="w-[100%]">
                <MessageList
                  referance={messageListReferance}
                  className="message-list"
                  lockable={true}
                  toBottomHeight={"100%"}
                  dataSource={chatParentState?.userMessages}
                  selfId={user?.id}
                  onClick={(data, index) => {
                    onImageViewr(data)
                  }}
                  getDataOpenPopup={(data) => {
                    if (data) {
                      setInfoModalData((prev) => ({ ...prev, isInfoOpen: true, isDataNew: data }))
                    }
                  }}
                />
              </ul>



              <ReactImageViewer imgs={imageVierwData?.ImgSrc}
                isOpen={imageVierwData.isOpenViewres}
                onClose={() => handleImageClose()} />
            </div>
            <div className="border-t px-3 py-3 flex items-center gap-3">
              <span className="text-lg">
                <label htmlFor="attachement">
                  <input
                    disabled={messagesState?.messageList?.length === 0}
                    type="file"
                    id="attachement"
                    name="attachement"
                    accept="image/*"
                    className="w-full hidden"
                    placeholder=""
                    onChange={(e) =>
                      sendMessageToUser(
                        user?.id,
                        chatParentState,
                        e.target.files[0],
                        "image"
                      )
                    }
                  />
                  <GrAttachment />
                </label>
              </span>

              {audioState?.timer > 0 && audioState?.recording ? (
                <div className="flex items-center w-full">
                  <span className="w-full text-center text-sm">
                    {formatTime(audioState?.timer)}
                  </span>
                </div>
              ) : (
                <input
                  type="text"
                  value={chatParentState.messgae}
                  className="w-full  bg-white outline-none"
                  placeholder="Enter something here..."
                  onChange={(e) => onMessageHandler(e.target.value)}
                />
              )}

              <div className={`${audioState?.timer > 0 ? "" : ""} w-fit`}>
                {chatParentState.messgae.trim("").length !== 0 ? (
                  <button
                    disabled={chatParentState?.chatUser === null ? true : false}
                    className="w-12 h-12 flex items-center justify-center rounded-full text-white  bg-primary-dark "
                  >
                    <LuSend
                      onClick={() =>
                        sendMessageToUser(
                          user?.id,
                          chatParentState,
                          chatParentState.messgae,
                          "text"
                        )
                      }
                      className="text-lg"
                    />
                  </button>
                ) : (
                  <button
                    // disabled={messagesState?.messageList?.length === 0}
                    // onClick={audioState?.recording ? stopRecording : startRecording}
                    className=""
                  >
                    <div className="flex items-center gap-2">
                      {!audioState?.recording ? (
                        <button
                          disabled={
                            chatParentState?.chatUser === null ? true : false
                          }
                          className="w-12 h-12 flex items-center justify-center rounded-full text-white  bg-primary-dark "
                        >
                          <MdOutlineKeyboardVoice
                            onClick={startRecording}
                            className="text-lg"
                          />
                        </button>
                      ) : (
                        <div className="flex items-center gap-2 w-full">
                          <button
                            disabled={
                              chatParentState?.chatUser === null ? true : false
                            }
                            className="w-12 h-12 flex items-center justify-center rounded-full text-white  bg-primary-dark "
                          >
                            <BsPause
                              // onClick={stopRecording}
                              onClick={() => {
                                stopRecording();
                                downloadRecordingaudio(
                                  user?.id,
                                  chatParentState,
                                  audioState?.audioChunks,
                                  "audio"
                                );
                              }}
                              className="text-lg"
                            />
                          </button>
                        </div>
                      )}
                      {audioState.audioChunks.length > 0 && (
                        <button
                          className="w-12 h-12 rounded-full flex items-center justify-center text-white  bg-primary-dark"
                          onClick={() =>
                            downloadRecordingaudio(
                              user?.id,
                              chatParentState,
                              audioState?.audioChunks,
                              "audio"
                            )
                          }
                        >
                          <FaFileAudio className="text-lg" />
                          {/* <span className="text-xs">Send Recording</span> */}
                        </button>
                      )}
                    </div>
                  </button>
                )}
              </div>
            </div>
          </>
        ) : (
          <div className="flex flex-col items-center justify-center text-paragraph min-h-[70vh]">
            <img src={emptyChatUserImage} alt="" className="w-[300px]" />
            <span>
              Please select a user from your contact list to start a conversation.
            </span>
          </div>
        )}
      </>
      <InfoModal state={infoModalData} setState={setInfoModalData} user={user} challengeID={infoModalData?.isDataNew?.challenge_id} stateNew={infoModalData?.isDataNew} time={true} />


    </>
  );
};

export default ChatUserView;
