import instance from "../../../AxiosFile/AxiosInstance";

export function useSettings() {
  const sendFeedback = async (body) => {
    let res = await instance.post(`/api/app_feedback`, body);
    return res.data;
  };

  const getBlockUsers = async (id) => {
    let res = await instance.get(`/api/blocked_user_list?user_id=${id}`);
    return res.data;
  };

  const onUnblockHandler = async (body) => {
    let res = await instance.post(`/api/user_unblock`, body);
    return res.data;
  };
  const onBlockHandler = async (body) => {
    let res = await instance.post(`/api/user_block`, body);
    return res.data;
  };

  return {
    sendFeedback,
    getBlockUsers,
    onUnblockHandler,
    onBlockHandler,
  };
}
