import moment from "moment";
import instance from "../../../AxiosFile/AxiosInstance";

export function useChallange() {
  const getChallangesList = async (id) => {
    let res = await instance.get(`/api/challenges_list`, {
      params: {
        user_id: id,
      },
    });
    return res.data;
  };
  const getChallangesSuggestionList = async (id) => {
    let res = await instance.get(`/api/challenges_suggestion_list`, {
      params: {
        user_id: id,
      },
    });
    return res.data;
  };
  // get
  const getChallangesScreen = async (id) => {
    let res = await instance.get(`/api/challenges_screen`, {
      params: {
        user_id: id,
      },
    });
    return res.data;
  };
  // post with headers
  const createChallange = async (body) => {
    const res = await instance.post("/api/create_challenge", body, {
      headers: {
        "Content-type": "multipart/form-data",
      },
    });
    return res.data;
  };

  const joinedChallange = async (body) => {
    const res = await instance.post("/api/user_join_challenge", body, {
      headers: {
        "Content-type": "multipart/form-data",
      },
    });
    return res.data;
  };

  const getChallangeDetail = async ({ id, challenge_id }) => {
    let res = await instance.get(`/api/challenge_detail`, {
      params: {
        user_id: id,
        challenge_id: challenge_id,
        date: moment().format("YYYY-MM-DD"),
      },
    });
    return res.data;
  };

  const getChallangesJoinedMember = async ({ id, challenge_id }) => {
    let res = await instance.get("/api/joined_members_list", {
      params: {
        user_id: id,
        challenge_id: challenge_id,
      },
    });
    return res.data;
  };

  const likePost = async (params) => {
    let res = await instance.post("/api/challenge_post_like", params, {
      headers: {
        "Content-type": "multipart/form-data",
      },
    });
    return res.data;
  };

  const commentPost = async (params) => {
    let res = await instance.post("/api/challenge_post_comment", params, {
      headers: {
        "Content-type": "multipart/form-data",
      },
    });
    return res.data;
  };

  // post detail /api/challenge_post_details?user_id=12&challenge_id=6&post_id=2
  const getChallangesPostDetail = async ({ id, challenge_id, postId }) => {
    let res = await instance.get("api/challenge_post_details", {
      params: {
        user_id: id,
        challenge_id: challenge_id,
        post_id: postId,
      },
    });
    return res.data;
  };

  // create post /api/create_challenge_post
  const createPost = async (body) => {
    const res = await instance.post("api/create_challenge_post", body, {
      headers: {
        "Content-type": "multipart/form-data",
      },
    });
    return res.data;
  };

  const checkedChallangeCompleted = async (body) => {
    // /api/complete_challenge
    const res = await instance.post("/api/complete_challenge", body, {
      headers: {
        "Content-type": "multipart/form-data",
      },
    });
    return res.data;
  };

  const challangeDelete = async (body) => {
    // /api/delete_challenge

    const res = await instance.post("/api/delete_challenge", body, {
      headers: {
        "Content-type": "multipart/form-data",
      },
    });
    return res.data;
  };

  const unJoinHandle = async (body) => {
    const res = await instance.post("/api/user_unjoin_challenge", body, {
      headers: {
        "Content-type": "multipart/form-data",
      },
    });

    return res.data;
  };

  const onResetHandler = async (body) => {
    const res = await instance.post("/api/reset_reminder_time", body, {
      headers: {
        "Content-type": "multipart/form-data",
        accept: "application/json",
      },
    });

    return res.data;
  };

  return {
    getChallangesScreen,
    getChallangesList,
    getChallangesSuggestionList,
    joinedChallange,
    createChallange,
    getChallangeDetail,
    getChallangesJoinedMember,
    likePost,
    commentPost,
    getChallangesPostDetail,
    createPost,
    checkedChallangeCompleted,
    challangeDelete,
    unJoinHandle,
    onResetHandler,
  };
}
