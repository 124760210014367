import React, { useEffect, useState } from "react";
import { faBell, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import { iscloseSidebar, isOpenSidebar } from "../Store/Slices/SidebarOpen";
import { isOpen } from "../Store/Slices/NotificationSidebar";
import BellIcon from "../assets/images/icons/bell_icon.svg";
import { Popover } from "antd";
import { useNavigate } from "react-router-dom";
import { authLogOut } from "../AxiosFile/ApisUrl";
import { MdNotifications, MdNotificationsNone } from "react-icons/md";
import { useUserProfileModalState } from "../pages/admin/Profile/ProfileApi";

const Header = () => {
  const {
    isSidebarOpen: { isSidebarOpen },
    AuthUser: { user },
  } = useSelector((state) => state);
  const navigate = useNavigate();

  const [profileImage, setProfileImageState] = useState();


  const dispatch = useDispatch();



  const onSidebarHandler = () => {
    if (isSidebarOpen) {
      dispatch(iscloseSidebar());
    } else {
      dispatch(isOpenSidebar());
    }
  };

  const onNotificationHanlder = () => {
    dispatch(isOpen());
  };



  return (
    <header
      className="sticky flex items-center top-0   bottom-0 h-16 z-20 "
      style={{ backdropFilter: "blur(5px)", background: "white" }}
    >
      <div className="w-full flex justify-between items-center mx-3 relative ">
        <div
          className="text-primary  sm:block md:block lg:hidden ms-2"
          onClick={onSidebarHandler}
        >
          <div className="icon hover:bg-gray-200 p-2 rounded-full">
            <svg
              aria-hidden="true"
              role="img"
              className="MuiBox-root css-1t9pz9x iconify iconify--eva"
              width="1.2em"
              height="1.2em"
              viewBox="0 0 24 24"
            >
              <circle cx="4" cy="12" r="1"></circle>
              <rect width="14" height="2" x="7" y="11" rx=".94" ry=".94"></rect>
              <rect width="18" height="2" x="3" y="16" rx=".94" ry=".94"></rect>
              <rect width="18" height="2" x="3" y="6" rx=".94" ry=".94"></rect>
            </svg>
          </div>
        </div>

        <span></span>
        <div className="flex items-center justify-between float-right p-4">
          <div className="flex gap-2 items-center h-full">
            <div
              onClick={onNotificationHanlder}
              className="h-10 w-10 flex items-center justify-center rounded-full border cursor-pointer"
            >
              <img className="" src={BellIcon} />
            </div>

            {/* <Popover
              content={content}
              title={
                <div className="border-dashed border-b pb-2">
                  <h2 className="flex flex-col">
                    {user?.user?.first_name} {user?.user?.last_name}
                    <span className="text-xs font-thin text-paragraph">
                      {user?.user?.email}
                    </span>
                  </h2>
                </div>
              }
            > */}
            <div
              onClick={() => navigate("/profile")}
              className="h-10 w-10 rounded-full border cursor-pointer"
            >
              <img
                src={
                  user?.user?.profile_image
                    ? `${process.env.REACT_APP_FILE_URL_PROFILE}/${user?.user?.profile_image} `
                    : `https://static.vecteezy.com/system/resources/thumbnails/004/511/281/small/default-avatar-photo-placeholder-profile-picture-vector.jpg`
                }

                alt="userprofile"
                className="h-10 w-10 rounded-ful"
                style={{ borderRadius: "50%" }}
              />
              {/* <img src="" /> */}
            </div>
            {/* </Popover> */}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
