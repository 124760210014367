import React from "react";
import gradient1 from "../../assets/images/gradient_bg_1.svg";
import gradient2 from "../../assets/images/gradient_bg_2.svg";

const AuthPageImage = ({
  AuthPageImage,
  ImageUrl,
  gradientbg,
  logo,
  mainImage,
  props,
}) => {
  return (
    <div
      className="w-full relative h-full  flex-col firstSlide bg-[#E8EFEF] flex items-center"
      {...props}
    >
      <div className="flex items-center justify-center pt-2">
        <img className="w-40  h-auto" src={logo} style={{ width: "140px" }} />
      </div>
      <div className="flex items-center justify-center relative h-full w-full ">
        {mainImage ? <img className="w-40  h-auto" src={ImageUrl} style={{ width: "140px" }} /> :
          gradientbg ? (
            <div className="">
              <img className="absolute top-[-140px] left-0" src={gradient1} />
              <img className="absolute bottom-0 right-0" src={gradient2} />
            </div>
          ) : (
            <div className="flex items-center justify-center">
              <img
                src={ImageUrl}
                alt={"login images"}
                className="h-full w-10/12 bottom-0 object-fill"
                style={{ width: "450px" }}
              />
            </div>
          )}
      </div>
    </div>
  );
};

export default AuthPageImage;
