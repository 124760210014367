import React from "react";
import { useState } from "react";
// import { useNavigate } from "react-router-dom";
// import { HomeIllustration } from "../../common/illustrations";
// import AppButton from "../../components/Button/AppButton";
import WishModals from "../../components/CommonModals/ModalWish";
import Dashboard from "./Dashbord/Dashboard";

const Home = () => {
  const [homeState, setHomeState] = useState({
    isInOpen: false,
    isCongrats: false,
    isResetOpen: false,
  });
  // const navigate = useNavigate();

  // const redirectToTaskPage = () => {
  //   navigate("/task");
  // };

  return (
    <div className="w-full h-screen">
      <Dashboard />
      {/* <div className="flex items-center justify-center">
        <div className="w-80">
          <img src={HomeIllustration} alt="Home Illustration" />
          <span className="text-[#8A8A8A] text-base font-medium w-32">
            Ready to conquer your day? Add your tasks here and watch your
            productivity soar!
          </span>

          <AppButton
            btnText="Create New Task"
            disabled={false} // Change this to your actual condition
            className="bg-black text-white w-full rounded-[10px] py-1 mt-6"
            btnType="button"
            onClick={redirectToTaskPage}
          />
          <button
            onClick={() =>
              setHomeState((prev) => ({
                ...prev,
                isCongrats: true,
              }))
            }
          >
            helo
          </button>
        </div>
      </div> */}

      <WishModals
        Title="In"
        message="Psst! Want to crush your day? Remember, a good night's sleep (at least 6 hours) is your secret weapon. Get those Zzz's and conquer!"
        state={homeState?.isCongrats}
        setState={setHomeState}
      />
    </div>
  );
};

export default Home;
