import React, { useEffect, useState } from "react";
import logoImage from "../../assets/images/logo.png";
import HelloScreen from "./HelloScreen";
import IntreastSkill from "./IntreastSkill";
import { useLocation } from "react-router-dom";

const SplashScreen = () => {
  const [splashData, setSplashData] = useState({
    helloScreen: false,
    intrestScreen: false,
  });
  const { state } = useLocation();

  useEffect(() => {
    if (!splashData.helloScreen) {
      setTimeout(() => {
        setSplashData((prev) => ({
          ...prev,
          helloScreen: true,
        }));
      }, 3000);
    }
  }, []);
  return (
    <div className="bg-primary-lighter w-full ">
      {state?.existing ? (
        <IntreastSkill splashData={splashData} setSplashData={setSplashData} />
      ) : (
        <>
          {!splashData?.helloScreen && !splashData.intrestScreen ? (
            <div className="logo-image flex w-full items-center justify-center h-[100vh]">
              <img src={logoImage} alt="log image" style={{ width: "160px" }} />
            </div>
          ) : splashData?.helloScreen ? (
            <HelloScreen
              splashData={splashData}
              setSplashData={setSplashData}
            />
          ) : splashData?.intrestScreen ? (
            <IntreastSkill
              splashData={splashData}
              setSplashData={setSplashData}
            />
          ) : (
            ""
          )}
        </>
      )}
    </div>
  );
};

export default SplashScreen;
