import React from "react";
import { useState } from "react";
import TabsCalender from "./TabsCalender";
import AppButton from "../../../components/Button/AppButton";
import flyingBird from "../../../assets/images/illustrations/flying_bird.svg";

import dateIcon from "../../../assets/images/icons/date_icon.svg";
import TaskSelection from "./TaskSelection";
import { DatePicker, Modal, TimePicker } from "antd";
import { formatedDayString, getWeekData } from "../../../Helpers/constants";

import NewCustomTaskModal from "./TaskModals/AddCustomTask";

import { useEffect } from "react";
import moment from "moment";
import { useTaskApis } from "./TaskModuleApis";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import HoursErrorModal from "./TaskModals/HoursErrorModal";
import CreateRoutineModal from "./TaskModals/CreateRoutineModal";
import { Field, Form, Formik, useFormik } from "formik";

const TaskPage = () => {
  const { createTask } = useTaskApis();
  const {
    AuthUser: {
      user: { user },
    },
  } = useSelector((state) => state);

  const { state } = useLocation();

  const [taskState, setTaskState] = useState({
    selectedIndex: 0,
    SelectedDay: [moment().format("yyyy-MM-DD")],
    isCustomOpen: false,
    isHrsCustomOpen: false,
    selectedDate: "",
    wakeUpTime: null,
    sleepTime: null,
    weekdata: [],
    timeError: "",
    task_name: [],
    loader: false,
    createRoutine: false,
    tenMehTaskOpen: false,
  });

  const navigate = useNavigate();
  const [loader, setLoader] = useOutletContext();

  const handleDateChange = (dateValue) => {
    setTaskState((prev) => ({
      ...prev,
      selectedDate: dateValue,
      selectedIndex: 0,
      SelectedDay: [moment(dateValue?.$d).format("yyyy-MM-DD")],
    }));
  };

  const setWakeupTime = (time) => {
    setTaskState((prev) => ({
      ...prev,
      wakeUpTime: time,
    }));
  };

  const setSleepTime = (time) => {
    setTaskState((prev) => ({
      ...prev,
      sleepTime: moment(time).startOf().subtract(1, "day"),
    }));
  };

  const handleTimeeChange = (time, field) => {
    setTaskState((prev) => ({
      ...prev,
      [field]: time,
      timeError: "",
    }));
  };

  useEffect(() => {
    const weeks = getWeekData();
    setTaskState((prev) => ({ ...prev, weekdata: weeks }));
  }, []);

  const onCreateTaskHanlder = () => {
    const difference = taskState?.sleepTime?.diff(
      taskState?.wakeUpTime,
      "hours",
      true
    );
    let sleepTime = moment(taskState?.sleepTime?.$d || taskState?.sleepTime?._d)
      .endOf()
      .subtract(1, "days")
      .format("yyyy-MM-DD hh:mm A");
    let WakeUpTime = moment(taskState?.wakeUpTime?.$d || taskState?.wakeUpTime?._d).format("yyyy-MM-DD hh:mm A");

    let atleastSixHoursSleep = moment(WakeUpTime).diff(
      moment(sleepTime),
      "minutes"
    );

    if (taskState?.task_name?.length > 10) {
      setTaskState((prev) => ({
        ...prev,
        tenMehTaskOpen: true,
      }));
    } else {
      if (
        taskState?.wakeUpTime === null ||
        (taskState?.sleepTime === null && taskState?.task_name?.length > 0)
      ) {
        setTaskState((prev) => ({
          ...prev,
          timeError: "Wake up time and sleep time are required",
        }));
      } else if (atleastSixHoursSleep >= 360) {
        // Proceed with task submission
        let wake_up_timeFlag = moment(taskState?.wakeUpTime?.$d || taskState?.wakeUpTime?._d);

        let timeFlag = "";

        if (wake_up_timeFlag.isBefore(moment())) {
          console.log("current");

          timeFlag = "current";
        } else {
          console.log("wakeup");
          timeFlag = "wake_up";
        }

        let data = {
          user_id: user?.id,
          assign_task_type:
            taskState?.selectedIndex === 0
              ? "single-day"
              : taskState?.selectedIndex === 1
                ? "week-days"
                : taskState?.selectedIndex === 2
                  ? "week-end"
                  : "",
          date:
            taskState?.selectedIndex === 0
              ? taskState?.SelectedDay?.[0]
              : taskState?.SelectedDay,
          current_time: moment().format("HH:mm A"),
          wake_up_time: moment(taskState?.wakeUpTime?.$d || taskState?.wakeUpTime?._d).format("hh:mm A"),
          sleep_time: moment(taskState?.sleepTime?.$d || taskState?.sleepTime?._d).format("hh:mm A"),
          task_name: taskState?.task_name,
          time_flag: timeFlag,
        };
        setTaskState((prev) => ({
          ...prev,
          loader: true,
        }));
        setLoader(true);
        createTask(data)
          .then((res) => {
            if (res.status === 200) {
              setTaskState((prev) => ({
                ...prev,
                loader: true,
              }));
              setLoader(false);
              navigate("/activity", {
                state: res?.data?.ai_tasks,
                isActivity: true,
              });
            }
          })
          .catch((error) => {
            setLoader(false);
          });
      } else {
        // Display an error message and prevent submission
        setTaskState((prev) => ({
          ...prev,
          timeError:
            // "Minimum 6 hours of difference between wake-up and sleep time required.",
            "",
        }));
        setTaskState((prev) => ({ ...prev, isHrsCustomOpen: true }));
        // alert('Minimum 6 hours of difference between wake-up and sleep time required.');
      }
    }
  };

  useEffect(() => {
    if (state) {
      let taskData = state?.map((ids) => parseInt(ids?.task_name_id));
      let userSleepAndWakeUpTime = state?.[0];

      if (userSleepAndWakeUpTime && taskData) {
        setTaskState((prev) => ({
          ...prev,
          wakeUpTime: moment(`${userSleepAndWakeUpTime?.wake_up_time}`, "h:mm A") || null,
          sleepTime: moment(` ${userSleepAndWakeUpTime?.sleep_time}`, "h:mm A") || null,
        }));
      }
    }
  }, []);

  console.log(taskState?.wakeUpTime, taskState?.sleepTime, "tme");

  const handleOk = () => {
    const difference = taskState?.sleepTime?.diff(
      taskState?.wakeUpTime,
      "hours",
      true
    );

    if (state) {
      let { task_date, wake_up_time, sleep_time } = state[state?.length - 1];

      let newWakeUpDate = moment(`${task_date} ${wake_up_time}`);
      let newSleepTime = moment(`${task_date} ${sleep_time}`);

      setTaskState((prev) => ({
        ...prev,
        wakeUpTime: newWakeUpDate,
        sleepTime: newSleepTime,
      }));
    } else {
      setTaskState((prev) => ({
        ...prev,
        wakeUpTime: null,
        sleepTime: null,
      }));
    }

    let sleepTime = moment(taskState?.sleepTime?.$d || taskState?.sleepTime?._d)
      .endOf()
      .subtract(1, "days")
      .format("yyyy-MM-DD hh:mm A");
    let WakeUpTime = moment(taskState?.wakeUpTime?.$d || taskState?.wakeUpTime?._d).format("yyyy-MM-DD hh:mm A");

    let atleastSixHoursSleep = moment(WakeUpTime).diff(
      moment(sleepTime),
      "minutes"
    );

    if (
      taskState?.wakeUpTime === null ||
      (taskState?.sleepTime === null && taskState?.task_name?.length > 0)
    ) {
      setTaskState((prev) => ({
        ...prev,
        timeError: "Wake up time and sleep time are required",
      }));
    } else if (atleastSixHoursSleep >= 360) {
      // Proceed with task submission

      let wake_up_timeFlag = moment(taskState?.wakeUpTime?.$d || taskState?.wakeUpTime?._d);

      let timeFlag = "";

      if (wake_up_timeFlag.isBefore(moment())) {
        timeFlag = "current";
      } else {
        timeFlag = "wake_up";
      }

      let data = {
        user_id: user?.id,
        assign_task_type:
          taskState?.selectedIndex === 0
            ? "single-day"
            : taskState?.selectedIndex === 1
              ? "week-days"
              : taskState?.selectedIndex === 2
                ? "week-end"
                : "",
        date:
          taskState?.selectedIndex === 0
            ? taskState?.SelectedDay?.[0]
            : taskState?.SelectedDay,
        current_time: moment().format("HH:mm A"),
        wake_up_time: moment(taskState?.wakeUpTime?.$d || taskState?.wakeUpTime?._d).format("hh:mm A"),
        sleep_time: moment(taskState?.sleepTime?.$d || taskState?.sleepTime?._d).format("hh:mm A"),
        task_name: taskState?.task_name,
      };
      setTaskState((prev) => ({
        ...prev,
        loader: true,
      }));
      setLoader(true);
      createTask(data)
        .then((res) => {
          if (res.status === 200) {
            setTaskState((prev) => ({
              ...prev,
              loader: true,
              tenMehTaskOpen: false,
            }));
            setLoader(false);
            navigate("/activity", {
              state: res?.data?.ai_tasks,
              isActivity: true,
            });
          }
        })
        .catch((error) => {
          setLoader(false);
        });
    } else {
      // Display an error message and prevent submission
      setTaskState((prev) => ({
        ...prev,
        timeError:
          // "Minimum 6 hours of difference between wake-up and sleep time required.",
          "",
      }));
      setTaskState((prev) => ({ ...prev, isHrsCustomOpen: true }));
      // alert('Minimum 6 hours of difference between wake-up and sleep time required.');
    }
  };

  const handleTenCancel = () => {
    setTaskState((prev) => ({ ...prev, tenMehTaskOpen: false }));
  };

  // const onChange = (time, timeString, type) => {
  //   setTaskState((prevActivityTask) => ({
  //     ...prevActivityTask,
  //     [type]: moment(timeString, "h:mm A"),
  //   }));

  // };

  const initialValues = {
    wakeUpTime: "", // You can set default values if needed
    sleepTime: "",
  };

  const handleTimeChange = (time, fieldName, form) => {
    form.setFieldValue(fieldName, time);
    setTaskState((prev) => ({
      ...prev,
      [fieldName]: moment(time?.$d),
      timeError: "",
    }));
  };

  return (
    <div className="relative lg:px-3 md:px-3 px-1 sm:px-2   pt-3">
      <div className="grid grid-cols-1  md:grid-cols-2 lg:grid-cols-2 gap-6 shadow bg-white p-3 rounded-[10px]">
        <div className="flex flex-col">
          <div className="title flex justify-between">
            <span></span>
            <h3 className="text-lg font-semibold">
              {moment(taskState?.SelectedDay?.[0]).format("dddd, MMM D")}
            </h3>
            <div className="calender datepicker_activity">
              {" "}
              <DatePicker
                disabledDate={(current) => {
                  let customDate = moment().format("YYYY-MM-DD");
                  return current && current < moment(customDate, "YYYY-MM-DD");
                }}
                selected={taskState?.selectedDate}
                onChange={handleDateChange}
                suffixIcon={<img className="w-4 h-4" src={dateIcon} />}
              // dateFormat="yyyy-MM-dd"
              />
            </div>
          </div>

          <TabsCalender taskState={taskState} setTaskState={setTaskState} />

          <div className="Time-select-section ">
            <div className="flex gap-5  flex-col sm:flex-row md:flex-row lg:flex-row">
              <div className="time-picker-field">
                <label className="text-sm font-medium text-black">
                  Wake Up Time <span className="text-error"> *</span>
                </label>
                <TimePicker
                  value={taskState?.wakeUpTime ? moment(taskState?.wakeUpTime?._d || taskState?.wakeUpTime?.$d) : null}
                  // defaultValue={taskState?.wakeUpTime}
                  onSelect={(time) => {
                    // handleTimeeChange(time, "wakeUpTime");
                    setTaskState((prev) => ({ ...prev, wakeUpTime: time }))
                  }}
                  format="hh:mm A"
                  className="w-full  rounded-[12px]"
                />
              </div>
              <div className="time-picker-field">
                <label className="text-sm font-medium text-black mb-2">
                  Sleep Time <span className="text-error">*</span>
                </label>
                <TimePicker
                  value={taskState?.sleepTime ? moment(taskState?.sleepTime?._d || taskState?.sleepTime?.$d) : null}
                  onSelect={(time) => {
                    // handleTimeeChange(time, "sleepTime");
                    setTaskState((prev) => ({ ...prev, sleepTime: time }))
                  }}
                  format="hh:mm A"
                  className="w-full rounded-[12px] -p-4"
                />
              </div>
            </div>

            {/* <Formik
              initialValues={initialValues}
              onSubmit={(values) => {
                console.log(values, "values getting mee");
              }}
            >
              <Form>
                {console.log(initialValues)}
                <div className="flex gap-5  flex-col sm:flex-row md:flex-row lg:flex-row">
                  <div className="time-picker-field">
                    <label className="text-sm font-medium text-black">
                      Wake Up Time <span className="text-error"> *</span>
                    </label>
                    <Field name="wakeUpTime">
                      {({ field, form }) => (
                        <TimePicker
                          {...field}
                          onSelect={(time) => {
                            handleTimeChange(time, "wakeUpTime", form);
                          }}
                          format="hh:mm A"
                          className="w-full  rounded-[12px]"
                        />
                      )}
                    </Field>
                  </div>
                  <div className="time-picker-field">
                    <label className="text-sm font-medium text-black mb-2">
                      Sleep Time <span className="text-error">*</span>
                    </label>
                    <Field name="sleepTime">
                      {({ field, form }) => (
                        <TimePicker
                          {...field}
                          onSelect={(time) => {
                            handleTimeChange(time, "sleepTime", form);
                          }}
                          format="hh:mm A"
                          className="w-full rounded-[12px] -p-4"
                        />
                      )}
                    </Field>
                  </div>
                </div>
              </Form>
            </Formik> */}

            {taskState?.timeError && (
              <span className="text-warning text-xs">
                {taskState?.timeError}
              </span>
            )}
          </div>
        </div>
        <div className="h-fit">
          <div className="title flex justify-between">
            <h4 className="text-lg lg:text-xl md:text-xl underline decoration-[#114442]  sm:font-medium font-medium lg:font-semibold md:font-semibold text-primary">
              Create Routine{" "}
            </h4>
            <AppButton
              btnText="New Custom"
              className="bg-primary-dark text-white py-1 px-2 rounded-[8px] "
              onClick={() => {
                setTaskState((prev) => ({ ...prev, isCustomOpen: true }));
              }}
            />
          </div>

          <TaskSelection
            taskState={taskState}
            setTaskState={setTaskState}
            user={user}
          />

          <AppButton
            btnText={`Done`}
            disabled={taskState.task_name?.length === 0 ? true : false}
            className={`w-full ${taskState.task_name?.length === 0
              ? "bg-ternary-lighter cursor-not-allowed text-ternary-light"
              : " bg-ternary"
              } font-semibold text-white py-[8px] px-2 rounded-[8px]`}
            onClick={onCreateTaskHanlder}
          />
        </div>
      </div>

      <NewCustomTaskModal
        state={taskState}
        setState={setTaskState}
        user={user}
      />

      <CreateRoutineModal
        taskState={taskState}
        setTaskState={setTaskState}
        state={state}
      />
      <HoursErrorModal state={taskState} setState={setTaskState} />

      <Modal
        open={taskState.tenMehTaskOpen}
        title=""
        onOk={handleTenCancel}
        destroyOnClose
        width={400}
        onCancel={handleTenCancel}
        closeIcon={false}
        footer={[
          <div className="text-start -pt-6 font-montserrat">
            <div className="flex items-center justify-center pb-4">
              <img src={flyingBird} className="h-24" />
            </div>

            <p className="font-semibold text-ternary pb-2">
              Hey, hey! Quick heads-up: less is more when it comes to tasks.
              Skip the overwhelm! Pick your top power moves instead of juggling
              10 meh tasks. Keep it real and prioritize like a boss!
            </p>
            <div className=" flex justify-end gap-2 pt-4 ">
              <AppButton
                onClick={handleOk}
                btnText="Continue"
                className="text-white bg-ternary rounded-[15px]  w-full font-bold  border-0  px-4 py-1 "
              />
            </div>
          </div>,
        ]}
      ></Modal>
    </div>
  );
};

export default TaskPage;
