import React from "react";
import { Modal, message } from "antd";
import wishImage from "../../assets/images/wishImage.svg";
import Lottie from "lottie-react";
import congracts from "../../assets/json/congracts.json";

const GamificationPopup = ({ parentState, setParentState, title, message }) => {
    const handleOk = () => {
        setParentState((prev) => ({ ...prev, IsGamificationOpen: false }));
    };
    const handleCancel = () => {
        setParentState((prev) => ({ ...prev, IsGamificationOpen: false }));
    };


    return (
        <>

            <Modal
                open={parentState?.IsGamificationOpen}
                title=""
                onOk={handleOk}
                destroyOnClose
                width={420}
                onCancel={handleCancel}
                closeIcon={false}
                footer={[
                    <div className="w-full relative text-center">
                        <div className="absolute top-0 ">
                            <Lottie
                                animationData={congracts}
                                className="bg-transparent"
                                style={{ background: "transparent !important" }}
                                loop={true}
                            />
                        </div>
                        <div className="pb-4 flex flex-col items-center">
                            <img src={wishImage} alt="asdf" width={100} height={100} />

                            <h2 className="font-semibold text-lg font-montserrat pt-2 py-3 text-primary-dark">
                                {title}
                            </h2>

                            <p className="text-sm font-montserrat  text-primary">
                                {message}
                            </p>
                        </div>
                    </div>,
                ]}
            >
            </Modal>

        </>
    );
};

export default GamificationPopup;


