import { DatePicker, Tooltip } from "antd";
import React from "react";
// import AppButton from "../../../components/Button/AppButton";
import Serchbar from "../../../components/Searchbar/Searchbar";
import AddJournalForm from "./AddJournalForm";
import { useSelector } from "react-redux";
import JournalListItem from "./JournalListItem";
import { useJournalApis } from "./JournalApis";
import { useEffect } from "react";
import { useState } from "react";
import dateIcon from "../../../assets/images/icons/date_icon.svg";
import { BiArrowBack } from "react-icons/bi";
import GamificationPopup from "../../../components/GamificationPopup/GamificationPopup";

const Journal = () => {
  const [journalState, setJournalState] = useState({
    journalList: [],
    globalJournal: [],
    render: false,
    addJournalView: false,
    IsGamificationOpen: false,
  });
  const {
    AuthUser: {
      user: { user },
    },
  } = useSelector((state) => state);

  const { getJournalsList, getSearchJournalsItem } = useJournalApis();

  const handleSerach = (query) => {
    if (query?.length > 0) {
      const filteredData = journalState?.globalJournal?.filter((ele) =>
        ele?.journal_title?.toLowerCase().includes(query.toLowerCase())
      );
      setJournalState({
        ...journalState,
        journalList: filteredData,
        render: false,
      });
    } else if (query?.length === 0) {
      setJournalState({
        ...journalState,
        journalList: journalState.globalJournal,
        render: false,
      });
    }
  };

  // getting journal List
  useEffect(() => {
    if (user) {
      getJournalsList(user?.id)
        .then((res) => {
          if (res?.status === 200) {
            setJournalState({
              ...journalState,
              journalList: res.data.journals,
              globalJournal: res.data.journals,
              render: false,
            });
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  }, [journalState.render]);

  return (
    <div className="bg-white  shadow-customshadow rounded-[15px] relative">
      <div className="grid grid-cols-12  ">
        <div className="col-span-12 md:col-span-5 lg:col-span-5 p-4 border-r mb-3 ">
          {journalState?.addJournalView ? (
            <>
              <div
                onClick={() => {
                  setJournalState((prev) => ({
                    ...prev,
                    addJournalView: false,
                  }));
                }}
                className="mobile-view w-[30px] h-[30px] hover:bg-gray-200 flex items-center justify-center rounded-full -mb-8 z-50"
              >
                <BiArrowBack />
              </div>
              <div className="rounded-[15px] py-4  mobile-view ">
                <AddJournalForm
                  journalState={journalState}
                  setJournalState={setJournalState}
                  user={user}
                />
              </div>
            </>
          ) : (
            <div>
              <div className="journal-header-contnet flex justify-between dekstop-view">
                <h1 className="text-lg lg:text-xl md:text-xl  sm:font-medium font-medium lg:font-semibold md:font-semibold ">
                  Journal
                </h1>
                <div className="flex gap-3">
                  {/* <div className="calender datepicker_activity">
                  <DatePicker
                    suffixIcon={<img className="w-4 h-4" src={dateIcon} />}
                  />
                </div> */}
                  <Tooltip placement="topLeft" title="Add Journal ">
                    <div
                      onClick={() => {
                        setJournalState((prev) => ({
                          ...prev,
                          addJournalView: true,
                        }));
                      }}
                      className="bg-primary mobile-view sm:hidden lg:hidden md:hidden cursor-pointer text-white w-[28px] h-[28px] flex items-center justify-center text-[20px]  rounded-full"
                    >
                      +
                    </div>
                  </Tooltip>
                </div>
              </div>
              <div className="searchbar--div py-3">
                <Serchbar onSearch={handleSerach} />
              </div>

              <div className="latest-post py-3">
                <ul
                  className="latest-journal-item_list custom-scrollbar "
                  style={{ height: "60vh", overflowY: "auto" }}
                >
                  {journalState?.journalList?.length > 0 ? (
                    journalState?.journalList?.map((journal, index) => {
                      let {
                        journal_attachment,
                        journal_desc,
                        journal_file,
                        journal_id,
                        journal_time,
                        journal_title,
                      } = journal;
                      return (
                        <JournalListItem
                          key={journal_id}
                          title={journal_title}
                          desc={journal_desc}
                          date={journal_time}
                          attachment={journal_file}
                          audio={journal_attachment}
                        />
                      );
                    })
                  ) : (
                    <li className="text-sm text-paragraph text-center">
                      Add new Journal Entry.
                    </li>
                  )}
                </ul>
              </div>
            </div>
          )}
        </div>
        <div className="col-span-12 md:col-span-7 lg:col-span-7 p-4 dekstop-view ">
          <div className=" rounded-[15px] py-4 ">
            <AddJournalForm
              journalState={journalState}
              setJournalState={setJournalState}
              user={user}
            />
          </div>
        </div>
      </div>

      <GamificationPopup
        parentState={journalState}
        setParentState={setJournalState}
        title={"Congratulation!"}
        message={
          "🔍 Reflect & Earn! 🌟 Your self-assessment skills are on point. Here are your points for reflecting and reviewing your progress. Keep the momentum going! 🚀🎉"
        }
      />
    </div>
  );
};

export default Journal;
