import { Progress } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AppButton from "../../../components/Button/AppButton";
import Chart from "./Chart";
import PlusIcon from "../../../assets/images/icons/plus_icon.svg";
import { useDahsbord } from "./DashboardApis";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { BsCheck } from "react-icons/bs";
import {
  MdHdrPlus,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from "react-icons/md";
import homeScreenArt from "../../../assets/images/illustrations/homeScreen.svg";
import DefaultHome from "../home/DefaultHome";
import ReschedulePopUp from "./ReschedulePopUp";
import { notifiactionsDyscores } from "../../../Helpers/constants";
import { closeRescheduleNotifiaction } from "../../../Store/Slices/DekstopNotificationSlice";
import FlyBirdModal from "../../../components/CommonModals/FlyBirdModal";

const Dashboard = () => {
  const [dashbardState, setDashboardState] = useState({
    dashboardData: {},
    activity: [],
    activityMessage: "",
    selectIndex: "0",
    currentMonth: moment(),
    params: {},
    disbaleFutureButtons: false,
    insidesReport: {},
    isRescheduleOpen: false,
    reScheduleTaskData: {},
    isflyBirdOpen: false
  });

  const { rescheduleData: { isReschduleNotificationOpen, taskData },
    AuthUser: {
      user: { user },
    },
  } = useSelector((state) => state);



  const {
    getDashboardDetail,
    getTaskActivites,
    getInsidesWeekData,
    getInsidesMonthData,
    getInsidesQuarterData,
    getInsidesYearData,
  } = useDahsbord();
  const navigate = useNavigate();

  const { state } = useLocation();


  const dispatch = useDispatch();

  const redirectToTaskPage = (pathName) => {
    if (pathName === "task") {
      navigate("/task", { state: dashbardState?.activity });
    } else if (pathName === "activity") {
      navigate("/activity");
    }
  };

  useEffect(() => {
    if (user)
      getDashboardDetail(user?.id)
        .then((res) => {
          if (res.status === 200) {
            if (Object.keys(res.data).length > 0) {
              setDashboardState((prev) => ({
                ...prev,
                dashboardData: res.data,
                activityMessage: "",
              }));
            } else {
              setDashboardState((prev) => ({
                ...prev,
                activityMessage: res.message,
                dashboardData: [],
              }));
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });

    getTaskActivites({ id: user?.id, date: moment().format("yyyy-MM-DD") })
      .then((res) => {
        if (res.status === 200) {
          if (res.status === 200) {
            setDashboardState((prev) => ({
              ...prev,
              activity: res.data.tasks_activities_list,
            }));

          }
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, [dashbardState?.isRescheduleOpen]);

  const handleselectValue = (value) => {
    if (value === "0") {
      const startOfWeek = moment(dashbardState.currentMonth, "YYYY-WW")
        .startOf("week")
        .format("YYYY-MM-DD");
      const endOfWeek = moment(dashbardState.currentMonth, "YYYY-WW")
        .endOf("week")
        .format("YYYY-MM-DD");

      const params = {
        user_id: user?.id,
        week_start: startOfWeek,
        week_end: endOfWeek,
      };
      setDashboardState((prev) => ({
        ...prev,
        selectIndex: value,
        params: params,
      }));
    }
    if (value === "1") {
      const month = moment(dashbardState.currentMonth).format("MM");

      const params = {
        user_id: user?.id,
        month: month,
      };
      setDashboardState((prev) => ({
        ...prev,
        selectIndex: value,
        params: params,
      }));
    }
    if (value === "2") {
      const startOfMonth = moment(dashbardState.currentMonth, "YYYY-Q")
        .startOf("quarter")
        .format("YYYY-MM-DD");
      const endOfMonth = moment(dashbardState.currentMonth, "YYYY-Q")
        .endOf("quarter")
        .format("YYYY-MM-DD");

      const params = {
        user_id: user?.id,
        start_quarter: startOfMonth,
        end_quarter: endOfMonth,
      };
      setDashboardState((prev) => ({
        ...prev,
        selectIndex: value,
        params: params,
      }));
    }
    if (value === "3") {
      const year = moment(dashbardState.currentMonth).format("YYYY");

      const params = {
        user_id: user?.id,
        year: year,
      };
      setDashboardState((prev) => ({
        ...prev,
        selectIndex: value,
        params: params,
      }));
    }
  };

  const onPreviouesHandler = () => {
    if (dashbardState.selectIndex === "0") {
      const previousWeek = moment(
        dashbardState.currentMonth,
        "YYYY-WW"
      ).subtract(1, "weeks");
      setDashboardState((prev) => ({
        ...prev,
        currentMonth: previousWeek,
        disbaleFutureButtons: false,
      }));
    } else if (dashbardState.selectIndex === "1") {
      const previousMonth = moment(dashbardState.currentMonth)
        .subtract(1, "months")
        .format("YYYY-MM");
      setDashboardState((prev) => ({
        ...prev,
        currentMonth: previousMonth,
        disbaleFutureButtons: false,
      }));
    } else if (dashbardState?.selectIndex === "2") {
      const previousQuarter = moment(dashbardState.currentMonth, "YYYY-Q")
        .subtract(1, "quarters")
        .format("YYYY-Q");
      setDashboardState((prev) => ({
        ...prev,
        currentMonth: previousQuarter,
        disbaleFutureButtons: false,
      }));
    } else if (dashbardState.selectIndex === "3") {
      const previousYear = moment(dashbardState.currentMonth)
        .subtract(1, "years")
        .format("YYYY");
      setDashboardState((prev) => ({
        ...prev,
        currentMonth: previousYear,
        disbaleFutureButtons: false,
      }));
    }
  };
  const onNextHandler = () => {
    if (dashbardState.selectIndex === "0") {
      const nextWeek = moment(dashbardState.currentMonth, "YYYY-WW").add(
        1,
        "weeks"
      );
      const currentYearEnd = moment().endOf("month");
      if (nextWeek.isBefore(nextWeek, "week")) {
        setDashboardState((prev) => ({
          ...prev,
          currentMonth: nextWeek.format("YYYY-WW"),
          disbaleFutureButtons: true,
        }));
      } else {
        setDashboardState((prev) => ({ ...prev, disbaleFutureButtons: true }));
      }
    } else if (dashbardState.selectIndex === "1") {
      const nextMonth = moment(dashbardState.currentMonth).add(1, "months");
      const currentMonthEnd = moment().endOf("month");
      if (nextMonth.isBefore(currentMonthEnd, "week")) {
        setDashboardState((prev) => ({
          ...prev,
          currentMonth: nextMonth.format("YYYY-MM"),
          disbaleFutureButtons: true,
        }));
      } else {
        setDashboardState((prev) => ({ ...prev, disbaleFutureButtons: true }));
      }
    } else if (dashbardState?.selectIndex === "2") {
      const nextQuarter = moment(dashbardState.currentMonth, "YYYY-Q").add(
        1,
        "quarters"
      );
      const currentYearEnd = moment().endOf("year");
      if (nextQuarter.isBefore(currentYearEnd, "week")) {
        setDashboardState((prev) => ({
          ...prev,
          currentMonth: nextQuarter.format("YYYY-Q"),
          disbaleFutureButtons: true,
        }));
      } else {
        setDashboardState((prev) => ({ ...prev, disbaleFutureButtons: true }));
      }
    } else if (dashbardState.selectIndex === "3") {
      const nextYear = moment(dashbardState.currentMonth).add(1, "years");
      const currentYearEnd = moment().endOf("year");
      if (nextYear.isBefore(currentYearEnd, "week")) {
        setDashboardState((prev) => ({
          ...prev,
          currentMonth: nextYear.format("YYYY"),
          disbaleFutureButtons: true,
        }));
      } else {
        setDashboardState((prev) => ({ ...prev, disbaleFutureButtons: true }));
      }
    }
  };

  const getQuarterRange = () => {
    const startOfMonth = moment(dashbardState?.currentMonth, "YYYY-Q")
      .startOf("quarter")
      .format("MMM");
    const endOfMonth = moment(dashbardState?.currentMonth, "YYYY-Q")
      .endOf("quarter")
      .format("MMM");
    const year = moment(dashbardState?.currentMonth, "YYYY-Q").format("YYYY");
    return `${startOfMonth} - ${endOfMonth} ${year}`;
  };

  const getWeekRange = () => {
    const startOfWeek = moment(dashbardState?.currentMonth, "YYYY-WW")
      .startOf("week")
      .format("D MMM");
    const endOfWeek = moment(dashbardState?.currentMonth, "YYYY-WW")
      .endOf("week")
      .format("D MMM");
    const year = moment(dashbardState?.currentMonth, "YYYY-WW").format("YYYY");
    return `${startOfWeek} - ${endOfWeek}, ${year}`;
  };

  useEffect(() => {
    if (dashbardState.selectIndex === "0") {
      const startOfWeek = moment(dashbardState.currentMonth, "YYYY-WW")
        .startOf("isoweek")
        .format("YYYY-MM-DD");
      const endOfWeek = moment(dashbardState.currentMonth, "YYYY-WW")
        .endOf("isoweek")
        .format("YYYY-MM-DD");

      const params = {
        user_id: user?.id,
        week_start: startOfWeek,
        week_end: endOfWeek,
      };

      getInsidesWeekData(params)
        .then((res) => {
          if (res.status === 200) {
            setDashboardState((prev) => ({
              ...prev,
              params: params,
              insidesReport: res.data.your_insights_week,
            }));
          }
        })
        .catch((error) => console.log("error", error));
    }
    if (dashbardState.selectIndex === "1") {
      const month = moment(dashbardState.currentMonth).format("MM");

      const params = {
        user_id: user?.id,
        month: month,
      };
      getInsidesMonthData(params)
        .then((res) => {
          if (res.status === 200) {
            if (res.status === 200) {
              setDashboardState((prev) => ({
                ...prev,
                params: params,
                insidesReport: res.data.your_insights_month,
              }));
            }
          }
        })
        .catch((error) => console.log("error", error));
    }
    if (dashbardState.selectIndex === "2") {
      const startOfMonth = moment(dashbardState.currentMonth, "YYYY-Q")
        .startOf("quarter")
        .format("YYYY-MM-DD");
      const endOfMonth = moment(dashbardState.currentMonth, "YYYY-Q")
        .endOf("quarter")
        .format("YYYY-MM-DD");

      const params = {
        user_id: user?.id,
        start_quarter: startOfMonth,
        end_quarter: endOfMonth,
      };
      getInsidesQuarterData(params)
        .then((res) => {
          if (res.status === 200) {
            if (res.status === 200) {
              setDashboardState((prev) => ({
                ...prev,
                params: params,
                insidesReport: res.data.your_insights_month,
              }));
            }
          }
        })
        .catch((error) => console.log("error", error));
    }
    if (dashbardState.selectIndex === "3") {
      const year = moment(dashbardState.currentMonth).format("YYYY");

      const params = {
        user_id: user?.id,
        year: year,
      };
      getInsidesYearData(params)
        .then((res) => {
          if (res.status === 200) {
            if (res.status === 200) {
              setDashboardState((prev) => ({
                ...prev,
                params: params,
                insidesReport: res.data.your_insights_month,
              }));
            }
          }
        })
        .catch((error) => console.log("error", error));
    }
  }, [dashbardState.currentMonth, dashbardState.selectIndex]);

  useEffect(() => {
    if (state) {

      setDashboardState((prev) => ({
        ...prev, isRescheduleOpen: true, reScheduleTaskData: state
      }))

    }
  }, [state])


  useEffect(() => {
    const closeDate = localStorage.getItem("goodToSeeYouCloseData")
    if (!closeDate || closeDate === null || moment().format("yyyy-MM-DD") !== moment(closeDate).format("yyyy-MM-DD")) {
      setDashboardState((prev) => ({
        ...prev,
        isflyBirdOpen: true
      }))
    }
  }, [])





  return (
    <>
      {Object.keys(dashbardState?.dashboardData).length > 0 ? (
        <>
          <div className="dashbord-sections ">
            <div className="grid grid-cols-12 gap-7">
              <div className="col-span-12 md:col-span-7 lg:col-span-7">
                <div className="welcome_short_dashboard p-4 bg-[#4A9D93] rounded-[15px] mb-4">
                  <p className="text-sm text-white">
                    {moment(
                      dashbardState?.dashboardData?.todays_date_time
                    ).format("dddd, MMM D YYYY")}
                  </p>
                  {dashbardState?.dashboardData?.completed_tasks === 0 ? (
                    <p className="text-lg lg:text-xl md:text-xl   font-medium  text-white  py-1">
                      {
                        dashbardState?.dashboardData?.slogan_for_crushing
                          ?.slogan_name
                      }
                    </p>
                  ) : (
                    <>
                      <h4 className="text-lg lg:text-xl md:text-xl   font-medium  text-white  py-1">
                        Crushing it!
                      </h4>

                      <div className="imge-section flex relative py-3 ]">
                        {dashbardState.dashboardData?.tasks_image
                          ?.slice(0, 8)
                          ?.map((img, id) => {
                            return (
                              <div
                                key={id}
                                className="w-10 h-10 rounded-full relative -ml-3"
                              >
                                <img
                                  src={`${process.env.REACT_APP_FILE_URL_TASKS}/${img}`}
                                  alt=""
                                  className="w-full h-full object-cover bg-[#FFEFE0] rounded-full  shadow-[1px_0.5px_0.8px_0.1px] border-[3px] border-[#A1CEC5]"
                                />
                              </div>
                            );
                          })}
                      </div>


                      <div className="progress-bar relative text-2xl font-medium ">
                        <Progress
                          percent={
                            dashbardState?.dashboardData
                              ?.completed_task_percentage?.length === 2
                              ? dashbardState?.dashboardData?.completed_task_percentage?.slice(
                                0,
                                1
                              )
                              : dashbardState?.dashboardData
                                ?.completed_task_percentage?.length === 3
                                ? dashbardState?.dashboardData?.completed_task_percentage?.slice(
                                  0,
                                  2
                                ) : dashbardState?.dashboardData?.completed_task_percentage?.slice(
                                  0,
                                  3
                                )
                          }
                          strokeColor="#f49789"

                          format={(percent) => {
                            let PercentageShow = <span className="text-white font-semibold">{`${percent}%`}</span>
                            return PercentageShow;
                          }}
                          trailColor="#67ada4"
                        />
                        <div className="ui-task-update absolute top-[18px] text-white text-xs mt-1">
                          {dashbardState?.dashboardData?.completed_tasks || 0}/
                          {dashbardState?.dashboardData?.total_tasks || 0} Tasks
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div className="bg-white rounded-[15px] shadow-customshadow  p-4">
                  <div className="w-full flex justify-between mb-3">
                    <h3 className="text-lg lg:text-xl md:text-xl  font-semibold">
                      {dashbardState?.dashboardData?.tasks?.text}
                    </h3>
                    <AppButton
                      leftIcon={<img className="" src={PlusIcon} />}
                      btnText="Add Task"
                      disabled={false} // Change this to your actual condition
                      className="py-1 px-3 bg-primary-dark text-white font-semibold rounded-[10px]"
                      btnType="button"
                      onClick={() => redirectToTaskPage("task")}
                    />
                  </div>
                  <div className="taskList ">
                    <ul
                      style={{ height: "60vh", overflowY: "auto" }}
                      className="custom-scrollbar pb-4"
                    >
                      {dashbardState?.dashboardData?.tasks?.tasks_data?.length >
                        0 ? (
                        dashbardState?.dashboardData?.tasks?.tasks_data.map(
                          (task) => {
                            let {
                              task_id,
                              task_start_time,
                              task_end_time,
                              task_sub_category_name,
                              task_sub_category_image,
                              task_status,
                              task_assigned_member_name,
                            } = task;
                            return (
                              <li
                                className="bg-[#FBFBFB] mb-2 py-2 px-3 rounded"
                                key={task_id}
                              >
                                <div className="flex justify-between items-center">
                                  <div className="flex">
                                    {task_status === "1" ? (
                                      <div className="w-[40px] h-[40px] rounded-full bg-[#18605D33] flex items-center justify-center ">
                                        <BsCheck className="text-4xl font-extrabold text-primary" />
                                      </div>
                                    ) : (
                                      <img
                                        src={`${process.env.REACT_APP_FILE_URL_TASKS}/${task_sub_category_image}`}
                                        alt=""
                                        className="w-[40px] h-[40px] rounded-full"
                                      />
                                    )}
                                    <div className="ms-3">
                                      <h5
                                        className={`flex flex-col text-lg font-semibold ${task_status === "1"
                                          ? "text-primary"
                                          : "text-black"
                                          }`}
                                      >
                                        {task_sub_category_name}
                                        <span className="text-xs text-grayLight font-medium">
                                          {task_status === "1" ? (
                                            <span className="text-primary">
                                              Completed!
                                            </span>
                                          ) : task_status === "2" ? (
                                            <span className="text-red-500">
                                              Cancelled
                                            </span>
                                          ) : (
                                            <span className="text-paragraph">
                                              Not Started
                                            </span>
                                          )}
                                          {task_assigned_member_name && (
                                            <p>
                                              Task assigned to{" "}
                                              {task_assigned_member_name}
                                            </p>
                                          )}
                                        </span>
                                      </h5>
                                    </div>
                                  </div>
                                  <div
                                    className={`text-xs font-medium  ${task_status === "1"
                                      ? "text-primary"
                                      : "text-[#8A8A8A]"
                                      }`}
                                  >
                                    {task_start_time} To {task_end_time}
                                  </div>
                                </div>
                              </li>
                            );
                          }
                        )
                      ) : (
                        <div className="text-center text-paragraph text-sm min-h-[20vh] ">
                          No task added yet.
                        </div>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-span-12 md:col-span-5 lg:col-span-5 ">
                <div className="activity_list  bg-white p-4 mb-4 shadow-customshadow rounded-[15px]">
                  <div className="title_activity flex justify-between mb-6 items-center">
                    <h4 className="text-lg lg:text-xl md:text-xl font-semibold">
                      Activities
                    </h4>
                    <AppButton
                      btnText="see all"
                      disabled={false} // Change this to your actual condition
                      className="py-1 px-3  text-primary-dark  rounded-[10px]"
                      btnType="button"
                      onClick={() => redirectToTaskPage("activity")}
                    />
                  </div>
                  <ul>
                    {dashbardState?.dashboardData?.tasks?.tasks_data?.length >
                      0 ? (
                      dashbardState?.dashboardData?.tasks?.tasks_data
                        ?.slice(0, 4)
                        ?.map((activity) => {
                          let {
                            task_id,
                            task_start_time,
                            task_end_time,
                            task_sub_category_name,
                            task_sub_category_image,
                            task_status,
                          } = activity;

                          return (
                            <li
                              className="bg-[#FBFBFB]  mb-2 py-2 px-3 rounded"
                              key={task_id}
                            >
                              <div className="flex justify-between items-center">
                                <div className="flex">
                                  <img
                                    src={`${process.env.REACT_APP_FILE_URL_TASKS}/${task_sub_category_image}`}
                                    alt=""
                                    className="w-[40px] h-[40px] rounded-full"
                                  />
                                  <div className="ms-3">
                                    <h5 className="flex flex-col text-base font-semibold">
                                      {task_sub_category_name}
                                      <span className="text-sm text-paragraph font-medium">
                                        {task_start_time} To {task_end_time}
                                      </span>
                                    </h5>
                                  </div>
                                </div>
                              </div>
                            </li>
                          );
                        })
                    ) : (
                      <div className="text-center text-paragraph text-sm min-h-[20vh]">
                        No activity added yet
                      </div>
                    )}
                  </ul>
                </div>

                <div className="overview-task bg-white p-4 rounded-[15px] shadow-customshadow">
                  <div className="insides_header flex justify-between items-center">
                    <h4 className="text-lg lg:text-xl md:text-xl  font-medium">
                      Your Insights{" "}
                    </h4>

                    <select
                      name=""
                      id=""
                      className="border p-1 rounded"
                      value={dashbardState.selectIndex}
                      onChange={(e) => handleselectValue(e.target.value)}
                    >
                      <option value="0">Week</option>
                      <option value="1">Month</option>
                      <option value="2">Quarter</option>
                      <option value="3">Year</option>
                    </select>
                  </div>
                  <div className="month-week-changes flex justify-between py-4">
                    <span
                      className={`bg-primary-lighter flex items-center justify-center rounded px-2`}
                      onClick={() => onPreviouesHandler()}
                      title="Previous"
                    >
                      <MdKeyboardArrowLeft />
                    </span>
                    <div className="bg-primary-lighter flex items-center justify-center rounded px-2 select-none	">
                      {dashbardState.selectIndex === "1"
                        ? moment(dashbardState.currentMonth).format("MMMM YYYY")
                        : dashbardState.selectIndex === "2"
                          ? getQuarterRange()
                          : dashbardState.selectIndex === "3"
                            ? moment(dashbardState.currentMonth).format("YYYY")
                            : getWeekRange()}
                    </div>
                    <span
                      className={` ${dashbardState?.disbaleFutureButtons
                        ? "cursor-not-allowed bg-gray-300"
                        : "bg-primary-lighter"
                        }  flex items-center justify-center rounded px-2`}
                      onClick={() => onNextHandler()}
                      title={
                        dashbardState?.disbaleFutureButtons
                          ? "Can't check future Dates"
                          : "Next"
                      }
                    >
                      <MdKeyboardArrowRight />
                    </span>
                  </div>
                  {Object.keys(dashbardState.insidesReport).length !== 0 && (
                    <Chart data={dashbardState.insidesReport} />
                  )}
                </div>
              </div>
            </div>
          </div>

          <ReschedulePopUp

            dashboardState={dashbardState}
            setDashboardState={setDashboardState}
            user={user}
          />
        </>
      ) : (
        <>
          {dashbardState?.activityMessage && (
            <DefaultHome message={dashbardState?.activityMessage} />
          )}
        </>
      )}
      <FlyBirdModal state={dashbardState} setState={setDashboardState} title={"It’s so good to see you again!"} message={"Haven’t seen you for a while. Welcome Back!"} button={false} modalUsage={"ShowGood"} />
    </>
  );
};

export default Dashboard;
