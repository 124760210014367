import instance from "../../../AxiosFile/AxiosInstance";

export function useJournalApis() {
  const createJournal = async (body) => {
    // api call to create a journal entry
    let res = await instance.post(`/api/journal_store`, body, {
      headers: {
        "Content-type": "multipart/form-data",
      },
    });
    return res.data;
  };

  const getJournalsList = async (id) => {
    // api call to create a journal entry
    let res = await instance.get(`/api/journal_list`, {
      params: {
        user_id: id,
      },
    });
    return res.data;
  };

  const getSearchJournalsItem = async ({ id, query }) => {
    // api call to create a journal entry
    let res = await instance.get(`/api/journal_search`, {
      params: {
        user_id: id,
        keyword: query,
      },
    });
    return res.data;
  };

  return { getJournalsList, createJournal, getSearchJournalsItem };
}
