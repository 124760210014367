import React from 'react'
import AuthPageImage from '../../components/AuthPagesImage/AuthPageImage'
import AppButton from '../../components/Button/AppButton'
import Image from "../../assets/images/resetBg.svg"
import Logo from "../../assets/images/logo.png"
import { Link } from 'react-router-dom'
import handImage from "../../assets/images/hand.svg"

const HelloScreen = ({ splashData, setSplashData }) => {
    const goOnIntrestSkill = () => {
        setSplashData((prev) => ({
            ...prev, intrestScreen: true, helloScreen: false
        }))
    }

    return (
        <div className="w-full  ">
            <div className="w-[100%]    flex h-screen">
                <div className="w-[50%]   lg:block md:hidden sm:hidden hidden  ">
                    <AuthPageImage ImageUrl={Image} logo={Logo} />
                </div>
                <div className="w-full   lg:w-[50%]  md:w-full  sm:w-full  px-4   sm:px-28  py-2 flex flex-col justify-center items-center relative bg-white">
                    <div className=" flex flex-col">
                        <h2 className="text-lg py-1 font-semibold">Make your life simple!</h2>
                        <p className="py-2 text-paragraph pb-3">
                            Unlock a world of possibilities and reclaim your valuable time. <span className='text-primary font-semibold'>Lakshya</span> uses behavioral science to simplify your life and make every moment more enjoyable.
                        </p>
                        <p className='py-2 text-paragraph pb-3 mb-6'>Let's embark on this transformative journey together and discover a better way to live, work, and thrive!</p>
                        <div className='text-center '>

                            <AppButton onClick={() => goOnIntrestSkill()} rightIcon={<img src={handImage} alt="sdf" />} className="w-full items-center justify-center gap-2  px-12 bg-ternary text-white py-2 flex   rounded-xl " btnText='Hello' disabled={false} />
                        </div>

                    </div>
                    <div className="dont_have_account    absolute bottom-3  text-center ">
                        <p className="w-full ps-2 text-paragraph ">
                            Already have an account?{" "}
                            <Link to="/login" className="font-semibold text-primary">
                                login
                            </Link>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HelloScreen