import { ErrorMessage, Field, Form, Formik } from 'formik'
import React, { useState } from 'react'
import * as Yup from "yup";
import AppButton from '../../../components/Button/AppButton'
import { notification } from 'antd';
import { useSettings } from './settingApis';
import BlockedTab from './BlockedTab';
import { AiFillCaretDown, AiFillCaretRight } from 'react-icons/ai';
import { BiArrowBack } from 'react-icons/bi';

const MobileViewSetting = ({ setSettingState, user }) => {
    const [mobileviewState, setMobileviewState] = useState({
        activeTab: "",

    })
    const validationSchema = Yup.object().shape({
        feedback: Yup.string().required("Feedback is required"),
    });
    const { sendFeedback } = useSettings();
    const initialValues = {
        feedback: "",
    };

    const handleFeedback = (value, { resetForm }) => {
        let data = {
            user_id: user?.id,
            feedback: value?.feedback,
        };
        sendFeedback(data)
            .then((res) => {
                if (res.status === 200) {
                    setSettingState((prev) => ({
                        ...prev,
                        error: "Feedback sent successfully",
                        loader: false,
                        IsGamificationOpen: true
                    }));
                    notification["success"]({
                        message: "Feedback sent successfully",
                        style: {
                            fontFamily: 'Montserrat, sans-serif',
                        },
                    });

                    resetForm();
                }
            })
            .catch((err) => {
                console.error(err);
                notification["error"]({
                    message: "Something went wrong",
                    style: {
                        fontFamily: 'Montserrat, sans-serif',
                    },
                });
            });
    };

    return (
        <div >

            <div className="col-span-12 md:col-span-5 lg:col-span-5 border-r  p-4">
                {!mobileviewState?.activeTab ? <>
                    <h3 className="text-lg lg:text-xl md:text-xl sm:text-lg sm:font-medium font-medium lg:font-semibold md:font-semibold">
                        Settings
                    </h3>
                    <ul className="mx-2 pt-4">
                        {[
                            "App Feedback",
                            "Privacy Policy",
                            "Terms & Condition",
                            "Blocked user",
                            "Help",
                        ].map((service, index) => {
                            return (
                                <li
                                    key={index + 1}
                                    onClick={() => setMobileviewState((prev) => ({ ...prev, activeTab: service }))}

                                    className={`py-3 flex justify-between px-4  w-full text-foundationDark   mb-3 text-lg font-medium rounded-full cursor-pointer`}
                                >
                                    {service} <span span >
                                        {mobileviewState?.activeTab === service ? (
                                            <AiFillCaretDown />
                                        ) : (
                                            <AiFillCaretRight />
                                        )
                                        }
                                    </span>
                                </li>
                            );
                        })}
                    </ul>

                </> :
                    <div className='w-full'>
                        <div className='flex items-center w-full  border-b '>

                            <span className=" w-[30px] ms-4 h-[30px] hover:bg-gray-200 flex items-center justify-center rounded-full  z-2000" onClick={() => setMobileviewState({ activeTab: "" })}> <BiArrowBack /></span>
                            <h5 className="  p-3   text-lg lg:text-xl md:text-xl sm:text-lg sm:font-medium font-medium lg:font-semibold md:font-semibold">
                                {mobileviewState?.activeTab}
                            </h5>
                        </div>

                        {
                            mobileviewState?.activeTab === "App Feedback" && (
                                <div className="flex flex-col py-4 relative w-full">
                                    <Formik
                                        initialValues={initialValues}
                                        validationSchema={validationSchema}
                                        onSubmit={handleFeedback}
                                    >
                                        <Form className="mx-4">
                                            <Field
                                                as="textarea"
                                                id="feedback"
                                                name="feedback"
                                                rows="8" // Set the number of rows to 12
                                                className="my-2 w-[90%] mx-auto p-2 border rounded bg-background outline-none"
                                                placeholder="Write something..."
                                            />
                                            <ErrorMessage
                                                name="feedback"
                                                component="div"
                                                className="formik-error text-error"
                                            />
                                            <div className="lg:mx-40 md:mx-20 sm:mx-4 mx-4">
                                                <AppButton
                                                    btnType="submit"
                                                    btnText="Send"
                                                    className="bg-black text-white w-full mx-auto py-2 px-4 mt-4 rounded-full"
                                                />
                                            </div>
                                        </Form>
                                    </Formik>
                                </div>
                            )
                        }

                        {
                            mobileviewState?.activeTab === "Privacy Policy" && (
                                <div className="mx-4">
                                    <p className="pt-3">This  clear instructions to click on the provided URL to access the privacy policy. </p>
                                    <a target="_blank" href="https://admin.dyscore.prometteur.in/privacy-and-policy">
                                        <AppButton
                                            btnText="Click Here"
                                            className=" text-blue-500    rounded-full"
                                        />
                                    </a>
                                </div>
                            )
                        }

                        {
                            mobileviewState?.activeTab === "Terms & Condition" && (
                                <div className="mx-4">
                                    <p className="pt-3">This  clear instructions to click on the provided URL to access the terms and conditions.</p>
                                    <a target="_blank" href="https://admin.dyscore.prometteur.in/terms-and-conditions ">
                                        <AppButton
                                            btnText="Click Here"
                                            className=" text-blue-500    rounded-full"
                                        />
                                    </a>
                                </div>
                            )
                        }


                        {mobileviewState?.activeTab === "Blocked user" && <BlockedTab user={user} />}

                        {
                            mobileviewState?.activeTab === "Help" && (
                                <div className="mx-4">
                                    {/* <p className="">Link</p> */}
                                    <a target="_blank" href="https://dyscore.prometteur.in/">
                                        <AppButton
                                            btnText="Click Here"
                                            className="bg-black text-white  w-1/2 mx-auto py-2 px-4 mt-4 rounded-full"
                                        />
                                    </a>
                                </div>
                            )
                        }
                    </div >}



            </div >
        </div>
    )
}

export default MobileViewSetting