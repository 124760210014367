import { createSlice } from "@reduxjs/toolkit";

const reScheduleSlice = createSlice({
  name: "dekstopNotification",
  initialState: {
    isReschduleNotificationOpen: false,
    taskData: {},
    render: false,
  },

  reducers: {
    isReschduleNotification: (state, action) => {
      state.isReschduleNotificationOpen = true;
      state.taskData = action.payload;
    },
    closeRescheduleNotifiaction: (state, actions) => {
      state.isReschduleNotificationOpen = false;
      state.taskData = {};
    },
    render: (state) => {
      state.render = !state.render;
    },
  },
  extraReducers: {},
});

export default reScheduleSlice.reducer;

export const { isReschduleNotification, closeRescheduleNotifiaction, render } =
  reScheduleSlice.actions;
