import {
  faDumbbell,
  faShare,
  faTimes,
  faUserEdit,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import accIcon from "../../../assets/images/personIcon.svg";
import React, { useEffect } from "react";
import { useState } from "react";
import EditProfile from "./EditProfile";
import { HiOutlineStar } from "react-icons/hi";
import { TbUserStar } from "react-icons/tb";
import { LiaUserCheckSolid, LiaAwardSolid } from "react-icons/lia";
import { BsQuestionCircle } from "react-icons/bs";
import AddUser from "./Modals/AddUser";
import EditImage from "./Modals/EditImage";
import { MdLockOutline, MdOutlineCameraEnhance } from "react-icons/md";
import StepModal from "./Modals/StepsModals";
import { useUserProfileModalState } from "./ProfileApi";
import SocialFriendsList from "./SocialFriendsList";
import SuggestionList from "./SuggestionList";
import { getImageProfile, streakItemReturn } from "../../../Helpers/constants";
import { StepsData } from "../../../Helpers/data";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AppButton from "../../../components/Button/AppButton";
import { AiFillMessage, AiFillSetting } from "react-icons/ai";
import { FaUserPlus } from "react-icons/fa";
import { BiSolidUserMinus } from "react-icons/bi";
import RewardPopup from "./Modals/RewardPopup";
import maxtreck_icon from "../../../assets/images/icons/maxtreck_icon.svg";
import following_icon from "../../../assets/images/icons/following_icon.svg";
import follower_icon from "../../../assets/images/icons/follower_icon.svg";
import add_user_icon from "../../../assets/images/icons/add_user_icon.svg";
import { sendNewUserMessage } from "../../../firebase/chat";
import { CgGym } from "react-icons/cg";
import { RiUserSearchLine } from "react-icons/ri";
import GamificationPopup from "../../../components/GamificationPopup/GamificationPopup";
import { LogIn } from "../../../Store/Slices/AuthSlice";

const Profile = () => {
  const { AuthUser } = useSelector((state) => state);
  const {
    customProfileState: {
      userProfile: { user, looking_for, statistics, friends_suggestion },
      followerList,
      followingList,
      suggestionList,
    },
    suggestionFreindFollow,
    fetchUserProfile,
    fetchFreindUserProfile,
    unFollowRequest,
  } = useUserProfileModalState();
  const userParamsId = useParams();
  const dispatch = useDispatch()

  const [profileState, setProfileState] = useState({
    userProfile: {},
    editProfile: false,
    addMemberIsOpen: false,
    editProfileIsOpen: false,
    stepIsModalOpen: false,
    seeList: "suggestion",
    render: false,
    loader: false,
    isRewardOpen: false,
    previousReward: null,
    IsGamificationOpen: false,
    urlImage: "",
    imageType: ""
  });
  const navigate = useNavigate();
  const editProfileHanlder = () => {
    setProfileState((prev) => {
      return { ...prev, editProfile: !prev?.editProfile };
    });
  };

  const onSocialListClick = (nameOfSeeList) => {
    setProfileState(() => ({ ...profileState, seeList: nameOfSeeList }));
    if (profileState.seeList === "followers") {
    } else if (profileState.seeList === "following") {
    }
  };



  useEffect(() => {
    if (userParamsId.id) {
      setProfileState((prev) => ({
        ...prev,
        loader: true
      }));
      fetchFreindUserProfile({
        id: AuthUser?.user?.user?.id,
        friend_id: userParamsId.id,
      }).then((res) => {
        if (res.status === 200) {
          setProfileState((prev) => ({
            ...prev,
            userProfile: res?.data,
            render: false,
            loader: false
          }));
        }
      });
    } else {
      setProfileState((prev) => ({
        ...prev,
        loader: true
      }));
      AuthUser?.user?.user && fetchUserProfile(AuthUser?.user?.user?.id).then((res) => {
        if (res.status === 200) {

          dispatch(LogIn({ ...AuthUser?.user, user: { ...AuthUser?.user?.user, profile_image: res.data?.user?.profile_image } }));
          localStorage.setItem("user", JSON.stringify({ ...AuthUser?.user, user: { ...AuthUser?.user?.user, profile_image: res.data?.user?.profile_image } }));
          const previousRewardPoints = localStorage.getItem(
            "previousRewardPoints"
          );
          const newRewardPoints = res?.data?.statistics?.points;
          if (newRewardPoints > parseInt(previousRewardPoints)) {
            setProfileState((prev) => ({
              ...prev,
              isRewardOpen: true,
              loader: false
            }));
          }
          if (newRewardPoints !== parseInt(previousRewardPoints)) {
            setProfileState((prev) => ({
              ...prev,

              userProfile: res?.data,
              previousReward: previousRewardPoints,
              render: false,
              loader: false
            }));
            localStorage.setItem("previousRewardPoints", newRewardPoints);
          } else {
            setProfileState((prev) => ({
              ...prev,
              userProfile: res?.data,
              render: false,
              loader: false
            }));
          }
        }
      });
    }
  }, [
    profileState?.editProfile,
    userParamsId.id,
    profileState?.render,
    profileState?.editProfileIsOpen,
  ]);

  const followSuggestionFriend = (id) => {
    if (id) {
      let data = {
        user_id: AuthUser?.user?.user?.id,
        friend_user_id: id,
      };

      suggestionFreindFollow(data)
        .then((res) => {
          if (res.status === 200) {
            setProfileState((prev) => ({
              ...prev,
              render: true,
              IsGamificationOpen: true,
            }));
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  const unfollowFreind = (id) => {
    if (id) {
      let data = {
        user_id: AuthUser?.user?.user?.id,
        friend_user_id: id,
      };

      unFollowRequest(data)
        .then((res) => {
          if (res.status === 200) {
            setProfileState((prev) => ({
              ...prev,
              render: true,
            }));
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  const handlechat = (user, toUser) => {
    sendNewUserMessage(user, toUser);
    navigate("/chat", {
      state: {
        user_ids: `${user?.id}-${toUser?.id}`,
        from_user: user,
        to_user: toUser,
      },
    });
  };

  useEffect(() => { }, [profileState?.editProfileIsOpen]);






  return (
    <div className="profile-section">
      {profileState?.loader ? <div className="loader-container">
        <div className="loader"></div>
      </div> : ""}
      <div className="grid grid-cols-12 gap-6">
        <div className="col-span-12 lg:col-span-7 md:col-span-7">
          {profileState?.editProfile && !userParamsId?.id ? (
            <EditProfile
              profileState={profileState}
              setProfileState={setProfileState}
              user={profileState?.userProfile?.user}
              looking_for={profileState?.userProfile?.looking_for}
            />
          ) : (
            <>
              <div className="profile_info  bg-white shadow-customshadow p-8 rounded-[20px]">
                <div className="img-info flex items-center flex-col lg:flex-row md:flex-row gap-5 ">
                  <div
                    className="shadow-md rounded-[32px] relative flex justify-center w-[130px] h-[130px]"
                    style={{ border: "7px solid white" }}
                  >
                    <img
                      src={
                        profileState?.userProfile?.user?.profile_image
                          ? `${process.env.REACT_APP_FILE_URL_PROFILE}/${profileState?.userProfile?.user?.profile_image} `
                          : `https://static.vecteezy.com/system/resources/thumbnails/004/511/281/small/default-avatar-photo-placeholder-profile-picture-vector.jpg`
                      }
                      alt=""
                      className="rounded-3xl w-full h-full object-cover "
                    />
                    {/* {!userParamsId?.id && (
                      <div className="plus-icon absolute -bottom-8 ">
                        <div
                          className="w-[35px] shadow h-[35px] cursor-pointer rounded-full text-sm bg-secondary flex items-center justify-center"
                          style={{ border: "5px solid white" }}
                          onClick={() => {
                            setProfileState((prev) => ({
                              ...prev,
                              editProfileIsOpen: true,
                            }));
                          }}
                        >
                          <MdOutlineCameraEnhance className="text-white" />
                        </div>
                      </div>
                    )} */}
                  </div>
                  <div className="div">
                    <h2>
                      {`${profileState?.userProfile?.user?.first_name
                        ? profileState?.userProfile?.user?.first_name
                        : ""
                        } ${profileState?.userProfile?.user?.last_name
                          ? profileState?.userProfile?.user?.last_name
                          : ""
                        }`}
                    </h2>
                    <p className="text-sm text-paragraph">
                      Bio :{" "}
                      {profileState?.userProfile?.user?.about
                        ? profileState?.userProfile?.user?.about
                        : ""}
                    </p>
                  </div>
                </div>

                <div className="looking-for flex flex-col lg:flex-row md:flex-row gap-1 pt-8">
                  <h3 className="font-lg font-bold text-ternary-light">
                    Looking For -
                  </h3>
                  <div className="flex gap-3 items-center">
                    {profileState?.userProfile?.looking_for?.length > 0 ? (
                      profileState?.userProfile?.looking_for?.map(
                        (item, index) => {
                          return (
                            <button
                              key={index + 1}
                              className="rounded-[20px] flex gap-2 items-center px-4  py-1 text-sm bg-secondary-lighter text-secondary-dark border-secondary-dark"
                            >
                              {item?.looking_for_title
                                ?.toLowerCase()
                                .includes("gym buddy", "gym") ? (
                                <CgGym className="text-xl font-bold" />
                              ) : item?.looking_for_title
                                ?.toLowerCase()
                                .includes("customize") ? (
                                <AiFillSetting className="text-sl font-bold" />
                              ) : (
                                <RiUserSearchLine className="text-sl font-bold" />
                              )}
                              {item?.looking_for_title}
                            </button>
                          );
                        }
                      )
                    ) : (
                      <span className="text-xs text-paragraph">
                        Not added yet...
                      </span>
                    )}
                  </div>
                </div>
              </div>

              <div className="profile_buttons-group gap-2 w-full flex justify-between py-4 ">
                {userParamsId?.id ? (
                  // freind user
                  <>
                    {profileState?.userProfile?.following_button_status ===
                      2 ? (
                      <>
                        <AppButton
                          onClick={() => unfollowFreind(userParamsId?.id)}
                          className="w-full border rounded-[10px] border-secondary  text-secondary font-medium py-1 flex items-center justify-center"
                          disabled={false}
                          leftIcon={
                            <BiSolidUserMinus className="text-sm me-2" />
                          }
                          btnText="Following"
                        />
                        <AppButton
                          className="w-full border rounded-[10px] border-secondary  text-secondary font-medium py-1 flex items-center justify-center "
                          disabled={false}
                          onClick={() =>
                            handlechat(
                              AuthUser?.user?.user,
                              profileState?.userProfile?.user
                            )
                          }
                          leftIcon={<AiFillMessage className="text-sm me-2" />}
                          btnText="Message"
                        />
                      </>
                    ) : profileState?.userProfile?.following_button_status ===
                      1 ? <AppButton
                      // onClick={() => followSuggestionFriend(userParamsId?.id)}
                      className="w-full border rounded-[10px] border-secondary  text-secondary font-medium py-1 flex items-center justify-center"
                      disabled={false}
                      leftIcon={<FaUserPlus className="text-sm me-2" />}
                      btnText="Requested"
                    /> : (
                      <AppButton
                        onClick={() => followSuggestionFriend(userParamsId?.id)}
                        className="w-full border rounded-[10px] border-secondary  text-secondary font-medium py-1 flex items-center justify-center"
                        disabled={false}
                        leftIcon={<FaUserPlus className="text-sm me-2" />}
                        btnText="Follow"
                      />
                    )}
                  </>
                ) : (
                  //  user profile
                  <>
                    <AppButton
                      onClick={() => editProfileHanlder()}
                      className="w-[46%] border rounded-[10px] border-secondary  text-secondary font-medium py-1 "
                      disabled={false}
                      leftIcon={
                        <FontAwesomeIcon
                          icon={faUserEdit}
                          className="text-sm me-2"
                        />
                      }
                      btnText="Edit Profile"
                    />

                    <AppButton
                      className="w-[46%] border rounded-[10px] border-secondary  text-secondary font-medium py-1 "
                      disabled={true}
                      leftIcon={
                        <FontAwesomeIcon
                          icon={faShare}
                          className="text-sm me-2"
                        />
                      }
                      btnText="Invite Friends"
                    />

                    <AppButton
                      onClick={() => {
                        setProfileState((prev) => ({
                          ...prev,
                          addMemberIsOpen: true,
                        }));
                      }}
                      className="w-[8%] border rounded-[10px] border-secondary text-secondary font-medium py-1 "
                      disabled={false}
                      leftIcon={<FontAwesomeIcon icon={faUserPlus} />}
                      btnText=""
                    />
                  </>
                )}
              </div>

              {profileState?.userProfile?.user?.privacy === "Public" || (profileState?.userProfile?.user?.privacy === "Private" && profileState?.userProfile?.following_button_status === 2) ||
                Object.keys(userParamsId ? userParamsId : null).length === 0 ? (
                <>
                  {/* streak section */}
                  <div className="streak-section flex justify-between items-center bg-white shadow-customshadow p-6 rounded-[20px]">
                    <div>
                      <h3 className="text-md">MAX STREAK</h3>
                      <h4 className="text-lg font-semibold pb-2">
                        {
                          streakItemReturn(
                            profileState?.userProfile?.user?.max_streak
                          )?.description
                        }
                      </h4>
                      {/* <p className="text-sm text-paragraph">Morning Routine</p> */}
                    </div>

                    {
                      <div onClick={() => {
                        setProfileState((prev) => ({
                          ...prev,
                          stepIsModalOpen: true,
                        }));
                      }} className="cursor-pointer">
                        <div>
                          <img
                            src={
                              streakItemReturn(
                                profileState?.userProfile?.user?.max_streak
                              )?.img
                            }
                            alt=""
                          />
                        </div>
                        <div className="text-sm font-semibold flex gap-1 items-center">
                          {
                            streakItemReturn(
                              profileState?.userProfile?.user?.max_streak
                            )?.title
                          }
                          <span

                          >
                            <BsQuestionCircle />
                          </span>
                        </div>
                      </div>
                    }
                  </div>
                  {/* statics */}
                  <div className="statics py-3 ">
                    <h3 className=" text-medium lg:text-lg md:text-lg font-medium ms-1 text-paragraph">
                      Statistics
                    </h3>
                    <div className="overviews bg-primary-light shadow-customshadow p-3 rounded-[20px] mt-2 ">
                      <ul
                        className={`grid grid-cols-2  gap-3  ${userParamsId?.id
                          ? "sm:grid-cols-3 lg:grid-cols-3 md:grid-cols-3"
                          : "sm:grid-cols-4 lg:grid-cols-4 md:grid-cols-4"
                          }   `}
                      >
                        <li className="text-center border-r w-full cursor-pointer ">
                          <div className="flex items-center flex-col">
                            <LiaAwardSolid className="md:text-2xl lg:text-2xl text-xl  text-white mb-2" />
                            <h4 className="text-sm  px-[6px]	 text-gray-50 uppercase ">
                              Points
                            </h4>{" "}
                            <p className="font-semibold text-xl text-white">
                              {profileState?.userProfile?.statistics?.points ||
                                0}
                            </p>
                          </div>
                        </li>
                        {!userParamsId.id && (
                          <li className="text-center sm:border-r md:border-r lg:border-r w-full cursor-pointer ">
                            <div className="flex items-center flex-col">
                              <HiOutlineStar className="md:text-2xl lg:text-2xl text-xl  text-white mb-2" />
                              <h4 className="text-sm  px-[6px]	 text-gray-50 uppercase ">
                                MEMBERS
                              </h4>
                              <p className="font-semibold text-xl text-white">
                                {profileState?.userProfile?.statistics
                                  ?.members || 0}
                              </p>
                            </div>
                          </li>
                        )}

                        <li
                          className="text-center sm:border-r md:border-r lg:border-r border-r w-full cursor-pointer  "
                          onClick={() => onSocialListClick("followers")}
                        >
                          <div className="flex items-center flex-col">
                            <TbUserStar className="md:text-2xl lg:text-2xl text-xl  text-white mb-2" />
                            <h4 className="text-sm  px-[6px]	 text-gray-50 uppercase ">
                              FOLLOWERS
                            </h4>{" "}
                            <p className="font-semibold text-xl text-white">
                              {profileState?.userProfile?.statistics
                                ?.followers || 0}
                            </p>
                          </div>
                        </li>
                        <li
                          className="text-center w-full cursor-pointer  "
                          onClick={() => onSocialListClick("following")}
                        >
                          <div className="flex items-center flex-col">
                            <LiaUserCheckSolid className="md:text-2xl lg:text-2xl text-xl  text-white mb-2" />
                            <h4 className="text-[12px]  px-[6px]	  text-gray-50 uppercase ">
                              FOLLOWING
                            </h4>{" "}
                            <p className="font-semibold text-xl text-white">
                              {profileState?.userProfile?.statistics
                                ?.following || 0}
                            </p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {userParamsId?.id && (
                    <div className="border border-gray-300 py-6 rounded-[15px] px-3 flex items-center justify-center gap-3">
                      <div className="border border-gray-600 p-2 text-gray-600 rounded-full text-2xl">
                        <MdLockOutline />
                      </div>{" "}
                      <div>
                        <h4>Add your Friend List</h4>
                        <p className="text-sm  text-paragraph">
                          Follow this account to see their account and send the
                          message.
                        </p>
                      </div>
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </div>
        <div className="col-span-12 lg:col-span-5 md:col-span-5 ">
          <div className="user_list_following bg-white shadow-customshadow rounded-[20px] ">
            {profileState.seeList === "suggestion" ? (
              <SuggestionList
                userList={profileState?.userProfile?.friends_suggestion}
                setProfileState={setProfileState}
                user={AuthUser?.user?.user}
              />
            ) : (
              <SocialFriendsList
                userList={
                  profileState.seeList === "request"
                    ? friends_suggestion
                    : profileState.seeList === "followers"
                      ? followerList
                      : profileState.seeList === "following"
                        ? followingList
                        : friends_suggestion
                }
                title={
                  profileState.seeList === "request"
                    ? "Follow Requests"
                    : profileState.seeList === "followers"
                      ? "All Followers"
                      : profileState.seeList === "following"
                        ? "All Following"
                        : ""
                }
                type={
                  profileState.seeList === "request"
                    ? "request"
                    : profileState.seeList === "followers"
                      ? "Followers"
                      : profileState.seeList === "following"
                        ? "Following"
                        : "suggestion"
                }
                request={""}
                user={userParamsId.id ? userParamsId : AuthUser?.user?.user}
                userParms={userParamsId.id ? userParamsId : null}
                setProfileState={setProfileState}
              />
            )}
          </div>
        </div>
      </div>

      <AddUser
        state={profileState}
        setState={setProfileState}
        user={AuthUser?.user?.user}
      />
      <EditImage
        profileState={profileState}
        setProfileState={setProfileState}
        user={AuthUser?.user?.user}
        looking_for={profileState?.userProfile?.looking_for}
      />
      <StepModal state={profileState} setState={setProfileState} />

      <RewardPopup
        parentState={profileState}
        setParentState={setProfileState}
      />

      <GamificationPopup
        parentState={profileState}
        setParentState={setProfileState}
        title={"Congratulations!"}
        message={
          "🤝 Social Power! 🌟 You've just scored extra points for your amazing teamwork. Keep collaborating and sharing tasks with friends or teammates to boost your score even higher! 🚀🎉"
        }
      />
    </div>
  );
};

export default Profile;
