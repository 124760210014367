import React, { useState } from "react";
import { BiSearchAlt } from "react-icons/bi";
import searchIcon from "../../assets/images/icons/search_icon.svg";

const Serchbar = ({ onSearch }) => {
  const [searchTerm, setSearchTerm] = useState("");
  function handleSearchInputChange(event) {
    const newSearchTerm = event.target.value;
    setSearchTerm(newSearchTerm);
    onSearch(newSearchTerm);
  }

  return (
    <div className="serchbar-section flex items-center bg-background rounded-[10px] py-1 px-3">
      {/* serchIcon */}
      <div className="serch-icon">
        <img src={searchIcon} alt="searchIcon" />
      </div>
      {/* Input field */}
      <div className="search-input w-dull ">
        <input
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={handleSearchInputChange}
          maxLength="20"
          className="w-full outline-none bg-background"
        />
      </div>
    </div>
  );
};

export default Serchbar;
