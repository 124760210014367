import React from "react";
import { BiArrowBack } from "react-icons/bi";
import ProfileEditForm from "./profileEditForm";
import { useUserProfileModalState } from "./ProfileApi";

const EditProfile = ({ setProfileState, profileState, user, looking_for }) => {
  const goBackPage = () => {
    setProfileState((prev) => {
      return {
        ...prev, editProfile: !prev?.editProfile, urlImage: "",
        imageType: ""
      };
    });
  };

  return (
    <div className="bg-white shadow-customshadow p-4 rounded-[15px] mb-3">
      <div className="header_edit_section flex justify-between items-center mb-3">
        <span onClick={goBackPage}>
          <BiArrowBack className="cursor-pointer" />
        </span>
        <h1 className="lg:text-2xl md:text-2xl text-lg font-mediu, ">
          Edit Profile
        </h1>
        <span></span>
      </div>
      <div>
        <ProfileEditForm
          user={user}
          profileState={profileState}
          looking_for={looking_for}
          setProfileState={setProfileState}
        />
      </div>
    </div>
  );
};

export default EditProfile;
