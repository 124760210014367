import React, { useEffect, useState } from "react";
import AuthPageImage from "../../components/AuthPagesImage/AuthPageImage";
import LoginForm from "./LoginForm";
import Image from "../../assets/images/illustrations/login_illustration.svg";
import Logo from "../../assets/images/logo.png";
import SocialLogins from "../../components/SocialLogins/SocialLogins";
import { Link, useNavigate } from "react-router-dom";

const Login = () => {
  const [existingUser, setExistingUser] = useState(
    localStorage?.getItem("already_user") || false
  );
  const navigate = useNavigate();

  return (
    <div className="w-full  ">
      <div className="w-[100%]    flex h-[100vh]">
        <div className="w-[50%]   lg:block md:hidden sm:hidden hidden  ">
          <AuthPageImage ImageUrl={Image} logo={Logo} />
        </div>
        <div className="w-full   lg:w-[50%]  md:w-full  sm:w-full  px-4   sm:px-28  py-2 flex flex-col justify-center items-center relative">
          <div className=" flex flex-col max-w-[345px]">
            <h2 className="py-1 font-semibold text-3xl">Login</h2>
            <p className="py-2 text-paragraph pb-3">
              Please enter your details below to continue
            </p>
            <LoginForm />
            <div className="or-section w-1/1 flex justify-center py-3">
              <div className="w-1/2  flex items-center justify-between">
                <span className="w-[40%] h-[1.5px] bg-[#E6E6E6]"></span>
                <span className="ps-2 text-paragraph">OR</span>
                <span className=" w-[40%] h-[1.5px] bg-[#E6E6E6]"></span>
              </div>
            </div>
            {/* social icons */}
            <SocialLogins />
          </div>
          <div className="dont_have_account    absolute bottom-3  text-center ">
            <p className="w-full ps-2 text-paragraph ">
              Don’t have an account?{" "}
              <p
                onClick={() =>
                  navigate("/", { state: { existing: existingUser } })
                }
                className="font-semibold text-primary "
              >
                Register
              </p>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
