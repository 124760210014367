import { Modal, notification } from 'antd';
import React, { useEffect, useState } from 'react'
import { getMessageList, getToUserProfileName, sendMessage } from "../../../../firebase/chat";

const ChatUsersModal = ({ state, setState, user, stateNew }) => {
    const [selectedUsers, setSelectedUsers] = useState([])
    const [shareModal, setShareModal] = useState({
        userList: []
    })

    const handleUserToggle = (userItem) => {
        console.log(userItem, "item");
        const isSelected = selectedUsers.some((selectedUser) => selectedUser.to_user.id === userItem.to_user.id);

        if (isSelected) {
            // User is already selected, so remove from the list
            setSelectedUsers(selectedUsers.filter((selectedUser) => selectedUser.to_user.id !== userItem.to_user.id));
        } else {
            // User is not selected, so add to the list
            setSelectedUsers([...selectedUsers, userItem]);
        }
    };

    const getAllUserList = () => {
        getMessageList(user?.id).then((res) => {
            if (res.data?.length > 0) {
                // const blockedUserIds = chatParentState.blockedUsers.map(
                //   (blockedUser) => blockedUser.user_id
                // );

                const filteredChatData = res?.data?.filter((chat) => {
                    const toUserId = chat.to_user.id;
                    return toUserId;
                });
                setShareModal((prev) => ({ ...prev, userList: filteredChatData }))
                // setChatParentState((prev) => ({ ...prev, allUsers: filteredChatData }));
                // setChatParentState((prev) => ({
                //     ...prev,
                //     globalUsers: filteredChatData,
                // }));

            }
        });
    };


    useEffect(() => {
        getAllUserList();
    }, []);

    const handleOk = () => {
        setState((prev) => ({ ...prev, isShareModalOpen: false }))
    }

    const handleCancel = () => {
        setState((prev) => ({ ...prev, isShareModalOpen: false }))
    }

    const handleShare = (e) => {
        if (selectedUsers?.length > 0) {
            selectedUsers.forEach((toUserId) => {
                onSendMessage(toUserId)
            })
            notification["success"]({
                message: "Message sent successfully",
                style: {
                    fontFamily: 'Montserrat, sans-serif',
                    fontSize: "14px"
                },
            });
            setState((prev) => ({ ...prev, isShareModalOpen: false }));
            setSelectedUsers([])
        }
    }

    async function onSendMessage(toUserId) {
        const msgStr = JSON.stringify(stateNew)
        const result = await sendMessage(user?.id ?? '', { chatUser: { from_user: { id: user?.id ?? '' }, to_user: { id: toUserId?.to_user?.id } } }, msgStr, 'challenge')

    }

    const getCheckIcon = (checkRecord) => {
        if (checkRecord) {
            return <svg stroke="currentColor" fill="#18605D" stroke-width="0" viewBox="0 0 512 512" height="1.3em" width="1.3em" xmlns="http://www.w3.org/2000/svg"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"></path></svg>
        } else {
            return <svg stroke="#B0B0B0" fill="#B0B0B0" stroke-width="0" viewBox="0 0 512 512" height="1.3em" width="1.3em" xmlns="http://www.w3.org/2000/svg"><path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200z"></path></svg>
        }
    }




    return (
        <Modal
            open={state.isShareModalOpen}
            title=""
            onOk={handleOk}
            destroyOnClose
            width={380}
            closeIcon={false}
            onCancel={handleCancel}
            footer={false}
        >
            <div className="font-montserrat">
                <div className='border-b border-1 mb-2'>
                    <h1 style={{ fontSize: '18px', fontWeight: '600' }}>Share</h1>
                </div>
                <div>
                    {/* Your Searchbar component */}
                </div>
                <div className="list-of-user">
                    <ul className="list custom-scrollbar "
                        style={{ maxHeight: "20vh", overflowY: "auto" }}>
                        {shareModal?.userList?.length > 0 ? shareModal?.userList?.map((item, index) => {
                            const isSelected = selectedUsers.some((selectedUser) => selectedUser?.to_user?.id === item?.to_user?.id);

                            return (
                                <li key={index + 1} className={`${isSelected ? "bg-slate-300" : ""} cursor-pointer py-3 rounded px-2 shadow bg-slate-50 mb-2 flex justify-between items-center`} onClick={() => handleUserToggle(item)}
                                >
                                    <span className='' >
                                        {index + 1}.<span className='ms-1 '>

                                            {getToUserProfileName(user?.id ?? "", item)}
                                        </span>
                                    </span>
                                    {/* <input
                                        type="radio"
                                        name="userSelection"
                                        checked={selectedUser === 'Lakhan nemane'}
                                        onChange={() => handleUserSelection(getToUserProfileName(user?.id ?? "", item))}
                                    /> */}
                                    {getCheckIcon(isSelected)}
                                </li>
                            )
                        }) : <li className='w-full text-center text-paragraph'>user not found!</li>}
                        {/* Add more list items with radio buttons as needed */}
                    </ul>
                </div>
                <div className='text-center mt-2'>

                    <button
                        className={`${selectedUsers?.length > 0 ? `bg-primary-dark text-white` : `bg-paragraph`} py-2 px-4 rounded font-semibold text-white font-md `}

                        // disabled={!selectedUser}
                        onClick={handleShare}
                    >
                        Share
                    </button>
                </div>
            </div>
        </Modal>
    )
}

export default ChatUsersModal;
