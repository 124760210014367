import instance from "../../../AxiosFile/AxiosInstance";

export function useActivityApis() {
  const getTaskActivites = async ({ id, date }) => {
    const response = await instance.get(`/api/task_activities`, {
      params: {
        user_id: id,
        date: date,
      },
    });
    if (response.data) {
      return response.data;
    }
  };

  const getMemberList = async (id) => {
    let res = await instance.get(`/api/choose_member_list`, {
      params: {
        user_id: id,
      },
    });

    return res.data;
  };

  const assignTaskToMember = async (body) => {
    let res = await instance.post(`/api/assign_tasks_to_members`, body, {
      headers: {
        "Content-type": "multipart/form-data",
      },
    });

    return res.data;
  };

  const doMarkAsComplete = async (body) => {
    let res = await instance.post(`/api/tracking_task_status_update`, body, {
      headers: {
        "Content-type": "multipart/form-data",
      },
    });

    return res.data;
  };

  const removeTaskApis = async (body) => {
    let res = await instance.post(`/api/task_remove`, body, {
      headers: {
        "Content-type": "multipart/form-data",
      },
    });

    return res.data;
  };

  const resetActivityPage = async (body) => {
    let res = await instance.post(`/api/reset_tasks`, body);

    return res.data;
  };

  const onTimeUpdateStatusTask = async (body) => {
    let res = await instance.post(`/api/task_activities_time_update`, body, {
      headers: {
        "Content-type": "multipart/form-data",
      },
    });
    return res.data;
  };

  const cancelOverlapingTasks = async (body) => {
    let res = await instance.post(`/api/task_overlapping_cancel`, body);
    return res;
  };

  return {
    getTaskActivites,
    getMemberList,
    assignTaskToMember,
    doMarkAsComplete,
    removeTaskApis,
    resetActivityPage,
    onTimeUpdateStatusTask,
    cancelOverlapingTasks,
  };
}
