import React from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";

import * as Yup from "yup";
import { Modal } from "antd";
import AppButton from "../../../../components/Button/AppButton";
import { useTaskApis } from "../TaskModuleApis";
import { useEffect } from "react";
import { useState } from "react";



const validationSchema = Yup.object().shape({
  task_sub_category_name: Yup.string().required('Task name is required'),
  task_category_name: Yup.string().required('Task is required'),
  task_sub_category_image: Yup.string().required('Image is required'),
});

const NewCustomTaskModal = ({ state, setState, user }) => {
  const { getTaskcategories, createSubcateoryCustom, getTaskategoriesImage } = useTaskApis();

  const [customModalState, setCustomModalState] = useState({
    CategoriesList: [],
    categoryImagesList: [],
    selectedAvatar: "",
    errorImage: ""
  });


  const handleOk = () => {
    setState((prev) => ({ ...prev, isCustomOpen: false }));
  };
  const handleCancel = () => {
    setState((prev) => ({ ...prev, isCustomOpen: false }));
  };
  useEffect(() => {
    getTaskcategories()
      .then((res) => {
        if (res.status === 200) {
          setCustomModalState((prev) => ({
            ...prev,
            CategoriesList: res?.data?.task_categories,
          }));
        }
      })
      .catch((error) => {
        console.log("error", error);
      });

  }, []);

  useEffect(() => {
    if (user?.id) {

      getTaskategoriesImage(user?.id).then((res) => {
        if (res.status === 200) {
          setCustomModalState((prev) => ({ ...prev, categoryImagesList: res.data }))
        } else {
          setCustomModalState((prev) => ({ ...prev, categoryImagesList: [] }))
        }
      }).catch((error) => {
        console.log(error, "error");
      })
    }
  }, [])

  const submitSubTask = (values, { resetForm }) => {

    let data = {
      ...values,
      task_category_name: parseInt(values.task_category_name),
      user_id: user?.id,
      //  task_sub_category_image: customModalState?.selectedAvatar

    };
    createSubcateoryCustom(data)
      .then((res) => {
        if (res.status === 200) {
          setState((prev) => ({ ...prev, isCustomOpen: false }));
          setCustomModalState((prev) => ({ ...prev, selectedAvatar: "" }))
          resetForm();
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });

  };





  return (
    <Modal
      open={state.isCustomOpen}
      title=""
      onOk={handleOk}
      destroyOnClose
      width={480}
      onCancel={handleCancel}
      closeIcon={false}
      footer={[
        <div className="w-full text-center font-montserrat custom-scrollbar" style={{ maxHeight: "400px", overflowY: "scroll" }}>
          <Formik
            initialValues={{
              task_sub_category_name: "",
              task_category_name: "",
              task_sub_category_image: null,
            }}
            validationSchema={validationSchema}
            onSubmit={(values, { resetForm }) => {
              submitSubTask(values, { resetForm });
            }}
          >
            {({ isSubmitting, values, setFieldValue, getFieldProps, errors, touched }) => (
              <Form>
                <div className="pb-4 relative w-full font-montserrat">
                  <h1 className="text-lg font-semibold">New Custom</h1>

                  <div className="mb-4 flex flex-col justify-start items-start">
                    <label htmlFor="taskName" > Task Name :</label>
                    <Field
                      type="text"
                      id="task_sub_category_name"
                      name="task_sub_category_name"
                      placeholder="Enter Name"
                      className="w-full px-3 py-2 rounded-md   placeholder-[#B0B0B0]"
                      style={{ background: "white !important" }}
                    />
                    <ErrorMessage
                      name="task_sub_category_name"
                      component="div"
                      className="text-red-500 text-sm pt-2 ps-2 formik-error"
                    />
                  </div>
                  <div className="mb-4 flex flex-col justify-start items-start">
                    <label htmlFor="task_category_name" className="mb-1">
                      Task :
                    </label>
                    <Field
                      as="select"
                      id="task_category_name"
                      name="task_category_name"
                      className="w-full px-3 py-2 bg-[#f5f5f5]  rounded-md"
                    >
                      <option value="">Select</option>
                      {customModalState?.CategoriesList?.map((category) => {
                        let { id, task_category_name } = category;
                        return (
                          <option key={id} value={id}>
                            {task_category_name}
                          </option>
                        );
                      })}
                    </Field>
                    <ErrorMessage
                      name="task_category_name"
                      component="div"
                      className="text-red-500 text-sm pt-2 ps-2 formik-error"
                    />
                  </div>

                  <div className="mb-4 mt-2 flex flex-col justify-start items-start">
                    <label htmlFor="task_category_name" className="mb-1">
                      Select Icon :
                    </label>
                    <ul
                      className="grid grid-cols-4 md:grid-cols-5 lg:grid-cols-6 gap-3 custom-scrollbar mb-2 mt-1"
                      style={{ height: "180px", overflowY: "scroll" }}
                    >
                      {customModalState?.categoryImagesList?.map((avatar, id) => {
                        let { image_name
                          , image_url } = avatar;

                        const fieldProps = getFieldProps("task_sub_category_image");

                        return (
                          <li
                            key={id + 1}
                            className={`${values.task_sub_category_image === image_name
                              ? "border-2 border-secondary rounded "
                              : ""
                              } 'rounded bg-gray-200 cursor-pointer `}
                            onClick={() => {

                              setCustomModalState((prev) => ({
                                ...prev,
                                selectedAvatar: image_name
                                ,
                              }))
                              setFieldValue("task_sub_category_image", image_name)
                            }
                            }
                          >
                            <img src={image_url
                              || image_url
                            } alt={image_url
                            } />
                          </li>
                        );
                      })}
                    </ul>
                    <div>
                      {touched.task_sub_category_image && errors.task_sub_category_image && (
                        <span className="text-red-500 text-sm pt-2 ps-2 formik-error">
                          {errors.task_sub_category_image}
                        </span>
                      )}                    </div>

                    <div className=" flex items-center w-full mt-3 text-center relative">
                      <AppButton
                        btnText="Create"
                        // disabled={isSubmitting}
                        btnType="Submit"
                        className={`bg-ternary font-semibold text-xl w-full text-white px-5 py-2 mx-8 rounded-xl ${""
                          }`}
                      />
                    </div>
                  </div>

                </div>
              </Form>
            )}
          </Formik>
        </div>,
      ]}
    >
      {/* Content of the modal */}
    </Modal>
  );
};

export default NewCustomTaskModal;
