
import { Modal, TimePicker, notification } from 'antd'
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import AppButton from '../../../components/Button/AppButton';
import { BiCalendarCheck } from "react-icons/bi"
import { useActivityApis } from '../Activity/ActivityApis';
import { Formik, Field, Form, ErrorMessage } from "formik";
import { render } from '../../../Store/Slices/DekstopNotificationSlice';
import { useDispatch } from 'react-redux';

const ReschedulePopUp = ({ dashboardState, setDashboardState, user }) => {

    const dispatch = useDispatch()


    const { onTimeUpdateStatusTask, doMarkAsComplete } = useActivityApis();
    const [rescheduleState, setRescheduleState] = useState({
        startTime: null,
        endTime: null,
        ResucheduleResonShow: false,
        timeWarning: ""
    })

    const initialValues = {
        startTime: rescheduleState.startTime,
        endTime: rescheduleState.endTime,
    };


    useEffect(() => {
        // this is record is comming and set the value for local state
        if (dashboardState?.reScheduleTaskData?.task_end_time && dashboardState?.reScheduleTaskData?.task_start_time) {
            setRescheduleState((prev) => ({
                ...prev,
                startTime: moment(dashboardState?.reScheduleTaskData?.task_start_time, "h:mm A"),
                endTime: moment(dashboardState?.reScheduleTaskData?.task_end_time, "h:mm A"),
            }));
        }

    }, [dashboardState?.reScheduleTaskData?.task_end_time, dashboardState?.reScheduleTaskData?.task_start_time]);


    const handleOk = () => {
        setDashboardState((prev) => ({
            ...prev,
            isRescheduleOpen: false
        }));
    };
    const handleCancel = () => {
        setDashboardState((prev) => ({
            ...prev,
            isRescheduleOpen: false
        }));
    };

    const onChange = (time, timeString, type) => {
        setRescheduleState((prevActivityTask) => ({
            ...prevActivityTask,
            [type]: moment(timeString, "h:mm A"),
        }));

    };




    const DoneHandler = () => {

        let data = {
            user_id: user?.id,
            task_id: dashboardState?.reScheduleTaskData?.task_id,
            task_status: 1,
            date: moment().format("YYYY-MM-DD"),
            max_streak_date: moment().utc().format("YYYY-MM-DD"),
            task_date_time: moment().format("YYYY-MM-DD hh:mm:ss")
        };
        // mark status as complete
        doMarkAsComplete(data).then((res) => {
            if (res.status === 200) {
                notification["success"]({
                    message: "Task completed successfully",
                    style: {
                        fontFamily: 'Montserrat, sans-serif',
                    },
                });
                setDashboardState((prev) => ({ ...prev, isRescheduleOpen: false }));
            }
        });
    }








    const onRescheduleHandler = () => {
        if (rescheduleState.endTime > rescheduleState.startTime && rescheduleState?.startTime !== null && rescheduleState?.endTime !== null) {
            setRescheduleState((prev) => ({
                ...prev, ResucheduleResonShow: true, timeWarning: ""
            }))



        } else {
            setRescheduleState((prev) => ({
                ...prev,
                timeWarning: "End time must be greater that start time"
            }))
        }

    }


    useEffect(() => {
        if (rescheduleState?.endTime > rescheduleState?.startTime) {
            setRescheduleState((prev) => ({
                ...prev,
                timeWarning: ""
            }))
        }
    }, [rescheduleState?.startTime, rescheduleState?.endTime])


    const cancelTaskHandler = () => {

        let data = {
            user_id: user?.id,
            task_id: dashboardState?.reScheduleTaskData?.task_id,
            task_status: 2,
            date: moment().format("YYYY-MM-DD"),
            max_streak_date: moment().utc().format("YYYY-MM-DD"),
            task_date_time: moment().format("YYYY-MM-DD hh:mm:ss")
        };
        // event status cancel
        doMarkAsComplete(data).then((res) => {
            if (res.status === 200) {
                notification["success"]({
                    message: "Task cancelled successfully",
                    style: {
                        fontFamily: 'Montserrat, sans-serif',
                    },
                });
                setDashboardState((prev) => ({ ...prev, isRescheduleOpen: false }));

            }
        });
    }

    const timeSubmitTask = (values) => {
        console.log("hello", values);
    }





    return (
        <Modal
            open={dashboardState.isRescheduleOpen}
            title=""
            onOk={handleOk}
            destroyOnClose
            width={430}
            closeIcon={false}
            onCancel={handleCancel}
            footer={

                [!rescheduleState?.ResucheduleResonShow ? <div>
                    <div className="title flex text-lg items-center font-montserrat">
                        <BiCalendarCheck className="text-red-400" /> <h1 className='ms-2 font-semibold'>Task of the day</h1>
                    </div>
                    <div className="task_deatil flex flex-col gap-4 sm:flex-row md:flex-row lg:flex-row w-full py-2">
                        <div className="info w-[220px] flex flex-col">

                            <h3 className='TaskName w-full text-base pb-2 text-start font-bold font-montserrat'>{dashboardState?.reScheduleTaskData?.task_sub_category_name}</h3>
                            <div className="timeEdit_activity  font-medium text-lg w-full">
                                <Formik initialValues={initialValues} onSubmit={timeSubmitTask} enableReinitialize>
                                    <Form >
                                        <div className="flex pb-2">
                                            <div>
                                                <Field name="startTime">
                                                    {({ field, form }) => (
                                                        <TimePicker
                                                            {...field}
                                                            use12Hours
                                                            format="h:mm A"
                                                            placeholder="Start Time"
                                                            value={field.value ? moment(field.value) : null} // Use defaultValue
                                                            onSelect={(time) => {
                                                                const timeString = time?.format("h:mm A");

                                                                onChange(time, timeString, "startTime");
                                                            }}
                                                        />
                                                    )}
                                                </Field>
                                            </div>
                                            &nbsp; -  &nbsp;
                                            <div>
                                                <Field name="endTime">
                                                    {({ field, form }) => (
                                                        <TimePicker
                                                            {...field}
                                                            use12Hours
                                                            format="h:mm A"
                                                            placeholder="Select End Time"
                                                            value={field.value ? moment(field.value) : null} // Use defaultValue
                                                            onSelect={(time) => {
                                                                const timeString = time?.format("h:mm A");
                                                                onChange(time, timeString, "endTime");
                                                            }}
                                                        />
                                                    )}
                                                </Field>
                                            </div>
                                        </div>


                                    </Form>
                                </Formik>

                            </div>
                            <span className='text-xs text-warning font-montserrat'>{rescheduleState?.timeWarning && rescheduleState?.timeWarning}</span>
                        </div>
                        <div className="img w-25" >
                            <img className='w-[120px] h-[110px]' src={`${process.env.REACT_APP_FILE_URL_TASKS}/${dashboardState?.reScheduleTaskData?.task_sub_category_image}`} alt="" />
                        </div>
                    </div>
                    <div className="buttons-group flex gap-3 justify-between py-3">
                        <AppButton className="bg-primary font-montserrat  w-full text-white px-2 py-1 rounded" disabled={false} btnText='Done' onClick={() => DoneHandler()} />
                        <AppButton className="bg-primary-light font-montserrat w-full text-white px-2 py-1 rounded" disabled={false} btnText='Reschedule' onClick={() => onRescheduleHandler()} />
                        <AppButton className="bg-secondary-dark font-montserrat w-full text-white px-2 py-1 rounded" disabled={false} btnText='Cancel' onClick={() => cancelTaskHandler()} />
                    </div>
                </div> :
                    <div className="w-full text-center">
                        <Formik
                            initialValues={{ reason: "Lack of Time", comment: "" }}
                            // validationSchema={validationSchema}
                            onSubmit={(values, { resetForm }) => {

                                let timeUpdateData = {
                                    task_id: dashboardState?.reScheduleTaskData?.task_id,
                                    user_id: user?.id,
                                    task_start_time: rescheduleState?.startTime?._i,
                                    task_end_time: rescheduleState?.endTime?._i,
                                };

                                onTimeUpdateStatusTask(timeUpdateData)
                                    .then((res) => {
                                        let data = {
                                            user_id: user?.id,
                                            task_id: dashboardState?.reScheduleTaskData?.task_id,
                                            task_status: 3,
                                            date: moment().format("YYYY-MM-DD"),
                                            max_streak_date: moment().utc().format("YYYY-MM-DD"),
                                            task_date_time: moment().format("YYYY-MM-DD hh:mm:ss")
                                        };
                                        doMarkAsComplete(data).then((res) => {
                                            if (res.status === 200) {
                                                dispatch(render())
                                                setDashboardState((prev) => ({ ...prev, isRescheduleOpen: false, }));
                                                setRescheduleState((prev) => ({ ...prev, ResucheduleResonShow: false }))
                                                notification["success"]({
                                                    message: "Task time updated successfully",
                                                    style: {
                                                        fontFamily: 'Montserrat, sans-serif',
                                                    },
                                                });

                                            }
                                        });
                                        resetForm();
                                    })
                                    .catch((error) => {
                                        console.log(error);
                                    });
                                // make task status as rescheule


                            }}
                        >
                            {({ isSubmitting }) => (
                                <Form>
                                    <div className="pb-4">
                                        <h1 className="text-lg font-semibold">Reschedule</h1>

                                        <div className="mb-4 flex flex-col justify-start items-start">
                                            <label htmlFor="taskName " className='font-montserrat'>Reason :</label>
                                            <Field
                                                as="select"
                                                id="reason"
                                                name="reason"
                                                className="w-full px-3 py-2 border  text-ternary rounded-md font-montserrat bg-background"
                                            >
                                                {[
                                                    "Lack of Time",
                                                    "Lack of Motivation",
                                                    "Health Issues",
                                                    "External Factors",
                                                    "Distractions",
                                                    "Poor Planning",
                                                    "Low Energy"].map((reason, index) => {
                                                        return <option key={index} value={reason}>{reason}</option>
                                                    })}


                                                {/* Add more options as needed */}
                                            </Field>

                                            <ErrorMessage
                                                name="reason"
                                                component="div"
                                                className="text-red-500 text-sm pt-2 ps-2 formik-error"
                                            />
                                        </div>

                                        <div className="mb-4 flex flex-col justify-start items-start">
                                            <label htmlFor="comment " className='font-montserrat'>Comment:</label>
                                            <Field
                                                as="textarea"
                                                rows={6}
                                                id="comment"
                                                name="comment"
                                                placeholder="Enter comment"
                                                className="w-full px-3 py-2 border text-ternary font-montserrat rounded-md bg-background"
                                            />
                                            <ErrorMessage
                                                name="comment"
                                                component="div"
                                                className="text-red-500 text-sm pt-2 ps-2 formik-error"
                                            />
                                        </div>

                                        <div className="w-full text-center mt-3 ">
                                            <AppButton
                                                btnText="Create"
                                                disabled={isSubmitting}
                                                type="submit"
                                                className={`bg-ternary font-semibold w-full text-white py-[8px] rounded-[6px] ${isSubmitting ? "opacity-50 cursor-not-allowed" : ""
                                                    }`}
                                            />
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>,
                ]

            }
        >




        </Modal>
    )
}

export default ReschedulePopUp