import React from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";

import * as Yup from "yup";
import { Modal, notification } from "antd";
import AppButton from "../../../components/Button/AppButton";
import { useEffect } from "react";
import { useState } from "react";
import { useChallange } from "./ChallangesApis";
import moment from "moment";



const validationSchema = Yup.object().shape({
    post_caption: Yup.string().required('Caption is required'),

})

const PostModal = ({ challengeParentState, setChallengeParentState, user, challengeID }) => {

    const { createPost } = useChallange()

    const handleOk = () => {
        setChallengeParentState((prev) => ({ ...prev, isOpenPostModal: false }));
    };
    const handleCancel = () => {
        setChallengeParentState((prev) => ({ ...prev, isOpenPostModal: false }));
    };
    useEffect(() => {

    }, []);

    const submitSubTask = (values, { resetForm }) => {

        // createChallange
        let data = {
            ...values,
            user_id: user?.id,
            challenge_id: challengeID,
            challenge_date: moment().format("YYYY-MM-DD hh:mm:ss")

        }
        createPost(data).then((res) => {
            if (res) {
                setChallengeParentState((prev) => ({ ...prev, isOpenPostModal: false, render: true }));
                notification["success"]({
                    message: res.message,
                    style: {
                        fontFamily: 'Montserrat, sans-serif',
                        fontSize: "14px"
                    },
                });
            }
        }).catch((err) => {
            console.log(err, "err");
        })




    };

    return (
        <Modal
            open={challengeParentState?.isOpenPostModal}
            title=""
            onOk={handleOk}
            destroyOnClose
            width={480}
            onCancel={handleCancel}
            closeIcon={false}
            footer={[
                <div className="w-full text-center font-montserrat">
                    <Formik
                        initialValues={{
                            post_caption: "",

                            post_image: null,
                        }}
                        validationSchema={validationSchema}
                        onSubmit={(values, { resetForm }) => {
                            submitSubTask(values, { resetForm });
                        }}
                    >
                        {({ isSubmitting, setFieldValue, values }) => (
                            <Form>
                                <div className="pb-4 relative w-full font-montserrat">
                                    <h1 className="text-lg font-semibold"> Post</h1>


                                    <div className="mb-4 flex flex-col justify-start items-start">
                                        <label htmlFor="task_category_name" className="mb-1">
                                            Caption :
                                        </label>

                                        <Field
                                            as="textarea"
                                            id="post_caption"
                                            name="post_caption"
                                            rows={3}
                                            placeholder="Enter"
                                            className="w-full px-3 py-2 rounded-md bg-[#f5f5f5] outline-none   placeholder-[#B0B0B0] resize-none"
                                            style={{ background: "white !important" }}
                                        />
                                        <ErrorMessage
                                            name="post_caption"
                                            component="div"
                                            className="text-red-500 text-sm pt-2 ps-2 formik-error"
                                        />
                                    </div>

                                    <div className="mb-4 mt-2 flex flex-col justify-start items-start">
                                        <label className=" bg-[#f5f5f5]  rounded-lg h-28 w-full">
                                            <div className="relative px-3 py-2 h-full w-full  rounded-lg  items-center justify-center flex ">
                                                <div className="flex items-center justify-center h-full w-full">
                                                    <p className=" px-4 py-1 text-sm border border-dashed border-[#18605D] bg-white text-black font-medium rounded-md">
                                                        {values?.post_image
                                                            ? values?.post_image?.name
                                                            : "Attach Photo"}
                                                    </p>
                                                </div>
                                                <Field
                                                    type="file"
                                                    accept="image/*"

                                                    id="post_image"
                                                    value={null}
                                                    name="post_image"
                                                    className="border  px-3 py-2  rounded-lg items-center justify-center hidden bg-white text-none "
                                                    onChange={(e) => {

                                                        setFieldValue(
                                                            "post_image",
                                                            e.target?.files?.[0]
                                                        );
                                                    }}
                                                />
                                            </div>
                                        </label>
                                        <ErrorMessage
                                            name="post_image"
                                            component="div"
                                            className="text-red-500 text-sm pt-2 ps-2 formik-error"
                                        />
                                        <div className=" flex items-center w-full mt-3 text-center relative">
                                            <AppButton
                                                btnText="Create Post "
                                                // disabled={isSubmitting}
                                                btnType="Submit"
                                                className={`bg-ternary font-semibold text-xl w-full text-white px-5 py-2 mx-8 rounded-xl ${isSubmitting ? "opacity-50 cursor-not-allowed" : ""
                                                    }`}
                                            />
                                        </div>
                                    </div>

                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>,
            ]}
        >
            {/* Content of the modal */}
        </Modal>
    );
};

export default PostModal;
