import React from "react";
import { useSelector } from "react-redux";

const NotDoingToday = ({
  wishingPopUpState,
  setWishingPopUpState,
  title,
  isEvening,
  PageNumber,
}) => {
  const handleChangeNotDoing = (e) => {
    setWishingPopUpState((prev) => ({
      ...prev,
      notDoingMessage: e.target.value,
    }));
  };
  const {
    AuthUser: {
      user: { user },
    },
  } = useSelector((state) => state);

  const handleSubmitTaskMessage = () => {
    if (isEvening) {
      setWishingPopUpState((prevPercent) => ({
        ...prevPercent,
        currentPageNumber: wishingPopUpState?.currentPageNumber + 1,
      }));
    } else {
      setWishingPopUpState((prev) => ({ ...prev, isWishModalOpen: false }));
      localStorage.setItem("seeAgainWishPopup", false);
      localStorage.setItem("isSameUserWishPopups", user?.id);
    }
  };

  return (
    <div className="flex flex-col justify-center items-center font-montserrat">
      {!isEvening && (
        <span>
          STEP {PageNumber} OF {!isEvening ? "3" : "4"}
        </span>
      )}

      <h2 className="text-lg font-montserrat text-ternary font-semibold pb-1 text-center">
        {title}
      </h2>

      {/* {} */}
      <div className="cursor-pointer w-full py-3">
        <div>
          <textarea
            type="text"
            placeholder="Enter Here"
            value={wishingPopUpState?.notDoingMessage}
            onChange={handleChangeNotDoing}
            className="w-[calc(100%)] p-2  border rounded-[10px] resize-none outline-none"
            rows={4}
          />
        </div>
      </div>

      <button
        className={`${
          wishingPopUpState?.notDoingMessage?.length > 1 &&
          "bg-ternary  text-white"
        } bg-gray-300 w-full mt-3 py-1 rounded`}
        disabled={wishingPopUpState?.notDoingMessage?.length > 2 ? false : true}
        onClick={() => handleSubmitTaskMessage()}
      >
        Done
      </button>
    </div>
  );
};

export default NotDoingToday;
