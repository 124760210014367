import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import authSliceReducer from "./Slices/AuthSlice";
import NotificationSidebar from "./Slices/NotificationSidebar";
import sidebarSliceReducer from "./Slices/SidebarOpen";
import IntrestSlice from "./Slices/IntrestSlice";
import reScheduleSlice from "./Slices/DekstopNotificationSlice";

const middleware = getDefaultMiddleware({
  serializableCheck: false,
});

const rootReducer = {
  AuthUser: authSliceReducer,
  isSidebarOpen: sidebarSliceReducer,
  notification: NotificationSidebar,
  IntreastSplash: IntrestSlice,
  rescheduleData: reScheduleSlice,
};

const store = configureStore({
  reducer: rootReducer,
  middleware,
});

export default store;
