import React, { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";

const AddTaskElement = ({
  wishingPopUpState,
  setWishingPopUpState,
  title,
  isEvening,
  PageNumber,
}) => {
  // step 3
  const [error, setError] = useState(null);
  const handleChangeSetTaskValue = (e) => {
    setWishingPopUpState((prev) => ({ ...prev, taskName: e.target.value }));
  };
  const handleAddTaskName = () => {
    if (wishingPopUpState?.taskName?.length > 1) {
      if (
        wishingPopUpState?.threeNonNegotiable?.includes(
          wishingPopUpState?.taskName
        )
      ) {
        setError("Already selected");
        // let Newdata = wishingPopUpState?.threeNonNegotiable?.filter((item) => {
        //   console.log("wishing popupItems => ", item);
        //   return item !== wishingPopUpState.taskName;
        // });
        // setWishingPopUpState((prev) => ({
        //   ...prev,
        //   threeNonNegotiable: Newdata,
        //   taskName: "",
        // }));
      } else {
        setWishingPopUpState((prev) => ({
          ...prev,
          threeNonNegotiable: [
            ...wishingPopUpState.threeNonNegotiable,
            wishingPopUpState.taskName,
          ],
          taskName: "",
        }));
        setError(null);
      }
    }
  };

  const removeTask = (taskName) => {
    let Newdata = wishingPopUpState?.threeNonNegotiable?.filter((item) => {
      return item !== taskName;
    });
    setWishingPopUpState((prev) => ({
      ...prev,
      threeNonNegotiable: Newdata,
      taskName: "",
    }));
  };

  //    ondone handlere here increament count

  const onDoneHandler = () => {
    setWishingPopUpState((prev) => ({
      ...prev,
      currentPageNumber: wishingPopUpState?.currentPageNumber + 1,
    }));
  };
  return (
    <div className="flex flex-col justify-center items-center font-montserrat">
      <span>STEP {PageNumber} OF 4</span>

      <h2 className="text-lg text-ternary font-semibold pb-1">{title}</h2>

      {/* {} */}
      <div className="cursor-pointer w-full">
        {/* list of selected task */}
        <ul className="flex flex-wrap gap-3 py-2">
          {wishingPopUpState?.threeNonNegotiable?.map((taskNameItem, index) => {
            return (
              <li
                title={taskNameItem}
                className="bg-secondary-lighter px-2 py-1 rounded flex items-center gap-2"
                key={index + 1}
              >
                {taskNameItem?.length > 20
                  ? `${taskNameItem.slice(0, 20)}...`
                  : taskNameItem}
                <span
                  className="text-xs hover:bg-secondary-light rounded-full p-1"
                  onClick={() => removeTask(taskNameItem)}
                >
                  <AiOutlineClose />
                </span>
              </li>
            );
          })}
        </ul>
        {wishingPopUpState?.threeNonNegotiable?.length < 3 ? (
          <>
            <div className="border flex items-center justify-center rounded-[8px] bg-gray-200">
              <input
                type="text"
                placeholder="Add a task"
                value={wishingPopUpState?.taskName}
                onChange={handleChangeSetTaskValue}
                className={`${wishingPopUpState?.threeNonNegotiable?.length < 3
                  }  ? w-full :w-[calc(100%-80px)]`}
                max={20}
                disabled={wishingPopUpState?.threeNonNegotiable?.length > 3}
              />
              {wishingPopUpState?.threeNonNegotiable?.length < 3 && (
                <button
                  onClick={handleAddTaskName}
                  className="w-[80px] py-1 me-1 rounded bg-ternary text-white"
                >
                  Add
                </button>
              )}
            </div>
            <p className="text-xs text-error">{error}</p>
          </>
        ) : null}
      </div>

      <button
        className={`${wishingPopUpState?.threeNonNegotiable?.length > 2 &&
          "bg-ternary  text-white"
          } bg-gray-300 w-full mt-3 py-1 rounded`}
        disabled={
          wishingPopUpState?.threeNonNegotiable?.length > 2 ? false : true
        }
        onClick={() => onDoneHandler()}
      >
        Next
      </button>
    </div>
  );
};

export default AddTaskElement;
