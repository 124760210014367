import { Alert, Form, Modal, notification } from "antd";
import React from "react";
import { useState } from "react";

import AppButton from "../../../../components/Button/AppButton";
import { useActivityApis } from "../ActivityApis";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import AddUser from "../../Profile/Modals/AddUser";
import { ErrorMessage, Field, Formik, useFormik } from "formik";
import { useUserProfileModalState } from "../../Profile/ProfileApi";
import moment from "moment";

const ChooseMemberModal = ({ state, setState }) => {
  const { getMemberList, assignTaskToMember } = useActivityApis();
  const { addMemberHandler } = useUserProfileModalState()
  const {
    AuthUser: {
      user: { user },
    },
  } = useSelector((state) => state);
  const [memberState, setMemberState] = useState({
    selectedUser: "",
    memberList: [],
    addMemberIsOpen: false,
  });
  const handleOk = () => {
    setState((prev) => ({
      ...prev,
      isChooseMemberOpen: false,
      assignTaskRecord: null,
    }));
    setMemberState((prev) => ({ ...prev, selectedUser: "", addMemberIsOpen: false }));
  };
  const handleCancel = () => {
    setState((prev) => ({
      ...prev,
      isChooseMemberOpen: false,
      assignTaskRecord: null,
    }));
    setMemberState((prev) => ({ ...prev, selectedUser: "", addMemberIsOpen: false }));
  };

  const onUserAssign = (userId) => {
    if (memberState.selectedUser === userId) {
      setMemberState((prev) => ({ ...prev, selectedUser: "" }));
    } else {
      setMemberState((prev) => ({ ...prev, selectedUser: userId }));
    }
  };

  useEffect(() => {
    if (user?.id) {
      getMemberList(user?.id)
        .then((res) => {
          if (res.status === 200) {
            setMemberState((prev) => ({
              ...prev,
              memberList: res?.data?.members,
            }));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [user?.id]);

  // Assign task to member
  const onMemberAddTask = () => {
    if (memberState?.selectedUser) {
      let data = {
        user_id: user.id,
        member_id: memberState.selectedUser,
        task_id: state?.assignTaskRecord,
      };
      assignTaskToMember(data)
        .then((res) => {
          if (res.status === 200) {
            setState((prev) => ({
              ...prev,
              isChooseMemberOpen: false,
              assignTaskRecord: null,
            }));
            setMemberState((prev) => ({ ...prev, selectedUser: "" }));
          }
          notification["success"]({
            message: "Task assigned successfully",
            style: {
              fontFamily: 'Montserrat, sans-serif',
            },
          });
        })
        .catch((error) => {
          console.log(error, "error");
        });
    }
  };

  const initialValues = {
    member_name: "",
    member_email: "",
    member_mobile: "",
  };

  const validate = (values) => {
    const errors = {};

    if (!values.member_name) {
      errors.member_name = 'Member name is required';
    }

    if (!values.member_email) {
      errors.member_email = 'Member email is required';
    } else if (!/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.(com|in)$/.test(values.member_email)) {
      errors.member_email = 'Invalid email address';
    }



    if (!values.member_mobile) {
      errors.member_mobile = 'Member mobile is required';
    } else if (!/^\d{10}$/i.test(values.member_mobile)) {
      errors.member_mobile = 'Invalid mobile number (10 digits only)';
    }

    return errors;
  };

  const formik = useFormik({
    initialValues,
    validate,
    onSubmit: (values, { resetForm }) => {

      let data = { ...values, user_id: user?.id, date_time: moment().format('YYYY-MM-DD hh:mm:ss') };

      addMemberHandler(data)
        .then((res) => {
          if (res.status === 200) {
            notification["success"]({
              message: res.message,
              style: {
                fontFamily: 'Montserrat, sans-serif',
              },
            });
            setMemberState((prev) => ({ ...prev, addMemberIsOpen: false }));
            setState((prev) => ({ ...prev, isChooseMemberOpen: false }))
          }
        })
        .catch((error) => {

          // setAddUserState((prev) => ({
          //   ...prev,
          //   error: error?.response?.data?.message,
          // }));
          // setTimeout(() => {
          //   setAddUserState((prev) => ({
          //     ...prev,
          //     error: "",
          //   }));
          // }, 4000);
        });
      resetForm();
    },
  });

  return (
    <Modal
      open={state.isChooseMemberOpen}
      title=""
      onOk={handleOk}
      destroyOnClose
      width={memberState?.addMemberIsOpen ? 450 : 400}
      closeIcon={false}
      onCancel={handleCancel}
      footer={false}
    >
      {memberState?.addMemberIsOpen ?
        <div className="w-full text-center font-montserrat">
          <h2 className="text-lg font-semibold  w-full text-center mb-5">
            Add Member
          </h2>
          <form onSubmit={formik.handleSubmit}>
            <div className=" mb-4  justify-start items-center">
              <div className="flex justify-start items-center">

                <label htmlFor="member_name" className="text-sm font-base text-black w-1/3 text-start" >Name:</label>
                <input
                  type="text"
                  id="member_name"
                  name="member_name"
                  placeholder="Enter Name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.member_name}
                  className="w-full px-3 py-2 rounded-md text-xs h-10 font-poppins font-normal "
                />
              </div>
              {formik.touched.member_name && formik.errors.member_name ? (
                <div className="text-red-500 text-sm pl-[105px]  text-start  formik-error">{formik.errors.member_name}</div>
              ) : null}
            </div>

            <div className=" mb-4 ">
              <div className="flex justify-start items-center">

                <label htmlFor="member_email" className="text-sm font-base text-black w-1/3 text-start"> Email Address:</label>
                <input
                  type="text"
                  id="member_email"
                  name="member_email"
                  placeholder="Enter Email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.member_email}
                  className="w-full px-3 py-2 h-10 rounded-md  text-xs font-poppins font-normal "
                />
              </div>
              {formik.touched.member_email && formik.errors.member_email ? (
                <div className="text-red-500 text-sm pl-[105px] text-start  formik-error">{formik.errors.member_email}</div>
              ) : null}
            </div>

            <div className="mb-4   ">
              <div className=" flex  justify-start items-center">

                <label htmlFor="member_mobile" className=" text-sm font-base text-black w-1/3 text-start">Mobile No:</label>
                <input
                  type="text"
                  id="member_mobile"
                  name="member_mobile"
                  placeholder="Enter No."
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.member_mobile}
                  className="w-full px-3 py-2 h-10 rounded-md  text-xs font-poppins font-normal "
                />
              </div>
              {formik.touched.member_mobile && formik.errors.member_mobile ? (
                <div className="text-red-500 text-sm  text-start pl-[105px]  formik-error">{formik.errors.member_mobile}</div>
              ) : null}
            </div>

            <button type="submit" className="bg-ternary w-full rounded font-semibold py-2 text-white">Add</button>
          </form>

        </div> : <div className="header  font-montserrat">
          <h2 className="text-lg font-semibold  w-full text-center mb-5">
            Choose Member
          </h2>

          <div
            className="list-day-streak custom-scrollbar "
            style={{ height: "250px", overflowY: "auto" }}
          >
            <ul className="list grid grid-cols-1   ">
              {memberState?.memberList?.length > 0 ? (
                memberState?.memberList?.map((member, index) => {
                  let { id, member_name } = member;
                  return (
                    <li
                      key={id}
                      className={`flex gap-2 mb-2 py-2 px-2 cursor-pointer border border-gray-100 rounded  ${id === memberState?.selectedUser &&
                        "bg-primary-light text-white font-medium "
                        }  `}
                      onClick={() => onUserAssign(id)}
                    >
                      {index + 1}. {member_name}
                    </li>
                  );
                })
              ) : (
                <li className="text-paragraph w-full text-center pt-2  ">
                  <div className="pb-2">
                    There are no members registered. Please add a member to get
                    started.
                  </div>
                </li>
              )}
            </ul>
          </div>

          {
            memberState?.memberList?.length > 0 ? <AppButton


              btnText={"Done"}
              disabled={memberState?.selectedUser ? false : true}
              className={`${!memberState?.selectedUser
                ? "bg-ternary-light text-gray-400 cursor-not-allowed"
                : "bg-ternary text-white cursor-pointer"
                }  w-full py-1 rounded mt-9 font-semibold`}
              btnType="button"
              onClick={() => onMemberAddTask()}
            /> : <button onClick={() => {
              // setState((prev) => ({
              //   ...prev,
              //   isChooseMemberOpen: false,

              // }));
              setMemberState((prev) => ({ ...prev, addMemberIsOpen: true }))
            }} className="text-primary w-full rounded font-semibold border-2 border-primary px-2 py-2 ">Add Member</button>
          }

        </div>}


    </Modal>
  );
};

export default ChooseMemberModal;
