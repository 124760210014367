import React, { useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import AppButton from "../../components/Button/AppButton";
import { resetPassword } from "../../AxiosFile/ApisUrl";
import { Alert } from "antd";
import { AiFillEye } from "react-icons/ai";
import { BsFillEyeSlashFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

const ResetForm = ({ state }) => {
  const [resetState, setResetState] = useState({
    success: "",
    error: "",
    passwordHideShow: false,
    cpasswordHideShow: false,
  });
  const navigate = useNavigate();
  // Define the validation schema using Yup
  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .matches(/^(?=.*[a-z])/ && /^(?=.*[0-9])/ && /^(?=.*[A-Z])/ && /^(?=.*[!@#%&])/ && '^.{8,}$', "Password must be at least 8 characters long with 1 number, 1 uppercase letter, 1 lowercase letter and 1 special character")
      .required("Password is required"),

    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });

  const getValues = (values, { resetForm }) => {
    delete values.confirmPassword;
    let data = {
      ...values,
      email: state?.email,
    };
    resetPassword(data)
      .then((res) => {
        if (res.status === 200) {
          setResetState((prev) => ({
            ...prev,
            success: res.message,
          }));
          setTimeout(() => {
            setResetState((prev) => ({
              ...prev,

              success: "Password reset Successfully",
            }));
            navigate("/login")
          }, 3000);
        } else if (res.status === 500) {
          setResetState((prev) => ({
            ...prev,
            error: res.message,
          }));
          setTimeout(() => {
            setResetState((prev) => ({
              ...prev,
              error: "",
            }));
          }, 3000);
        } else if (res.status === 400) {
          setResetState((prev) => ({
            ...prev,
            error: res.message,
          }));
          setTimeout(() => {
            setResetState((prev) => ({
              ...prev,
              error: "",
            }));
          }, 3000);
        }
      })
      .catch((error) => {
        setResetState((prev) => ({
          ...prev,
          error: "something went wrong",
        }));
        setTimeout(() => {
          setResetState((prev) => ({
            ...prev,
            error: "",
          }));
        }, 3000);
      });

    resetForm();
  };

  const PassswordHideShow = ({ Field, Form }) => {
    return (
      <>
        <div className="absolute right-0 top-0">hide show</div>
      </>
    );
  };

  return (
    <div>
      <Formik
        initialValues={{ password: "", confirmPassword: "" }}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => {
          getValues(values, { resetForm });
        }}
      >
        {({ isSubmitting, values }) => (
          <Form>
            <div className="mb-4">
              {resetState?.success ? (
                <Alert message={resetState?.success} type="success" showIcon />
              ) : resetState?.error ? (
                <Alert message={resetState?.error} type="error" showIcon />
              ) : (
                ""
              )}
            </div>
            <div className="form-group w-full pb-3">
              <div className="relative">
                <Field
                  type={resetState?.passwordHideShow ? "text" : "password"}
                  id="password"
                  name="password"
                  placeholder="Password"
                  className="form-control w-full"
                />
                <div
                  onClick={() =>
                    setResetState((prev) => ({
                      ...prev,
                      passwordHideShow: !resetState?.passwordHideShow,
                    }))
                  }
                  className="absolute top-1 bottom-0 mr-2 right-0 cursor-pointer"
                >
                  {resetState?.passwordHideShow ? (
                    <AiFillEye className="text-xl pt-1 font-medium" />
                  ) : (
                    <BsFillEyeSlashFill className="text-xl pt-1 font-medium" />
                  )}
                </div>
              </div>
              <ErrorMessage
                name="password"
                component="div"
                className="text-error formik-error"
              />
            </div>

            <div className="form-group pb-3 relative">
              <div className="relative">
                <Field
                  type={resetState?.cpasswordHideShow ? "text" : "password"}
                  id="confirmPassword"
                  name="confirmPassword"
                  className="form-control  w-full"
                  placeholder="Confirm Password"
                // component={PassswordHideShow}
                />
                <div
                  onClick={() =>
                    setResetState((prev) => ({
                      ...prev,
                      cpasswordHideShow: !resetState?.cpasswordHideShow,
                    }))
                  }
                  className="absolute top-1 bottom-0 mr-2 right-0 cursor-pointer"
                >
                  {resetState?.cpasswordHideShow ? (
                    <AiFillEye className="text-xl pt-1 font-medium" />
                  ) : (
                    <BsFillEyeSlashFill className="text-xl pt-1 font-medium" />
                  )}
                </div>
              </div>
              <ErrorMessage
                name="confirmPassword"
                component="div"
                className="text-error formik-error"
              />
            </div>

            <AppButton
              btnText="Submit"
              disabled={isSubmitting}
              className="bg-black text-white w-full rounded-2xl py-2 px-4   mt-4"
              btnType={"Submit"}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ResetForm;
