import { createSlice } from "@reduxjs/toolkit";

// const BaseUrl = process.env.REACT_APP_SMART_BASE_URL;

const IntrestSlice = createSlice({
  name: "IntreastSplash",
  initialState: {
    intreast: [],
  },
  reducers: {
    addeIntrest: (state, action) => {
      if (state.intreast.includes(action.payload)) {
        let newData = state.intreast.filter((item) => {
          return item !== action.payload;
        });
        state.intreast = newData;
      } else {
        state.intreast = [...state.intreast, action.payload];
      }
    },
    resetIntrest: (state, action) => {
      state.intreast = [];
    },
  },
  extraReducers: {},
});

export default IntrestSlice.reducer;

export const { addeIntrest, resetIntrest } = IntrestSlice.actions;
