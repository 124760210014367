import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
// import "./index.css";
import "./assets/CSS/media.css";
import "./assets/CSS/global.css";
import "./assets/CSS/app.css";
import App from "./App";
import store from "./Store";
import { GoogleOAuthProvider } from "@react-oauth/google";

const root = ReactDOM.createRoot(document.getElementById("root"));
const CLIENT_ID =
  "598556046676-04h9o6a0phto595efmmdev6jou0uncko.apps.googleusercontent.com";

root.render(
  <Provider store={store}>
    <GoogleOAuthProvider clientId={CLIENT_ID}>
      <App />
    </GoogleOAuthProvider>
  </Provider>
);
