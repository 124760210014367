import React, { useEffect } from "react";
import Serchbar from "../../../components/Searchbar/Searchbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { BiArrowBack } from "react-icons/bi";
import PropTypes from "prop-types";
import { useState } from "react";
import { useUserProfileModalState } from "./ProfileApi";
import AppButton from "../../../components/Button/AppButton";
import placeholderImgae from "../../../assets/images/placeholderImage.jpg";
import { useNavigate } from "react-router";

const SocialFriendsList = ({
  userList,
  title,
  type,
  request,
  setProfileState,
  user,
  userParms,
}) => {
  const {
    getFollowerList,
    getFollowingList,
    getPendingRequestList,
    AcceptFriendRequest,
    RejectFriendRequest,
    unFollowRequest
  } = useUserProfileModalState();
  const [state, setState] = useState(true);
  const navigate = useNavigate();
  const [socialState, setSocialState] = useState({
    followingList: [],
    followerList: [],
    requestList: [],
    isRequest: false,
    requestListShow: false,
    render: false,
    requestCount: 0,
    renderRequest: false
  });

  const seeFreindProfile = (id) => {
    navigate(`/Profile/${id}`);
  };

  useEffect(() => {
    if (type === "Followers") {
      getFollowerList(user?.id)
        .then((res) => {
          if (res.status === 200) {
            setSocialState((prev) => ({
              ...prev,
              followerList: res.data.followers_list || [],
              followingList: [],
              requestListShow: false,
              render: false,
              requestCount: res.data.followers_request_pending,
            }));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (type === "Following") {
      getFollowingList(user.id)
        .then((res) => {
          if (res.status === 200) {
            setSocialState((prev) => ({
              ...prev,
              followerList: [],
              followingList: res.data?.following_list || [],
              requestListShow: false,
              render: false,
            }));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [type, socialState?.render, socialState?.isRequest]);

  useEffect(() => {
    if (type === "Followers" && socialState?.requestListShow) {
      getPendingRequestList(user?.id)
        .then((res) => {
          if (res.status === 200) {
            setSocialState((prev) => ({
              ...prev,
              requestList: res.data.followers_pending_list || [],
              followerList: [],
              followingList: [],
              renderRequest: false
            }));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [socialState?.requestListShow, socialState?.renderRequest]);

  const acceptUserRequest = (id) => {
    let data = {
      id: user?.id,
      friend_id: id,
    };
    AcceptFriendRequest(data)
      .then((res) => {
        if (res.status === 200) {
          setSocialState((prev) => ({ ...prev, renderRequest: true }));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const rejectUserRequest = (id) => {
    let data = {
      id: user?.id,
      friend_id: id,
    };
    RejectFriendRequest(data)
      .then((res) => {
        if (res.status === 200) {
          setSocialState((prev) => ({ ...prev, render: true }));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };


  const removeUnfollowRequest = (id) => {
    let data = {
      user_id: id,
      friend_user_id: user?.id,
    };
    unFollowRequest(data)
      .then((res) => {
        if (res.status === 200) {
          setSocialState((prev) => ({ ...prev, render: true }));
          setProfileState((prev) => ({ ...prev, render: true }))
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const handleSearch = (searchValue) => {
    if (type === "Followers") {
      if (searchValue?.length === 0) {
        setSocialState((prev) => ({
          ...prev,
          render: true,
        }));
      } else {
        const filteredFollwerdUserUsers = socialState?.followerList.filter(
          (user) => {
            return (
              user?.first_name
                ?.toLowerCase()
                ?.includes(searchValue?.toLowerCase()) ||
              user?.last_name
                ?.toLowerCase()
                ?.includes(searchValue?.toLowerCase())
            );
          }
        );
        setSocialState((prev) => ({
          ...prev,
          followerList: filteredFollwerdUserUsers,
        }));
      }
    } else if (type === "Following") {
      if (searchValue?.length === 0) {
        setSocialState((prev) => ({
          ...prev,
          render: true,
        }));
      } else {
        const filteredFollwingUserUsers = socialState?.followingList.filter(
          (user) => {
            return (
              user?.first_name
                ?.toLowerCase()
                ?.includes(searchValue.toLowerCase()) ||
              user?.last_name
                ?.toLowerCase()
                ?.includes(searchValue?.toLowerCase())
            );
          }
        );
        setSocialState((prev) => ({
          ...prev,
          followingList: filteredFollwingUserUsers,
        }));
      }
    }
  };



  return (
    <div className="social_freind_listing">
      <div className="header flex justify-between items-center px-5 pt-5">
        <span
          className="hover:bg-gray-300 p-1 rounded-full"
          onClick={() => {
            if (socialState.requestListShow) {
              setSocialState((prev) => ({ ...prev, requestListShow: false }));
              setProfileState((prev) => ({ ...prev, seeList: "suggestion" }));
            } else {
              setProfileState((prev) => ({ ...prev, seeList: "suggestion" }));
            }
          }}
        >
          <BiArrowBack />
        </span>
        <h2>{!socialState?.requestListShow ? title : "Follow Requests"}</h2>
        <span></span>
      </div>
      {socialState.requestListShow !== true && (
        <div
          className="px-6 py-4
      "
        >
          <Serchbar onSearch={handleSearch} data={socialState?.followingList} />

          {/* <div className="absolute right-0 -top-3 cursor-pointer ">
            <div className="border bg-white shadow-customshadow rounded-full h-[20px] w-[20px] flex items-center justify-center">
              <FontAwesomeIcon icon={faTimes} style={{ fontSize: "14px" }} />
            </div>
          </div> */}
        </div>
      )}

      {!userParms ? (
        <>
          {type === "Followers" && socialState.requestListShow === false && socialState?.requestCount > 0 && (
            <div className="border-b px-5 pb-3 mb-5  cursor-pointer">
              <div
                className="flex relative"
                onClick={() => {
                  setSocialState((prev) => ({
                    ...prev,
                    requestListShow: true,
                  }));
                }}
              >
                <img
                  src={
                    socialState?.requestList?.[
                      socialState?.requestList?.length - 1
                    ]?.profile_image
                      ? socialState?.requestList?.[
                        socialState?.requestList?.length - 1
                      ]?.profile_image
                      : placeholderImgae
                  }
                  alt=""
                  className="w-[40px] h-[40px] me-2 rounded-full bg-gray-400"
                />
                {socialState?.requestCount > 0 ? (
                  <span className="absolute text-sm top-0 left-7 w-[15px] h-[15px] shadow-customshadow bg-[#FF0000] text-white flex items-center justify-center rounded-full">
                    {socialState?.requestCount}
                  </span>
                ) : (
                  <></>
                )}

                <div>
                  <h5 className="flex flex-col">
                    Follow Requests
                    <span className="text-sm text-paragraph">
                      Approved or Ignore request
                    </span>
                  </h5>
                </div>
              </div>

              {/* <div className="absolute right-0 -top-3 cursor-pointer ">
                <div className="border bg-white shadow-customshadow rounded-full h-[20px] w-[20px] flex items-center justify-center">
                  <FontAwesomeIcon
                    icon={faTimes}
                    style={{ fontSize: "14px" }}
                  />
                </div>
              </div> */}
            </div>
          )}
        </>
      ) : (
        <></>
      )}

      <div className="list_of_user px-5 pb-5 cursor-pointer">
        {type === "Followers" && type !== "Following" && !socialState.requestListShow ? (
          <ul className="min-h-[40vh] custom-scrollbar" style={{ height: "60vh", overflowY: "auto" }}
          >
            {socialState.followerList?.length > 0 ? (
              socialState.followerList?.map((user) => {
                let {
                  id,
                  user_id,
                  first_name,
                  last_name,
                  profile_image,
                  follow_status,
                } = user;
                return (
                  <li

                    key={user}
                    className="border-b mb-3 p-2 rounded-[8px] flex items-center justify-between relative"
                  >
                    <div className="flex items-center" onClick={() => seeFreindProfile(user_id)}>
                      <img
                        src={
                          profile_image
                            ? `${process.env.REACT_APP_FILE_URL_PROFILE}/${profile_image} `
                            : `https://static.vecteezy.com/system/resources/thumbnails/004/511/281/small/default-avatar-photo-placeholder-profile-picture-vector.jpg`
                        }
                        alt=""
                        className="w-[40px] h-[40px] me-2 rounded-full"
                      />
                      <div>
                        <h5 className="flex flex-col">
                          {`${first_name} ${last_name}`}
                        </h5>
                      </div>
                    </div>
                    <div>
                      <button onClick={() => removeUnfollowRequest(user_id)} className="bg-gray-200 border-gray-300  py-1 px-3 rounded-[8px]">
                        Remove
                      </button>
                    </div>
                  </li>
                );
              })
            ) : (
              <li className="text-center text-paragraph text-sm pt-4">
                Your fan club is still under construction – 'No followers found' for now!
              </li>
            )}
          </ul>
        ) : type === "Following" && type !== "Followers" && !socialState.requestListShow ? (
          <ul className="min-h-[40vh] custom-scrollbar" style={{ height: "60vh", overflowY: "auto" }}>
            {socialState.followingList?.length > 0 ? (
              socialState.followingList?.map((user) => {
                let {
                  id,
                  user_id,
                  first_name,
                  last_name,
                  profile_image,
                  follow_status,
                } = user;
                return (
                  <li
                    onClick={() => seeFreindProfile(user_id)}
                    key={id}
                    className="border cursor-pointer mb-3 p-2 rounded-[8px] flex items-center justify-between relative"
                  >
                    <div className="flex items-center">
                      <img
                        src={
                          profile_image
                            ? `${process.env.REACT_APP_FILE_URL_PROFILE}/${profile_image} `
                            : placeholderImgae
                        }
                        alt=""
                        className="w-[40px] h-[40px] me-2 rounded-full"
                      />
                      <div>
                        <h5 className="flex flex-col">
                          {`${first_name} ${last_name}`}
                        </h5>
                      </div>
                    </div>
                    <div>
                      <AppButton
                        btnText="Following"
                        className="bg-gray-200 border-gray-200 py-1 px-3 rounded-[8px]"
                      />
                    </div>
                    {/* <div className="absolute right-0 -top-3 cursor-pointer ">
                        <div className="border bg-white shadow-customshadow rounded-full h-[20px] w-[20px] flex items-center justify-center">
                          <FontAwesomeIcon
                            icon={faTimes}
                            style={{ fontSize: "14px" }}
                          />
                        </div>
                      </div> */}
                  </li>
                );
              })
            ) : (
              <li className="text-center text-paragraph text-sm pt-4">
                Start following fellow life adventurers!
              </li>
            )}
          </ul>
        ) : socialState.requestList && socialState.requestListShow ? (
          <ul className="min-h-[40vh] py-6 custom-scrollbar" style={{ height: "60vh", overflowY: "auto" }}>
            {socialState.requestList?.length !== 0 &&
              socialState.requestListShow ? (
              socialState.requestList?.map((user) => {
                let {
                  id,
                  user_id,
                  first_name,
                  last_name,
                  profile_image,
                  follow_status,
                } = user;
                return (
                  <li
                    key={id}
                    className=" mb-3 p-2 rounded-[8px] flex items-center justify-between relative border-b"
                  >
                    <div className="flex items-center">
                      <img
                        src={profile_image ? profile_image : placeholderImgae}
                        alt=""
                        className="w-[40px] h-[40px] me-2 rounded-full"
                      />
                      <div>
                        <h5 className="flex flex-col font-semibold">
                          {`${first_name} ${last_name}`}
                        </h5>
                      </div>
                    </div>



                    <div className="cursor-pointer flex gap-1 items-center ">
                      <AppButton
                        btnText="Confirm"
                        disabled={false}
                        onClick={() => acceptUserRequest(user_id)}
                        className="bg-primary-dark text-white py-1 px-3 text-sm rounded-[8px]"
                      />
                      <div
                        onClick={() => rejectUserRequest(user_id)}
                        className="border-2 mx-1 border-grayLight p-2 shadow-customshadow rounded-full h-[15px] w-[15px] flex items-center justify-center"
                      >
                        <FontAwesomeIcon
                          className="text-grayLight"
                          icon={faTimes}
                          style={{ fontSize: "12px" }}
                        />
                      </div>
                    </div>
                  </li>
                );
              })
            ) : (
              <li className="text-center text-paragraph text-sm pt-4">
                No request found.
              </li>
            )}
          </ul>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

SocialFriendsList.propTypes = {
  userList: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  request: PropTypes.string,
  setProfileState: PropTypes.func,
};

export default SocialFriendsList;
