import React, { useState } from "react";
import AuthPageImage from "../../components/AuthPagesImage/AuthPageImage";
import AppButton from "../../components/Button/AppButton";
import Image from "../../assets/images/resetBg.svg";
import Logo from "../../assets/images/logo.png";
import { Link, useNavigate } from "react-router-dom";
import { IntrestArray } from "../../Helpers/constants";
import { useDispatch, useSelector } from "react-redux";
import { addeIntrest } from "../../Store/Slices/IntrestSlice";


const IntreastSkill = ({ splashData, setSplashData }) => {
    const [seletedIntreast, setSlectedIntreast] = useState([]);
    const {
        IntreastSplash: { intreast },
    } = useSelector((state) => state);
    const dispatch = useDispatch();
    const navigate = useNavigate();


    const onSelectIntrest = (id) => {
        dispatch(addeIntrest(id));
    };

    const goOnSignupPage = () => {
        navigate("/signup")
    }

    return (
        <div className="w-full h-[100vh] ">
            <div className="w-[100%]    flex h-full">
                <div className="w-[50%]   lg:block md:hidden sm:hidden hidden  ">
                    <AuthPageImage ImageUrl={Image} logo={Logo} />
                </div>
                <div className="w-full   lg:w-[50%]  md:w-full  sm:w-full  px-4 bg-white   sm:px-28  py-2 flex flex-col justify-center items-center relative">
                    <div className=" flex flex-col">
                        <h2 className="text-lg py-1 font-semibold">
                            Can you relate?
                        </h2>

                        <div className="text-start py-6 mb-6">
                            <ul className="flex flex-wrap gap-4 custom-scrollbar "
                                style={{ maxHeight: "70vh", overflowY: "auto" }}>
                                {IntrestArray.map((item) => {
                                    let { id, task } = item;
                                    return (
                                        <li
                                            key={id}
                                            onClick={() => onSelectIntrest(id)}
                                            className={`flex items-center justify-center   me-2 py-2 cursor-pointer rounded-[8px]  px-4 ${intreast?.includes(id)
                                                ? "border-secondary-lighter text-ternary-light bg-secondary-lighter border text-sm font-medium "
                                                : "text-ternary-light border  bg-primary-lighter border-primary-lighter text-sm font-medium"
                                                }`}
                                        >
                                            {task}
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                        <div className="text-center">
                            <AppButton
                                title={!intreast?.length > 0 ? "Selecte above intreast" : "Go Next page"}
                                className={`${intreast?.length > 0 ? "bg-ternary text-white" : "bg-gray-200 text-gray-400 cursor-not-allowed"} w-full px-12  py-3 rounded-2xl`}
                                btnText="Done"
                                disabled={intreast?.length > 0 ? false : true}
                                onClick={() => goOnSignupPage()}
                            />
                        </div>
                    </div>
                    <div className="dont_have_account    mt-2 bottom-3  text-center ">
                        <p className="w-full ps-2 text-paragraph  text-sm font-normal">
                            Already have an account?{" "}
                            <Link to="/login" className="font-semibold text-primary">
                                Login
                            </Link>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default IntreastSkill;
