import { DatePicker } from "antd";
import moment from "moment";
import React from "react";
import { useState } from "react";
import AppButton from "../../../components/Button/AppButton";
import dateIcon from "../../../assets/images/icons/date_icon.svg";
import resetIcon from "../../../assets/images/icons/reset_icon.svg";
import { getTaskConflict, getWeekData } from "../../../Helpers/constants";

import ChooseMemberModal from "./Modals/ChooseMember";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useActivityApis } from "./ActivityApis";
import ListItemActivity from "./ListItemActivity";
import { useLocation } from "react-router-dom";
import { BiPlus } from "react-icons/bi";

// import { google } from "googleapis";
import { useGoogleLogin } from "@react-oauth/google";

import GamificationPopup from "../../../components/GamificationPopup/GamificationPopup";
import useGoogleCalender from "./GoogleCalender/GoogleCalender";
import { render } from "../../../Store/Slices/DekstopNotificationSlice";
import OverlapRemoveTask from "./Modals/OverlapRemoveTask";
import { object } from "prop-types";


const Activity = () => {
  const {
    AuthUser: {
      user: { user },
    },
  } = useSelector((state) => state);
  const [eventAdded, setEventAdded] = useState(false);

  const { state } = useLocation();

  const { getTaskActivites, resetActivityPage } = useActivityApis();


  const [activityState, setActivityState] = useState({
    selectedDate: state?.[0]?.task_date || moment().format("yyyy-MM-DD"),
    isChooseMemberOpen: false,
    weekData: [],
    TaskActivitylist: [],
    assignTaskRecord: null,
    render: false,
    sloganData: {},
    IsGamificationOpen: false,
    earnedPoint: "",
    earnedType: "",
    congratesMessage: "",
    dyscoreCalender: {},
    AllEvents: [],
    render: false,
    loader: false,
    isShowOverLap: false,
    isShowoverlapped: false,
    reseting: false
  });

  const {
    getDyscoresCalender,
    createDyscoresCalender,
    getAlldyscoreEvents,
    createEventInCalendar,
    deleteEventFromCalender,
    editGoogleCaldenderEvent,
  } = useGoogleCalender();

  const dispatch = useDispatch();
  const handleDateChange = (date) => {
    setActivityState((prev) => ({
      ...prev,
      selectedDate: moment(date?.$d).format("yyyy-MM-DD"),
    }));
  };

  useEffect(() => {
    // weekData
    const data = getWeekData();
    setActivityState((prev) => ({ ...prev, weekData: data }));

    // getUserActivities
    if (user) {
      getTaskActivites({ id: user?.id, date: activityState.selectedDate })
        .then((res) => {





          if (res.status === 200) {
            dispatch(render())
            setActivityState((prev) => ({
              ...prev,
              TaskActivitylist: res?.data?.tasks_activities_list,
              sloganData: {},
            }));
            if (!activityState?.isShowoverlapped && state) {

              let data = getTaskConflict(res?.data?.tasks_activities_list);

              if (data > 0) {
                setActivityState((prev) => ({ ...prev, isShowOverLap: true, isShowoverlapped: true }))
              }
            }

          } else if (res.status === 500) {
            setActivityState((prev) => ({
              ...prev,
              sloganData: res?.data,
              TaskActivitylist: [],
            }));
          }
        })
        .catch((error) => {
          console.log(error, "error");
        });
    }

    setActivityState((prev) => ({ ...prev, render: false }));
  }, [
    activityState?.render,
    activityState?.selectedDate,
    activityState?.isChooseMemberOpen,
  ]);

  const selectdayHandler = (date) => {
    setActivityState((prev) => ({
      ...prev,
      selectedDate: moment(date).format("yyyy-MM-DD"),
    }));
  };


  const resetPage = () => {
    setActivityState((prev) => ({
      ...prev, reseting: true
    }))
    if (state) {
      let data = {
        user_id: user?.id,
        assign_task_type:
          state?.selectedIndex === 0
            ? "single-day"
            : state?.selectedIndex === 1
              ? "week-days"
              : state?.selectedIndex === 2
                ? "week-end"
                : "",
        date:
          state?.selectedIndex === 0
            ? state?.SelectedDay?.[0]
            : state?.SelectedDay,
        wake_up_time: moment(state?.wakeUpTime?.$d).format("hh:mm A"),
        sleep_time: moment(state?.sleepTime?.$d).format("hh:mm A"),
        task_name: state?.task_name,
      };

      resetActivityPage(data)
        .then((res) => {
          localStorage.setItem("render", 0)
          setActivityState((prev) => ({ ...prev, render: true, reseting: false }));
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setTimeout(() => {
        setActivityState((prev) => ({ ...prev, render: true, reseting: false }));
      }, 1000);
    }
  };



  const login = useGoogleLogin({
    scope: "https://www.googleapis.com/auth/calendar",
    accessType: "offline",
    prompt: "consent",
    onSuccess: (tokenResponse) => {
      if (tokenResponse) {

        const redirectUri = "http://localhost:3000";
        const webRedirect = "https://web.dyscore.prometteur.in"
        const tokenEndpoint = "https://oauth2.googleapis.com/token";

        let requestBody = {
          code: tokenResponse?.code,
          client_id: process.env.REACT_APP_CALENDER_CLIENT_ID,
          client_secret: process.env.REACT_APP_CALENDER_CLIENT_SECRET,
          redirect_uri: webRedirect,
          grant_type: "authorization_code",
        };



        fetch(tokenEndpoint, {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: new URLSearchParams(requestBody),
        })
          .then((response) => response.json())
          .then((tokenResponse) => {

            // Save access token and refresh token to localStorage
            const accessToken = tokenResponse.access_token;
            const refreshToken = tokenResponse.refresh_token;
            setActivityState((prev) => ({ ...prev, render: false }));

            localStorage.setItem("access_token", accessToken);
            localStorage.setItem("refresh_token", refreshToken);
          })
          .catch((error) => {
            console.error("Error exchanging authorization code for tokens", error);
          });
      }


    },
    flow: 'auth-code',
  });

  const convertDateTimeFormat = (date, time) => {
    let combinedDateTime = date + " " + time;
    let dateTime = moment(combinedDateTime, "YYYY-MM-DD h:mm A");
    let formattedStartDateTime = dateTime.format("YYYY-MM-DDTHH:mm:ssZ");
    return formattedStartDateTime;
  };

  const createEvent = () => {
    if (localStorage?.getItem("access_token") && Object?.keys(activityState?.dyscoreCalender)?.length > 0) {

      let datsa =
        activityState?.TaskActivitylist &&
        activityState?.TaskActivitylist.map((item) => {
          const matchingEvent = activityState?.AllEvents?.find(
            (dataItem) => parseInt(dataItem.description) === item.task_id

          );
          setActivityState((prev) => ({ ...prev, loader: true }))

          if (matchingEvent) {
            // Update the existing event with dataItem instead of item
            const requestBody = {
              kind: "calendar#Dyscore",
              name: "Lakshya",
              id: item.id,
              summary: item.task_sub_category_name || "task",
              description: `${item?.task_id}`,
              start: {
                dateTime: convertDateTimeFormat(
                  item.task_date,
                  item.task_start_time
                ),
                timeZone: "UTC",
              },
              end: {
                dateTime: convertDateTimeFormat(
                  item.task_date,
                  item.task_end_time
                ),
                timeZone: "UTC",
              },
              recurrence: ["RRULE:FREQ=DAILY;COUNT=1"],
              reminders: {
                useDefault: true,
              },
              guestsCanSeeOtherGuests: true,
            };


            return editGoogleCaldenderEvent(
              requestBody,
              activityState?.dyscoreCalender,

              matchingEvent,
              setActivityState
            );
          } else {
            const requestBody = {
              kind: "calendar#Dyscore",
              name: "Lakshya",

              summary: item.task_sub_category_name || "task",
              description: `${item?.task_id}`,
              start: {
                dateTime: convertDateTimeFormat(
                  item.task_date,
                  item.task_start_time
                ),
                timeZone: "UTC",
              },
              end: {
                dateTime: convertDateTimeFormat(
                  item.task_date,
                  item.task_end_time
                ),
                timeZone: "UTC",
              },
              recurrence: ["RRULE:FREQ=DAILY;COUNT=1"],
              reminders: {
                useDefault: true,
              },
              guestsCanSeeOtherGuests: true,
              // id: item.task_id,
            };


            return createEventInCalendar(
              requestBody,
              activityState?.dyscoreCalender,
              setActivityState
            );
          }
        });
    }
  };






  useEffect(() => {
    if (localStorage?.getItem("access_token")) {
      getDyscoresCalender().then((res) => {
        if (res?.summary === "Lakshya") {
          getAlldyscoreEvents(res).then((response) => {
            setActivityState((pre) => ({
              ...pre,
              dyscoreCalender: res,
              existingEvent: response.items,
              AllEvents: response.items,
              render: false,
            }));
          });
        }
      });
    }
  }, [activityState?.render]);




  useEffect(() => {

    if (localStorage?.getItem("refresh_token") !== undefined) {
      const regenrateAccessToken = () => {

        const tokenEndpoint = "https://oauth2.googleapis.com/token";
        let requestBody = {
          refresh_token: localStorage.getItem("refresh_token"),
          client_id: process.env.REACT_APP_CALENDER_CLIENT_ID,
          client_secret: process.env.REACT_APP_CALENDER_CLIENT_SECRET,
          grant_type: "refresh_token",
        };
        fetch(tokenEndpoint, {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: new URLSearchParams(requestBody),
        })
          .then((response) => response.json())
          .then((tokenResponse) => {
            if (!tokenResponse.error) {
              // Save access token and refresh token to localStorage
              const accessToken = tokenResponse.access_token;



              localStorage.setItem("access_token", accessToken);
              // localStorage.setItem("refresh_token", refreshToken);
            }


          })
          .catch((error) => {
            console.error("Error exchanging authorization code for tokens", error);
          });
      }
      regenrateAccessToken();
    }

  }, [])

  return (
    <div className="bg-white p-4 shadow-customshadow rounded-[15px]">
      <h1 className="text-lg lg:text-xl md:text-xl  font-medium">Activity</h1>
      <div className="grid grid-cols-12 gap-5 py-2">
        <div className="col-span-12 md:col-span-6 lg:col-span-6">
          <div className="min-h-[70vh]  relative">
            <div className="flex flex-col bg-background p-4 rounded-[18px]">
              <div className="title flex justify-between">
                <h3 className="text-base font-semibold">
                  {moment(activityState?.selectedDate).format("dddd, MMM DD")}
                </h3>
                <div className="calender datepicker_activity">
                  <DatePicker
                    suffixIcon={<img className="w-4 h-4" src={dateIcon} />}
                    selected={activityState?.selectedDate}
                    onChange={handleDateChange}
                  // dateFormat="yyyy-MM-dd"
                  />
                </div>
              </div>
              {/* dates */}
              <ul className="date-list grid grid-cols-7 lg:gap-6 md:gap-4 gap-1   py-8 ">
                {activityState?.weekData?.map((item, index) => {
                  return (
                    <li
                      key={index + 1}
                      className={`flex items-center my-1  px-3 md:py-2 lg:py-2 py-1  flex-col rounded text-[16px] cursor-pointer ${item === activityState?.selectedDate
                        ? "bg-primary text-white shadow-customshadow"
                        : ""
                        } text-base font-medium `}
                      onClick={() => selectdayHandler(item)}
                    >
                      {moment(item).format("ddd")}
                      <span className="text-[14px]">
                        {moment(item).format("DD")}
                      </span>
                    </li>
                  );
                })}
              </ul>
              {/* reset button */}

              {activityState?.TaskActivitylist ? (
                <AppButton
                  btnText={"Reset"}
                  leftIcon={
                    <img src={resetIcon} className="text-2xl h-4 font-medium" />
                  }
                  className="bg-[#4A9D93] text-white py-2 px-4 rounded-md w-fit shadow-customshadow"
                  onClick={() => {
                    resetPage();
                  }}
                />
              ) : (
                <AppButton
                  btnText={"Add Task"}
                  leftIcon={<BiPlus className="text-xl font-medium" />}
                  className="bg-[#4A9D93] text-white py-2 px-4 rounded-md w-fit shadow-customshadow"
                  onClick={() => {
                    resetPage();
                  }}
                />
              )}
            </div>
            <div className="absolute bottom-0 w-full flex">
              {localStorage?.getItem("access_token") && localStorage.getItem("refresh_token") ? <AppButton
                btnText={activityState?.loader ? "Sync..." : "Sync to calender"}
                onClick={createEvent}
                className="bg-ternary border w-full text-center rounded-2xl border-ternary text-white py-2 px-5"
              /> : <AppButton
                btnText="Sync your calender"
                onClick={() => {
                  login();
                }}
                className="bg-ternary border w-full text-center rounded-2xl border-ternary text-white py-2 px-5"
              />}

            </div>


          </div>
        </div>
        <div className="col-span-12 md:col-span-6 lg:col-span-6">
          <div>

            {activityState?.reseting && <span className="w-full flex justify-center items-center text-paragraph ">Refreshing... Please wait</span>}
            <ul
              style={{ height: "70vh", overflowY: "auto" }}
              className="custom-scrollbar"
            >
              {

                activityState?.TaskActivitylist?.length > 0 && !activityState?.reseting &&
                  Object.keys(activityState?.sloganData)?.length === 0 ? (
                  activityState?.TaskActivitylist?.map((task) => {
                    let {
                      task_id,
                      task_sub_category_name,
                      task_sub_category_image,
                      task_start_time,
                      task_status,
                      task_assigned_member_name,
                      task_end_time,
                    } = task;
                    return (
                      <ListItemActivity
                        key={task_id}
                        task={task}
                        task_id={task_id}
                        task_sub_category_name={task_sub_category_name}
                        task_sub_category_image={task_sub_category_image}
                        task_start_time={task_start_time}
                        task_status={task_status}
                        task_end_time={task_end_time}
                        activityState={activityState}
                        setActivityState={setActivityState}
                        task_assigned_member_name={task_assigned_member_name}
                        user={user}
                      />
                    );
                  })
                ) : (
                  <li className="  text-paragraph  py-3 rounded-[15px]">
                    {Object.keys(activityState?.sloganData)?.length > 0 && !activityState?.reseting &&
                      activityState?.TaskActivitylist?.length === 0 && (
                        <div className="flex items-center flex-col">
                          <img
                            src={
                              activityState?.sloganData?.task_data_not_found_image
                            }
                            alt=""
                            className="w-[200px] h-[200px]"
                          />
                          <span>{activityState?.sloganData?.slogan}</span>
                        </div>
                      )}
                  </li>
                )}
            </ul>
          </div>
        </div>
      </div>

      <ChooseMemberModal state={activityState} setState={setActivityState} />

      <OverlapRemoveTask activityState={activityState} setActivityState={setActivityState} taskData={state} user={user} />

      <GamificationPopup
        parentState={activityState}
        setParentState={setActivityState}
        title={"Congratulation!"}
        message={activityState?.congratesMessage}
      />
    </div>
  );
};

export default Activity;