import React, { useEffect } from "react";
import { useState } from "react";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { GrAttachment } from "react-icons/gr";
import { MdOutlineKeyboardVoice } from "react-icons/md";
import { LuSend } from "react-icons/lu";
import Serchbar from "../../../components/Searchbar/Searchbar";
import ChatList from "./ChatList";
import { useSelector } from "react-redux";
import ChatUserView from "./ChatUser";
import attachmentIcon from "../../../assets/images/icons/attachment_icon.svg";
import { useLocation } from "react-router-dom";
import { useSettings } from "../Setting/settingApis";

const Chat = () => {
  const { state } = useLocation();
  const { getBlockUsers } = useSettings();
  const {
    AuthUser: {
      user: { user },
    },
  } = useSelector((state) => state);
  const [chat, setChat] = useState({
    chatUser: state ? state : null,
    messgae: "",
    userMessages: [],
    activeUser: state ? state : null,
    dataChange: false,
    state: state,
    allUsers: [],
    globalUsers: [],
    blockedUsers: [],
  });

  const getBlockedUser = () => {
    getBlockUsers(user?.id)
      .then((res) => {
        if (res.status === 200) {
          setChat((prev) => ({
            ...prev,
            blockedUsers: res.data,
          }));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getBlockedUser();
  }, [chat?.activeUser]);

  function handleSearch(newSearchTerm) {
    if (newSearchTerm?.length > 0) {
      const filteredData = chat?.globalUsers?.filter(
        (ele) =>
          ele?.to_user?.first_name
            ?.toLowerCase()
            .startsWith(newSearchTerm.toLowerCase()) ||
          ele?.to_user?.last_name
            ?.toLowerCase()
            .startsWith(newSearchTerm.toLowerCase())
      );
      setChat({
        ...chat,
        allUsers: filteredData,
      });
    } else if (newSearchTerm?.length === 0) {
      setChat({
        ...chat,
        allUsers: chat.globalUsers,
      });
    }
  }

  return (
    <div className="user-chat-page">
      <div className="grid grid-cols-12 gap-3 bg-white shadow-customshadow  rounded-[15px]">
        <div className="col-span-12 sm:col-span-4 md:col-span-4 lg:col-span-4 p-3">
          <div className="w-full relative ">
            <div className="searchbar">
              <Serchbar onSearch={handleSearch} />
            </div>
            <ChatList
              user={user}
              chatParentState={chat}
              setChatParentState={setChat}
            />
          </div>
        </div>

        <div className="col-span-12 sm:col-span-8  md:col-span-8 lg:col-span-8 border-l ">
          <div className="hidden sm:block lg:block md:block">
            <ChatUserView
              user={user}
              chatParentState={chat}
              setChatParentState={setChat}
              backbutton={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chat;
