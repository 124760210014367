import React, { useState } from "react";
import { AiOutlineMinus } from "react-icons/ai";
import { BiSolidCamera, BiSolidImageAlt } from "react-icons/bi";
import { useSelector } from "react-redux";
import Webcam from "react-webcam";
import { useUserProfileModalState } from "../../ProfileApi";
const PICTURE_SELECTED_MODE = {
  CAMERA: "camera",
  BROWSE: "browse",
};
const videoConstraints = {
  width: 1280,
  height: 720,
  facingMode: "user",
};
const PickedImageFromSystem = ({
  setState,
  capturedImage,
  onCapturedImage,
  state,
  user,
  looking_for,
}) => {
  const { editProfileUser, removeUserProfileImage } =
    useUserProfileModalState();
  const [takeImageState, setTakeImageState] = useState({
    isShowCameraView: false,
    imageLInk: null,
  });
  const fileInputRef = React.useRef(null);

  const webcamRef = React.useRef(null);

  const postSelectedProfileImage = (params, pictureSelectedMode) => {
    editProfileUser(params)
      .then((res) => {
        if (res.status === 200) {
          // onCapturedImage(URL.createObjectURL(params?.profile_image));
          onCapturedImage(res?.data?.img_url);
          //redux store

          if (pictureSelectedMode === PICTURE_SELECTED_MODE.CAMERA) {
            cameraOnOff(false);
          } else {
          }
          setState((prev) => ({ ...prev, editProfileIsOpen: false }));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const createBlob = (imageSrc) => {
    const data = fetch(imageSrc)
      .then((response) => response.blob())
      .then((blob) => {
        return blob;
      });
    return data;
  };

  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();

    setState((prev) => ({
      ...prev,
      editProfileIsOpen: false,
      urlImage: imageSrc,
      imageType: "camera",
    }));
  }, [webcamRef]);

  const cameraOnOff = (flag) => {
    setTakeImageState((prev) => ({
      ...prev,
      isShowCameraView: flag,
    }));
  };

  const handleFileUpload = () => {
    fileInputRef.current.click();
  };

  const handleFileSelected = (e) => {
    setState((prev) => ({
      ...prev,
      editProfileIsOpen: false,
      urlImage: e.target.files[0],
      imageType: "file",
    }));
  };

  const deleteProfilePhoto = () => {
    setState((prev) => ({
      ...prev,
      editProfileIsOpen: false,
      urlImage: "",
      imageType: "",
    }));
  };

  return (
    <div className="avatar-dumy flex w-full flex-col item-center justify-center">
      {takeImageState?.isShowCameraView ? (
        <>
          <Webcam
            audio={false}
            height={720}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            width={1280}
            videoConstraints={videoConstraints}
          />
          <button
            className=" bg-primary text-white py-1 px-2 my-2"
            onClick={() => capture()}
          >
            Capture photo
          </button>
        </>
      ) : (
        <>
          <div className="w-full  flex item-center justify-center  py-6">
            <div className="w-[100px] h-[100px] relative">
              <img
                src={
                  state?.userProfile?.user?.profile_image
                    ? `${process.env.REACT_APP_FILE_URL_PROFILE}/${state?.userProfile?.user?.profile_image} `
                    : `https://static.vecteezy.com/system/resources/thumbnails/004/511/281/small/default-avatar-photo-placeholder-profile-picture-vector.jpg`
                }
                alt="capturedImage"
                className="rounded-full w-full h-full object-cover"
              />
            </div>
          </div>
          <span
            className="text-error text-center cursor-pointer "
            onClick={() => deleteProfilePhoto()}
          >
            Delete Photo
          </span>
          <div className="or-div text-center w-full flex justify-center items-center gap-1">
            <span className="text-gray-500">
              <AiOutlineMinus />
            </span>
            <span className="text-gray-500">OR</span>
            <span className="text-gray-500">
              <AiOutlineMinus />
            </span>
          </div>
          <div className="add-imag-btns-group flex flex-col sm:flex-row md:flex-row lg:flex-row gap-3  pt-3">
            <button
              className=" w-full flex items-center justify-center border border-primary-dark text-primary-dark py-1 rounded-[8px]"
              onClick={handleFileUpload}
            >
              <BiSolidImageAlt className="text-xl me-2" /> Upload from Computer
            </button>
            <input
              accept="image/*"
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={(e) => handleFileSelected(e)}
            />

            <button
              className=" w-full  flex items-center justify-center border border-primary-dark text-primary-dark py-1 rounded-[8px]"
              onClick={() => cameraOnOff(!takeImageState?.isShowCameraView)}
            >
              <BiSolidCamera className="text-xl me-2" /> Take a picture
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default PickedImageFromSystem;
