import React, { useEffect, useState } from "react";
import { useActivityApis } from "../admin/Activity/ActivityApis";
import moment from "moment";
import { useSelector } from "react-redux";
import { BsCheck } from "react-icons/bs";
import GamificationPopup from "../../components/GamificationPopup/GamificationPopup";

const UserTaskLists = ({
  wishingPopUpState,
  setWishingPopUpState,
  title,
  isEvening,
  PageNumber,

}) => {
  const [taskListState, setTaskListState] = useState({
    todaysTasks: [],
    IsGamificationOpen: false,
    render: false,
    earnedType: "",
    congratesMessage: "",
  });
  const {
    AuthUser: {
      user: { user },
    },
  } = useSelector((state) => state);
  const { getTaskActivites, doMarkAsComplete } = useActivityApis();

  const handleSubmitTaskMessage = () => {
    setWishingPopUpState((prev) => ({ ...prev, isWishModalOpen: false }));
    localStorage.setItem("seeAgainWishPopup", false);
    localStorage.setItem("isSameUserWishPopups", user?.id);
  };

  // handle check uncheck
  const onMarkAsComplete = (statusNumber, task_id) => {
    let data = {
      user_id: user?.id,
      task_id: task_id,
      task_status: statusNumber,
      date: moment().format("YYYY-MM-DD"),
      max_streak_date: moment().utc().format("YYYY-MM-DD"),
      task_date_time: moment().format("YYYY-MM-DD hh:mm:ss")
    };
    doMarkAsComplete(data)
      .then((res) => {
        if (res.status === 200) {
          let {
            category_completion_flag,
            category_completion_name,
            max_streak_flag,
            milestone_achievements: { milestone_achievement_flag },
            todays_task_completion_flag,
            todays_task_completion_point,
          } = res?.data;
          getTaskListHandler();
          if (res.status === 200) {
            const lastStreakDate = localStorage.getItem('last_streak_date')
            let {

              milestone_achievements: { milestone_achievement_flag },

            } = res?.data;
            if (
              res.data?.milestone_achievements?.milestone_achievement_flag !==
              0 &&
              statusNumber !== 0
            ) {
              setTaskListState((prev) => ({
                ...prev,
                render: true,
                IsGamificationOpen: true,

                earnedType: "milestone",
                congratesMessage: `🏁 Milestone Unlocked! 🌟 You've reached a significant milestone on your journey – 10 tasks completed! Enjoy your well-earned bonus points and keep reaching for the stars! 🚀🎉`,
              }));
            } else if (res.data?.max_streak_flag !== 0 && lastStreakDate != moment().format('YYYY-MM-DD') && statusNumber !== 0) {
              setTaskListState((prev) => ({
                ...prev,
                render: true,
                IsGamificationOpen: true,

                earnedType: "maxStreak",
                congratesMessage: `🔥 Streak On! 🌟 Your dedication pays off with bonus points for keeping the streak alive. Keep up the winning streak! 💪🎉`,
              }));
              localStorage.setItem('last_streak_date', moment().format('YYYY-MM-DD'))
            } else if (
              res.data?.category_completion_flag !== 0 &&

              statusNumber !== 0
            ) {
              setTaskListState((prev) => ({
                ...prev,
                render: true,
                IsGamificationOpen: true,
                earnedType: "Category",
                congratesMessage: `🏆 Category Conqueror! 🌟 You've aced all the tasks in the  ${res.data?.category_completion_name} category. Enjoy your well-deserved bonus points and keep dominating those categories! 🚀🎉`,
              }));
            } else if (

              statusNumber !== 0
            ) {
              setTaskListState((prev) => ({
                ...prev,
                render: true,
                IsGamificationOpen: true,
                earnedType: "Task",
                congratesMessage: `🏆 Task Completed! 🌟 You've earned ${res.data?.todays_task_completion_point} points for conquering another task. Keep up the great work!`,
              }));
            } else {
              setTaskListState((prev) => ({
                ...prev,
                render: true,
              }));
            }


          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getTaskListHandler = () => {
    if (user) {
      getTaskActivites({ id: user?.id, date: moment().format("YYYY-MM-DD") })
        .then((res) => {
          if (res.status === 200) {
            setTaskListState((prev) => ({
              ...prev,
              todaysTasks: res?.data?.tasks_activities_list,
            }));
          } else if (res.status === 500) {
            setTaskListState((prev) => ({
              ...prev,
              todaysTasks: [],
            }));
          }
        })
        .catch((error) => {
          console.error(error, "error");
        });
    }
  };
  useEffect(() => {
    // getUserActivities
    getTaskListHandler();
  }, []);


  return (
    <>
      <div className="flex flex-col justify-center items-center font-montserrat">
        {!isEvening && <span>STEP {PageNumber} OF 4</span>}
        <h2 className="text-lg font-montserrat text-ternary font-semibold pb-1 text-center">
          {title}
        </h2>

        {/* {} */}
        {taskListState?.todaysTasks?.length > 0 ? (
          <div>
            <p className="text-paragraph text-sm mb-2">
              Please tick the completed daily task
            </p>

            <div className="mb-3 custom-scrollbar" style={{ maxHeight: "200px", overflowY: "auto" }}>
              <table className="w-full table-auto text-center " >
                <thead>
                  <tr className="bg-primary text-white">
                    <th className="table-cell p-2 text-semibold w-1/2">
                      Tasks
                    </th>
                    <th className="table-cell p-2 text-semibold w-1/2">
                      Today
                    </th>
                  </tr>
                </thead>
                <tbody >
                  {taskListState?.todaysTasks?.map((ele) => {
                    return (
                      <tr key={ele?.task_id} className="border">
                        <td className="border p-2 w-1/2 ">
                          <p className="font-semibold text-sm">
                            {ele?.task_sub_category_name}
                          </p>
                        </td>
                        <td className=" p-2 w-full flex items-center justify-center">
                          <div>
                            <label
                              className="cursor-pointer"
                              onClick={() =>
                                onMarkAsComplete(
                                  ele?.task_status === "1" ? 0 : 1,
                                  ele?.task_id
                                )
                              }
                              htmlFor={`tasks${ele?.task_id}`}
                            >
                              {ele?.task_status === "1" ? (
                                <div className="h-6 w-6 rounded-full border-2 bg-primary border-primary flex items-center justify-center">
                                  <BsCheck className="text-xl font-extrabold text-white" />
                                </div>
                              ) : (
                                <div className="h-6 w-6 rounded-full border-2 border-paragraph"></div>
                              )}
                              <input
                                type="checkbox"
                                className="hidden"
                                id={`tasks${ele?.task_id}`}
                                name={`tasks${ele?.task_id}`}
                              // defaultChecked={
                              //   ele?.task_status === "1" ? true : false
                              // }
                              />
                            </label>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <button
              className={
                "bg-ternary  text-white px-20 py-2 rounded-2xl mt-4 w-full"
              }
              onClick={() => handleSubmitTaskMessage()}
            >
              Done
            </button>
          </div>
        ) : (
          <p>
            {taskListState?.todaysTasks?.length === 0
              ? null
              : "There is no task awailable"}
          </p>
        )}
      </div>
      <GamificationPopup
        parentState={taskListState}
        setParentState={setTaskListState}
        title={"Congratulation"}
        message={taskListState?.congratesMessage}
      />
    </>
  );
};

export default UserTaskLists;
