import React, { useState } from "react";
import placeholderImage from "../../../assets/images/placeholderImage.jpg"


const PostItem = ({ post, onLikeHandler, postMessage,
    setPostMessage, postMessageHandler, onSeePostDetail }) => {


    let {
        comment_count,
        like_count,
        like_status,
        name,
        post_caption,
        post_id,
        post_image,
        profile_image,
        user_id,
    } = post;

    let LikeIcon;

    if (like_status) {
        LikeIcon = (
            <svg
                stroke="currentColor"
                fill="#0066FF"
                stroke-width="0"
                viewBox="0 0 1024 1024"
                height="1.3em"
                width="1.3em"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M885.9 533.7c16.8-22.2 26.1-49.4 26.1-77.7 0-44.9-25.1-87.4-65.5-111.1a67.67 67.67 0 0 0-34.3-9.3H572.4l6-122.9c1.4-29.7-9.1-57.9-29.5-79.4A106.62 106.62 0 0 0 471 99.9c-52 0-98 35-111.8 85.1l-85.9 311h-.3v428h472.3c9.2 0 18.2-1.8 26.5-5.4 47.6-20.3 78.3-66.8 78.3-118.4 0-12.6-1.8-25-5.4-37 16.8-22.2 26.1-49.4 26.1-77.7 0-12.6-1.8-25-5.4-37 16.8-22.2 26.1-49.4 26.1-77.7-.2-12.6-2-25.1-5.6-37.1zM112 528v364c0 17.7 14.3 32 32 32h65V496h-65c-17.7 0-32 14.3-32 32z"></path>
            </svg>
        );
    } else {
        LikeIcon = (
            <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 1024 1024"
                height="1.3em"
                width="1.3em"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M885.9 533.7c16.8-22.2 26.1-49.4 26.1-77.7 0-44.9-25.1-87.4-65.5-111.1a67.67 67.67 0 0 0-34.3-9.3H572.4l6-122.9c1.4-29.7-9.1-57.9-29.5-79.4A106.62 106.62 0 0 0 471 99.9c-52 0-98 35-111.8 85.1l-85.9 311H144c-17.7 0-32 14.3-32 32v364c0 17.7 14.3 32 32 32h601.3c9.2 0 18.2-1.8 26.5-5.4 47.6-20.3 78.3-66.8 78.3-118.4 0-12.6-1.8-25-5.4-37 16.8-22.2 26.1-49.4 26.1-77.7 0-12.6-1.8-25-5.4-37 16.8-22.2 26.1-49.4 26.1-77.7-.2-12.6-2-25.1-5.6-37.1zM184 852V568h81v284h-81zm636.4-353l-21.9 19 13.9 25.4a56.2 56.2 0 0 1 6.9 27.3c0 16.5-7.2 32.2-19.6 43l-21.9 19 13.9 25.4a56.2 56.2 0 0 1 6.9 27.3c0 16.5-7.2 32.2-19.6 43l-21.9 19 13.9 25.4a56.2 56.2 0 0 1 6.9 27.3c0 22.4-13.2 42.6-33.6 51.8H329V564.8l99.5-360.5a44.1 44.1 0 0 1 42.2-32.3c7.6 0 15.1 2.2 21.1 6.7 9.9 7.4 15.2 18.6 14.6 30.5l-9.6 198.4h314.4C829 418.5 840 436.9 840 456c0 16.5-7.2 32.1-19.6 43z"></path>
            </svg>
        );
    }

    const onMessageHanlder = (e) => {
        setPostMessage(e.target.value)
    }



    return (
        <li className="bg-[#E8EFEF] rounded-[10px] px-4 py-4 mb-2 relative overflow-hidden"  >
            <div className="post-header flex gap-3">
                <img
                    src={
                        profile_image
                            ? `${process.env.REACT_APP_FILE_URL_PROFILE}/${profile_image}`
                            : placeholderImage
                    }
                    alt=""
                    style={{ width: "35px", height: "35px" }}
                    className="rounded-full"
                />
                <span className="font-semibold">{name}</span>
            </div>
            <div className="description text-sm text-paragraph py-2 w-full truncate text-ellipsis me-3" >
                {post_caption}
            </div>
            {post_image && (
                <img
                    src={
                        post_image
                            ? `${process.env.REACT_APP_FILE_URL_CHALLANGE_POST}/${post_image}`
                            : ""
                    }
                    alt=""
                    className="py-2 "
                    style={{ width: "100%", height: "250px", objectFit: "cover" }}
                ></img>
            )}

            {/* like and comments */}
            <div className="flex justify-between ">
                <div className="like flex gap-3 text-sm">
                    <span onClick={onLikeHandler} className="cursor-pointer"> {LikeIcon} </span > <span className="text-gray-700 font-semibold -me-2">{like_count}</span>
                </div>
                <div className="like flex gap-3 text-sm" onClick={onSeePostDetail}>
                    <span className="text-sm text-primary-dark cursor-pointer">{comment_count} Comments</span>
                </div>
            </div>

            <div className="add comment bg-white rounded-[10px] px-2 py-1 flex items-center my-2">
                <input
                    type="text"
                    name="postMessage"
                    accept=".jpg, .jpeg, .png"
                    value={postMessage}
                    onChange={onMessageHanlder}
                    className="bg-white w-[90%]"
                    placeholder="Add comments"
                />
                <button className="w-[15%] border-2 border-primary-dark text-primary-dark font-semibold rounded-[10px] py-1 px-2" onClick={() => {
                    if (postMessage) {
                        postMessageHandler(postMessage)
                    }
                }}


                >post</button>
            </div>
        </li>
    );
};

export default PostItem;
