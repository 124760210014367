import { Modal } from 'antd'
import React from 'react'
import AppButton from '../../../../components/Button/AppButton';
import moment from 'moment';

const CreateRoutineModal = ({ taskState, setTaskState, state }) => {

    const handleOk = () => {
        if (state) {
            let taskData = state?.map((ids) => parseInt(ids?.task_name_id))
            let userSleepAndWakeUpTime = state?.[0]
            if (userSleepAndWakeUpTime && taskData) {
                setTaskState((prev) => ({
                    ...prev,
                    wakeUpTime: moment(userSleepAndWakeUpTime?.wake_up_time, "h:mm A"),
                    sleepTime: moment(userSleepAndWakeUpTime?.sleep_time, "h:mm A"),
                    task_name: taskData,
                    createRoutine: false
                }))

            }
        }
    };
    const handleCancel = () => {
        setTaskState((prev) => ({ ...prev, createRoutine: false }));
    };



    return (
        <Modal
            open={taskState.createRoutine}
            title=""
            onOk={handleOk}
            destroyOnClose
            className="routinePopup"
            width={300}
            onCancel={handleCancel}
            closeIcon={false}
            footer={[
                <div className="text-start -pt-6 font-montserrat">



                    <p className="font-semibold text-ternary">
                        Are you sure you want to create same routine?
                    </p>
                    <div className=" flex justify-end gap-2 ">

                        <AppButton
                            onClick={handleOk}
                            btnText="Yes"
                            className="text-ternary-dark font-bold  border-0  px-4 py-1 "
                        />
                        <AppButton
                            onClick={handleCancel}
                            btnText="No"
                            className="text-secondary px-4 py-1"
                        />
                    </div>

                </div>,
            ]}
        ></Modal>
    )
}

export default CreateRoutineModal