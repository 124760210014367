import sproutImage from "../assets/images/sprout.svg";
import SeedlingImage from "../assets/images/seeding.svg";
import ChrysalisImage from "../assets/images/bug.svg";
import MetamorphosisImage from "../assets/images/metamorphosis.svg";
import EmergingImage from "../assets/images/emergingbutterFly.svg";
import gracefulImage from "../assets/images/graceful.svg";
import colorfulImage from "../assets/images/colorful.svg";

export const StepsData = [
  {
    id: 1,
    min: 0,
    max: 1,
    value: 0,
    description: "1 day",
    title: "Seedling",
    img: SeedlingImage,
  },
  {
    id: 2,
    min: 2,
    max: 3,
    value: 2,
    description: "Consecutive 2 to 3 days",
    title: "Sprout",
    img: sproutImage,
  },
  {
    id: 3,
    value: 3,
    min: 4,
    max: 7,
    description: "Consecutive 4 to 7 days",
    title: "Chrysalis",
    img: ChrysalisImage,
  },
  {
    id: 4,
    value: 4,
    min: 8,
    max: 14,
    description: "Consecutive 8 to 14 days",
    title: "Metamorphosis",
    img: MetamorphosisImage,
  },
  {
    id: 5,
    value: 5,
    min: 15,
    max: 21,
    description: "Consecutive 15 to 21 days",
    title: "Emerging Butterfly",
    img: EmergingImage,
  },
  {
    id: 6,
    value: 6,
    min: 22,
    max: 28,
    description: "Consecutive 22 to 28 days",
    title: "Graceful Flutter",
    img: gracefulImage,
  },
  {
    id: 7,
    value: 7,
    min: 29,
    max: 35,
    description: "Consecutive 29 to 35 days",
    title: "Colorful Wings",
    img: colorfulImage,
  },
];
