import React from "react";
import AppButton from "../../../../../components/Button/AppButton";
import avatarImage from "../../../../../assets/images/avatar.svg";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";
import { useUserProfileModalState } from "../../ProfileApi";

const PickedAvatars = ({ setState, state, looking_for }) => {




  const [avtarState, setAvatarState] = useState({
    AvatarList: [],
    selectedAvatar: "",
  });
  const {
    AuthUser: {
      user: { user },
    },
  } = useSelector((state) => state);

  const { getAvatarList } = useUserProfileModalState();

  useEffect(() => {
    if (user?.id) {
      getAvatarList(user?.id).then((res) => {
        if (res.status === 200) {
          setAvatarState((prev) => ({ ...prev, AvatarList: res.data.avatars }));
        }
      });
    }
  }, []);

  const setAvtarAsProfilePhoto = () => {

    setState((prev) => ({
      ...prev,
      editProfileIsOpen: false,
      urlImage: avtarState?.selectedAvatar,
      imageType: "avtar",

    }));

  };

  return (
    <div className="avatars">
      <ul
        className="grid grid-cols-4 md:grid-cols-5 lg:grid-cols-6 gap-3 custom-scrollbar my-2"
        style={{ height: "230px", overflowY: "scroll" }}
      >
        {avtarState?.AvatarList?.map((avatar) => {
          let { id, avatar_image, avatar_name } = avatar;
          return (
            <li
              key={id}
              className={`${avtarState.selectedAvatar === avatar_name
                ? "border border-secondary "
                : ""
                } 'rounded bg-gray-200 cursor-pointer `}
              onClick={() =>
                setAvatarState((prev) => ({
                  ...prev,
                  selectedAvatar: avatar_name,
                }))
              }
            >
              <img src={avatar_image || avatarImage} alt={avatar_name} />
            </li>
          );
        })}
      </ul>
      <div className="pt-2">
        <AppButton
          btnText="Upload"
          onClick={() => setAvtarAsProfilePhoto()}
          disabled={false}
          className={"bg-ternary text-white w-full py-1 rounded"}
          btnType="button"
        />
      </div>
    </div>
  );
};

export default PickedAvatars;
