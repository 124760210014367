import React from "react";

const JournalListItem = ({ title, desc, date, attachment, audio }) => {
  return (
    <li className="py-2 px-2 bg-background rounded mb-2"
    >
      {audio && title ? <div className="title-header flex justify-between mb-2">
        <h3 className="capitalize font-montserrat text-base lg:font-bold md:font-bold sm:font-semibold font-semibold">
          {title?.length > 20 ? `${title.slice(0, 20)}...` : title}
        </h3>
        <p className="text-sm text-paragraph">{date}</p>
      </div>
        : audio ?
          <div className="title-header flex justify-between mb-2">

            <h3 className=" ms-1 capitalize font-montserrat text-base lg:font-bold md:font-bold sm:font-semibold font-semibold ">{date}</h3>
          </div> : <div className="title-header flex justify-between mb-2">
            <h3 className="capitalize font-montserrat text-base lg:font-bold md:font-bold sm:font-semibold font-semibold">
              {title?.length > 20 ? `${title.slice(0, 20)}...` : title}
            </h3>
            <p className="text-sm text-paragraph">{date}</p>
          </div>}

      <div className=" custom-scrollbar " style={{ maxHeight: "50px", overflowY: "auto" }}>

        <p className="message text-sm text-ternary-light">{desc}</p>
      </div>
      {attachment && (
        <div className="rounded-lg h-40 w-fit bg-white flex items-center justify-center p-1 mt-2">
          <img
            className="rounded-lg object-contain w-full h-full"
            src={attachment}
          />
        </div>
      )}

      {audio && (
        <div>
          <audio controls>
            <source src={audio} type="audio/mpeg" />
            Your browser does not support the audio element.
          </audio>
        </div>
      )}
    </li>
  );
};

export default JournalListItem;
