import instance from "./AxiosInstance";

// ========================= user ==============================

export const registerUser = async (body) => {
  let Response = await instance.post(`api/register`, body, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return Response;
};

export const otpVarificationApi = async (body) => {
  let Response = await instance.post(`/api/otp_verification`, body, {
    headers: {
      Accept: "application/json",
    },
  });
  return Response;
};

// -- Reset password --
export const resetPassword = async (paramsBody) => {
  let res = await instance.post(`/api/reset_password`, paramsBody);

  return res.data;
};

export const forgotPassword = async (body) => {
  let res = await instance.post(`/api/forget_password`, body, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return res.data;
};

export const userLogin = async (params) => {
  let res = await instance.post(`api/login`, params, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return res.data;
};

export const userSocialLogin = async (params) => {
  let res = await instance.post(`/users/login_google`, params);

  return res.data;
};

// -- get profile --
export const getUserProfile = async () => {
  let res = await instance.get(`/users/profile`);

  return res.data;
};

export const getUserProfileImage = async () => {
  let res = await instance.get(`/users/profile`);

  return res.data.data;
};
export const updateProfilePicture = async (body) => {
  let res = await instance.post(`/users/upload`, body, {
    headers: {
      "Content-type": "multipart/form-data",
    },
  });
  return res;
};

// -- edit profile --
export const editUserProfile = async (params) => {
  let res = await instance.put(`/users/edit`, params);
  return res.data.data;
};

export const authLogOut = async () => {
  let res = await instance.post(`/api/logout`, {
    headers: {
      Accept: "application/json",
    },
  });
  return res;
};
