import React from "react";
import "./AudioMessage.css";

const AudioMessage = (props) => {
  const controlsList = props?.data?.controlsList;

  return (
    <div className={"rce-mbox-audio"} style={props.customStyle}>
      {props?.type === "audio" && (
        <audio
          {...props.audioProps}
          controls
          controlsList={controlsList ? controlsList : "nodownload"}
        >
          <source
            src={props?.text}
            type={props?.data?.audioType || "audio/mp3"}
          />
          Your browser does not support the audio element.
        </audio>
      )}
      {/* {props.text && <div className="rce-mbox-text">{props.text}</div>} */}
    </div>
  );
};

export default AudioMessage;
