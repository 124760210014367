import React from "react";
import Lottie from "lottie-react";
import congracts from "../../assets/json/congracts.json";
import stickLoader from "../../assets/json/stickLoader.json";
import cardFly from "../../assets/json/cardFly.json";
import magicStick from "../../assets/images/illustrations/magic_candle.svg";
import gradientLine from "../../assets/images/illustrations/gradient_line.svg";

const Loader = () => {
  return (
    <div>
      <div className="h-screen  w-full bg-white">
        <div className="flex h-full w-full items-center justify-center">
          <div className="text-center flex flex-col items-center justify-center">
            <div className="relative pb-6">
              <div className="w-[250px] pb-6" >
                <Lottie
                  animationData={stickLoader}
                  className="bg-transparent"
                  style={{ background: "transparent !important" }}
                  loop={true}
                />
              </div>
              <div className="absolute top-[54px] left-[20px] ">
                <img src={magicStick} className="h-[130px]" />
              </div>

            </div>
            <img src={gradientLine} className="" />
            <p className="text-sm font-medium">
              AI is working the magic to schedule your activities
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loader;
