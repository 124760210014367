import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useChallange } from "./ChallangesApis";
import { MdKeyboardBackspace } from "react-icons/md";
import moment from "moment";
import { useSelector } from "react-redux";
import placeHolderImage from "../../../assets/images/placeholderImage.jpg"

const ChallangePostDetail = () => {

    const {
        AuthUser: {
            user: { user },
        },
    } = useSelector((state) => state);
    const [postDetailState, setPostDetailState] = useState({
        postDetail: {},
        render: false,

    });
    const [loader, setLoader] = useState(false)

    const [postMessage, setPostMessage] = useState('')


    const { state } = useLocation();


    const navigate = useNavigate();

    const { getChallangesPostDetail, commentPost, likePost } = useChallange();

    useEffect(() => {
        if (state) {
            setLoader(true)
            getChallangesPostDetail({
                id: state?.user_id,
                challenge_id: state?.Challange_Id,
                postId: state?.post_id,
            })
                .then((res) => {
                    setPostDetailState((prev) => ({
                        ...prev,
                        postDetail: res.data.posts,
                        render: false
                    }));
                    setLoader(false)
                })
                .catch((err) => {
                    console.log("err");
                });
        }
    }, [state, postDetailState?.render]);
    const goBackPage = () => {
        navigate(-1)
    }

    const postLikeHandler = () => {


        likePost({ user_id: user?.id, challenge_id: state?.Challange_Id, post_id: state?.post_id }).then((res) => {
            if (res) {
                setPostDetailState((prev) => ({
                    ...prev, render: true

                }))
            }
        }).catch((err) => {
            console.log(err, "err")
        })


    }

    const onCommentOnHandler = (commentMessage) => {


        commentPost({ user_id: user?.id, challenge_id: state?.Challange_Id, post_id: state?.post_id, comment: commentMessage, challenge_comment_date: moment().format('YYYY-MM-DD hh:mm:ss') }).then((res) => {
            if (res) {
                setPostDetailState((prev) => ({
                    ...prev, render: true

                }))
                setPostMessage('')
            }
        }).catch((err) => {
            console.log(err, "err")
        })
    }

    let LikeIcon;

    let LikedSvg = <svg
        stroke="currentColor"
        fill="#0066FF"
        stroke-width="0"
        viewBox="0 0 1024 1024"
        height="1em"
        width="1em"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M885.9 533.7c16.8-22.2 26.1-49.4 26.1-77.7 0-44.9-25.1-87.4-65.5-111.1a67.67 67.67 0 0 0-34.3-9.3H572.4l6-122.9c1.4-29.7-9.1-57.9-29.5-79.4A106.62 106.62 0 0 0 471 99.9c-52 0-98 35-111.8 85.1l-85.9 311h-.3v428h472.3c9.2 0 18.2-1.8 26.5-5.4 47.6-20.3 78.3-66.8 78.3-118.4 0-12.6-1.8-25-5.4-37 16.8-22.2 26.1-49.4 26.1-77.7 0-12.6-1.8-25-5.4-37 16.8-22.2 26.1-49.4 26.1-77.7-.2-12.6-2-25.1-5.6-37.1zM112 528v364c0 17.7 14.3 32 32 32h65V496h-65c-17.7 0-32 14.3-32 32z"></path>
    </svg>

    if (state?.like_status) {
        LikeIcon = (
            <svg
                stroke="currentColor"
                fill="#0066FF"
                stroke-width="0"
                viewBox="0 0 1024 1024"
                height="1.3em"
                width="1.3em"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M885.9 533.7c16.8-22.2 26.1-49.4 26.1-77.7 0-44.9-25.1-87.4-65.5-111.1a67.67 67.67 0 0 0-34.3-9.3H572.4l6-122.9c1.4-29.7-9.1-57.9-29.5-79.4A106.62 106.62 0 0 0 471 99.9c-52 0-98 35-111.8 85.1l-85.9 311h-.3v428h472.3c9.2 0 18.2-1.8 26.5-5.4 47.6-20.3 78.3-66.8 78.3-118.4 0-12.6-1.8-25-5.4-37 16.8-22.2 26.1-49.4 26.1-77.7 0-12.6-1.8-25-5.4-37 16.8-22.2 26.1-49.4 26.1-77.7-.2-12.6-2-25.1-5.6-37.1zM112 528v364c0 17.7 14.3 32 32 32h65V496h-65c-17.7 0-32 14.3-32 32z"></path>
            </svg>
        );
    } else {
        LikeIcon = (
            <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 1024 1024"
                height="1.3em"
                width="1.3em"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M885.9 533.7c16.8-22.2 26.1-49.4 26.1-77.7 0-44.9-25.1-87.4-65.5-111.1a67.67 67.67 0 0 0-34.3-9.3H572.4l6-122.9c1.4-29.7-9.1-57.9-29.5-79.4A106.62 106.62 0 0 0 471 99.9c-52 0-98 35-111.8 85.1l-85.9 311H144c-17.7 0-32 14.3-32 32v364c0 17.7 14.3 32 32 32h601.3c9.2 0 18.2-1.8 26.5-5.4 47.6-20.3 78.3-66.8 78.3-118.4 0-12.6-1.8-25-5.4-37 16.8-22.2 26.1-49.4 26.1-77.7 0-12.6-1.8-25-5.4-37 16.8-22.2 26.1-49.4 26.1-77.7-.2-12.6-2-25.1-5.6-37.1zM184 852V568h81v284h-81zm636.4-353l-21.9 19 13.9 25.4a56.2 56.2 0 0 1 6.9 27.3c0 16.5-7.2 32.2-19.6 43l-21.9 19 13.9 25.4a56.2 56.2 0 0 1 6.9 27.3c0 16.5-7.2 32.2-19.6 43l-21.9 19 13.9 25.4a56.2 56.2 0 0 1 6.9 27.3c0 22.4-13.2 42.6-33.6 51.8H329V564.8l99.5-360.5a44.1 44.1 0 0 1 42.2-32.3c7.6 0 15.1 2.2 21.1 6.7 9.9 7.4 15.2 18.6 14.6 30.5l-9.6 198.4h314.4C829 418.5 840 436.9 840 456c0 16.5-7.2 32.1-19.6 43z"></path>
            </svg>
        );
    }

    if (loader) {
        return <div className="loader-container">
            <div className="loader"></div>
        </div>
    }
    return (
        <div className="bg-white px-4 py-4 rounded-[15px] h-[85vh]">
            <div className="flex gap-3 items-center justify-between mb-2 ">
                <div className="flex items-center gap-2">
                    <span className="backArrow_nest_page" onClick={goBackPage}>
                        <MdKeyboardBackspace className="cursor-pointer" />
                    </span>
                </div>
            </div>

            <div className="grid grid-cols-12 gap-4 ">
                <div className="col-span-12 md:col-span-6 lg:col-span-6   rounded-[15px]">
                    <div className=" rounded-[10px] px-4 py-4 mb-2"  >
                        <div className="post-header flex gap-3">
                            <img
                                src={
                                    postDetailState?.postDetail?.profile_image
                                        ? `${process.env.REACT_APP_FILE_URL_PROFILE}/${postDetailState?.postDetail?.profile_image}`
                                        : placeHolderImage
                                }
                                alt=""
                                style={{ width: "40px", height: "40px" }}
                                className="rounded-full"
                            />
                            <span className="font-semibold">{`${postDetailState?.postDetail?.first_name} ${postDetailState?.postDetail?.last_name}`}</span>
                        </div>
                        <div className="description text-sm text-paragraph py-2">
                            {postDetailState?.postDetail?.post_caption}
                        </div>
                        {postDetailState?.postDetail?.post_image && (
                            <img
                                src={
                                    postDetailState?.postDetail?.post_image
                                        ? `${process.env.REACT_APP_FILE_URL_CHALLANGE_POST}/${postDetailState?.postDetail?.post_image}`
                                        : ""
                                }
                                alt=""
                                className="py-2 rounded-[10px] "
                                style={{ width: "100%", height: "250px", objectFit: "cover" }}
                            ></img>
                        )}

                        {/* like and comments */}
                        <div className="flex justify-between ">
                            <div className="like flex gap-3 text-sm">
                                <span
                                    //  onClick={postLikeHandler} 
                                    className="cursor-pointer"> {LikeIcon} </span> <span className="text-gray-700 font-semibold -me-2">{postDetailState?.postDetail?.like_count}</span>
                            </div>
                            {/* <div className="like flex gap-3 text-sm" onClick={onSeePostDetail}> */}
                            <span className="text-sm text-primary-dark cursor-pointer">{postDetailState?.postDetail?.comment_count} Comments</span>
                            {/* </div> */}
                        </div>

                        <div className="add comment mt-2 bg-[#E8EFEF] rounded-[10px] px-2 py-1 flex items-center my-2">
                            <input
                                type="text"
                                name="postMessage"
                                value={postMessage}
                                onChange={(e) => setPostMessage(e.target.value)}
                                className="bg-[#E8EFEF] w-[85%]"
                                placeholder="Add comments"
                            />
                            <button className="w-[15%] border-2 border-primary-dark text-primary-dark font-semibold rounded-[10px] py-1 px-2 " onClick={() => {
                                if (postMessage) {
                                    onCommentOnHandler(postMessage)
                                }
                            }}>Post</button>
                        </div>
                    </div>
                </div>

                <div className="col-span-12 md:col-span-6 lg:col-span-6   rounded-[15px]">
                    <div className=" px-2 py-4 ">
                        <div>

                            <h4 className="font-semibold mb-2">Likes</h4>
                            <ul className="list-of-user-liked-post flex">
                                {postDetailState?.postDetail?.like_list?.length > 0 ? postDetailState?.postDetail?.like_list?.map((user) => {
                                    let { like_id, profile_image } = user;
                                    return (<li className="me-2 relative" key={like_id}>
                                        <img src={user.profile_image ? `${process.env.REACT_APP_FILE_URL_PROFILE}/${user.profile_image}` : placeHolderImage} alt="" style={{ width: "35px", height: "35px" }} className="rounded-full" />
                                        <span className="absolute bottom-1 right-1 w-[8px] h-[8px]" >{LikedSvg}</span>
                                    </li>)
                                }) : <li className="text-center text-paragraph w-full"> Likes not found!</li>}
                            </ul>
                        </div>
                        <div className="pt-6">

                            <h4 className="font-semibold mb-2">Comments </h4>
                            <ul className="list-of-user-comment-post custom-scrollbar " style={{ height: "55vh", overflowY: "auto" }}>
                                {postDetailState?.postDetail?.comment_list?.length > 0 ? postDetailState?.postDetail?.comment_list?.map((user) => {
                                    let { comment_id, profile_image, comment, challenge_comment_date, name, user_id } = user;
                                    return (<li className="mb-2 w-full border-b rounded-[10px] py-2 bg-[#E8EFEF] px-2" key={comment_id}>
                                        <div className="flex gap-4">
                                            <div>

                                                <img src={profile_image ? `${process.env.REACT_APP_FILE_URL_PROFILE}/${profile_image}` : placeHolderImage} alt="" style={{ width: "30px", height: "30px" }} className="rounded-full" />
                                            </div>
                                            <div className="flex flex-col w-full">

                                                <div className=" text-gray-500 flex justify-between items-center w-full">{name}
                                                    {/* <span className="text-xs text-paragraph">{challenge_comment_date}</span> */}
                                                </div>
                                                <span className="text-sm">{comment}</span>

                                            </div>
                                        </div>

                                    </li>)
                                }) : <li className="text-center text-paragraph w-full"> Comments not found!</li>}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ChallangePostDetail;
