import React from 'react';
import { useFormik } from 'formik';
import { TimePicker, Button } from 'antd';
import moment from 'moment';

const format = 'h:mm A';

const initialValues = {
    alert_from: moment("9:00 AM", format),
    alert_to: moment("5:00 PM", format),
};

const Dummy = () => {
    const formik = useFormik({
        initialValues,
        onSubmit: (values) => {
            // Handle form submission
            console.log(values);
        },
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="p-8">
                <label htmlFor="alert_from" className="d-flex align-items-center">
                    <span className="w-25" style={{ color: "black", fontWeight: "700" }}>
                        From
                    </span>
                    <TimePicker
                        id="alert_from"
                        popupClassName="timepicker"
                        status={
                            formik.touched.alert_from && formik.errors.alert_from
                                ? "error"
                                : ""
                        }
                        use12Hours
                        value={formik.values.alert_from ? moment(formik.values.alert_from) : null}
                        className="form-control FormikInput w-100"
                        style={{ width: "120px !important" }}
                        format={format}
                        onSelect={(time) => {
                            formik.setFieldValue('alert_from', time ? time : null);
                        }}
                        onOpenChange={false}
                    />
                </label>

                <label htmlFor="alert_to" className="d-flex align-items-center">
                    <span className="w-25" style={{ color: "black", fontWeight: "700" }}>
                        To
                    </span>
                    <TimePicker
                        id="alert_to"
                        popupClassName="timepicker"
                        status={
                            formik.touched.alert_to && formik.errors.alert_to
                                ? "error"
                                : ""
                        }
                        use12Hours
                        value={formik.values.alert_to ? moment(formik.values.alert_to) : null}
                        className="form-control FormikInput w-100"
                        style={{ width: "120px !important" }}
                        format={format}
                        onSelect={(time) => {
                            formik.setFieldValue('alert_to', time ? time : null);
                        }}
                        onOpenChange={false}
                    />
                </label>

                <Button type="primary" htmlType="submit">
                    Submit
                </Button>
            </div>
        </form>
    );
};


export default Dummy;