import React from "react";

import "./PhotoMessage.css";

import { FaCloudDownloadAlt, FaExclamationTriangle } from "react-icons/fa";
import ProgressCircle from "../Circle/Circle";

const PhotoMessage = (props) => {
  var progressOptions = {
    strokeWidth: 2.3,
    color: "#efe",
    trailColor: "#aaa",
    trailWidth: 1,
    step: (state, circle) => {
      circle.path.setAttribute("trail", state?.state?.color);
      circle.path.setAttribute("trailwidth-width", state?.state?.width);

      var value = Math.round(circle.value() * 100);
      if (value === 0) circle.setText("");
      else circle.setText(value);
    },
  };

  const error = props?.data?.status && props?.data?.status.error === true;


  return (
    <div className="rce-mbox-photo">
      <div
        className="rce-mbox-photo--img"
        style={{
          ...(props?.width &&
            props?.height && {
            width: props.width,
            height: props.height,
          }),
        }}
      >

        <img
          src={props?.text}
          alt={props?.alt}
          onClick={props.onOpen}
          onLoad={props.onLoad}
          onError={props.onPhotoError}
        />

        {/* <ImageViewer
        isOpen={isImageViewerOpen}
        onClose={() => setImageViewerOpen(false)}
        images={[{ src: props?.text, alt: props?.alt }]}
        currentIndex={currentImageIndex}
      /> */}
        {error && (
          <div className="rce-mbox-photo--img__block">
            <span className="rce-mbox-photo--img__block-item rce-mbox-photo--error">
              <FaExclamationTriangle />
            </span>
          </div>
        )}
        {!error && props?.data?.status && !props?.data?.status?.download && (
          <div className="rce-mbox-photo--img__block">
            {!props?.data?.status.click && (
              <button
                onClick={props.onDownload}
                className="rce-mbox-photo--img__block-item rce-mbox-photo--download"
              >
                <FaCloudDownloadAlt />
              </button>
            )}
            {typeof props?.data?.status.loading === "number" &&
              props?.data?.status.loading !== 0 && (
                <ProgressCircle
                  animate={props?.data?.status.loading}
                  progressOptions={progressOptions}
                  className="rce-mbox-photo--img__block-item"
                />
              )}
          </div>
        )}
      </div>
      {/* {props?.text && <div className="rce-mbox-text">{props.text}</div>} */}
    </div>
  );
};

export default PhotoMessage;
