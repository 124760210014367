import React, { useEffect } from "react";
import { useState } from "react";
import { AiFillCaretRight, AiFillCaretDown } from "react-icons/ai";
import Img from "../../../assets/images/meditation.svg";
import { useTaskApis } from "./TaskModuleApis";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { Popconfirm } from "antd";
import { RxCross2 } from "react-icons/rx";

const data = [
  {
    id: 1,
    name: "Create Routine",
  },
  { id: 2, name: "New Custom" },
  { id: 3, name: "Health & Fitness" },
  { id: 4, name: "Business & Career" },
  { id: 5, name: "Family & Friends" },
  { id: 6, name: "Romance" },
  { id: 7, name: "Personal Development" },
  { id: 8, name: "Finance" },
  { id: 9, name: "Recreation" },
  { id: 10, name: "Spiritual" },
];
const TaskSelection = ({ taskState, setTaskState, user }) => {
  const { getTaskList, deleteSubCategory } = useTaskApis();

  const [selection, setSelection] = useState({
    allCategory: [],
    categoryOpen: null,
    render: false,
  });

  const openCategoryHandler = (categoryId) => {
    if (selection?.categoryOpen !== categoryId) {
      setSelection((prev) => ({ ...prev, categoryOpen: categoryId }));
    } else if (selection?.categoryOpen === categoryId) {
      setSelection((prev) => ({ ...prev, categoryOpen: null }));
    }
  };

  useEffect(() => {
    if (user) {
      getTaskList(user?.id).then((res) => {
        if (res.status === 200) {
          setSelection((prev) => ({ ...prev, allCategory: res.data, render: false }));
        }
      });
    }
  }, [taskState?.isCustomOpen, selection.render]);

  const onselectTaskName = (TaskId) => {
    if (taskState.task_name.includes(TaskId)) {
      let newData = taskState?.task_name?.filter((item) => item !== TaskId);
      setTaskState((prev) => ({ ...prev, task_name: newData }));
    } else {
      setTaskState((prev) => ({
        ...prev,
        task_name: [...prev.task_name, TaskId],
      }));
    }
  };
  const onRemoveHandler = (id) => {
    let data = {
      user_id: user?.id,
      tasks_sub_category_id: id
    }
    deleteSubCategory(data).then((res) => {
      setSelection((prev) => ({ ...prev, render: true }));
    }).catch((error) => {
      console.log(error);
    })
  }

  return (
    <div className="pt-3">
      <ul
        className="my-4 py-2 custom-scrollbar"
        style={{ height: "60vh", overflowY: "auto" }}
      >
        {selection?.allCategory?.map((item) => {
          let {
            category: { id, category_name },
            subcatgories,
          } = item;
          return (
            <li
              key={id}
              className="cursor-pointer pb-3 text-[15px] sm:text-[17px] md:text-[18px] lg:text-[19px] "
            >
              <div
                className="flex  justify-between font-semibold "
                onClick={() => openCategoryHandler(id)}
              >
                {category_name}
                <span>
                  {selection.categoryOpen === id ? (
                    <AiFillCaretDown />
                  ) : (
                    <AiFillCaretRight />
                  )}
                </span>
              </div>
              {selection.categoryOpen === id ? (
                <div className="text-sm grid grid-cols-12 gap-3 py-2">
                  {subcatgories?.length > 0 ? (
                    subcatgories?.map((subCategory) => {
                      let {
                        id,
                        user_id,
                        task_sub_category_name,
                        task_sub_category_image,
                      } = subCategory;
                      return (
                        <div
                          className={`col-span-4 border relative  flex flex-col items-center justify-center py-2 rounded ${taskState?.task_name?.includes(id) &&
                            "border-secondary"
                            }`}

                        >
                          <div onClick={() => onselectTaskName(id)}>

                            <img
                              src={`${process.env.REACT_APP_FILE_URL_TASKS}/${task_sub_category_image}`}
                              alt={task_sub_category_name}
                              className="w-[70px] h-[70px] px-2"
                            />
                            <p className="text-xs text-center font-semibold pt-1">
                              {task_sub_category_name}
                            </p>
                          </div>
                          {user_id === user?.id && <span className="absolute -top-2 -right-2 shadow rounded-full">
                            <Popconfirm
                              title="Are you sure want to delete this task?"
                              onConfirm={() => onRemoveHandler(id)}
                              okText={
                                <span className="confirmpopUp-custom-button-style sure_btn font-montserrat">Yes</span>
                              }
                              cancelText={
                                <span className="confirmpopUp-custom-button-style font-montserrat  ">No</span>
                              }
                              overlayClassName="custom-popconfirm"
                            >
                              <div className="bg-white shadow-customshadow cursor-pointer rounded-full h-[20px] w-[20px] flex items-center justify-center">
                                <RxCross2 className="text-sm" />
                              </div>
                            </Popconfirm>

                          </span>}
                        </div>
                      );
                    })
                  ) : (
                    <span className="col-span-12 text-center py-2 text-paragraph">
                      You have not created any subcategories yet.
                    </span>
                  )}
                </div>
              ) : null}

            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default TaskSelection;
