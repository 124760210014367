import { Modal } from "antd";
import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import TaskSelectionContent from "./TaskSelectionContent";
import { useSelector } from "react-redux";
import NotDoingToday from "./NotDoingToday";
import { useActivityApis } from "../admin/Activity/ActivityApis";
import CheckContnet from "./CheckContnet";
import HowFeelingToday from "./HowFeelingToday";
import AddTaskElement from "./AddTaskElement";
import FeedbackEmojies from "./FeedbackEmojies";
import UserTaskLists from "./UserTaskLists";

const WishPopups = () => {
  const {
    AuthUser: {
      user: { user },
    },
  } = useSelector((state) => state);
  const [wishingPopUpState, setWishingPopUpState] = useState({
    isMorning: false,
    isEvening: false,
    isWishModalOpen: true,
    currentPageNumber: 1,
    percent: null,
    threeNonNegotiable: [],
    taskName: "",
    AllTaskList: [],
    notDoingMessage: "",
    selectedTask: [],
    selectedFeedBack: {},
  });

  const { getTaskActivites } = useActivityApis();
  // const getTasks =

  useEffect(() => {
    if (user) {
      getTaskActivites({ id: user?.id, date: moment().format("yyyy-MM-DD") })
        .then((res) => {
          if (res.status === 200) {
            setWishingPopUpState((prev) => ({
              ...prev,
              AllTaskList: res?.data?.tasks_activities_list,
            }));
          }
        })
        .catch((error) => {
          console.log(error, "error");
        });
    }
  }, []);

  // useEffect(() => {
  //     setTimeout(() => {
  //         setWishingPopUpState((prev) => ({
  //             ...prev, currentPageNumber: wishingPopUpState?.currentPageNumber + 1
  //         }))
  //     }, 3000);
  // }, [])



  const wishOffFocusModal = () => {
    localStorage.setItem("seeAgainWishPopup", false);
    setWishingPopUpState((prev) => ({ ...prev, isWishModalOpen: false }));
  };

  const closeWishModal = () => {
    localStorage.setItem("seeAgainWishPopup", false);
    setWishingPopUpState((prev) => ({ ...prev, isWishModalOpen: false }));
  };

  let content;

  if (wishingPopUpState?.isMorning) {
    if (wishingPopUpState.currentPageNumber === 1) {
      content = (
        <CheckContnet
          wishingPopUpState={wishingPopUpState}
          setWishingPopUpState={setWishingPopUpState}
        />
      );
    } else if (wishingPopUpState.currentPageNumber === 2) {
      content = (
        <HowFeelingToday
          wishingPopUpState={wishingPopUpState}
          setWishingPopUpState={setWishingPopUpState}
          PageNumber={1}
        />
      );
    } else if (wishingPopUpState.currentPageNumber === 3) {
      content = (
        <>
          {wishingPopUpState?.AllTaskList?.length >= 3 ? (
            <TaskSelectionContent
              setWishingPopUpState={setWishingPopUpState}
              user={user}
              wishingPopUpState={wishingPopUpState}
              PageNumber={2}
            />
          ) : (
            <NotDoingToday
              wishingPopUpState={wishingPopUpState}
              setWishingPopUpState={setWishingPopUpState}
              title={"what is one thing I will not do today?"}
              PageNumber={3}
            />
          )}
        </>
      );
    } else if (wishingPopUpState.currentPageNumber === 4) {
      content = (
        <NotDoingToday
          wishingPopUpState={wishingPopUpState}
          setWishingPopUpState={setWishingPopUpState}
          title={"what is one thing I will not do today?"}
          PageNumber={3}
          user={user}
        />
      );
    }
  } else if (wishingPopUpState?.isEvening) {
    if (wishingPopUpState.currentPageNumber === 1) {
      content = (
        <CheckContnet
          wishingPopUpState={wishingPopUpState}
          setWishingPopUpState={setWishingPopUpState}
          isEvening={true}
        />
      );
    } else if (wishingPopUpState.currentPageNumber === 2) {
      content = (
        <HowFeelingToday
          wishingPopUpState={wishingPopUpState}
          setWishingPopUpState={setWishingPopUpState}
          isEvening={true}
          PageNumber={1}
        />
      );
    } else if (wishingPopUpState.currentPageNumber === 3) {
      content = (
        <AddTaskElement
          wishingPopUpState={wishingPopUpState}
          isEvening={true}
          setWishingPopUpState={setWishingPopUpState}
          title={"What are the 3 things you are grateful for today?"}
          PageNumber={2}
        />
      );
    } else if (wishingPopUpState.currentPageNumber === 4) {
      content = (
        <FeedbackEmojies
          wishingPopUpState={wishingPopUpState}
          isEvening={true}
          setWishingPopUpState={setWishingPopUpState}
          PageNumber={3}
        />
      );
    } else if (wishingPopUpState.currentPageNumber === 5) {
      content = (
        <NotDoingToday
          wishingPopUpState={wishingPopUpState}
          isEvening={true}
          setWishingPopUpState={setWishingPopUpState}
          title={"Would you like to improve anything for tomorrow?"}
          PageNumber={4}
          user={user}
        />
      );
    } else if (wishingPopUpState.currentPageNumber === 6) {
      content = (
        <UserTaskLists
          wishingPopUpState={wishingPopUpState}
          isEvening={true}
          setWishingPopUpState={setWishingPopUpState}
          title={"Task"}
          PageNumber={5}
          user={user}
        />
      );
    }
  }

  useEffect(() => {
    if (!localStorage?.getItem("seeAgainWishPopup") === false && parseInt(localStorage.getItem("isSameUserWishPopups")) === user?.id)
      setWishingPopUpState((prev) => ({
        ...prev,
        isMorning: false,
        isEvening: false,
        isWishModalOpen: false,
      }));
  }, []);

  useEffect(() => {
    const checkTime = () => {
      const currentTime = moment();
      const morningStart = moment("05:00:00", "HH:mm:ss");
      const morningEnd = moment("11:59:59", "HH:mm:ss");
      const eveningStart = moment("16:00:00", "HH:mm:ss");
      const eveningEnd = moment("18:59:59", "HH:mm:ss");

      if (currentTime.isAfter(morningEnd) && currentTime.isBefore(eveningStart)) {
        localStorage.removeItem("seeAgainWishPopup")
      }

      if (currentTime.isAfter(eveningEnd) && currentTime.isAfter(morningStart)) {
        localStorage.removeItem("seeAgainWishPopup")
      }

      setWishingPopUpState((prev) => ({
        ...prev,
        isMorning: currentTime.isBetween(morningStart, morningEnd),
        isEvening: currentTime.isBetween(eveningStart, eveningEnd),
      }));
    };

    // Check the time periodically (e.g., every minute) to update the modal display.
    const interval = setInterval(checkTime, 60000);
    // Initial check
    checkTime();

    return () => {
      clearInterval(interval);
    };
  }, []);



  return (
    <Modal
      open={
        (wishingPopUpState?.isMorning || wishingPopUpState?.isEvening) &&
        wishingPopUpState?.isWishModalOpen
      }
      title=""
      onOk={wishOffFocusModal}
      destroyOnClose
      width={400}
      closeIcon={false}
      onCancel={closeWishModal}
      footer={false}
    >
      {content}
    </Modal>
  );
};

export default WishPopups;
