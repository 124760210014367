import { createSlice } from "@reduxjs/toolkit";

const notificationSidebarSlice = createSlice({
  name: "Sidebar",
  initialState: {
    isSidebarOpen: false,
  },
  reducers: {
    isOpen: (state) => {
      state.isSidebarOpen = true;
    },
    isClose: (state) => {
      state.isSidebarOpen = false;
    },
  },
  extraReducers: {},
});

export default notificationSidebarSlice.reducer;

export const { isOpen, isClose } = notificationSidebarSlice.actions;
