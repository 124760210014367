import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import AppButton from "../../components/Button/AppButton";
import { registerUser } from "../../AxiosFile/ApisUrl";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { Alert } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { resetIntrest } from "../../Store/Slices/IntrestSlice";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import FlyBirdModal from "../../components/CommonModals/FlyBirdModal";
import { emailRegex } from "../../Helpers/constants";

const SignupForm = () => {
  const {
    IntreastSplash: { intreast },
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [signuForm, setSignuForm] = useState({
    error: "",
    success: "",
    loader: false,
    passwordHideShow: false,
    cpasswordHideShow: false,
    isflyBirdOpen: false,
  });

  const initialValues = {
    auth_type: "manual",
    first_name: "",
    last_name: "",
    gender: "",
    email: "",
    password: "",
    confirmPassword: "",
    age: "",
    terms_policy_status: false,
    isflyBirdOpen: false,
  };



  const validationSchema = Yup.object({
    first_name: Yup.string().required("First Name is required"),
    last_name: Yup.string().required("Last Name is required"),
    gender: Yup.string().required("Gender is required"),
    email: Yup.string()
      .matches(emailRegex, "Invalid email address")
      .required("Email is required"),
    password: Yup.string()
      .matches(
        /^(?=.*[a-z])(?=.*[0-9])(?=.*[A-Z])(?=.*[!@#%&]).{8,}$/,
        "Password must be at least 8 characters long with 1 number, 1 uppercase letter, 1 lowercase letter and 1 special character"
      )
      .required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Password do not match")
      .required("Confirm Password is required"),
    age: Yup.number()
      .positive("Age must be a positive number")
      .required("Age is required")
      .min(10)
      .max(100),
    terms_policy_status: Yup.boolean().oneOf([true], "Please accept the terms"),
  });

  const onSubmit = (values, { resetForm }) => {
    delete values.confirmPassword;

    let data = {
      ...values,
      splash_que_ans: intreast.join(","),
    };

    registerUser(data)
      .then((res) => {
        if (res.data.status === 200) {
          dispatch(resetIntrest());
          setSignuForm((prev) => ({
            ...prev,
            success: res.data.message,
          }));
          setTimeout(() => {
            setSignuForm((prev) => ({
              ...prev,
              success: "",
            }));
          }, 3000);

          setTimeout(() => {
            navigate("/login");
          }, 3000);

          setSignuForm((prev) => ({
            ...prev,
            isflyBirdOpen: true,
          }));
          resetForm();
        }
      })
      .catch((error) => {
        setSignuForm((prev) => ({
          ...prev,
          error: error.response.data.message,
        }));
        setTimeout(() => {
          setSignuForm((prev) => ({
            ...prev,
            error: "",
          }));
        }, 3000);
      });
  };

  return (
    <div className="">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        <Form>

          {signuForm.success ? (
            <div className="py-2"><Alert message={`${signuForm.success}`} type="success" showIcon />  </div>
          ) : signuForm.error ? (
            <div className="py-2"> <Alert message={`${signuForm.error}`} type="error" showIcon /></div>
          ) : (
            ""
          )}

          <div className="flex gap-3 pb-2">
            <div className="w-[50%]">
              <br />
              <Field
                type="text"
                id="first_name"
                name="first_name"
                placeholder="First Name"
                className="w-full text-sm"
              />
              <ErrorMessage
                name="first_name"
                component="div"
                className="text-red-500 formik-error"
              />
            </div>

            <div className="w-[50%]">
              <br />
              <Field
                type="text"
                id="last_name"
                name="last_name"
                placeholder="Last Name"
                className="w-full text-sm"
              />
              <ErrorMessage
                name="last_name"
                component="div"
                className="text-red-500 formik-error"
              />
            </div>
          </div>

          <div className="pb-2">
            <Field
              type="email"
              id="email"
              name="email"
              placeholder="Email Address"
              className="w-full mt-2 text-sm font-normal p-5"
            />
            <ErrorMessage
              name="email"
              component="div"
              className="text-red-500 formik-error "
            />
          </div>

          <div className="flex gap-3  mt-2">
            <div className="w-[50%]">
              <Field
                as="select"
                id="gender"
                name="gender"
                placeholder="Gender"
                className="w-full bg-[#F5F5F5] px-4 py-2 text-sm font-normal rounded-md "
              >
                <option value="">select gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="other">Other</option>
              </Field>
              <ErrorMessage
                name="gender"
                component="div"
                className="text-red-500 formik-error"
              />
            </div>

            <div className="w-[50%]">
              <Field
                type="number"
                id="age"
                name="age"
                placeholder="Age"
                className="w-full"
              />
              <ErrorMessage
                name="age"
                component="div"
                className="text-red-500 formik-error"
              />
            </div>
          </div>

          <div className="flex gap-3 mt-4">
            <div className="w-[50%]">
              <div className="relative mb-2 ">
                <Field
                  type={signuForm?.passwordHideShow ? "text" : "password"}
                  id="password"
                  name="password"
                  placeholder="Password"
                  className="w-full text-sm pr-10"
                />
                <button
                  type="button"
                  onClick={() =>
                    setSignuForm((prev) => ({
                      ...prev,
                      passwordHideShow: !signuForm?.passwordHideShow,
                    }))
                  }
                  className="absolute top-0 bottom-0 mr-2 right-0 h-full "
                >
                  {signuForm?.passwordHideShow ? (
                    <BsEye className={`text-lg font-medium  `} />
                  ) : (
                    <BsEyeSlash className="text-lg font-medium  " />
                  )}
                </button>
              </div>
              <ErrorMessage
                name="password"
                component="div"
                className="text-red-500 formik-error "
              />
            </div>

            <div className="w-[50%]">
              <div className="relative mb-2">
                <Field
                  type={signuForm?.cpasswordHideShow ? "text" : "password"}
                  id="confirmPassword"
                  name="confirmPassword"
                  placeholder="Confirm Password"
                  className="w-full text-sm"
                />
                <button
                  type="button"
                  onClick={() =>
                    setSignuForm((prev) => ({
                      ...prev,
                      cpasswordHideShow: !signuForm?.cpasswordHideShow,
                    }))
                  }
                  className="absolute top-0 bottom-0 mr-2 right-0 h-full "
                >
                  {signuForm?.cpasswordHideShow ? (
                    <BsEye className={`text-lg font-medium  `} />
                  ) : (
                    <BsEyeSlash className="text-lg font-medium  " />
                  )}
                </button>
              </div>
              <ErrorMessage
                name="confirmPassword"
                component="div"
                className="text-red-500 formik-error"
              />
            </div>
          </div>

          <div className="w-full text-center pt-2 pb-4">
            <div>
              <label
                htmlFor="terms_policy_status"
                className="ps-2 text-black text-sm font-medium"
              >
                <Field
                  type="checkbox"
                  id="allow"
                  name="terms_policy_status"
                  className="me-2"
                />
                I agree to all <span className="text-[#18605D]">Terms</span> &{" "}
                <span className="text-[#18605D]">Privacy Policy</span>
              </label>
            </div>
            <ErrorMessage
              name="terms_policy_status"
              component="div"
              className="text-red-500 formik-error"
            />
          </div>

          <AppButton
            btnText="Sign Up"
            disabled={false}
            className="bg-black text-white w-full rounded-2xl py-3 px-4 mt-4"
            btnType={"Submit"}
          />
        </Form>
      </Formik>
      <FlyBirdModal
        state={signuForm}
        setState={setSignuForm}
        title="You’re In !"
        message="Let’s Start your Adventure."
        button={false}
      />
    </div>
  );
};

export default SignupForm;
