import { Modal } from "antd";
import React from "react";
import flyingBird from "../../../../assets/images/illustrations/flying_bird.svg";
import AppButton from "../../../../components/Button/AppButton";

const HoursErrorModal = ({ state, setState, user }) => {
  const handleOk = () => {
    setState((prev) => ({ ...prev, isHrsCustomOpen: false }));
  };
  const handleCancel = () => {
    setState((prev) => ({ ...prev, isHrsCustomOpen: false }));
  };

  return (
    <Modal
      open={state.isHrsCustomOpen}
      title=""
      style={{ marginTop: "20px" }}
      onOk={handleOk}
      destroyOnClose
      width={400}
      onCancel={handleCancel}
      closeIcon={false}
      footer={[
        <div className="w-full text-center font-montserrat">
          <div className="pb-4 relative w-full">
            <div className="flex items-center justify-center">
              <img src={flyingBird} className="h-24" />
            </div>

            <p className="mt-3">
              Psst! Want to crush your day? Remember, a good night's sleep (at
              least 6 hours) is your secret weapon. Get those Zzz's and
              conquer!"
            </p>
            <div className="mt-8">
              <AppButton
                onClick={handleOk}
                btnText="Continue"
                className="bg-ternary border w-full text-center rounded-2xl border-ternary text-white py-2 px-5"
              />
            </div>
          </div>
        </div>,
      ]}
    ></Modal>
  );
};

export default HoursErrorModal;
