import React from "react";
import { useNavigate } from "react-router-dom";
import AppButton from "../../../components/Button/AppButton";
import homeScreenArt from "../../../assets/images/illustrations/homeScreen.svg"

const DefaultHome = ({ message }) => {
  const navigate = useNavigate();
  const redirectToTaskPage = (pathName) => {
    if (pathName === "task") {
      navigate("/task");
    } else if (pathName === "activity") {
      navigate("/activity");
    }
  };

  return (
    <div className="w-full h-[80vh] flex items-center justify-center">
      <div className="flex flex-col items-center justify-center gap-3">
        <img src={homeScreenArt} />
        <p className="text-sm font-medium">
          {message ? message : 'Ready to conquer your day? Add your tasks here and watch your productivity soar!'}
        </p>
        <AppButton
          btnText="Create New Task"
          disabled={false} // Change this to your actual condition
          className="py-2 px-4 bg-black text-white font-semibold w-full max-w-[400px] rounded-2xl"
          btnType="button"
          onClick={() => redirectToTaskPage("task")}
        />
      </div>
    </div>
  );
};

export default DefaultHome;
