import React from 'react'
import wishImage from "../../assets/images/wishImage.svg";


const CheckContnet = ({ wishingPopUpState, setWishingPopUpState }) => {

    const GoNextPage = () => {
        setWishingPopUpState((prev) => ({
            ...prev, currentPageNumber: wishingPopUpState?.currentPageNumber + 1
        }))
    }
    return (
        <div className="flex flex-col justify-center items-center cursor-pointer font-montserrat" onClick={() => GoNextPage()}>
            <img src={wishImage} alt="" className="w-20 h-20 mb-3" />
            <span className="text-ternary-light font-montserrat text-xs pb-1">
                {wishingPopUpState?.isMorning
                    ? "Morning Check-In"
                    : wishingPopUpState?.isEvening ? "Evening Check-In" : ""}
            </span>
            <h3 className="text-lg text-ternary font-montserrat  font-semibold pb-1">
                {wishingPopUpState?.isMorning ? "Good Morning!" : wishingPopUpState?.isEvening ? "Good Evening!" : ""}
            </h3>
            <p className="text-ternary-light font-montserrat text-sm">
                {wishingPopUpState?.isMorning
                    ? "Nurture your mind and body. Engage in activities that promote your well-being. Whether it's a morning workout, yoga, meditation, or simply enjoying a nutritious breakfast, prioritize self-care and set the intention to take care of yourself throughout the day."
                    : wishingPopUpState?.isEvening ? "As the day comes to a close, it's important to reflect on the accomplishments of the day and prepare yourself for a restful night. Your evening routine plays a vital role in setting the stage for a peaceful and rejuvenating sleep. Let's infuse it with inspiration and positivity." : ""}
            </p>
        </div>
    )
}

export default CheckContnet