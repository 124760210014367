import React, { useEffect } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import AppButton from "../../../components/Button/AppButton";
import { useJournalApis } from "./JournalApis";
import { useState } from "react";
import attachmentIcon from "../../../assets/images/icons/attachment_icon.svg";
import voiceIcon from "../../../assets/images/icons/voice_icon.svg";
import { BsStop, BsStopFill } from "react-icons/bs";
import AudioRecorder, {
  useAudioRecorder,
} from "../../../components/Audio/AudioRecorder";
import moment from "moment";

const initialValues = {
  journal_title: "",
  journal_desc: "",
  journal_file: null,
};



const AddJournalForm = ({ journalState, setJournalState, user }) => {
  const {
    stopRecording,
    startRecording,
    setAudioState,
    audioState,
    formatTime,
    getRecordFile,
  } = useAudioRecorder();

  const { createJournal } = useJournalApis();

  const [addFormState, setAddFormState] = useState({
    loader: false,
  });

  const handleFormSubmit = (values, { resetForm }) => {
    const recordfile = sendrecording();


    if (recordfile) {
      let data = {
        ...values,
        user_id: user?.id,
        journal_attachment: recordfile?.file_attachment,
        journal_date: moment().format("YYYY-MM-DD")
      };
      createJournal(data)
        .then((res) => {
          if (res.status === 200) {
            resetForm();
            setJournalState((prev) => ({
              ...prev,
              IsGamificationOpen: true,
              render: true,
            }));
          }
        })
        .catch((error) => {
          console.log("Error", error);
        });
    } else {
      let data = {
        ...values,
        user_id: user?.id,
        journal_date: moment().format("YYYY-MM-DD")
      };
      createJournal(data)
        .then((res) => {
          if (res.status === 200) {
            resetForm();
            setJournalState((prev) => ({
              ...prev,
              IsGamificationOpen: true,
              render: true,
            }));
          }
        })
        .catch((error) => {
          console.log("Error", error);
        });
    }
  };

  // useEffect(() => {
  //   if (audioState?.audioChunks.length > 0) {
  //     const blob = new Blob(audioState?.audioChunks, { type: "audio/wav" });
  //     setAudioState((prev) => ({
  //       ...prev,
  //       audioURL: URL.createObjectURL(blob),
  //     }));
  //   }
  // }, [audioState?.audioChunks]);

  const sendrecording = () => {
    const recordfile = getRecordFile();
    setAudioState((prevState) => ({
      recording: false,
      mediaRecorder: null,
      audioChunks: [],
      audioURL: null,
      timer: 0,
      timerInterval: null,
      audioData: null,
    }));

    return recordfile || false;
  };


  const validationSchema = (values) => {
    let errors = {};

    if (audioState.audioChunks?.length === 0) {
      if (!values.journal_title) {
        errors.journal_title = "Title is required";
      }
      if (!values.journal_desc) {
        errors.journal_desc = "Description is required";
      }
    }






    return errors;
  };

  //   Yup.object().shape({
  //   journal_title: Yup.string().required("Title is required"),
  //   journal_desc: Yup.string().required("Message is required"),

  // });
  return (
    <div className="relative">
      <h1 className="text-lg lg:text-xl md:text-xl  sm:font-medium font-medium lg:font-semibold md:font-semibold text-center">
        Add Journal
      </h1>
      <div className="bg-background mt-2 pb-4 rounded-md relative">
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validate={validationSchema}
          onSubmit={handleFormSubmit}
        >
          {({ setFieldValue, values, resetForm, setErrors }) => (
            <Form>
              <div className="pb-4 px-4 border-ternary border-b">
                <Field
                  type="text"
                  id="journal_title"
                  name="journal_title"
                  placeholder="Title"
                  className="w-full mt-4 h-12 bg-white"
                  style={{ background: "white !important" }}
                />
                <ErrorMessage
                  name="journal_title"
                  component="div"
                  className="formik-error text-error"
                />
              </div>

              <div className="px-4 py-3">
                <Field
                  as="textarea"
                  id="journal_desc"
                  name="journal_desc"
                  rows="6"
                  placeholder="Write something.."
                  style={{ background: "white !important" }}
                  className="w-full mt-4 outline-0 p-3 rounded-md bg-white"
                />
                <ErrorMessage
                  name="journal_desc"
                  component="div"
                  className="formik-error text-error"
                />
              </div>

              <div className="mb-5 px-4 py-4">
                <label htmlFor="journal_file" className="w-full ">
                  <div className="flex items-center  justify-between bg-white p-3 rounded-md">
                    <p className="ml-4">
                      {values?.journal_file
                        ? values?.journal_file?.name
                        : "Attach here"}
                    </p>
                    <p className="mr-4">
                      <img src={attachmentIcon} alt="journal_file" />
                    </p>
                  </div>
                  <input
                    type="file"
                    accept="image/*"
                    id="journal_file"
                    name="journal_file"
                    onChange={(e) => {
                      setFieldValue("journal_file", e?.target?.files?.[0]);
                    }}
                    style={{ background: "white !important" }}
                    className="w-full hidden bg-white"
                  />
                </label>
                <ErrorMessage
                  name="journal_file"
                  component="div"
                  className="formik-error text-error"
                />
              </div>
              <div className="pb-4 text-center">
                {audioState.timer > 0 ? formatTime(audioState?.timer) : null}
              </div>
              <div className="px-4">
                <div className="mx-4 ">
                  <div className="flex w-full h-full items-center " onClick={() => setErrors({})}>
                    <div
                      className="flex items-center justify-center w-[50px] h-[50px] bg-black rounded-full"
                      onClick={
                        audioState?.recording ? stopRecording : startRecording
                      }
                    >
                      {audioState?.recording ? (
                        <BsStop className="text-white w-[30px] h-[30px]" />
                      ) : (
                        <img src={voiceIcon} alt="voiceIcon" />
                      )}
                    </div>

                    <button
                      type="submit"
                      className={`${audioState.audioURL ? "" : "hidden"
                        }  ml-3 py-2 px-4 bg-black text-white w-10/12 rounded-full  text-4xl font-semibold`}
                    >
                      <span className="sm:text-lg md:text-lg lg:text-lg lg:font-medium md:font-medium sm:font-normal font-normal  ">
                        Send
                      </span>
                    </button>

                    <button
                      type="submit"
                      className={` ${audioState.audioURL ? "hidden" : ""
                        } ml-3 py-2 px-4 bg-black text-white w-10/12 rounded-full  text-4xl font-semibold`}
                    >
                      <span className="sm:text-lg md:text-lg lg:text-lg lg:font-medium md:font-medium sm:font-normal font-normal  ">
                        Send
                      </span>
                    </button>
                  </div>
                </div>
                {/* <AppButton
              btnText="Send"
              btnType="Submit"
              className="w-full bg-ternary text-white py-1 mb-6"
            /> */}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AddJournalForm;
