import { Modal } from "antd";
import React, { useEffect } from "react";
import flyingBird from "../../assets/images/illustrations/flying_bird.svg";
import AppButton from "../Button/AppButton";
import moment from "moment";

const FlyBirdModal = ({
  state,
  setState,
  title,
  message,
  button,
  modalUsage,
}) => {
  const handleOk = () => {
    if (modalUsage === "ShowGood") {
      localStorage.setItem("goodToSeeYouCloseData", moment());
    }
    setState((prev) => ({ ...prev, isflyBirdOpen: false }));
  };
  const handleCancel = () => {
    if (modalUsage === "ShowGood") {
      localStorage.setItem("goodToSeeYouCloseData", moment());
    }
    setState((prev) => ({ ...prev, isflyBirdOpen: false }));
  };

  return (
    <Modal
      open={state.isflyBirdOpen}
      title=""
      style={{ marginTop: "20px" }}
      onOk={handleOk}
      destroyOnClose
      width={350}
      onCancel={handleCancel}
      closeIcon={false}
      footer={[
        <div className="w-full text-center font-montserrat">
          <div className="pb-4 relative w-full">
            <div className="flex items-center pb-3 justify-center">
              <img src={flyingBird} className="h-24" />
            </div>
            <h2
              className={`${
                modalUsage !== "ShowGood" ? "text-primary" : "text-ternary"
              }  text-lg font-medium  `}
            >
              {title}
            </h2>
            <p
              className={` ${
                modalUsage !== "ShowGood"
                  ? "text-primary mt-3"
                  : "text-ternary mt-1"
              }  text-sm font-medium`}
            >
              {message}
            </p>
            {button ? (
              <div className="mt-8">
                <AppButton
                  onClick={handleOk}
                  btnText="Continue"
                  className="bg-ternary border w-full text-center rounded-2xl border-ternary text-white py-2 px-5"
                />
              </div>
            ) : null}
          </div>
        </div>,
      ]}
    ></Modal>
  );
};

export default FlyBirdModal;
