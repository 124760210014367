import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ChallangesModal from "./ChallangesModal";
import { useNavigate } from "react-router-dom";
import { useChallange } from "./ChallangesApis";
import moment from "moment";
import { notification } from "antd";
import InfoModal from "./InfoModal";

const Challanegs = () => {

    const navigate = useNavigate();
    const { getChallangesScreen, getChallangesList,
        getChallangesSuggestionList, joinedChallange, challangeDelete } = useChallange()

    const [infoModalData, setInfoModalData] = useState({
        isInfoOpen: false,
        isDataNew: {}
    })

    const [chalangesState, setChallangesState] = useState({
        seeChallanegsList: false,
        seeChallanegsSuggestionList: false,
        isCreateChallangeOpen: false,
        weekData: [],
        slogan: "",
        challangeListData: [],
        challangeSuggestionListData: [],
        render: false,
        loader: false,

    })

    const {
        AuthUser: {
            user: { user },
        },
    } = useSelector((state) => state);




    const onViewListHandler = (identity) => {
        if (identity === "suggestion") {


            // getChallangesSuggestionList(user?.id).then((res) => {
            //     setChallangesState((prev) => ({
            //         ...prev,
            //         challangeSuggestionListData: res.data.challenges,
            //     }))
            // }).catch((err) => {
            //     console.log(err);
            // })


            setChallangesState((prev) => ({
                ...prev,
                seeChallanegsSuggestionList: !chalangesState.seeChallanegsSuggestionList,
            }))
        } else if (identity === "Challanges") {

            getChallangesList(user?.id).then((res) => {
                setChallangesState((prev) => ({
                    ...prev, challangeListData: res.data.challenges,
                }))
            }).catch((err) => {
                console.log(err);
            })
            setChallangesState((prev) => ({
                ...prev, seeChallanegsList: !chalangesState?.seeChallanegsList,
            }))
        }
    }

    const onPressChallange = (id, item) => {
        navigate(`/challenges/${id}`, { state: item })
    }


    useEffect(() => {
        if (user) {
            setChallangesState((prev) => ({
                ...prev, loader: true

            }))
            getChallangesScreen(user.id).then((res) => {
                setChallangesState((prev) => ({
                    ...prev, seeChallanegsList: false, seeChallanegsSuggestionList: false, slogan: res?.data?.slogan, challangeListData: res.data.challenges,
                    challangeSuggestionListData: res.data.Challenges_suggestions, render: false, loader: false
                }))
            }).catch((err) => {
                console.log("err", err)
            })
        }
    }, [chalangesState.render, chalangesState?.isCreateChallangeOpen, infoModalData.isInfoOpen])


    const onJoinedHandler = (id) => {
        let data = {
            user_id: user?.id,
            challenge_id: id,
            user_challenge_joinned_date: moment().format("YYYY-MM-DD hh:mm:ss")
        }

        joinedChallange(data).then((res) => {

            if (res.status === 200) {
                notification["success"]({
                    description: res.message,
                    style: {
                        fontFamily: 'Montserrat, sans-serif',
                        fontSize: "14px"
                    },
                });
            } else if (res.status === 500) {
                notification["warning"]({
                    description: res.message,
                    style: {
                        fontFamily: 'Montserrat, sans-serif',
                        fontSize: "14px"
                    },
                });
            }
            setChallangesState((prev) => ({
                ...prev, render: true

            }))
        }).catch((err) => {
            console.log("err", err)
        })

    }



    const deleteChallange = (id) => {
        let data = {
            challenge_id: id,
            user_id: user?.id

        }
        challangeDelete(data).then((res) => {
            console.log(res);
            setChallangesState((prev) => ({
                ...prev, render: true

            }))
            notification["success"]({
                description: res.message,
                style: {
                    fontFamily: 'Montserrat, sans-serif',
                    fontSize: "14px"
                },
            });
        }).catch((err) => {
            console.log(err);
        })
    }






    return (
        <div className="  shadow- rounded-[15px] relative">

            <div className="bg-white rounded-[15px] py-6 px-4 items-center  flex justify-between">
                <div className=" items-center mb-4 text-[#18605D]">
                    {chalangesState?.slogan || ""}
                </div>
                <div className="w-[15%]  flex justify-end">
                    <button className="bg-primary-dark h-fit text-white py-2 px-4 rounded font-semibold" onClick={() =>
                        setChallangesState((prev) => ({ ...prev, isCreateChallangeOpen: true }))
                    }>Create</button>
                </div>
            </div>



            <div className="grid grid-cols-12 gap-4 pt-4">
                <div className="col-span-12 md:col-span-6 lg:col-span-6 bg-white p-4 rounded-[15px]">
                    <div className="challaneges_list">
                        <div className="header-list flex justify-between items-center">

                            <h5 className="font-semibold">Active Challenges </h5>
                            {/* <button className="text-primary-dark  py-1 px-2 rounded" onClick={() => onViewListHandler("Challanges")}>{!chalangesState?.seeChallanegsList ? "view All" : ""}</button> */}
                        </div>

                        <ul className="latest-challenges-item_list w-full custom-scrollbar py-4"
                            style={{ height: "60vh", overflowY: "auto" }}>
                            {
                                chalangesState?.challangeListData?.length > 0 ?
                                    chalangesState?.challangeListData.map((item, index) => {
                                        return (
                                            <li
                                                key={item.id}
                                                className=" w-full h-[110px]  mb-3 p-2 rounded-[8px] flex items-center justify-between relative border-foundationlight  bg-[#f6faf9]"
                                                onClick={() => onPressChallange(item.challenge_id, item)}
                                            >
                                                <div
                                                    className=" flex w-full cursor-pointer items-center justify-between"

                                                >
                                                    <div className=" w-[80%] pe-2">
                                                        <h5 className=" text-sm font-medium text-black truncate  ">
                                                            {item.challenges_title}

                                                        </h5>
                                                        <div className="description text-paragraph text-sm truncate ">
                                                            {item?.challenges_desc}
                                                        </div>
                                                    </div>
                                                    {item.challenges_image && <img
                                                        src={
                                                            item.challenges_image
                                                                ? `${process.env.REACT_APP_FILE_URL_CHALLANGE}/${item.challenges_image}` : ""
                                                        }
                                                        alt="chllanege image"
                                                        className="w-[20%] h-[100px] rounded-[10px]"
                                                    />}


                                                </div>


                                            </li>
                                        )
                                    }) : <li className='text-center text-paragraph pt-5'>{chalangesState?.loader ? "loading..." : "Create New Challenges!"} </li>
                            }
                        </ul>
                    </div>
                </div>
                <div className="col-span-12 md:col-span-6 lg:col-span-6 bg-white p-4 rounded-[15px]">
                    <div className="challaneges_suggestion_list">
                        <div className="header-list flex justify-between items-center">

                            <h5 className="font-semibold">Recommended For You</h5>
                            <button className="text-primary-dark  py-1 px-2 rounded" onClick={() => onViewListHandler("suggestion")}>{!chalangesState?.seeChallanegsSuggestionList ? "view All" : ""}</button>
                        </div>

                        <ul className="latest-challenges-item_list custom-scrollbar py-4"
                            style={{ height: "60vh", overflowY: "auto" }}>
                            {chalangesState?.challangeSuggestionListData?.length > 0 ?
                                chalangesState?.challangeSuggestionListData.slice(0, !chalangesState?.seeChallanegsSuggestionList ? 4 : chalangesState?.challangeSuggestionListData?.length).map((item) => {
                                    return (
                                        <li
                                            key={item.id}
                                            className="border mb-3 w-full h-[70px]  relative  p-2 rounded-[8px] flex items-center justify-between relative border-[#c7e2dc] bg-[#f6faf9]"
                                        >
                                            <div
                                                className="flex cursor-pointer w-[80%] items-center"
                                            // onClick={() => seeFreindProfile(id)}
                                            >
                                                {item.image && <img
                                                    src={item.image
                                                        ? `${process.env.REACT_APP_FILE_URL_CHALLANGE}/${item.image} ` : ""}

                                                    className={`w-[60px] h-[60px] me-2 rounded-full`}
                                                    alt="challengeImage"
                                                />}


                                                <h5 className=" text-sm font-medium text-black truncate 	">
                                                    {item.title}
                                                </h5>

                                            </div>
                                            <div className="flex gap-4 items-center w-[20%] pe-2" >

                                                <button onClick={() => {
                                                    if (!item?.joined) {
                                                        if (item) {
                                                            setInfoModalData((prev) => ({ ...prev, isInfoOpen: true, isDataNew: item }))
                                                        }
                                                        // onJoinedHandler(item?.id)
                                                    }
                                                }} className={` border ${item?.joined ? "bg-primary-dark text-white border-primary" : "bg-gray-200 border-gray-200"}  py-1 px-3 rounded-[8px]`}>
                                                    {item?.joined ? "Joined" : "Join"}
                                                </button>
                                                {
                                                    user?.id === item?.user_id && <span onClick={() => deleteChallange(item?.id)} className=""><svg stroke="currentColor" fill="#B0B0B0" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c-9.4 9.4-9.4 24.6 0 33.9l47 47-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l47-47 47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47 47-47c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-47 47-47-47c-9.4-9.4-24.6-9.4-33.9 0z"></path></svg></span>
                                                }

                                            </div>

                                        </li>
                                    )
                                })
                                : <li className='text-center text-paragraph pt-5'> {chalangesState?.loader ? "loading..." : "Suggestions not found!"}</li>
                            }
                        </ul>
                    </div>
                </div>
            </div>


            <ChallangesModal challengeParentState={chalangesState} setChallengeParentState={setChallangesState} user={user} />
            <InfoModal state={infoModalData} setState={setInfoModalData} user={user} challengeID={infoModalData?.isDataNew?.id} stateNew={infoModalData?.isDataNew} time={true} />



        </div>
    );
};

export default Challanegs;
