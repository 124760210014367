import React, { useState } from "react";
import instance from "../../../AxiosFile/AxiosInstance";
import { useSelector } from "react-redux";
import moment from "moment";

export function useUserProfileModalState() {
  const [customProfileState, setCustomProfileState] = useState({
    userProfile: {},
    suggestionList: [],
    followingList: [],
    followerList: [],
  });
  const {
    AuthUser: {
      user: { user },
    },
  } = useSelector((state) => state);

  const fetchUserProfile = async (id) => {
    const response = await instance.get(`api/profile/user_profile`, {
      params: {
        user_id: id,
        date: moment().format("YYYY-MM-DD"),
        max_streak_date: moment().utc().format("YYYY-MM-DD hh:mm:ss"),
      },
    });
    return response.data;
  };

  const fetchFreindUserProfile = async ({ id, friend_id }) => {
    const response = await instance.get(`/api/profile/friend_profile`, {
      params: {
        user_id: id,
        friend_user_id: friend_id,
        date: moment().format("YYYY-MM-DD"),
        max_streak_date: moment().utc().format("YYYY-MM-DD hh:mm:ss"),
      },
    });
    return response.data;
  };

  const addMemberHandler = async (body) => {
    const response = await instance.post(`/api/profile/add_members`, body, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  };

  const editProfileUser = async (body) => {
    const response = await instance.post(`/api/profile/edit_profile`, body, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  };

  const getAvatarList = async (id) => {
    let res = await instance.get(`/api/get_user_avatars`, {
      params: {
        user_id: id,
      },
    });
    return res.data;
  };

  const suggestionFreindFollow = async (body) => {
    const res = await instance.post(
      "/api/profile/friend_suggestion_follow",
      body
    );
    return res.data;
  };

  const unFollowRequest = async (body) => {
    const res = await instance.post("/api/profile/friend_unfollow", body);
    return res.data;
  };

  const getFollowingList = async (id) => {
    let res = await instance.get(`/api/profile/following_list`, {
      params: {
        user_id: id,
      },
    });
    return res.data;
  };

  const getFollowerList = async (id) => {
    let res = await instance.get(`/api/profile/followers_list`, {
      params: {
        user_id: id,
      },
    });
    return res.data;
  };

  const getPendingRequestList = async (id) => {
    let res = await instance.get(
      `/api/profile/followers_pending_requests_list`,
      {
        params: {
          user_id: id,
        },
      }
    );
    return res.data;
  };

  const AcceptFriendRequest = async ({ id, friend_id }) => {
    let res = await instance.post(`/api/profile/approve_friend_request`, {
      user_id: id,
      friend_user_id: friend_id,
    });
    return res.data;
  };

  const RejectFriendRequest = async ({ id, friend_id }) => {
    let res = await instance.post(`api/profile/reject_friend_request`, {
      user_id: id,
      friend_user_id: friend_id,
    });
    return res.data;
  };

  // user profile delete

  const removeUserProfileImage = async (id) => {
    let res = await instance.post(`/api/profile/remove_profile_image`, {
      user_id: id,
    });
    return res.data;
  };

  return {
    customProfileState,
    fetchFreindUserProfile,
    setCustomProfileState,
    fetchUserProfile,
    addMemberHandler,
    editProfileUser,
    getAvatarList,
    suggestionFreindFollow,
    getFollowingList,
    getFollowerList,
    getPendingRequestList,
    AcceptFriendRequest,
    RejectFriendRequest,
    unFollowRequest,
    removeUserProfileImage,
  };
}
