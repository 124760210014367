import { BrowserRouter, HashRouter } from "react-router-dom";
import PageRouter from "./components/Router";
import "./index.css";
function App() {
  return (
    <HashRouter basename="/">
      <PageRouter />
    </HashRouter>
  );
}

export default App;
