// LoginForm.js
import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import AppButton from "../../components/Button/AppButton";
import ModalForgot from "./ForgotModal/Modal";
import { useNavigate } from "react-router-dom";
import { userLogin } from "../../AxiosFile/ApisUrl";
import { Alert } from "antd";
import { useDispatch } from "react-redux";
import { LogIn } from "../../Store/Slices/AuthSlice";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { emailRegex } from "../../Helpers/constants";

const LoginForm = () => {
  const [loginState, setLoginState] = useState({
    open: false,
    loader: false,
    error: "",
    passwordHideShow: false,
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const showModal = () => {
    setLoginState((prev) => ({ ...prev, open: true }));
  };

  const initialValues = {
    auth_type: "manual",
    email: "",
    password: "",
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .matches(emailRegex, "Invalid email address")
      .required("Email is required"),
    password: Yup.string()
      .matches(
        /^(?=.*[a-z])(?=.*[0-9])(?=.*[A-Z])(?=.*[!@#%&]).{8,}$/,
        "Password must be at least 8 characters long with 1 number, 1 uppercase letter, 1 lowercase letter and 1 special character"
      )
      .required("Password is required"),
  });

  const onSubmit = (values, { resetForm }) => {
    // Handle login logic here (e.g., send data to the server)
    // navigate("/home");
    setLoginState((prev) => ({ ...prev, loader: true }));
    userLogin(values)
      .then((res) => {
        if (res.status === 200) {
          localStorage.setItem("user", JSON.stringify(res.data));
          dispatch(LogIn(res.data));
          localStorage.setItem("user_token", res.data.token);
          localStorage.setItem("already_user", true);
          navigate("/home");
          setLoginState((prev) => ({ ...prev, loader: false }));
          resetForm();
        } else {
          setLoginState((prev) => ({
            ...prev,
            error: res?.message,
            loader: false,
          }));

          setTimeout(() => {
            setLoginState((prev) => ({
              ...prev,
              error: "",
            }));
          }, 3000);
        }
      })
      .catch((error) => {
        setLoginState((prev) => ({
          ...prev,
        }));
        setTimeout(() => {
          setLoginState((prev) => ({
            ...prev,
            error: "",
            loader: false,
          }));
        }, 3000);
      });
  };

  return (
    <div className="w-full">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        <Form className="py-4">
          {loginState?.error ? (
            <div className="py-2">
              <Alert message={`${loginState?.error}`} type="error" showIcon />
            </div>
          ) : (
            ""
          )}
          <div className="mb-4">
            <Field
              type="email"
              id="email"
              name="email"
              placeholder="Email Address"
              className="w-full px-2 py-2 rounded-[8px] text-sm font-normal"
            />
            <ErrorMessage
              name="email"
              component="div"
              className="text-red-500 formik-error"
            />
          </div>
          <div className="relative mb-2">
            <Field
              type={loginState?.passwordHideShow ? "text" : "password"}
              id="password"
              name="password"
              className="form-control w-full text-sm"
            />
            <button
              type="button"
              onClick={() =>
                setLoginState((prev) => ({
                  ...prev,
                  passwordHideShow: !loginState?.passwordHideShow,
                }))
              }
              className="absolute top-0 bottom-0 mr-2 right-0 h-full "
            >
              {loginState?.passwordHideShow ? (
                <BsEye className={`text-lg font-medium  `} />
              ) : (
                <BsEyeSlash className="text-lg font-medium  " />
              )}
            </button>
          </div>
          <ErrorMessage
            name="password"
            component="div"
            className="text-red-500 formik-error"
          />

          <div className="flex justify-between pb-3 ps-2 text-paragraph">
            <div>
              <div>
                <Field type="checkbox" id="allow" name="allow" className="" />
                <label className="ps-2 text-sm font-normal" htmlFor="allow ">
                  Always Logged-in
                </label>
              </div>
            </div>
            <div
              onClick={showModal}
              className="cursor-pointer text-[#18605D] text-sm font-medium"
            >
              {" "}
              Forgot Password?
            </div>
          </div>

          <AppButton
            btnText={`${loginState?.loader ? "Loging.." : "Login"} `}
            disabled={false}
            className="bg-black text-white w-full rounded-md py-1 mt-4"
            btnType={"Submit"}
          />
        </Form>
      </Formik>

      <ModalForgot state={loginState} setState={setLoginState} />
    </div>
  );
};

export default LoginForm;
