import React, { useEffect, useRef } from "react";
import "./MessageBox.css";

import PhotoMessage from "../PhotoMessage/PhotoMessage";
import FileMessage from "../FileMessage/FileMessage";
import SystemMessage from "../SystemMessage/SystemMessage";
import VideoMessage from "../VideoMessage/VideoMessage";
import AudioMessage from "../AudioMessage/AudioMessage";

import Avatar from "../Avatar/Avatar";

import { RiShareForwardFill } from "react-icons/ri";
import { IoIosDoneAll } from "react-icons/io";
import {
  MdAccessTime,
  MdCheck,
  MdMessage,
  MdDelete,
  MdBlock,
  MdDoneAll,
} from "react-icons/md";

import { format } from "timeago.js";

import classNames from "classnames";
import moment from "moment";


const MessageBox = ({ focus = false, notch = true, styles, ...props }) => {
  const prevProps = useRef(focus);
  const messageRef = useRef();

  function ChatMessageTimestamp(date) {
    const currentDate = moment();
    const messageDate = moment(date);

    // Check if the message date is today
    if (currentDate.isSame(messageDate, "day")) {
      return messageDate.format("h:mm A");
    } else {
      return messageDate.format("YYYY-MM-DD, h:mm A");
    }
  }

  var positionCls = classNames("rce-mbox", {
    "rce-mbox-right": props.position === "left",
  });
  var thatAbsoluteTime =
    !/(text|video|file|meeting|audio)/g.test(props.type || "text") &&
    !(props.type === "location" && props.text);
  const dateText2 = props.date && (props.dateString || format(props.date));
  const dateText = ChatMessageTimestamp(props.date);
  useEffect(() => {
    if (prevProps.current !== focus && focus === true) {
      if (messageRef) {
        messageRef.current?.scrollIntoView({
          block: "center",
          behavior: "smooth",
        });

        props.onMessageFocused(prevProps);
      }
    }
    prevProps.current = focus;
  }, [focus, prevProps]);


  let challengeData = {};

  if (props.type === "challenge") {
    challengeData = JSON.parse(props.text);
  }


  return (
    <div
      ref={messageRef}
      className={classNames("rce-container-mbox", props.className)}
      onClick={props.onClick}
    >
      {props.renderAddCmp instanceof Function
        ? props.renderAddCmp()
        : props.renderAddCmp}
      {props.type === "system" ? (
        <SystemMessage {...props} focus={focus} notch={notch} />
      ) : (
        <div
          style={styles}
          className={classNames(
            positionCls,
            { "rce-mbox--clear-padding": thatAbsoluteTime },
            { "rce-mbox--clear-notch": !notch },
            { "message-focus": focus }
          )}
        >
          <div className="rce-mbox-body" onContextMenu={props.onContextMenu}>
            {!props.retracted && props.forwarded === true && (
              <div
                className={classNames(
                  "rce-mbox-forward",
                  { "rce-mbox-forward-right": props.position === "right" },
                  { "rce-mbox-forward-left": props.position === "left" }
                )}
                onClick={props.onForwardClick}
              >
                <RiShareForwardFill />
              </div>
            )}

            {!props.retracted && props.replyButton === true && (
              <div
                className={
                  props.forwarded !== true
                    ? classNames(
                      "rce-mbox-forward",
                      {
                        "rce-mbox-forward-right": props.position === "right",
                      },
                      { "rce-mbox-forward-left": props.position === "left" }
                    )
                    : classNames(
                      "rce-mbox-forward",
                      {
                        "rce-mbox-reply-btn-right":
                          props.position === "right",
                      },
                      {
                        "rce-mbox-reply-btn-left": props.position === "left",
                      }
                    )
                }
                onClick={props.onReplyClick}
              >
                <MdMessage />
              </div>
            )}

            {!props.retracted && props.removeButton === true && (
              <div
                className={
                  props.forwarded === true
                    ? classNames(
                      "rce-mbox-remove",
                      { "rce-mbox-remove-right": props.position === "right" },
                      { "rce-mbox-remove-left": props.position === "left" }
                    )
                    : classNames(
                      "rce-mbox-forward",
                      {
                        "rce-mbox-reply-btn-right":
                          props.position === "right",
                      },
                      {
                        "rce-mbox-reply-btn-left": props.position === "left",
                      }
                    )
                }
                onClick={props.onRemoveMessageClick}
              >
                <MdDelete />
              </div>
            )}

            {(props.title || props.avatar) && (
              <div
                style={{ ...(props.titleColor && { color: props.titleColor }) }}
                onClick={props.onTitleClick}
                className={classNames("rce-mbox-title", {
                  "rce-mbox-title--clear": props.type === "text",
                })}
              >
                {props.avatar && (
                  <Avatar letterItem={props.letterItem} src={props.avatar} />
                )}
                {props.title && <span>{props.title}</span>}
              </div>
            )}

            {/* {props.reply && <ReplyMessage onClick={props.onReplyMessageClick} {...props.reply} />} */}

            {props.type === "text" && (
              <div
                className={classNames("rce-mbox-text", {
                  "rce-mbox-text-retracted": props.retracted,
                  left: props.position === "left",
                  right: props.position === "right",
                })}
              >
                {props.retracted && <MdBlock />}

                {props.text}
              </div>
            )}


            {props.type === "challenge" && (
              <div
                className={classNames("rce-mbox-text", {
                  "rce-mbox-text-retracted": props.retracted,
                  left: props.position === "left",
                  right: props.position === "right",
                })}
              >
                {props.retracted && <MdBlock />}

                <div>
                  <div className="flex justify-between ">{challengeData?.challenges_title?.slice(0, 10)} <span> <span className='cursor-pointer' onClick={() => props.showPopup(challengeData)}>

                    {/* <Popover placement="leftBottom" content={state?.challenges_desc} style={{ maxWidth: '400px' }}  > */}
                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="20px" width="20px" xmlns="http://www.w3.org/2000/svg"><path d="M256 76c48.1 0 93.3 18.7 127.3 52.7S436 207.9 436 256s-18.7 93.3-52.7 127.3S304.1 436 256 436c-48.1 0-93.3-18.7-127.3-52.7S76 304.1 76 256s18.7-93.3 52.7-127.3S207.9 76 256 76m0-28C141.1 48 48 141.1 48 256s93.1 208 208 208 208-93.1 208-208S370.9 48 256 48z"></path><path d="M256.7 160c37.5 0 63.3 20.8 63.3 50.7 0 19.8-9.6 33.5-28.1 44.4-17.4 10.1-23.3 17.5-23.3 30.3v7.9h-34.7l-.3-8.6c-1.7-20.6 5.5-33.4 23.6-44 16.9-10.1 24-16.5 24-28.9s-12-21.5-26.9-21.5c-15.1 0-26 9.8-26.8 24.6H192c.7-32.2 24.5-54.9 64.7-54.9zm-26.3 171.4c0-11.5 9.6-20.6 21.4-20.6 11.9 0 21.5 9 21.5 20.6s-9.6 20.6-21.5 20.6-21.4-9-21.4-20.6z"></path></svg>
                    {/* </Popover> */}
                  </span></span></div>
                  <span>{challengeData?.challenges_desc?.slice(0, 10)}</span>
                </div>
              </div>
            )}

            {/* {props.type === 'location' && <LocationMessage focus={focus} notch={notch} {...props} />} */}

            {props.type === "image" && (
              <PhotoMessage focus={focus} notch={notch} {...props} />
            )}

            {props.type === "video" && (
              <VideoMessage focus={focus} notch={notch} {...props} />
            )}

            {props.type === "file" && (
              <FileMessage focus={focus} notch={notch} {...props} />
            )}

            {/* {props.type === 'spotify' && <SpotifyMessage focus={focus} notch={notch} {...props} />} */}

            {/* {props.type === 'meeting' && <MeetingMessage focus={focus} notch={notch} {...props} />} */}
            {props?.type === "audio" && (
              <AudioMessage focus={focus} notch={notch} {...props} />
            )}

            {/* {props.type === 'meetingLink' && (
              <MeetingLink focus={focus} notch={notch} {...props} actionButtons={props?.actionButtons} />
            )} */}

            <div
              title={props.statusTitle}
              className={classNames(
                "rce-mbox-time",
                { "rce-mbox-time-block": thatAbsoluteTime },
                { "non-copiable": !props.copiableDate }
              )}
              data-text={props.copiableDate ? undefined : dateText}
            >
              {props.copiableDate && props.date
                ? props.dateString || format(props.date)
                : ""}
              {props.status && (
                <span className="rce-mbox-status">
                  {props.status === "waiting" && <MdAccessTime />}

                  {props.status === "sent" && <MdCheck />}

                  {props.status === "received" && <IoIosDoneAll />}

                  {props.status === "read" && <MdDoneAll color="#4FC3F7" />}
                </span>
              )}
            </div>
          </div>

          {notch &&
            (props.position === "right" ? (
              <svg
                style={props.notchStyle}
                className={classNames("rce-mbox-right-notch", {
                  "message-focus": focus,
                })}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M0 0v20L20 0" />
              </svg>
            ) : (
              <div>
                <svg
                  style={props.notchStyle}
                  className={classNames("rce-mbox-left-notch", {
                    "message-focus": focus,
                  })}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <defs>
                    <filter id="filter1" x="0" y="0">
                      <feOffset
                        result="offOut"
                        in="SourceAlpha"
                        dx="-2"
                        dy="-5"
                      />
                      <feGaussianBlur
                        result="blurOut"
                        in="offOut"
                        stdDeviation="3"
                      />
                      <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
                    </filter>
                  </defs>
                  <path d="M20 0v20L0 0" filter="url(#filter1)" />
                </svg>
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default MessageBox;
