import React, { useEffect } from "react";
import { RxCross2 } from "react-icons/rx";
import { FaRegCalendarCheck } from "react-icons/fa";

import { useDispatch, useSelector } from "react-redux";
import { isClose } from "../../../Store/Slices/NotificationSidebar";
import { useState } from "react";
import RescheduleModal from "./NotificationModals/RescheduleModal";
import { useNotification } from "./NotificationApis";
import AppButton from "../../../components/Button/AppButton";
import moment from "moment";

const NoficationSidebar = () => {
  const [notificationState, setNotificationState] = useState({
    isRescheduleOpen: false,
    notificationList: [],
    render: false,
    notificationloader: false,

  });
  const dispatch = useDispatch();

  const {
    AuthUser: {
      user: { user },
    },
  } = useSelector((state) => state);

  const {
    getNotifications,
    storeNotification,
    acceptNotification,
    rejectNotification,
    acceptMemberNotification,
    rejectMemberNotification
  } = useNotification();

  const closeNotification = () => {
    dispatch(isClose());
  };

  const onOpenModalHandler = () => {
    setNotificationState((prev) => ({ ...prev, isRescheduleOpen: true }));
  };

  useEffect(() => {
    if (user) {
      setNotificationState((prev) => ({ ...prev, notificationloader: true }));
      getNotifications(user?.id)
        .then((res) => {
          if (res.status === 200) {
            setNotificationState((prev) => ({
              ...prev,
              notificationList: res?.data?.notifications || [],
              render: false,
              notificationloader: false
            }));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [notificationState?.render]);

  const acceptNotificationHandler = (
    assignId,
    notificationId,
    memberId,
    taskId
  ) => {
    let data = {
      user_id: user?.id,
      assigned_user_id: assignId,
      member_id: memberId,
      task_id: taskId,
      notification_id: notificationId,
      date: moment().format('YYYY-MM-DD hh:mm:ss')
    };
    acceptNotification(data)
      .then((res) => {
        if (res.status === 200) {
          setNotificationState((prev) => ({ ...prev, render: true }));
        }
      })
      .catch((error) => {

        console.log(error);

      });
  };

  const onRejectHandler = (assignId, notificationId, memberId, taskId) => {
    let data = {
      user_id: user?.id,
      assigned_user_id: assignId,
      member_id: memberId,
      task_id: taskId,
      notification_id: notificationId,
      date: moment().format('YYYY-MM-DD hh:mm:ss')
    };
    rejectNotification(data)
      .then((res) => {
        if (res.status === 200) {
          setNotificationState((prev) => ({ ...prev, render: true }));
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };


  const acceptMemberRequestHandler = (assignId, notificaionId) => {

    let data = {
      user_id: assignId,
      notification_id: notificaionId,
      date_time: moment().format('YYYY-MM-DD hh:mm:ss')
    }
    acceptMemberNotification(data)
      .then((res) => {
        if (res.status === 200) {
          setNotificationState((prev) => ({ ...prev, render: true }));
        }
      })
      .catch((error) => {

        console.log(error);

      });

  }
  const rejectMemberRequestHandler = (assignId, notificaionId) => {
    let data = {
      user_id: assignId,
      notification_id: notificaionId,
      date_time: moment().format('YYYY-MM-DD hh:mm:ss')
    }
    rejectMemberNotification(data)
      .then((res) => {
        if (res.status === 200) {
          setNotificationState((prev) => ({ ...prev, render: true }));
        }
      })
      .catch((error) => {

        console.log(error);

      });
  }




  return (
    <div
      className="fixed right-0 w-[290px] bg-white   h-[calc(100%-60px)] shadow-notificationShadow "
      style={{ zIndex: "1000" }}
    // onMouseLeave={() => closeNotification()}
    >
      <div className="p-3 flex justify-between items-center">
        <div className="notification_title text-lg lg:text-xl md:text-xl  font-medium">
          Notifications{" "}
        </div>
        <span className="cursor-pointer" onClick={() => closeNotification()}>
          <RxCross2 className="cursor-pointer" />
        </span>
      </div>

      <div className="list-of_notification relative">
        {notificationState?.notificationloader ? <span className="flex justify-center items-center w-full  ">loading...</span> : <ul
          className="list p-2 custom-scrollbar "
          style={{ height: "85vh", overflowY: "auto" }}
        >
          {notificationState?.notificationList?.length > 0 ? (
            notificationState?.notificationList?.map((notification) => {
              let {
                id,
                notification_type,
                task_desc,
                task_time, task_title,
                title, description,
                assigned_user_id,
                member_id,
                date_time,
                task_id,
                notification_status,
              } = notification;
              return (
                <li key={id} className="bg-background mb-2 rounded p-2 ">
                  <div className="flex flex-col">
                    <div className="flex items-center gap-1">
                      <FaRegCalendarCheck
                        className="text-secondary-dark -mt-1 text-2xl me-1"
                        style={{ fontSize: "16px" }}
                      />
                      <h5 className="text-xs font-semibold">{title || task_title}</h5>
                      <span className=" text-paragraph text-[10px] w-[80px]">
                        {task_time || date_time}
                      </span>
                    </div>
                    <p className="text-xs text-paragraph ms-5">{task_desc || description}</p>
                  </div>
                  <div className="btn-group pt-3 px-5 flex gap-2">
                    {notification_status === "accepted" ? (
                      <>
                        <AppButton
                          btnText="Accepted"
                          btnType={"button"}
                          className="bg-primary-lighter text-xs font-semibold text-primary rounded py-1 px-2 "
                        />
                      </>
                    ) : notification_status === "rejected" ? (
                      <>
                        <AppButton
                          btnText="Rejected"
                          btnType={"button"}
                          className="bg-secondary-lighter text-xs font-semibold text-secondary    rounded py-1 px-2 "
                        />
                      </>
                    ) : (
                      <>
                        <AppButton
                          btnText="Accept"
                          btnType={"button"}
                          onClick={() => {
                            if (notification_type === "task_assign") {

                              acceptNotificationHandler(
                                assigned_user_id,
                                id,
                                member_id,
                                task_id
                              )
                            }
                            else {
                              acceptMemberRequestHandler(assigned_user_id, id)
                            }
                          }}
                          className="bg-primary text-xs font-semibold text-white rounded py-1 px-2 "
                        />
                        <AppButton
                          btnText="Reject"
                          btnType={"button"}
                          onClick={() => {
                            if (notification_type === "task_assign") {

                              onRejectHandler(
                                assigned_user_id,
                                id,
                                member_id,
                                task_id
                              )
                            } else {
                              rejectMemberRequestHandler(assigned_user_id, id)
                            }
                          }
                          }
                          className="bg-secondary text-xs font-semibold text-white rounded py-1 px-2 "
                        />
                      </>
                    )}
                  </div>
                </li>
              );
            })
          ) : (
            <li className="text-paragraph text-sm mb-12 text-center">
              You're all caught up
            </li>
          )}
        </ul>}


        {/* <button
          onClick={() => onOpenModalHandler()}
          className="bg-primary text-white py-2 px-3 mx-2"
        >
          Open reschedule
        </button> */}
      </div>
      <RescheduleModal
        state={notificationState}
        setState={setNotificationState}
      />
    </div>
  );
};

export default NoficationSidebar;
