import React from 'react'

const TaskSelectionContent = ({ setWishingPopUpState, wishingPopUpState, user, isEvening, PageNumber }) => {

    const onSetTaskDoneByToday = (id) => {

        if (wishingPopUpState?.selectedTask?.includes(id)) {
            let newData = wishingPopUpState?.selectedTask?.filter((item) => {
                return item !== id
            })
            setWishingPopUpState((prev) => ({ ...prev, selectedTask: newData }))
        } else {
            if (wishingPopUpState?.selectedTask?.length >= 2) {
                setWishingPopUpState((prev) => ({ ...prev, selectedTask: [...wishingPopUpState.selectedTask, id], currentPageNumber: wishingPopUpState.currentPageNumber + 1 }))

            } else if (wishingPopUpState?.selectedTask?.length < 2) {
                setWishingPopUpState((prev) => ({ ...prev, selectedTask: [...wishingPopUpState.selectedTask, id] }))
            }

        }



    }




    return (
        <div className='flex flex-col gap-3 items-center justify-center'>
            <span>STEP {PageNumber} OF {!isEvening ? "3" : "4"}</span>
            <h2 className="text-lg text-ternary font-semibold pb-1 font-montserrat">Choose the 3 tasks non-negotiables you will get done today.</h2>
            <ul className='flex flex-wrap justify-between gap-2 rounded-full w-full mx-8'>
                {wishingPopUpState?.AllTaskList?.map((item) => {
                    let { task_sub_category_image, task_id, task_sub_category_name } = item;
                    return <li className={`rounded-full ${wishingPopUpState?.selectedTask?.includes(task_id) && "text-primary font-semibold"} flex flex-col items-center`} key={task_id} onClick={() => onSetTaskDoneByToday(task_id)} >
                        <img src={`${process.env.REACT_APP_FILE_URL_TASKS}/${task_sub_category_image}`} alt="sdf" className={`activity_image w-[60px] h-[60px] p-1 rounded-full  hover:border-2 border-primary-light ${wishingPopUpState?.selectedTask?.includes(task_id) && "border-2 border-primary font-bold text-primary"}`} />{task_sub_category_name}</li>
                })}
            </ul>
        </div>
    )
}

export default TaskSelectionContent