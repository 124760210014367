import { Modal } from "antd";
import React from "react";

import { Formik, Field, Form, ErrorMessage } from "formik";

import AppButton from "../../../../components/Button/AppButton";

const RescheduleModal = ({ state, setState }) => {
  const handleOk = () => {
    setState((prev) => ({ ...prev, isRescheduleOpen: false }));
  };
  const handleCancel = () => {
    setState((prev) => ({ ...prev, isRescheduleOpen: false }));
  };

  return (
    <Modal
      open={state.isRescheduleOpen}
      title=""
      onOk={handleOk}
      destroyOnClose
      width={450}
      closeIcon={false}
      onCancel={handleCancel}
      footer={[
        <div className="w-full text-center">
          <Formik
            initialValues={{ reason: "", comment: "" }}
            // validationSchema={validationSchema}
            onSubmit={(values, { resetForm }) => {
              resetForm();
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <div className="pb-4">
                  <h1 className="text-lg font-semibold">Reschedule</h1>

                  <div className="mb-4 flex flex-col justify-start items-start">
                    <label htmlFor="taskName">Reason :</label>
                    <Field
                      type="text"
                      id="reason"
                      name="reason"
                      placeholder="Enter Reason"
                      className="w-full px-3 py-2 border rounded-md"
                    />
                    <ErrorMessage
                      name="reason"
                      component="div"
                      className="text-red-500 text-sm pt-2 ps-2 formik-error"
                    />
                  </div>

                  <div className="mb-4 flex flex-col justify-start items-start">
                    <label htmlFor="comment">Comment:</label>
                    <Field
                      as="textarea"
                      rows={6}
                      id="comment"
                      name="comment"
                      placeholder="Enter comment"
                      className="w-full px-3 py-2 border rounded-md bg-background"
                    />
                    <ErrorMessage
                      name="comment"
                      component="div"
                      className="text-red-500 text-sm pt-2 ps-2 formik-error"
                    />
                  </div>

                  <div className="w-full text-center mt-3 ">
                    <AppButton
                      btnText="Create"
                      disabled={isSubmitting}
                      type="submit"
                      className={`bg-ternary font-semibold w-full text-white py-[8px] rounded-[6px] ${isSubmitting ? "opacity-50 cursor-not-allowed" : ""
                        }`}
                    />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>,
      ]}
    ></Modal>
  );
};

export default RescheduleModal;
