import React from "react";
import SignupForm from "./SignupForm";
import Image from "../../assets/images/logo.png"
import signupImage from "../../assets/images/signupImage.png"
import AuthPageImage from "../../components/AuthPagesImage/AuthPageImage";
import SocialLogins from "../../components/SocialLogins/SocialLogins";
import { Link, useParams } from "react-router-dom";

const Signup = () => {

  const { state } = useParams();


  return (
    <div className="w-full">
      <div className="w-[100%]  flex h-[100vh]">
        <div className="w-[50%]  lg:block md:hidden sm:hidden hidden  ">
          <AuthPageImage ImageUrl={signupImage} logo={Image} />
        </div>
        <div className="w-full lg:w-[50%] md:w-full sm:w-full  px-4  lg:px-16 sm:px-16 md:px-16  py-2 flex flex-col justify-center items-center relative">
          <div className="flex flex-wrap flex-col max-w-[440px]">
            <h2 className="py-1 font-semibold	text-3xl">Sign Up</h2>
            <p className="pt-2 text-paragraph pb-1 text-start">
              Please enter your details below to continue
            </p>
            <SignupForm />
            <div className="or-section w-1/1 flex justify-center py-3">
              <div className="w-1/2  flex items-center justify-between">
                <span className="w-[40%] h-[1.5px] bg-[#E6E6E6]"></span>
                <span className="text-paragraph">OR</span>
                <span className=" w-[40%] h-[1.5px] bg-[#E6E6E6]"></span>
              </div>
            </div>
            <SocialLogins />
          </div>

          <div className="dont_have_account    absolute bottom-3  text-center ">
            <p className="w-full text-paragraph ">
              Already have an account?{" "}
              <Link to="/login" className="font-bold text-primary text-base">
                Login
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
