// import firestore from "@react-native-firebase/firestore";

import {
  collection,
  getDocs,
  query,
  where,
  orderBy,
  setDoc,
  doc,
  updateDoc,
  serverTimestamp,
  getFirestore,
  addDoc,
  onSnapshot,
} from "firebase/firestore";
import { db } from "./firebase";

/**
 * Sorting the data according to userID and toUserID
 * Generating a unique id of collection based on this id filtering and updating the data
 * According to thr UserID need to improve the logic
 * @param {*} userID
 * @param {*} toUserID
 */
function getUniqueSorted(userID, toUserID) {
  let array = [parseInt(userID), parseInt(toUserID)].sort((a, b) =>
    a > b ? 1 : 0
  );
  array = array.map((e) => e.toString());
  return array;
}

/**
 * check collection available on firestore or not
 * create a new collection if it is not available on firestore
 * @param {*} user
 * @param {*} toUser
 * @returns
 */
// export async function checkCollection(user, toUser) {
//   const result = await getCollection(user, toUser);
//   if (result?.status && result?.data) {
//     return result;
//   } else {
//     const setResult = setCollection(user, toUser);
//     return await getCollection(user, toUser);
//   }
// }

/**
 * getCollection
 * Pass user and toUser details as follow it will get message collection from the firestore
 * user: {
 *   id: <user_id>,
 *   first_name: <first name of user>
 *   lase_name: <last name of user>
 *   profile_image: <profile image of the user>
 * }
 * @param {*} user
 * @param {*} toUser
 * @returns
 */
// export async function getCollection(user, toUser) {
//   try {
//     const uniqueStr = getUniqueSorted(user.id, toUser.id).join("-");
//     const querySnapshot = await Firestore()
//       .collection("messages")
//       .where("user_ids", "==", uniqueStr)
//       .get();

//     const array = [];
//     querySnapshot.forEach((documentSnapshot) => {
//       array.push(documentSnapshot.data());
//     });

//     return {
//       status: true,
//       data: array.length > 0 ? array[0] : null,
//       error: "",
//     };
//   } catch (error) {
//     return { status: false, error: error.message };
//   }
// }

/**
 * setCollection
 * Pass user and toUser details as follow it will store your data to the firestore
 * user: {
 *   id: <user_id>,
 *   first_name: <first name of user>
 *   lase_name: <last name of user>
 *   profile_image: <profile image of the user>
 * }
 * @param {*} user
 * @param {*} toUser
 * @returns
 */
// export async function setCollection(user, toUser) {
//   try {
//     const uniqueStr = getUniqueSorted(user.id, toUser.id).join("-");

//     await Firestore().collection("messages").doc(uniqueStr).set({
//       user_ids: uniqueStr,
//       from_user: user,
//       to_user: toUser,
//       createdAt: Firestore.FieldValue.serverTimestamp(),
//     });

//     return { status: true, error: "" };
//   } catch (error) {
//     return { status: false, error: error.message };
//   }
// }

/**
 * get Current Other User Profile Picture
 * @param {*} userID - Current User ID
 * @param {*} details - Chat Details
 */
export function getToUserProfilePicture(userID, details) {
  if (details?.from_user?.id === userID) {
    return details?.to_user?.profile_image;
  } else {
    return details?.from_user?.profile_image;
  }
}

/**
 * get Current Other User Profile Name
 * @param {*} userID - Current User ID
 * @param {*} details - Chat Details
 */
export const getToUserProfileName = (userID, details) => {
  if (details?.from_user?.id === userID) {
    return (
      (details?.to_user?.first_name ?? "") +
      " " +
      (details?.to_user?.last_name ?? "")
    );
  } else {
    return (
      (details?.from_user?.first_name ?? "") +
      " " +
      (details?.from_user?.last_name ?? "")
    );
  }
};

export const getMessageList = async (userID) => {
  try {
    const messagesRef = collection(db, "messages");
    const q = query(
      messagesRef,
      where("from_user.id", "==", userID),
      orderBy("lastMessageTime")
    );

    const querySnapshot = await getDocs(q);

    const array = [];
    querySnapshot.forEach((documentSnapshot) => {
      array.push(documentSnapshot.data());
    });
    const newArray = array.reverse();
    return { status: true, data: newArray, error: "" };
  } catch (error) {
    return { status: false, error: error.message };
  }
};

export const getMessageListRealtime = (
  userID,
  chatParentState,
  setChatListData,
  setChatParentState
) => {
  try {
    const messagesRef = collection(db, "messages");
    const q = query(
      messagesRef,
      where("from_user.id", "==", userID),
      orderBy("lastMessageTime")
    );

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const newArray = [];
      querySnapshot.forEach((documentSnapshot) => {
        newArray.push(documentSnapshot.data());
      });

      // Sort in descending order based on lastMessageTime
      newArray.sort((a, b) => b.lastMessageTime - a.lastMessageTime);

      // Update your state or data here
      setChatListData((prev) => ({ ...prev, chatList: newArray }));

      // Handle setting the active chat user if needed
      newArray.forEach((item) => {
        if (item?.to_user?.id === chatParentState?.activeUser?.touser?.id) {
          setChatParentState((prev) => ({ ...prev, chatUser: item }));
        }
      });
    });

    // Return the unsubscribe function to stop listening when needed
    return unsubscribe;
  } catch (error) {
    console.error("Error getting message list:", error);
    return null;
  }
};

export async function getMessages(details) {
  try {
    const uniqueStr = getUniqueSorted(
      details?.from_user?.id,
      details?.to_user?.id
    ).join("-");

    const messagesRef = collection(db, `messages/${uniqueStr}/messages`);
    const q = query(messagesRef, orderBy("time"));

    const querySnapshot = await getDocs(q);

    const messageArray = [];
    querySnapshot.forEach((doc) => {
      messageArray.push(doc.data());
    });

    return { status: true, data: messageArray, error: "" };
  } catch (error) {
    return { status: false, error: error.message };
  }
}

export async function sendMessage(userID, details, messageText, messageType) {
  // const uniqueStr = getUniqueSorted(
  //   details?.chatUser?.to_user?.id,
  //   details?.chatUser?.from_user?.id
  // ).join("-");

  const uniqueStr2 = getUniqueSorted(
    details?.chatUser?.from_user?.id,
    details?.chatUser?.to_user?.id
  ).join("-");

  // const uniqueStr = [details?.from_user?.id, details?.to_user.id]
  //   .filter(Boolean)
  //   .join("-");

  const message = {
    text: messageText,
    type: messageType,
    from_user_id: userID,
    time: serverTimestamp(),
  };

  try {
    // const docref = doc(db, "messages", uniqueStr);
    // const subcollectionref = collection(docref, "messages");
    // await addDoc(subcollectionref, message);
    const docref2 = doc(db, "messages", uniqueStr2);
    const subcollectionref2 = collection(docref2, "messages");
    await addDoc(subcollectionref2, message);

    // const lastMessageRef = doc(db, "messages", uniqueStr);
    // await updateDoc(lastMessageRef, {
    //   lastMessageText: messageText,
    //   lastMessageType: messageType,
    //   lastMessageTime: serverTimestamp(),
    // });


    const lastMessageRef2 = doc(db, "messages", uniqueStr2);
    await updateDoc(lastMessageRef2, {
      lastMessageText: messageText,
      lastMessageType: messageType,
      lastMessageTime: serverTimestamp(),
    });

    return { status: true, data: null, error: "" };
  } catch (error) {
    return { status: false, error: error.message };
  }
}

export async function sendNewUserMessage(user, toUser) {
  try {
    const uniqueStr = getUniqueSorted(user?.id, toUser?.id).join("-");

    const docref = doc(db, "messages", uniqueStr);

    await setDoc(docref, {
      user_ids: uniqueStr,
      from_user: user,
      to_user: toUser,
      createdAt: serverTimestamp(),
      lastMessageTime: serverTimestamp(),
    });
  } catch (error) {
    return { status: false, error: error.message };
  }
}
