import { Modal } from 'antd'
import React from 'react'
import AppButton from '../../../../components/Button/AppButton';
import moment from 'moment';
import { useActivityApis } from '../ActivityApis';

const OverlapRemoveTask = ({ activityState, setActivityState, taskData, user }) => {

    const { cancelOverlapingTasks } = useActivityApis();
    let InoverLopIds = taskData && taskData?.map((item) => item.task_main_id)


    const cancelOveflowTask = () => {

        if (taskData && user && InoverLopIds) {
            let params = {
                "user_id": user?.id,
                "task_ids": InoverLopIds
            }
            cancelOverlapingTasks(params).then((res) => {

                if (res.status === 200) {
                    setActivityState((prev) => ({ ...prev, isShowOverLap: false, render: true }));
                }
            })
        }

    };
    const handleContinue = () => {
        setActivityState((prev) => ({ ...prev, isShowOverLap: false }));
    };
    const handleCancel = () => {
        setActivityState((prev) => ({ ...prev, isShowOverLap: false }));
    };




    return (
        <Modal
            open={activityState.isShowOverLap}
            title=""
            onOk={handleCancel}
            destroyOnClose
            className="routinePopup"
            width={350}
            onCancel={handleCancel}
            closeIcon={false}
            footer={[
                <div className="text-start -pt-6 font-montserrat">



                    <p className="font-semibold text-ternary">
                        Oops! Your task is overlapping with other tasks. Please update if necessary
                    </p>
                    <div className=" flex justify-end gap-2 pt-2 py-2 ">
                        <AppButton
                            onClick={cancelOveflowTask}
                            btnText="Cancel"
                            className="text-ternary-dark font-bold  border-0  px-4 py-1 "
                        />
                        <AppButton
                            onClick={handleContinue}
                            btnText="Continue"
                            className="text-secondary font-bold px-4 py-1"
                        />
                    </div>

                </div>,
            ]}
        ></Modal>
    )
}

export default OverlapRemoveTask