import { Modal } from "antd";
import React from "react";

import avatarImage from "../../../../assets/images/avatar.svg";
import AppButton from "../../../../components/Button/AppButton";
import { StepsData } from "../../../../Helpers/data";

const StepModal = ({ state, setState }) => {
  const handleOk = () => {
    setState((prev) => ({ ...prev, stepIsModalOpen: false }));
  };
  const handleCancel = () => {
    setState((prev) => ({ ...prev, stepIsModalOpen: false }));
  };

  return (
    <Modal
      open={state.stepIsModalOpen}
      title=""
      onOk={handleOk}
      destroyOnClose
      width={550}
      closeIcon={false}
      onCancel={handleCancel}
      footer={false}
    >
      <div className="header font-montserrat ">
        <h2 className="text-lg font-semibold mb-1 w-full text-center">Steps</h2>
        <p className="text-sm text-paragraph mb-4 w-full text-center">
          Currently achieving 0 days in a row
        </p>
        <div
          className="list-day-streak custom-scrollbar "
          style={{ height: "300px", overflowY: "auto" }}
        >
          <ul
            className="list grid grid-cols-1 gap-2
           sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 "
          >
            {StepsData.map((step) => {
              let { id,

                description,
                title,
                img } = step;
              return (
                <li key={id} className="flex gap-2 mb-2 py-2 ">
                  <div className="pe-4">
                    <img src={img} alt="" className="w-[25px] h-[25px] " />
                  </div>
                  <div>
                    <h4 className="font-semibold">{title}</h4>
                    <p className="text-sm text-paragraph">
                      {`(${description})`}
                    </p>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>

        <AppButton
          btnText="OK"
          disabled={false}
          onClick={() => setState((prev) => ({ ...prev, stepIsModalOpen: false }))}
          className={
            "bg-primary-light text-white w-full py-1 rounded mt-6 font-semibold"
          }
          btnType="button"
        />
      </div>
    </Modal>
  );
};

export default StepModal;
