import React, { useState } from "react";
import AppButton from "../../../components/Button/AppButton";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { useSettings } from "./settingApis";
import { useSelector } from "react-redux";
import { Popconfirm, notification } from "antd";
import BlockedTab from "./BlockedTab";
import MobileViewSetting from "./MobileViewSetting";
import GamificationPopup from "../../../components/GamificationPopup/GamificationPopup";
import { RxCross2 } from "react-icons/rx";
import { authLogOut } from "../../../AxiosFile/ApisUrl";
import { useNavigate } from "react-router-dom";

const Setting = () => {
  const [settingState, setSettingState] = useState({
    activeTab: "App Feedback",
    error: "",
    loader: false,
    IsGamificationOpen: false
  });
  const navigate = useNavigate();
  const {
    AuthUser: {
      user: { user },
    },
  } = useSelector((state) => state);

  const { sendFeedback, getBlockUsers, onUnblockHandler } = useSettings();
  const initialValues = {
    feedback: "",
  };

  const validationSchema = Yup.object().shape({
    feedback: Yup.string().required("Feedback is required"),
  });

  const handleFeedback = (value, { resetForm }) => {
    let data = {
      user_id: user?.id,
      feedback: value?.feedback,
    };
    sendFeedback(data)
      .then((res) => {
        if (res.status === 200) {
          setSettingState((prev) => ({
            ...prev,
            error: "Feedback sent successfully",
            loader: false,
            IsGamificationOpen: true

          }));
          notification["success"]({
            message: "Feedback sent successfully",
            style: {
              fontFamily: 'Montserrat, sans-serif',
            },
          });

          resetForm();
        }
      })
      .catch((err) => {
        console.error(err);
        notification["error"]({
          message: "Something went wrong",
          style: {
            fontFamily: 'Montserrat, sans-serif',
          },
        });
      });
  };


  const logoutHandler = () => {
    authLogOut()
      .then((res) => {
        if (res.status === 200) {
          localStorage.removeItem("user");
          localStorage.removeItem("user_token");
          localStorage.removeItem("previousRewardPoints");
          localStorage.removeItem("access_token");
          localStorage.removeItem("expires_in");
          localStorage.removeItem("expires_in");
          navigate("/login");
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };


  return (
    <div className="bg-white min-h-[80vh]   shadow-customshadow rounded-[15px]">
      <div className="grid grid-cols-12">
        <div className="col-span-12 md:col-span-5 lg:col-span-5 border-r  p-4 dekstop-view">
          <h3 className="text-lg lg:text-xl md:text-xl sm:text-lg sm:font-medium font-medium lg:font-semibold md:font-semibold">
            Settings
          </h3>
          <ul className="mx-2 pt-4">
            {[
              "App Feedback",
              "Privacy Policy",
              "Terms & Condition",
              "Blocked user",
              "Help",

            ].map((service, index) => {
              return (
                <li
                  key={index + 1}
                  onClick={() => {


                    setSettingState((prev) => ({ ...prev, activeTab: service }))
                  }
                  }

                  className={`py-3 px-4 hover:text-white w-full text-foundationDark hover:bg-primary  ${settingState?.activeTab === service ? "bg-primary text-white" : ""} mb-3 text-lg font-medium rounded-full cursor-pointer`}
                >
                  {service}
                </li>
              );
            })}
            <Popconfirm
              placement="topLeft"
              title="Are you sure want to log out?"
              onConfirm={() => logoutHandler()}
              okText={
                <span className="confirmpopUp-custom-button-style sure_btn font-montserrat">Log out</span>
              }
              cancelText={
                <span className="confirmpopUp-custom-button-style font-montserrat  ">Cancel</span>
              }
              overlayClassName="custom-popconfirm"
            >
              <li
                className={`relative py-3 px-4 hover:text-white w-full text-foundationDark hover:bg-primary   mb-3 text-lg font-medium rounded-full cursor-pointer`}
              // style={{ marginLeft: "23px" }}
              >
                Logout
              </li>
            </Popconfirm>
          </ul>


        </div>

        <div className="col-span-12 md:col-span-7 lg:col-span-7 dekstop-view">
          <h5 className="border-b  p-3   text-lg lg:text-xl md:text-xl sm:text-lg sm:font-medium font-medium lg:font-semibold md:font-semibold">
            {settingState?.activeTab}
          </h5>
          {settingState?.activeTab === "App Feedback" && (
            <div className="flex flex-col py-4 relative w-full">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleFeedback}
              >
                <Form className="mx-4">
                  <Field
                    as="textarea"
                    id="feedback"
                    name="feedback"
                    rows="8" // Set the number of rows to 12
                    className="my-2 w-[90%] mx-auto p-2 border rounded bg-background outline-none"
                    placeholder="Write something..."
                  />
                  <ErrorMessage
                    name="feedback"
                    component="div"
                    className="formik-error text-error"
                  />
                  <div className="lg:mx-40 md:mx-20 sm:mx-4 mx-4">
                    <AppButton
                      btnType="submit"
                      btnText="Send"
                      className="bg-black text-white w-full mx-auto py-2 px-4 mt-4 rounded-full"
                    />
                  </div>
                </Form>
              </Formik>
            </div>
          )}

          {settingState?.activeTab === "Privacy Policy" && (
            <div className="mx-4">
              <p className="pt-3">This  clear instructions to click on the provided URL to access the privacy policy.</p>
              <a target="_blank" href="https://admin.dyscore.prometteur.in/privacy-and-policy">
                <AppButton
                  btnText="Click Here"
                  className=" text-blue-500    rounded-full"
                />
              </a>
            </div>
          )}

          {settingState?.activeTab === "Terms & Condition" && (
            <div className="mx-4">
              <p className="pt-3"> This  clear instructions to click on the provided URL to access the terms and conditions.</p>
              <a target="_blank" href="https://admin.dyscore.prometteur.in/terms-and-conditions ">
                <AppButton
                  btnText="Click Here"
                  className=" text-blue-500    rounded-full"
                />
              </a>
            </div>
          )}


          {settingState?.activeTab === "Blocked user" && <BlockedTab user={user} />}

          {settingState?.activeTab === "Help" && (
            <div className="mx-4">
              {/* <p className="">Link</p> */}
              <a target="_blank" href="https://dyscore.prometteur.in/">
                <AppButton
                  btnText="Click Here"
                  className="bg-black text-white  w-1/2 mx-auto py-2 px-4 mt-4 rounded-full"
                />
              </a>
            </div>
          )}

        </div>
        <div className={`col-span-12 mobile-view`}>
          <MobileViewSetting setSettingState={setSettingState} settingState={settingState} user={user} />
        </div>



        <GamificationPopup parentState={settingState} setParentState={setSettingState} title={""} message={"Voice of the Users! 🌟 Your feedback makes the app shine. Here's your reward for being our guiding star."} />
      </div>
    </div>
  );
};

export default Setting;
