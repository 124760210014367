import React from "react";

import { Modal } from "antd";
import wishImage from "../../../../assets/images/wishImage.svg";
import Lottie from "lottie-react";
import congracts from "../../../../assets/json/congracts.json";

const RewardPopup = ({ parentState, setParentState }) => {
  const handleOk = () => {
    setParentState((prev) => ({ ...prev, isRewardOpen: false }));
  };
  const handleCancel = () => {
    setParentState((prev) => ({ ...prev, isRewardOpen: false }));
  };

  return (
    <>
      {parentState?.userProfile?.statistics?.points -
        parentState?.previousReward >
        0 && (
          <Modal
            open={parentState?.isRewardOpen}
            title=""
            onOk={handleOk}
            destroyOnClose
            width={420}
            onCancel={handleCancel}
            closeIcon={false}
            footer={[
              <div className="w-full relative text-center font-montserrat">
                <div className="absolute top-0 ">
                  <Lottie
                    animationData={congracts}
                    className="bg-transparent"
                    style={{ background: "transparent !important" }}
                    loop={true}
                  />
                </div>
                <div className="pb-4 flex flex-col items-center">
                  <img src={wishImage} alt="asdf" width={100} height={100} />

                  <h2 className="font-semibold text-lg pt-2 py-3">
                    Congratulations !
                  </h2>

                  <p className="text-sm text-paragraph">
                    You have earned {parentState?.userProfile?.statistics?.points}{" "}
                    points! <br /> Keep up the great work and watch <br /> your
                    point grow!
                  </p>
                </div>
              </div>,
            ]}
          >
            {/* Content of the modal */}
          </Modal>
        )}
    </>
  );
};

export default RewardPopup;
