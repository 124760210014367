import { Progress } from "antd";
import React, { useState } from "react";
import greatEmoji from "../../assets/images/greatEmoji.svg";
import okeyEmoji from "../../assets/images/okayEmoji.svg";
import teribleEmoji from "../../assets/images/terribleEmoji.svg";
import Slider from 'react-rangeslider';
import 'react-rangeslider/lib/index.css';
const FeedbackReview = [
  { id: 5, img: teribleEmoji, title: "Terrible", min: 0, max: 5 },
  { id: 3, img: okeyEmoji, title: "Okay", min: 40, max: 60 },
  { id: 1, img: greatEmoji, title: "Great", min: 90, max: 100 },
];

const HowFeelingToday = ({
  wishingPopUpState,
  setWishingPopUpState,
  isEvening,
  PageNumber,
}) => {
  // State to manage the value of the slider
  const [value, setValue] = useState(0);
  const handleProgressClick = (precent) => {
    setWishingPopUpState((prevPercent) => ({
      ...prevPercent,
      percent: precent,
    }));
    setTimeout(() => {
      setWishingPopUpState((prevPercent) => ({
        ...prevPercent,
        currentPageNumber: wishingPopUpState?.currentPageNumber + 1,
      }));
    }, 2000);
  };



  // Event handler for when the slider value changes
  const handleChange = (newValue) => {
    setValue(newValue);
    setTimeout(() => {
      setWishingPopUpState((prevPercent) => ({
        ...prevPercent,
        currentPageNumber: wishingPopUpState?.currentPageNumber + 1,
      }));
    }, 2000);
  };


  const getHailightesApp = FeedbackReview.filter((item) => item.min <= value && value <= item.max)


  return (
    <div className="flex flex-col justify-center items-center font-montserrat">
      <span>STEP {PageNumber} OF {!isEvening ? "3" : "4"}</span>

      <h2 className="text-lg text-ternary font-semibold pb-1 font-montserrat">
        How are you feeling today?
      </h2>
      <ul className="flex justify-between items-center w-full py-2 position-relative">
        {FeedbackReview?.map((emoji) => {
          let { id, img, title, percent } = emoji;


          return (
            <div
              // className={`${getHailightesApp?.[0]?.title === title ? "w-8 h-8 " : "w-6 h-6"
              //   }`}
              onClick={() => handleProgressClick(percent)}
            >
              <li
                key={id}
                className={`flex items-center flex-col  cursor-pointer`}
              >
                {" "}
                <img src={img} alt="" className={`${getHailightesApp?.[0]?.title === title ? "w-8 h-8 " : "w-4 h-4"
                  }`} style={{}} />{" "}
                <span className={`${getHailightesApp?.[0]?.title === title ? "font-semibold text-xs text-primary" : "text-xs"
                  }`}>{title}</span>
              </li>
            </div>
          );
        })}
      </ul>
      <div className="cursor-pointer w-full">
        <Slider
          value={value}
          onChange={handleChange}
          min={0}
          max={100}
          tooltip={false}
        />
      </div>
    </div>
  );
};

export default HowFeelingToday;
