import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useUserProfileModalState } from "./ProfileApi";
import placeholderImage from "../../../assets/images/placeholderImage.jpg";
import { useNavigate } from "react-router-dom";
import Serchbar from "../../../components/Searchbar/Searchbar";

const SuggestionList = ({ userList, user, setProfileState }) => {
  const [suggestionListState, setSuggestionListState] = useState({
    sugestedUser: [],
    globalUsers: [],
    isViewAll: false,
  });
  const { suggestionFreindFollow, unFollowRequest } =
    useUserProfileModalState();
  const navigate = useNavigate();
  const onFollowHandler = (id) => {
    let data = {
      user_id: user?.id,
      friend_user_id: id,
    };

    suggestionFreindFollow(data)
      .then((res) => {
        if (res.status === 200) {
          setProfileState((prev) => ({
            ...prev,
            render: true,
            IsGamificationOpen: true
          }));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const seeFreindProfile = (id) => {
    navigate(`/Profile/${id}`);
  };

  const handleSearch = (newSearchTerm) => {
    if (newSearchTerm?.length > 0) {
      const filteredData = suggestionListState?.globalUsers?.filter(
        (ele) =>
          ele?.first_name
            ?.toLowerCase()
            .startsWith(newSearchTerm.toLowerCase()) ||
          ele?.last_name?.toLowerCase().startsWith(newSearchTerm.toLowerCase())
      );
      setSuggestionListState((prev) => ({
        ...prev,
        sugestedUser: filteredData,
      }));
    } else if (newSearchTerm?.length === 0) {
      setSuggestionListState((prev) => ({
        ...prev,
        sugestedUser: suggestionListState.globalUsers,
      }));
    }
  };
  const setViewAllUsers = () => {
    setSuggestionListState((prev) => ({
      ...prev,
      sugestedUser: suggestionListState?.globalUsers,
      isViewAll: true,
    }));
  };
  const unfollowFreind = (id) => {
    if (id) {
      let data = {
        user_id: user?.id,
        friend_user_id: id,
      };

      unFollowRequest(data)
        .then((res) => {
          if (res.status === 200) {
            setProfileState((prev) => ({
              ...prev,
              render: true,
            }));
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  useEffect(() => {
    setSuggestionListState((prev) => ({
      ...prev,
      sugestedUser: userList ? userList?.slice(0, 5) : [],
    }));
    setSuggestionListState((prev) => ({
      ...prev,
      globalUsers: userList ? userList : [],
    }));
  }, [userList]);
  return (
    <div className="user_list_following p-5">
      <div className="header-list flex justify-between mb-4 text-base font-medium text-foundationDark">
        {!suggestionListState?.isViewAll ? (
          <h4>Friend Suggestions</h4>
        ) : (
          <h4>Search Friends</h4>
        )}
        {!suggestionListState?.isViewAll ? (
          <button
            onClick={() => setViewAllUsers()}
            className="text-primary-dark text-sm font-semibold"
          >
            View All
          </button>
        ) : (
          <button
            onClick={() => {
              setSuggestionListState((prev) => ({ ...prev, isViewAll: false }));

              setSuggestionListState((prev) => ({
                ...prev,
                sugestedUser: suggestionListState?.globalUsers.slice(0, 5),
              }));
            }}
            className="text-primary-dark text-sm font-semibold"
          >
            Hide
          </button>
        )}
      </div>
      {suggestionListState?.isViewAll && (
        <div className="searchbar">
          <Serchbar onSearch={handleSearch} />
        </div>
      )}
      <div className="list_of_user mt-4">
        <ul
          className="custom-scrollbar pt-4  "
          style={{ height: "60vh", overflowY: "auto" }}
        >
          {suggestionListState?.sugestedUser?.length !== 0 ? (
            suggestionListState?.sugestedUser?.map((user) => {
              let {
                id,
                first_name,
                last_name,
                profile_image,
                follow_status,
                followed_by,
              } = user;
              return (
                <li
                  key={id}
                  className="border mb-3 p-2 rounded-[8px] flex items-center justify-between relative border-foundationlight  bg-[#FCFCFC]"
                >
                  <div
                    className="flex cursor-pointer"
                    onClick={() => seeFreindProfile(id)}
                  >
                    <img
                      src={
                        profile_image
                          ? `${process.env.REACT_APP_FILE_URL_PROFILE}/${profile_image}`
                          : placeholderImage
                      }
                      alt={`${first_name} image_profile`}
                      className={`${!profile_image && "text-foundataion"
                        } w-[40px] h-[40px] me-2 rounded-full`}
                    />
                    <div>
                      <h5 className="flex flex-col text-sm font-medium text-black">
                        {`${first_name ? first_name : ""} ${last_name ? last_name : ""
                          }`}
                        <span className="text-xs font-normal text-paragraph">
                          {followed_by ? `Followed by ${followed_by}` : null}
                        </span>
                      </h5>
                    </div>
                  </div>
                  <div>
                    {follow_status === 1 ? (
                      <button className="bg-gray-200 border border-gray-200 py-1 px-3 rounded-[8px]">
                        Following
                      </button>
                    ) : (
                      <button
                        className="bg-primary-dark text-white py-1 px-3 rounded-[8px]"
                        onClick={() => onFollowHandler(id)}
                      >
                        Follow
                      </button>
                    )}
                  </div>
                  <div className="absolute -right-1 -top-3 cursor-pointer ">
                    {/* <div onClick={() => unfollowFreind(id)} className="border bg-white shadow-customshadow rounded-full h-[20px] w-[20px] flex items-center justify-center">
                      <FontAwesomeIcon
                        icon={faTimes}
                        className="text-sm font-normal"
                      />
                    </div> */}
                  </div>
                </li>
              );
            })
          ) : (
            <li className="text-paragraph text-center text-sm pt-4">
              User not found!
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default SuggestionList;
