import React, { useRef, useState } from "react";
import { useChatApis } from "../../pages/admin/Chat/ChatModuleApis";
import { sendMessage } from "../../firebase/chat";

export function useAudioRecorder() {
  const chunks = [];
  const mediaRecorderRef = useRef(null);
  const timerIntervalRef = useRef(null);
  const { addChatAttachment } = useChatApis();

  const [audioState, setAudioState] = useState({
    recording: false,
    mediaRecorder: null,
    audioChunks: [],
    audioURL: null,
    timer: 0,
    timerInterval: null,
    audioData: null,
  });

  const startRecording = () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        const mediaRecorder = new MediaRecorder(stream);
        mediaRecorder.ondataavailable = (e) => {
          if (e.data.size > 0) {
            setAudioState((prevState) => ({
              ...prevState,
              audioChunks: [...prevState.audioChunks, e.data],
            }));
          }
        };
        mediaRecorder.start();
        setAudioState((prevState) => ({
          ...prevState,
          mediaRecorder: mediaRecorder,
          recording: true,
          audioChunks: [], // Clear any existing audio chunks
          timer: 0, // Reset the timer
          timerInterval: setInterval(() => {
            setAudioState((prevState) => ({
              ...prevState,
              timer: prevState.timer + 1,
            }));
          }, 1000),
        }));
      })
      .catch((error) => console.error("Error accessing microphone:", error));
  };

  const stopRecording = () => {
    if (audioState.mediaRecorder) {
      audioState.mediaRecorder.stop();
      clearInterval(audioState.timerInterval);
      setAudioState((prevState) => ({
        ...prevState,
        recording: false,
      }));
    }
  };

  const downloadRecording = () => {
    if (audioState.audioChunks.length === 0) {
      return;
    }
    const blob = new Blob(audioState.audioChunks, {
      type: "audio/mp3",
      name: "record.mp3",
    });

    const url = URL.createObjectURL(blob);
    // const a = document.createElement("a");
    // a.href = url;
    // a.download = "recording.mp3";
    // a.click();

    const file = new File([blob], "recording.mp3", { type: "audio/mp3" });

    let dataBody = {
      file_attachment: file,
    };

    const data = addChatAttachment(dataBody).then((res) => {
      if (res.status === 200) {
        setAudioState((prev) => ({
          ...prev,
          audioURL: res?.data?.file_url,
        }));
        // sendMessage(userID, details, url, messageType);
        return res?.data;
      }
    });
    return data;
  };

  const pauseRecording = () => {
    mediaRecorderRef.current?.stop();
    clearInterval(timerIntervalRef.current);
    setAudioState((prevState) => ({
      ...prevState,
      recording: false,
    }));
  };

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  const getRecordFile = () => {
    if (audioState.audioChunks.length === 0) {
      return;
    }
    const blob = new Blob(audioState.audioChunks, {
      type: "audio/mp3",
      name: "record.mp3",
    });

    const url = URL.createObjectURL(blob);
    // const a = document.createElement("a");
    // a.href = url;
    // a.download = "recording.mp3";
    // a.click();

    const file = new File([blob], "recording.mp3", { type: "audio/mp3" });

    let dataBody = {
      file_attachment: file,
    };

    return dataBody;
  };

  return {
    stopRecording,
    startRecording,
    downloadRecording,
    audioState,
    formatTime,
    getRecordFile,
    setAudioState,
    pauseRecording,
  };
}
