import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

function Chart({ data }) {
  const [state, setState] = useState({
    series: [0, 100, 0, 0],
    options: {
      chart: {
        type: "donut",
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 300,
            },
            legend: {
              show: true,
              position: 'bottom',
              style: {
                fontSize: '12px',
                fontWeight: 600,
              }
            },
          },
        },
      ],
      labels: ["Completed", "Incomplete", "Cancelled", "Rescheduled"],
      colors: ["#4A9D93", "#F5F5F5", "#F97E6D", "#FAC390"],
      plotOptions: {
        pie: {
          donut: {
            size: "40%",
            fill: "origin"
          },
        },
      },
      dataLabels: {
        enabled: false,
        position: "bottom",
        fill: 'origin',
        // hideOnSeries: [1],
        style: {
          fontSize: '12px',
          fontWeight: 600,
        },
      },
      tooltip: {
        enabled: false,
      },
    },
  });

  useEffect(() => {
    if (data) {
      const { completed_tasks, not_completed_tasks, cancelled_tasks, rescheduled_tasks } = data;

      if (completed_tasks > 0 || not_completed_tasks > 0 || cancelled_tasks > 0 || rescheduled_tasks > 0) {

        setState((prevState) => ({
          ...prevState,
          series: [completed_tasks, not_completed_tasks, cancelled_tasks, rescheduled_tasks],
          options: {
            ...state?.options,
            labels: [`Completed (${completed_tasks}%)`, `Incomplete (${not_completed_tasks}%)`, `Cancelled (${cancelled_tasks}%)`, `Rescheduled (${rescheduled_tasks}%)`],
          }

        }));

      } else {
        setState((prevState) => ({
          ...prevState,
          series: [0, 100, 0, 0],
          options: {
            ...state?.options,
            labels: [`Completed (${0}%)`, `Incomplete (${100}%)`, `Cancelled (${0}%)`, `Rescheduled (${0}%)`],
          }

        }));
      }
    }
  }, [data]);


  return (
    <div className="chart">
      {data ? (
        <ReactApexChart
          options={state?.options}
          series={state?.series}
          type="donut"
          width={"100%"}
          height={350}
        />
      ) : (
        <p>Loading or no data available</p>
      )}
    </div>
  );
}

export default Chart;
