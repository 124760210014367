import React from "react";

import { Modal } from "antd";

const WishModals = ({ Title, message, state, setState }) => {
  const handleOk = () => {
    setState((prev) => ({ ...prev, isCongrats: false }));
  };
  const handleCancel = () => {
    setState((prev) => ({ ...prev, isCongrats: false }));
  };

  return (
    <Modal
      open={state}
      title=""
      onOk={handleOk}
      destroyOnClose
      width={420}
      onCancel={handleCancel}
      closeIcon={false}
      footer={[
        <div className="w-full  text-center">
          <div className="pb-4 flex flex-col items-center">
            <img
              src="http://timme.prometteur.in/assets/illustrations/illustration_login.png"
              alt="asdf"
              width={200}
              height={200}
            />

            <h2 className="font-semibold text-lg pt-2 py-3">{Title}</h2>

            <p className="text-sm text-paragraph">{message}</p>
          </div>
        </div>,
      ]}
    >
      {/* Content of the modal */}
    </Modal>
  );
};

export default WishModals;
