import instance from "../../../AxiosFile/AxiosInstance";

export function useTaskApis() {
  const getTaskList = async (id) => {
    const response = await instance.get(`/api/custom_task_create_view`, {
      params: {
        user_id: id,
      },
    });
    if (response.data) {
      return response.data;
    }
  };

  const createTask = async (body) => {
    let res = await instance.post("api/custom_task_create", body);

    return res.data;
  };

  const getTaskcategories = async (id) => {
    let res = await instance.get("api/task_categories");

    return res.data;
  };
  const getTaskategoriesImage = async (id) => {
    let res = await instance.get(`/api/custom_task_images_list?user_id=${id}`);

    return res.data;
  };
  const cretaeNewCustom = async (body) => {
    let res = await instance.post("api/task_categories", body);

    return res.data;
  };

  const createSubcateoryCustom = async (body) => {
    let res = await instance.post("/api/custom_task_sub_category", body, {
      headers: {
        "Content-type": "multipart/form-data",
      },
    });
    return res.data;
  };

  const deleteSubCategory = async (body) => {
    let res = await instance.post("api/delete_custom_sub_category", body, {
      headers: {
        "Content-type": "multipart/form-data",
      },
    });
    return res.data;
    // api/delete_custom_sub_category
  };

  return {
    getTaskList,
    createTask,
    getTaskcategories,
    cretaeNewCustom,
    createSubcateoryCustom,
    getTaskategoriesImage,
    deleteSubCategory,
  };
}
