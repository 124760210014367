import { current } from "@reduxjs/toolkit";
import moment from "moment";
import * as Yup from "yup";
import { StepsData } from "./data";
import { extendMoment } from "moment-range";
export const emailRegex =
  /@([a-zA-Z0-9-]+\.[a-zA-Z]{2,}|[a-zA-Z0-9-]+\.(com|in))$/;

export const validationSchema = Yup.object().shape({
  email: Yup.string()
    .matches(emailRegex, "Invalid email address")
    .required("Email is required"),
});

export const generateDays = (selectedOption, weekData, SingleDate) => {
  // const currentDate = moment();
  const currentDate = moment().format("YYYY-MM-DD");
  let days = [];

  switch (selectedOption) {
    case 0:
      let data = [SingleDate];
      return data;
      break;
    case 1:
      const selected = weekData?.slice(1, 6);
      let setNewData = selected.filter((item) => {
        if (currentDate <= item) {
          return item;
        }
      });
      return setNewData;
      break;
    case 2:
      let selectedWeekend;
      if (currentDate >= weekData[0]) {
        selectedWeekend = [weekData[weekData?.length - 1]];
      } else {
        selectedWeekend = [weekData[0], weekData[weekData?.length - 1]];
      }

      return selectedWeekend;
      break;
    default:
      break;
  }

  return days;
};

export const formatedDayString = (date) => {
  return moment(date?._d).format("dddd, MMM D"); // Friday, Sep 29
};

export const getWeekData = () => {
  var currentDate = moment();

  var weekStart = currentDate.clone().startOf("week");

  var days = [];

  for (var i = 0; i <= 6; i++) {
    days.push(moment(weekStart).add(i, "days").format("YYYY-MM-DD"));
  }

  return days;
};

export const IntrestArray = [
  { id: 1, task: "I have too much on my plate right now" },
  {
    id: 2,
    task: "I need a better way to communicate and delegate tasks in my home",
  },
  { id: 3, task: "I procrastinate" },
  { id: 4, task: "I over plan, but don’t get things done on time" },
  { id: 5, task: "I often forget important things" },
  { id: 6, task: "I want to improve my health and well-being" },
  { id: 7, task: "I want to work on my mindfulness" },
  { id: 8, task: "I need help quitting unhealthy habits" },
  { id: 9, task: "none" },
];

export const streakItemReturn = (value) => {
  let stepValue = StepsData.filter((step) => {
    if (step.min <= value && step.max >= value) {
      return step;
    }
  });

  return stepValue?.[0];
};

export const notifiactionsDyscores = (data) => {
  if ("Notification" in window) {
    if (Notification.permission === "granted") {
      // Create a notification
      const title = "Dyscores";
      const options = {
        body: `${data?.task_sub_category_name} task for start remain 10 minutes`,
      };
      const notification = new Notification(title, options);

      // Handle click on the notification
      notification.onclick = () => {
        // Redirect to the homepage when notification is clicked
        window.location.href = "/#/home";
      };
    } else if (Notification.permission !== "denied") {
      // Ask the user for permission
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          // Create a notification
          const title = "Dyscores";
          const options = {
            body: `${data?.task_sub_category_name} task for start remain 10 minutes`,
          };
          const notification = new Notification(title, options);
          // Handle click on the notification
          notification.onclick = () => {
            // Redirect to the homepage when notification is clicked
            window.location.href = "/#/home";
          };
        }
      });
    }
  }
};

export function getTaskConflict(tasks) {
  let conflictCount = 0;

  tasks.forEach((taskParent, indexParent) => {
    //EVENT AND CURRENT ITEM DATES
    const startDate = moment(
      taskParent.task_date + " " + taskParent.task_start_time,
      "yyyy-MM-DD H:mm a"
    );
    const endDate = moment(
      taskParent.task_date + " " + taskParent.task_end_time,
      "yyyy-MM-DD H:mm a"
    );
    const parentDates = [startDate, endDate];

    tasks.forEach((taskChild, indexChild) => {
      if (
        taskParent.task_id != taskChild.task_id &&
        indexChild >= indexParent
      ) {
        //EVENT AND CURRENT ITEM DATES
        const startDateChild = moment(
          taskChild.task_date + " " + taskChild.task_start_time,
          "yyyy-MM-DD H:mm a"
        );
        const endDateChild = moment(
          taskChild.task_date + " " + taskChild.task_end_time,
          "yyyy-MM-DD H:mm a"
        );
        const childDates = [startDateChild, endDateChild];

        //EVENT AND CURRENT ITEM RANGE
        const parentRange = extendMoment(moment).range(parentDates);
        const childRange = extendMoment(moment).range(childDates);

        //CHECK CONFLICT
        const isConflict = parentRange.overlaps(childRange);

        if (isConflict) {
          conflictCount = conflictCount + 1;
        }
      }
    });
  });

  return conflictCount;
}

// {
//     "userProfile": {
//         "user": {
//             "id": 208,
//             "profile_image": "Profile_77495787618.jpg",
//             "first_name": "Laxman M",
//             "last_name": "Nemane",
//             "about": "Software Tester",
//             "gender": "Male",
//             "age": 23,
//             "max_streak": 0,
//             "privacy": "Private",
//             "updated_at": "2023-12-04T10:39:51.000000Z"
//         },
//         "looking_for": [
//             {
//                 "looking_for_title": "Cricket"
//             },
//             {
//                 "looking_for_title": "Gym Buddy"
//             }
//         ],

//     "urlImage": "Avatar_4.png",
//     "imageType": "avtar"
// }

export const getImageProfile = (profileData) => {
  if (profileData?.imageType === "file") {
    const selectedFile = URL.createObjectURL(profileData?.urlImage);
    return selectedFile;
  } else if (profileData?.imageType === "camera") {
    return profileData?.urlImage;
  } else if (profileData?.imageType === "avtar") {
    const selectedFile = `${process.env.REACT_APP_FILE_URL_PROFILE}/${profileData?.urlImage}`;
    return selectedFile;
  } else if (profileData?.userProfile?.user?.profile_image) {
    return `${process.env.REACT_APP_FILE_URL_PROFILE}/${profileData?.userProfile?.user?.profile_image}`;
  } else {
    return `https://static.vecteezy.com/system/resources/thumbnails/004/511/281/small/default-avatar-photo-placeholder-profile-picture-vector.jpg`;
  }
};
