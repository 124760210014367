import React from "react";
import { generateDays } from "../../../Helpers/constants";
import moment from "moment";

const TabsCalender = ({ taskState, setTaskState }) => {
  const selecteTab = (TabName) => {
    let data;
    if (TabName === 0) {
      data = generateDays(
        TabName,
        taskState.weekdata,
        moment().format("YYYY-MM-DD")
      );
    } else {
      data = generateDays(
        TabName,
        taskState.weekdata,
        taskState?.SelectedDay?.[0]
      );
    }





    return setTaskState((prev) => ({
      ...prev,
      selectedIndex: TabName,
      SelectedDay: data,
    }));
  };

  const handleClickDate = (date) => {
    setTaskState((prev) => ({
      ...prev,
      selectedIndex: 0,
      SelectedDay: [moment(date).format("yyyy-MM-DD")],
      selectedDate: moment(date)
    }));
  };

  const fsClass = "text-sm sm:text-sm md:text-sm lg:text-sm";
  return (
    <div className="tab-and-calnder-section py-4">
      <ul className="grid grid-cols-3 cursor-pointer  gap-2 lg:gap-8  items-center bg-[#18605D1A] text-white px-1 py-1 rounded-[8px]">
        <li
          className={
            taskState.selectedIndex === 0
              ? `bg-[#4A9D93] md:px-2 sm:px-2 lg:px-4 py-2 md:text-base lg:text-base text-[10px] font-medium text-white  ${fsClass} rounded-lg flex justify-center`
              : ` md:px-2 lg:px-4 sm:px-2 py-2 rounded-[8px] md:text-base lg:text-base text-[10px] font-medium text-[#4A9D93] ${fsClass}  flex justify-center`
          }
          onClick={() => selecteTab(0)}
        >
          Single Day
        </li>
        <li
          className={
            taskState.selectedIndex === 1
              ? `bg-[#4A9D93] md:px-2 sm:px-2 lg:px-4 py-2 md:text-base lg:text-base text-[10px] font-medium text-white  ${fsClass} rounded-lg flex justify-center`
              : ` md:px-2 lg:px-4 sm:px-2 py-2 rounded-[8px] md:text-base lg:text-base text-[10px] font-medium text-[#4A9D93] ${fsClass}  flex justify-center`
          }
          onClick={() => selecteTab(1)}
        >
          Week Days
        </li>
        <li
          className={
            taskState.selectedIndex === 2
              ? `bg-[#4A9D93] md:px-2 lg:px-4 sm:px-2 py-2 md:text-base lg:text-base text-[10px] font-medium text-white  ${fsClass} rounded-lg flex justify-center`
              : ` md:px-2 lg:px-4 py-2 sm:px-2 rounded-[8px] md:text-base lg:text-base text-[10px] font-medium text-[#4A9D93] ${fsClass}  flex justify-center`
          }
          onClick={() => selecteTab(2)}
        >
          Weekend
        </li>
      </ul>

      <div className="grid grid-cols-7 mt-5 lg:gap-4 md:gap-4 gap-2  items-center bg-[#FEF2F0] text-white px-3 py-3 rounded-[8px]">
        {taskState?.weekdata?.length > 0
          ? taskState?.weekdata?.map((date) => {
            return (
              <button
                disabled={moment().format("YYYY-MM-DD") > moment(date).format("YYYY-MM-DD")}
                className={`${taskState?.SelectedDay?.includes(date)
                  ? "bg-[#F97E6D] text-white"
                  : "bg-white text-black"
                  }  px-2 py-1 rounded-full flex justify-center h-8 w-8 sm:h-9 sm:w-9   md:h-11 md:w-11  lg:h-12 lg:w-12 items-center`}
                onClick={() => handleClickDate(date)}
              >
                <span className="font-semibold text-sm">
                  {moment(date, "YYYY-MM-DD").format("dd").slice(0, 1)}</span>
              </button>
            );
          })
          : ""}
      </div>
    </div>
  );
};

export default TabsCalender;
