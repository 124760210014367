import { Modal } from "antd";
import React from "react";
import { useState } from "react";

import PickedImageFromSystem from "./EditImageSection/PickedImageFromSystem";
import PickedAvatars from "./EditImageSection/PickedAvatars";

const EditImage = ({ profileState, setProfileState, user, looking_for }) => {
  const [editImageState, setEditImageState] = useState({
    selectTab: 0,
    capturedImage: null,
  });

  const handleOk = () => {
    setProfileState((prev) => ({ ...prev, editProfileIsOpen: false }));
  };

  const handleCancel = () => {
    setProfileState((prev) => ({ ...prev, editProfileIsOpen: false }));
  };
  const onSelectTab = (TabNumber) => {
    setEditImageState((prev) => ({ ...prev, selectTab: TabNumber }));
  };



  return (
    <Modal
      open={profileState?.editProfileIsOpen}
      title=""
      onOk={handleOk}
      destroyOnClose
      width={480}
      onCancel={handleCancel}
      footer={false}
    >
      <div className="header text-center font-semibold">Profile Photo</div>
      <div className="tab-btn-group flex justify-between my-2 bg-gray-200 py-1 rounded px-2">
        <span
          className={`text-center w-[50%] py-1 cursor-pointer ${editImageState.selectTab === 0 && "bg-primary rounded text-white"
            }`}
          onClick={() => onSelectTab(0)}
        >
          From Computer
        </span>
        <span
          className={`text-center w-[50%] py-1  cursor-pointer ${editImageState.selectTab === 1 && "bg-primary rounded text-white"
            }`}
          onClick={() => onSelectTab(1)}
        >
          Avatar
        </span>
      </div>

      {editImageState.selectTab === 0 ? (
        <PickedImageFromSystem
          setState={setProfileState}
          state={profileState}
          capturedImage={editImageState?.capturedImage}
          onCapturedImage={(imageSrc) => {
            setEditImageState((prev) => ({ ...prev, capturedImage: imageSrc }));
          }}
          user={user}
          looking_for={looking_for}
        />
      ) : (
        <PickedAvatars setState={setProfileState} user={user} looking_for={looking_for} />
      )}
    </Modal>
  );
};

export default EditImage;
