import React, { useEffect, useState } from 'react'
import { MdKeyboardBackspace } from "react-icons/md";

import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { getWeekData } from '../../../Helpers/constants';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import placeholderImage from "../../../assets/images/placeholderImage.jpg"
import AppButton from '../../../components/Button/AppButton';
import PostItem from './PostItem';
import { useSelector } from 'react-redux';
import { useChallange } from './ChallangesApis';
import PostModal from './PostModal';
import { Popover } from 'antd';
import { useUserProfileModalState } from '../Profile/ProfileApi';
import { IoMdShareAlt } from "react-icons/io";
import ChatUsersModal from './ChatUser/ChatUsersModal';
import InfoModal from './InfoModal';


const ChallangeItem = () => {
    const {
        AuthUser: {
            user: { user },
        },
    } = useSelector((state) => state);
    const { state } = useLocation();

    const [challangeDetail, setChallangeDetail] = useState({
        weekData: [],
        consistancyScore: 0,
        memberList: [],
        Posts: [],
        render: false,
        isOpenPostModal: false,
        isShareModalOpen: false,
        isInfoOpen: false,


    })
    const [postMessage, setPostMessage] = useState('')

    const { getChallangeDetail, commentPost, getChallangesJoinedMember, likePost, checkedChallangeCompleted } = useChallange()

    const { suggestionFreindFollow } = useUserProfileModalState()

    const { id } = useParams();

    const navigate = useNavigate();

    const goBackPage = () => {

        navigate(-1)
    }
    useEffect(() => {
        if (id) {

            getChallangeDetail({ id: user?.id, challenge_id: parseInt(id) }).then((res) => {
                setChallangeDetail((prev) => ({
                    ...prev, weekData: res.data?.dates?.data,
                    consistancyScore: res?.data?.dates?.consistancy_data,
                    Posts: res?.data?.posts || []

                }))
            }).catch((err) => {
                console.log("err")
            })
            getChallangesJoinedMember({ id: user?.id, challenge_id: parseInt(id) }).then((res) => {
                setChallangeDetail((prev) => ({
                    ...prev, memberList: res?.data?.list, render: false

                }))
            })


        }
    }, [challangeDetail?.render, challangeDetail?.isInfoOpen])



    const postLikeHandler = (PostId) => {


        likePost({ user_id: user?.id, challenge_id: parseInt(id), post_id: PostId }).then((res) => {
            if (res) {
                setChallangeDetail((prev) => ({
                    ...prev, render: true

                }))
            }
        }).catch((err) => {
            console.log(err, "err")
        })


    }

    const onCommentOnHandler = (postId, commentMessage) => {


        commentPost({ user_id: user?.id, challenge_id: parseInt(id), post_id: postId, comment: commentMessage, challenge_comment_date: moment().format('YYYY-MM-DD hh:mm:ss') }).then((res) => {
            if (res) {
                setChallangeDetail((prev) => ({
                    ...prev, render: true

                }))
                setPostMessage('')
            }
        }).catch((err) => {
            console.log(err, "err")
        })
    }

    const getCheckIcon = (checkRecord) => {
        if (checkRecord?.challenge_status !== "incompleted") {
            return <svg stroke="currentColor" fill="#18605D" stroke-width="0" viewBox="0 0 512 512" height="1.3em" width="1.3em" xmlns="http://www.w3.org/2000/svg"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"></path></svg>
        } else {
            return <svg stroke="#B0B0B0" fill="#B0B0B0" stroke-width="0" viewBox="0 0 512 512" height="1.3em" width="1.3em" xmlns="http://www.w3.org/2000/svg"><path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200z"></path></svg>
        }
    }

    const doCompleteChallange = () => {
        let data = {
            user_id: user?.id,
            challenge_id: id,
            challenge_completed_date: moment().format("yyyy-MM-DD hh:mm:ss")
        }
        checkedChallangeCompleted(data).then((res) => {
            if (res) {
                setChallangeDetail((prev) => ({
                    ...prev, render: true

                }))
            }
        }).catch((err) => {
            console.log(err, "err");
        })
    }

    const doFollowUser = (user_id) => {
        if (user_id) {
            let data = {
                user_id: user?.id,
                friend_user_id: user_id,
            };
            suggestionFreindFollow(data)
                .then((res) => {
                    if (res.status === 200) {
                        setChallangeDetail((prev) => ({
                            ...prev, render: true

                        }))
                    }
                })
                .catch((err) => {
                    console.error(err);
                });
        }
    }


    return (
        <div className='bg-white px-4 py-4 rounded-[15px]'>
            <div className='flex gap-3 items-center justify-between mb-2 '>
                <div className='flex items-center gap-2'>
                    <span className="backArrow_nest_page" onClick={goBackPage}>
                        <MdKeyboardBackspace className="cursor-pointer" />
                    </span>

                </div>


            </div>

            <div className="grid grid-cols-12 gap-4 ">
                <div className="col-span-12 md:col-span-6 lg:col-span-6   rounded-[15px]">
                    <div className='bg-[#F2F2F2] px-2 py-4 rounded-[15px]'>
                        <div className="challange_name flex justify-between font-semibold truncate text-ellipsis w-full px-2 ">
                            <h2 className='w-[75%] truncate ' >{state?.challenges_title}</h2>
                            <span className='w-[25%] flex justify-end items-end'>
                                <span className='px-2 cursor-pointer' onClick={() => {
                                    setChallangeDetail((prev) => ({ ...prev, isShareModalOpen: true }))
                                }} >

                                    <IoMdShareAlt style={{ fontSize: "20px" }} />
                                </span>
                                <span className='cursor-pointer' onClick={() => {
                                    setChallangeDetail((prev) => ({ ...prev, isInfoOpen: true }))
                                }}>

                                    {/* <Popover placement="leftBottom" content={state?.challenges_desc} style={{ maxWidth: '400px' }}  > */}
                                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="20px" width="20px" xmlns="http://www.w3.org/2000/svg"><path d="M256 76c48.1 0 93.3 18.7 127.3 52.7S436 207.9 436 256s-18.7 93.3-52.7 127.3S304.1 436 256 436c-48.1 0-93.3-18.7-127.3-52.7S76 304.1 76 256s18.7-93.3 52.7-127.3S207.9 76 256 76m0-28C141.1 48 48 141.1 48 256s93.1 208 208 208 208-93.1 208-208S370.9 48 256 48z"></path><path d="M256.7 160c37.5 0 63.3 20.8 63.3 50.7 0 19.8-9.6 33.5-28.1 44.4-17.4 10.1-23.3 17.5-23.3 30.3v7.9h-34.7l-.3-8.6c-1.7-20.6 5.5-33.4 23.6-44 16.9-10.1 24-16.5 24-28.9s-12-21.5-26.9-21.5c-15.1 0-26 9.8-26.8 24.6H192c.7-32.2 24.5-54.9 64.7-54.9zm-26.3 171.4c0-11.5 9.6-20.6 21.4-20.6 11.9 0 21.5 9 21.5 20.6s-9.6 20.6-21.5 20.6-21.4-9-21.4-20.6z"></path></svg>
                                    {/* </Popover> */}
                                </span>
                            </span>
                        </div>
                        <ul className="date-list grid grid-cols-7 lg:gap-6 md:gap-4 gap-1   py-2  ">
                            {challangeDetail?.weekData?.map((item, index) => {

                                return (
                                    <li
                                        key={index + 1}
                                        className={`flex items-center my-1  px-3 md:py-2 lg:py-2 py-1  flex-col rounded text-[16px] cursor-pointer  text-base font-medium text-[#333333] `}
                                    >
                                        {moment(item.date).format("ddd")}
                                        <span className="text-[14px] text-[#545454]">
                                            {moment(item.date).format("DD")}
                                        </span>
                                        <span className='mt-2' onClick={() => {
                                            if (moment().format("yyyy-MM-DD") === item.date) {
                                                doCompleteChallange()
                                            }
                                        }}>{getCheckIcon(item)}</span>
                                    </li>
                                );
                            })}

                        </ul>
                        <div className='flex'>Consistency Score :<span className='text-primary font-semibold mx-2'>{challangeDetail.consistancyScore || 0} Days </span> </div>
                    </div>




                    {/* joined Members */}
                    <div className='bg-[#F2F2F2] px-2 mt-4 py-4 rounded-[15px]'>
                        <h5>Total Members : {challangeDetail.memberList?.length || 0}</h5>
                        <ul className="imge-section  py-3 custom-scrollbar " style={{ height: "40vh", overflowY: "auto" }}>
                            {challangeDetail.memberList?.length > 0 ?
                                challangeDetail.memberList?.map((item, id) => {
                                    let { first_name,
                                        follow_status,
                                        joined_member_id,
                                        last_name,
                                        profile_image,
                                        user_id
                                    } = item;
                                    return (
                                        <li
                                            key={id + 1}
                                            className=" mb-3 p-2 rounded-[8px] flex items-center justify-between relative border-b"
                                        >
                                            <div className="flex items-center cursor-pointer"
                                            //  onClick={() => {
                                            //     if (joined_member_id !== parseInt(user_id)) {
                                            //         navigate(`/Profile/${joined_member_id}`)
                                            //     }
                                            // }}
                                            >
                                                <img
                                                    src={profile_image
                                                        ? `${process.env.REACT_APP_FILE_URL_PROFILE}/${profile_image}` : placeholderImage}
                                                    alt=""
                                                    className="w-[40px] h-[40px] me-2 rounded-full"
                                                />
                                                <div>
                                                    <h5 className="flex flex-col font-semibold">
                                                        {`${first_name} ${last_name}`}
                                                    </h5>
                                                </div>
                                            </div>



                                            <div className="cursor-pointer flex gap-1 items-center ">
                                                {user?.id !== joined_member_id && <AppButton
                                                    btnText={follow_status === 1 ? "Following" : follow_status === 2 ? "Following" : "Follow"}
                                                    disabled={false}
                                                    className={`${follow_status === 1 || follow_status === 2 ? "bg-gray-200 border border-gray-200 " : "bg-primary-dark text-white"}  py-1 px-3 text-sm rounded-[8px]`}
                                                    onClick={() => {
                                                        if (user?.id !== joined_member_id && follow_status === 0) {
                                                            doFollowUser(joined_member_id)
                                                        }
                                                    }}
                                                />}


                                            </div>
                                        </li>
                                    );
                                }) : <li className='text-center text-paragraph pt-5'>Member not found!</li>}
                        </ul>
                    </div>


                </div>
                <div className="col-span-12 md:col-span-6 lg:col-span-6 bg-white  rounded-[15px]">
                    <div onClick={() => setChallangeDetail((prev) => ({
                        ...prev,
                        isOpenPostModal: true
                    }))} className='bg-[#E8EFEF] rounded-[10px] py-2 px-3  flex justify-between text-[#8A8A8A] ' style={{ margin: " 0 5px 10px 5px " }}>Post Something...<span><svg stroke="currentColor" fill="#000000" stroke-width="0" viewBox="0 0 512 512" height="1.3em" width="1.3em" xmlns="http://www.w3.org/2000/svg"><path d="M448 405.333V106.667C448 83.198 428.802 64 405.333 64H106.667C83.198 64 64 83.198 64 106.667v298.666C64 428.802 83.198 448 106.667 448h298.666C428.802 448 448 428.802 448 405.333zM181.333 288l53.334 64 74.666-96 96 128H106.667l74.666-96z"></path></svg></span></div>
                    <div className="all-post">
                        <ul className="list-of-all-post custom-scrollbar" style={{ height: "80vh", overflowY: "auto" }}>
                            {challangeDetail?.Posts?.length > 0 ?
                                challangeDetail?.Posts?.map((post) => {
                                    return (
                                        <PostItem key={post.post_id} post={post} image={true} onLikeHandler={() => postLikeHandler(post?.post_id)}
                                            postMessageHandler={(message) => {
                                                onCommentOnHandler(post?.post_id, message);
                                            }}
                                            onSeePostDetail={() => {
                                                navigate(`/postDetail/${id}`, { state: { ...post, Challange_Id: parseInt(id) } })
                                            }}
                                            postMessage={postMessage} setPostMessage={setPostMessage} />
                                    )
                                }) : <li className='text-center text-paragraph pt-5'> Posts not found!</li>
                            }
                        </ul>
                    </div>


                </div>

                <PostModal challengeParentState={challangeDetail} setChallengeParentState={setChallangeDetail} challengeID={id} user={user} />
                <ChatUsersModal state={challangeDetail} setState={setChallangeDetail} challengeID={id} user={user} stateNew={state} />
                <InfoModal state={challangeDetail} setState={setChallangeDetail} challengeID={id} user={user} stateNew={state} />

            </div>
        </div>
    )
}

export default ChallangeItem