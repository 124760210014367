import React, { useEffect, useRef, useState } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import Sidebar from "./Sidebar";
import Header from "./Header";
import { useDispatch, useSelector } from "react-redux";
import NoficationSidebar from "../pages/admin/Notification/sidebar";
import WishPopups from "../pages/WishPopups/WishPopups";
import Loader from "../components/loader/Loader";
import { isReschduleNotification } from "../Store/Slices/DekstopNotificationSlice";
import moment from "moment";
import { useDahsbord } from "../pages/admin/Dashbord/DashboardApis";
import { ScheduledNotification } from "../Helpers/ScheduleNotifiactions";

const AdminLayout = () => {

  const [activityList, setActivityList] = useState()
  const { AuthUser, rescheduleData: { isReschduleNotificationOpen, taskData, render },
    notification: { isSidebarOpen }, AuthUser: { user: { user } }
  } = useSelector((state) => state);

  const {
    getTaskActivites,
  } = useDahsbord();

  const dispatch = useDispatch();

  const ref = useRef();

  const navigate = useNavigate()

  const [loader, setLoader] = useState(false);
  const notifyUser = async (message, data) => {
    if ("Notification" in window) {
      if (Notification.permission === "granted") {
        const title = "Lakshya";
        const options = { body: message };
        const notification = new Notification(title, options);
        if (data) {
          notification.onclick = () => navigate("/home", { state: data });
        } else {
          notification.onclick = () => navigate("/activity");
        }
      } else if (Notification.permission !== "denied") {
        await Notification.requestPermission();
        notifyUser(message); // Call recursively after obtaining permission
      }
    }
  };


  const fetchTaskActivities = async () => {
    try {
      const res = await getTaskActivites({ id: user?.id, date: moment().format("yyyy-MM-DD") });
      if (res.status === 200) {
        const tasksList = res?.data?.tasks_activities_list;
        setActivityList(tasksList);
      }
    } catch (error) {
      console.log("error", error);
    }
  };








  useEffect(() => {
    const timeouts = [];
    if (activityList?.length > 0) {
      for (const item of activityList) {
        let dateStr = item.task_date + ' ' + item.task_start_time;
        let date = moment(dateStr, 'YYYY-MM-DD hh:mm A').add(-10, 'minutes');
        let timeDifference = date.diff(moment(), 'milliseconds');

        if (date >= moment() && timeDifference > 0) {
          let timeoutId = setTimeout(() => {
            notifyUser(`${item?.task_sub_category_name ?? ''} is about to start in 10 min`, item)
          }, timeDifference);
          timeouts.push(timeoutId);

        }
      }

      let item = activityList[activityList?.length - 1]
      let dateStr = item.task_date + " " + item.sleep_time
      let date10 = moment(dateStr, 'YYYY-MM-DD HH:mm A').add(-10, 'minutes')
      let timeDifferenced10 = date10.diff(moment(), 'milliseconds');
      let date30 = moment(dateStr, 'YYYY-MM-DD HH:mm A').add(-30, 'minutes')
      let timeDifferenced30 = date30.diff(moment(), 'milliseconds');


      if (date10 >= moment() && timeDifferenced10 > 0) {
        let timeoutId = setTimeout(() => {
          notifyUser(`Please update the status of your today's task`)
        }, timeDifferenced10);
        timeouts.push(timeoutId);

      }

      if (timeDifferenced30 >= moment() && timeDifferenced30 > 0) {
        let timeoutId = setTimeout(() => {
          notifyUser(`Please update the status of your today's task`)
        }, timeDifferenced30);
        timeouts.push(timeoutId);

      }
    }
    return () => {
      timeouts.forEach((timeoutId) => clearTimeout(timeoutId));
    };

  }, [activityList])

  useEffect(() => {
    fetchTaskActivities();
  }, [render]);



  useEffect(() => { }, [loader]);

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <div className="w-full h-full flex relative">
          <Sidebar />
          <div className="lg:w-[calc(100%-280px)] md:w-full sm:w-full w-full relative ">
            <Header />
            {isSidebarOpen && <NoficationSidebar />}
            <WishPopups />
            <main className="pb-2 main-wrapper h-full min-h-screen w-full bg-[#f5f6fa] rounded-[15px_0_0_0] p-3 pt-6 sm:p-4 md:p-6 lg:p-7 relative">
              <Outlet context={[loader, setLoader]} />
            </main>
          </div>
        </div>
      )}
    </>
  );
};

export default AdminLayout;
