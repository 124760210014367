import React, { useEffect, useState } from "react";
import { db, database } from "../../../firebase/firebase";
import { collection, getDocs, onSnapshot } from "firebase/firestore";
import { getMessageList, getToUserProfileName } from "../../../firebase/chat";
import placeholderImgae from "../../../assets/images/placeholderImage.jpg";
import moment from "moment";

const ChatList = ({ user, chatParentState, setChatParentState }) => {
  const [chatListData, setChatListData] = useState({
    chatList: [],
  });
  //get awailable user list
  const getAllUserList = () => {
    getMessageList(user?.id).then((res) => {
      if (res.data?.length > 0) {
        // setChatListData((prev) => ({ ...prev, chatList: res.data }));

        const blockedUserIds = chatParentState.blockedUsers.map(
          (blockedUser) => blockedUser.user_id
        );

        // Filter the chat data to exclude blocked users
        const filteredChatData = res?.data?.filter((chat) => {
          const toUserId = chat.to_user.id;
          return !blockedUserIds.includes(toUserId);
        });

        setChatParentState((prev) => ({ ...prev, allUsers: filteredChatData }));
        setChatParentState((prev) => ({
          ...prev,
          globalUsers: filteredChatData,
        }));
        // res?.data?.map((item) => {
        //   if (item?.to_user?.id === chatParentState?.activeUser?.touser?.id) {
        //     setChatParentState((prev) => ({ ...prev, chatUser: item }));
        //   }
        // });
      }
    });
  };




  useEffect(() => {
    // fetch all message list
    getAllUserList();
  }, [
    chatParentState?.activeUser,
    chatParentState?.userMessages,
    chatParentState?.activeUser,

    // chatParentState?.dataChange,
    // chatParentState?.blockedUser,
  ]);

  useEffect(() => {
    const chatCollection = collection(db, "messages"); // Replace 'chat' with your collection name
    const unsubscribe = onSnapshot(chatCollection, (querySnapshot) => {
      const updatedMessages = [];
      querySnapshot.forEach((doc) => {
        updatedMessages.push(doc.data());
      });
      getAllUserList();
      setChatParentState((prev) => ({
        ...prev,
        dataChange: !chatParentState?.dataChange,
      }));
    });

    return () => unsubscribe();
  }, []);

  //set active user here
  const seeUserChat = (chatUser) => {
    setChatParentState((prev) => ({ ...prev, chatUser: chatUser }));
    setChatParentState((prev) => ({ ...prev, activeUser: chatUser }));
  };

  return (
    <div className="user_list pt-6 pb-2">
      <ul
        className=" custom-scrollbar "
        style={{ height: "60vh", overflowY: "auto" }}
      >
        {chatParentState?.allUsers?.map((item, index) => {
          const date = new Date(item?.lastMessageTime?.seconds * 1000);
          return (
            <li
              key={index + 1}
              className={` ${chatParentState?.activeUser?.user_ids === item?.user_ids
                ? "active bg-slate-100"
                : ""
                } cursor-pointer flex mb-2 px-2 py-2 border items-center rounded-[8px]`}
              onClick={() => seeUserChat(item)}
            >
              <img
                src={
                  item?.to_user?.profile_image
                    ? `${process.env.REACT_APP_FILE_URL_PROFILE}/${item?.to_user?.profile_image} `
                    : placeholderImgae
                }
                alt="profile pic"
                className=" w-[40px] h-[40px] rounded-full me-2"
              />
              <div className="overflow-hidden truncate max-full">
                <h4 className="w-full flex justify-between items-center">
                  {getToUserProfileName(user?.id ?? "", item)} -{" "}
                  <span className="text-xs text-paragraph">
                    {item?.lastMessageTime ? moment(date).fromNow() : "-"}
                  </span>
                </h4>
                <p className="text-xs truncate text-paragraph">
                  {item.lastMessageType == "text"
                    ? item?.lastMessageText
                    : item.lastMessageType}
                </p>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default ChatList;
