import React, { useEffect, useState } from "react";
import AppButton from "../../../components/Button/AppButton";
import { useSettings } from "./settingApis";
import { Popconfirm, notification } from "antd";

const BlockedTab = ({ user }) => {
  const { getBlockUsers, onUnblockHandler } = useSettings();

  const [blocktabState, setBlockTabState] = useState({
    blockedUsers: [],
    render: false,
  });
  useEffect(() => {
    getBlockUsers(user?.id)
      .then((res) => {
        if (res.status === 200) {
          setBlockTabState((prev) => ({
            ...prev,
            blockedUsers: res.data,
            render: false,
          }));

        }
      })
      .catch((error) => {

        console.error(error);
      });
  }, [blocktabState?.render]);

  const onunBlockclick = (id) => {
    const data = {
      user_id: user?.id,
      friend_user_id: id,
    };

    onUnblockHandler(data)
      .then((res) => {
        if (res.status) {
          setBlockTabState((prev) => ({
            ...prev,
            render: true,
          }));
          notification["success"]({
            message: "user unblocked successfully",
            style: {
              fontFamily: 'Montserrat, sans-serif',
            },
          })
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div className="mx-4">
      <ul className="blocked-user-list py-3 mt-2">
        {blocktabState?.blockedUsers?.length > 0 ? (
          blocktabState?.blockedUsers?.map((user) => {
            return (
              <li
                key={user}
                className="bg-gray-50 hover:bg-gray-100 py-2 px-2 mb-2 rounded shodow"
              >
                <div className="flex justify-between text-lg">
                  {user?.first_name} {user?.last_name}


                  <AppButton
                    btnText="Unblock"
                    btnType="button"
                    className="bg-primary hover:bg-primary-dark text-white rounded text-sm py-1 px-2"
                    onClick={() => onunBlockclick(user)}
                  />

                </div>
              </li>
            );
          })
        ) : (
          <li className="text-paragraph text-center w-full py-6">
            User not found!
          </li>
        )}
      </ul>
    </div>
  );
};

export default BlockedTab;
